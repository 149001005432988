export const sidebarRoutes = [
    {
        name: "Capacitor Analysis",
        children: [
            {
                name: "Simulation",
                link: "/capacitor-simulation"
            } as SidebarSubCategory,
            {
                name: "Ceramic Aging",
                link: "/ceramic-aging"
            } as SidebarSubCategory,
            {
                name: "Capacitance from Power",
                link: "/capacitance-from-power"
            } as SidebarSubCategory
        ]
    } as SidebarUrlCategory,
    {
        name: "Lifetime Analysis",
        children: [
            {
                name: "KLEM 3.0",
                link: "/film-lifetime"
            } as SidebarSubCategory,
            {
                name: "Aluminum Electrolytics",
                link: ""
            } as SidebarSubCategory
        ]
    } as SidebarUrlCategory,
    {
        name: "Inductor Analysis",
        children: [
            {
                name: "Inductance",
                link: "/inductor-simulation"
            } as SidebarSubCategory,
            {
                name: "Power Loss",
                link: "/inductor-simulation"
            } as SidebarSubCategory,
            {
                name: "Temerature Rise",
                link: "/inductor-simulation"
            } as SidebarSubCategory
        ]
    } as SidebarUrlCategory,
    {
        name: "Worked Examples",
        children: [
            {
                name: "Power Inductor Temperature Rise",
                link: "https://ksim3.kemet.com/share/3e351852ac6d4bcfb687efc1311ab5b1"
            } as SidebarSubCategory,
            {
                name: "Capacitor Temperature Rise",
                link: "https://ksim3.kemet.com/share/791b6855039f44efaa3275b14cf9c87f"
            } as SidebarSubCategory,
            {
                name: "Capacitor ESR Comparison",
                link: "https://ksim3.kemet.com/share/f728f85a234840b6b608743d5e7393d9"
            } as SidebarSubCategory,
            {
                name: "Capacitor DC Bias Comparison",
                link: "https://ksim3.kemet.com/share/93c8ee415f8d4093a0d03e261e848687"
            } as SidebarSubCategory,
            {
                name: "Temperature Bias Effect",
                link: "https://ksim3.kemet.com/share/4cf43c25df784ffb8abe4ab48193d044"
            } as SidebarSubCategory,
            {
                name: "Ceramic Aging",
                link: "https://ksim3.kemet.com/share/5046e8c6f76543b0a9adfed97ac7ea6a"
            } as SidebarSubCategory,
            {
                name: "Wide Bandwidth Decoupling",
                link: "https://ksim3.kemet.com/share/64e611dbe6f7494689a27b5708305036"
            } as SidebarSubCategory
            //{
            //    name: "Life Expectancy Model",
            //    link: "https://ksim3.kemet.com/share/949fa9b636d24f7381bc02af2d553c0c"
            //} as SidebarSubCategory
        ]
    } as SidebarUrlCategory
];