<template>
  <div class="e-share">
    <BaseButton type=":word"
                :disabled="disabled"
                @baseButtonClick="share"
                style="--color-text: #fff;"
    >
      <svg class="ex-icon">
        <use :xlink:href="id"></use>
      </svg>
      &nbsp;{{ title }}
    </BaseButton>
    <span v-if="hash !== ''">
            {{ curUrl }}/share/{{ hash }}
        </span>
  </div>
</template>

<script lang="ts">
import {stringify} from 'flatted';
import {instance} from "@/util/Alerts";
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      title: "Share",
      disabled: false,
      id: "#share",
      hash: "",
      curUrl: window.location.href.split("/", 3).join("/")
    }
  },
  methods: {
    share() {
      const url = "/api/share/save";
      instance.post(url,
          {
            state: stringify(this.$store.state),
            route: this.$router.currentRoute.path
          }
      )
          .then((response) => {
            this.hash = response.data;
            const fullUrl = this.curUrl + "/share/" + response.data;

            if (navigator.clipboard && window.isSecureContext) {
              // navigator.clipboard is available
              navigator.clipboard.writeText(fullUrl)
                  .then(() => {
                    alert('Link copied to clipboard');
                  })
                  .catch((err) => {
                    console.error('Could not copy text: ', err);
                  });
            } else {
              // navigator.clipboard is not available, fall back to previous method
              const el = document.createElement('textarea');
              el.value = fullUrl;
              document.body.appendChild(el);
              el.select();
              document.execCommand('copy');
              document.body.removeChild(el);
              alert('Link copied to clipboard');
            }
          })
          .catch((error) => {
            console.error('An error occurred:', error);
          });
    }
  }
})
</script>

<style lang="postcss">
.ex-icon {
  width: 1em;
  height: 1em;

  color: currentColor;
}

.e-share {
  margin-left: auto;
}
</style>