<template>
  <div class="e-panel:3 l-box:inset2">
    <h2>Environmental Test Data</h2>
    <br />
    <table>
      <thead class="film-static-data">
      <tr class="cap-tr">
        <th title="Temperature">Temperature</th>
        <th title="Relative Humidity">Relative Humidity</th>
        <th title="Voltage">Voltage</th>
        <th title="Lifetime">Lifetime</th>
      </tr>
      </thead>
      <tbody class="cap-tbody">
      <tr class="cap-tr"
          v-for="(value, index) in 6"
          v-if="parts[highlight][`tempStatic${index + 1}`] !== 0
                                && parts[highlight][`relativeHumidityStatic${index + 1}`] !== 0
                                && parts[highlight][`voltageVacStatic${index + 1}`] !== ''
                                && parts[highlight][`lifetimeStatic${index + 1}`] !== '' ">
        <td class="cap-td">{{parts[highlight][`tempStatic${index + 1}`]}}</td>
        <td class="cap-td">{{parts[highlight][`relativeHumidityStatic${index + 1}`]}}</td>
        <td class="cap-td">{{parts[highlight][`voltageVacStatic${index + 1}`]}}</td>
        <td class="cap-td">{{parts[highlight][`lifetimeStatic${index + 1}`]}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('filmLifetimeV2');
import Vue from 'vue';

export default Vue.extend({
  computed: {
    ...mapState([
      'highlight',
      'parts',
    ])
  }
})
</script>

<style scoped>
.film-static-data {
  background-color: lightgray;
}
</style>
