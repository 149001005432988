<template>
    <div style="max-height: 87vh; overflow: auto;">
        <div style="text-align: center;">
            <h1>{{releaseInfo.title}}</h1>
            <h3>{{releaseInfo.description}}</h3>
        </div>

        <br />

        <div style="padding-left: 7px;">
            <div v-for="release in releaseInfo.releases">
                <hr />
                <h2>Version {{release.version}}</h2>
                <h4>Date: {{release.date.substring(0,10)}}</h4>
                <h4 v-if="release.note">Note: {{release.note}}</h4>
                <br />
                <h3>Changes:</h3>
                <ul><li v-for="change in release.changes">{{change}}</li></ul>
                <h3>Issues:</h3>
                <ul><li v-for="issue in release.issues">{{issue}}</li></ul>
                <h3>Backlog:</h3>
                <ul><li v-for="backlog in release.backlog">{{backlog}}</li></ul>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue';
    import { instance } from "@/util/Alerts";
    export default Vue.extend({
        data() {
            return {
                releaseInfo: Object
            }
        },
        mounted() {
            instance.get("/api/release/").then((response) => {
                this.releaseInfo = response.data;
            });
        }
    })
</script>

<style lang="css">
    ul {
        padding-left: 30px;
    }
</style>