<template>
    <div class="ex-export_group">
        <label class="e-label" title="Various filetypes are available for export.">
            Export
        </label>
        <BaseButton title="TODO"
                    v-for="button in buttons" :key="button.title" type=":word"
                    :disabled="button.disabled"
                    @baseButtonClick="clicked(button.title)">
            <svg class="ex-icon">
                <use :xlink:href="button.id"></use>
            </svg>
            &nbsp;{{button.title}}
        </BaseButton>
        <!-- TODO make base modal class that can be extended -->
        <!-- TODO allow ability to close when pressing "esc" -->
        <modal v-if="showCSVModal" @close="showCSVModal = false">
            <h2 slot="header">CSV Export</h2>
            <div slot="body">
                <p>
                    <span v-html="csvPreview" />
                </p>
                <button v-if="csvDownloadEnabled" autofocus @click="csvExport">
                    Download CSV
                </button>
            </div>
            <div slot="footer">
                <p>tip: Press <b>'Esc'</b> to close</p>
            </div>
        </modal>
        <modal v-if="showImageModal" @close="showImageModal = false">
            <h2 slot="header">Image Export</h2>
            <div slot="body">
                <div>
                    <p>
                        Export the currently shown chart.
                    </p>
                    <p>
                        notes:
                        <ul style="margin-left: 2em;">
                            <b>Image export depends on the current size of your browser window.</b>
                            <li>
                                Expand your browser to full screen for best results.
                            </li>
                            <li>
                                You may close the left sidebar for a slight improvement.
                            </li>
                        </ul>
                    </p>
                    <button autofocus @click="imageExport">
                        Download Image
                    </button>
                </div>
            </div>
            <div slot="footer">
                <p>tip: Press <b>'Esc'</b> to close</p>
            </div>
        </modal>
    </div>
</template>

<script lang="ts">
    import * as htmlToImage from 'html-to-image';
    const FileSaver = require('file-saver');
    import { instance } from "@/util/Alerts";
    import Modal from '@/components/Modal.vue';

    import { createNamespacedHelpers } from 'vuex';
    const { mapState, mapGetters, mapActions } = createNamespacedHelpers('calcL');

    import Vue from 'vue';
    export default Vue.extend({
        components: {
            Modal
        },
        props: {
            plotData: Object,
        },
        data() {
            return {
                buttons: {
                    csv: {
                        title: "CSV",
                        disabled: false,
                        id: "#download"
                    },
                    image: {
                        title: "Image",
                        disabled: false,
                        id: "#download"
                    },
                },
                csvPreview: "",
                csvDownloadEnabled: false,
                showCSVModal: false,
                showImageModal: false,
                savedBlob: new Blob,
                contentDisposition: "",
            }
        },
        computed: {
            ...mapState([
                'plotType',
                'parts'
            ]),
            ...mapGetters([
                'bodyArray',
            ]),
        },
        mounted() {
            document.body.addEventListener('keyup', e => {
                if (e.keyCode === 27) {
                    this.showCSVModal = false;
                    this.showImageModal = false;
                }
            })
        },
        methods: {
            clicked(buttonTitle: string) {
                if (buttonTitle === "CSV") {
                    if (this.parts.length == 0) {
                        this.csvPreview = "Please select a part first."
                        this.csvDownloadEnabled = false;
                    }
                    else {
                        this.fetchCSVPreview();
                        this.csvDownloadEnabled = true;
                    }
                    this.showCSVModal = true;
                }
                else if (buttonTitle === "Image") {
                    this.showImageModal = true;
                } else {
                    alert("Magic button pressed.");
                }
            },
            fetchCSVPreview() {
                instance.post("/api/calcL/csv-preview/", { "plotType": this.plotType.select, "requestList": this.bodyArray }).then((response) => {
                    this.csvPreview = response.data
                });
            },
            csvExport() {
                instance.post("/api/calcL/csv/", { "plotType": this.plotType.select, "requestList": this.bodyArray }, { responseType: "blob" }).then((response) => {
                    this.savedBlob = new Blob([response.data], { type: response.data.type });
                    const fileName = this.plotType.select + ".csv"
                    this.downloadBlob(this.savedBlob, fileName);
                    this.showCSVModal = false;
                });
            },
            imageExport() {
                const fileName = `${this.plotType.select}.png`;
                htmlToImage.toBlob(document.getElementById('base-chart-id') as HTMLElement, {backgroundColor: "white"})
                    .then(function (blob) {
                        FileSaver.saveAs(blob, fileName);
                    });               
            },
            downloadBlob(blob: Blob, fileName: string) {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url); //TODO: use in image export?
            },
        },
        watch: {
        }
    })
</script>

<style scoped>
    .e-label {
        display: inline-block;
        margin-right: 1rem;
    }

    .ex-export_group {
        position: relative;
        display: flex;
        align-items: baseline;
        margin-top: auto;
        padding-top: 1rem;
        &::before

    {
        content: '';
        position: absolute;
        top: 0;
        left: -1.5rem;
        width: calc(100% + 3rem);
        height: 1px;
        background-color: var(--color-gray-lightest);
    }

    }

    .ex-icon {
        width: 1em;
        height: 1em;
        color: currentColor;
    }
</style>