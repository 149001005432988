<template>
    <div>
        <div class="l-inline"
             v-for="(profile, index) in profileList"
             :key="'profile'+index+componentKey">

            <div class="e-panel:3 l-box:inset2 klem-profile">
                <div class="profileErrorMessage" v-if="profilesData && profilesData.length > 0 && profilesData[highlight].length > 0 && profilesData[highlight][index]  > 0 && profilesData[highlight][index].operatingTemperature > parts[highlight].maxTemp">
                    Warning: The operating temperature is higher than the max temperature
                </div>
                <div class="profileErrorMessage" v-if="profilesData && profilesData.length > 0 && profilesData[highlight].length > 0 && profilesData[highlight][index].isMaxOperatingVoltageFlagOn && profilesData[highlight][index] && profile.voltageDC.select !== 0">
                    Warning: The input voltage is exceeding the performance data of the datasheet for these conditions
                </div>
                <div class="profileErrorMessage" v-if="profilesData && profilesData.length > 0 && profilesData[highlight].length > 0">
                    {{profilesData[highlight][index].isImprobableAirClimateMessage}}
                </div>
                <div class="l-inline" style="--loc-inline-spacing: 1rem;">
                    <div class="profile-header">
                        <h3 style="color: rgba(63,72,204,1)">
                            Profile {{ index+1 }}
                        </h3>
                        <div class="display-on-mobile add-remove-profile">
                            <div class="l-inline:center l-box:squish-1"
                                 @click="duplicateProfile(index)">
                                <span class="e-button:word cap-duplicate"
                                      css-icon
                                      title="Duplicate Profile">
                                    +
                                </span>
                            </div>
                            <div class="l-inline:center l-box:squish-1"
                                 @click="removeProfile(index)">
                                <span class="e-button:word cap-remove"
                                      css-icon
                                      title="Remove Profile (note: Operating Percentage is added to above profile).">
                                    &times;
                                </span>
                            </div>
                        </div>
                    </div>
                    <!--Inputs-->
                    <BaseFilmInputNumber v-for="input in profile"
                                         v-if="(input.title && isVac && input.title !== 'Vdc (V)') || (input.title && !isVac && input.title !== 'Fund. Freq. (Hz)' && input.title !== 'Vac (V)')"
                                         :key="input.title"
                                         :title="input.title"
                                         :width="input.width"
                                         :min="input.min"
                                         :max="input.max"
                                         :step="input.step"
                                         :disabled="input.disabled"
                                         :value="input.select"
                                         @change="(value) => {
                                            input.select = value;
                                            debouncedFetchLifetime()
                                         }"
                                         :isRequired="input.isRequired" />
                    <!--Output: Lifetime Per Profile-->
                    <div v-if="workingTimeValid && voltageValid && tempValid && relativeHumidityValid" class="hide-on-mobile">
                        <div v-if="(profilesData.length > 0) && (profilesData.length === parts.length) && (highlight <= profilesData.length - 1)">
                            Time per
                            <br />
                            Profile:
                            <h4 class="errorMessage" v-if="errorFetchingData">Error</h4>
                            <h4 v-if="!errorFetchingData && profilesData && profilesData[highlight] && profilesData[highlight][index]">
                                {{ profilesData[highlight][index].profileLifetime | precision(0) | commas }}
                            </h4>
                        </div>
                    </div>

                    <!--Error Indication-->
                    <div v-else-if="!workingTimeValid && voltageValid && tempValid && relativeHumidityValid" style="color: red">
                        Check Working Time
                    </div>
                    <div v-else-if="workingTimeValid && tempValid && relativeHumidityValid && ((isVac && profile.voltage.select > profile.voltage.max) || !isVac && profile.voltage.select > profile.voltageDC.max)" style="color: red">
                        Check Voltage
                    </div>
                    <div v-else-if="workingTimeValid && voltageValid && relativeHumidityValid && profile.tempAmbient.select > parts[index].maxTemp" style="color: red">
                        Check Temperature
                    </div>
                    <div v-else-if="workingTimeValid && voltageValid && tempValid && profile.relativeHumidity.select > 100.0" style="color: red">
                        Check Rel. Humid.
                    </div>

                    <!--Duplicate and Remove Profile-->
                    <div class="hide-on-mobile">
                        <div class="l-inline:center l-box:squish-1"
                             @click="duplicateProfile(index)">
                            <span class="e-button:word cap-duplicate"
                                  css-icon
                                  title="Duplicate Profile">
                                +
                            </span>
                        </div>
                        <div class="l-inline:center l-box:squish-1"
                             @click="removeProfile(index)">
                            <span class="e-button:word cap-remove"
                                  css-icon
                                  title="Remove Profile (note: Operating Percentage is added to above profile).">
                                &times;
                            </span>
                        </div>
                    </div>
                </div>
                <div class="overlay-frequency-section">
                    <div v-for="(input) in profile" v-if="input.length > 0" :key="index">
                        <div v-if="!isVac" class="overlay-frequency-row">
                            <BaseInputNumberHorizontal v-for="info in profile.overlayVac" :key="info.title"
                                                       :title="info.title + ':'"
                                                       :width="info.width"
                                                       :profileIndex="index"
                                                       :min="info.min"
                                                       :max="calculateOverlayVacMaxValue(index, info.select, info.max, info.title)"
                                                       :step="info.step"
                                                       :disabled="info.disabled"
                                                       :value="info.select"
                                                       @change="(value) => {info.select = value; debouncedFetchLifetime()}" />
                        </div>
                        <div v-for="(subset, i) in input" class="current-frequency-row">
                            <BaseInputNumberHorizontal v-for="info in subset" :key="info.title+i"
                                                       :title="info.title + ' ' + (i+1) +':'"
                                                       :width="info.width"
                                                       :min="info.min"
                                                       :max="info.max"
                                                       :step="info.step"
                                                       :disabled="info.disabled"
                                                       :value="info.select"
                                                       @change="(value) => {info.select = value; debouncedFetchLifetime()}" />
                            <!--Duplicate and Remove Current / Frequency Row-->
                            <div>
                                <div v-if="i === 0" class="l-inline:center l-box:squish-1"
                                     @click="addCurrFreRow([index, i])">
                                    <span class="e-button:word cap-duplicate"
                                          css-icon
                                          title="Add Current Frequency Row">
                                        +
                                    </span>
                                </div>
                                <div v-else class="l-inline:center l-box:squish-1"
                                     @click="removeCurrFreRow([index, i])">
                                    <span class="e-button:word cap-remove"
                                          css-icon
                                          title="Remove Current Frequency Row">
                                        &times;
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="hot-spot-data hide-on-mobile">
                        <label>Hot Spot Temperature:</label>
                        <h4 v-if="profilesData && profilesData.length > 0 && profilesData[highlight].length > 0 && !(profilesData[highlight][index].operatingTemperature > parts[highlight].maxTemp)">{{profilesData[highlight][index].hotSpotTemperature}} °C</h4>
                        <div class="profileErrorMessage" v-else>Warning: Max. rated temperature exceeded</div>
                        <label>Self-Heating:</label>
                        <h4 v-if="profilesData && profilesData.length > 0 && profilesData[highlight].length > 0 && !profilesData[highlight][index].isSelfHeatingAboveMax">{{profilesData[highlight][index].selfHeating}} °C</h4>
                        <div class="profileErrorMessage" v-else>Warning: Max. self-heating exceeded</div>
                    </div>
                </div>
                <div class="hot-spot-data display-on-mobile">
                    <label>Hot Spot Temperature:</label>
                    <h4 v-if="profilesData && profilesData.length > 0 && profilesData[highlight].length > 0 && !(profilesData[highlight][index].operatingTemperature > parts[highlight].maxTemp)">{{profilesData[highlight][index].hotSpotTemperature}} °C</h4>
                    <div class="profileErrorMessage" v-else>Warning: Max. rated temperature exceeded</div>
                    <label>Self-Heating:</label>
                    <h4 v-if="profilesData && profilesData.length > 0 && profilesData[highlight].length > 0 && !profilesData[highlight][index].isSelfHeatingAboveMax">{{profilesData[highlight][index].selfHeating}} °C</h4>
                    <div class="profileErrorMessage" v-else>Warning: Max. self-heating exceeded</div>
                </div>
                <div v-if="workingTimeValid && voltageValid && tempValid && relativeHumidityValid" class="display-on-mobile">
                    <div v-if="(profilesData.length > 0) && (profilesData.length === parts.length) && (highlight <= profilesData.length - 1)">
                        Time per Profile:
                        <h4 class="errorMessage" v-if="errorFetchingData">Error</h4>
                        <h4 v-if="!errorFetchingData && profilesData && profilesData[highlight] && profilesData[highlight][index]">
                            {{ profilesData[highlight][index].profileLifetime | precision(0) | commas }}
                        </h4>
                    </div>
                </div>
                <div class="displayFilmCharts">
                    <BaseButton type=":main" @baseButtonClick="displayCharts(index)">{{selectedProfile == index ? 'Hide Charts' : 'Display Charts'}}</BaseButton>
                </div>
            </div>
            <div v-if="selectedProfile === index" class="klem-charts">
                <div>
                    <div class="currentFreqChart">
                        <canvas class="lifetimeCharts" :id="'lifetimeFrequencyChart' + index"></canvas>
                    </div>
                    <p v-show="profilesData.length > 0 && profilesData[highlight][index].lifetimeVsTemperaturePlotPoints.absoluteHumidityGreaterThan60" class="chartErrorMessage">*The graph calculation has been stopped before {{parts[highlight].maxTemp}}°C, because it becomes an improbable climate in air</p>
                </div>
                <div class="currentFreqChart">
                    <canvas class="lifetimeCharts" :id="'lifetimeVoltageChart' + index"></canvas>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    //External Utilities
    import debounce from 'lodash.debounce';
    import Chart from "chart.js";

    import { chartMixin } from "@/mixins/chartMixin";

    import { createNamespacedHelpers } from 'vuex';
    const { mapState, mapGetters, mapActions } = createNamespacedHelpers('filmLifetimeV2');


    import Vue from 'vue';
    import BaseFilmInputNumber from "@/components/_base/base-film-input-number.vue";
    import BaseInputNumberHorizontal from "@/components/_base/base-input-number-horizontal.vue";
    Chart.Tooltip.positioners.cursor = function (elements, position) { return { x: position.x, y: (position.y >= 0) ? position.y : 0 } };
    export default Vue.extend({
        components: {
            BaseInputNumberHorizontal,
            BaseFilmInputNumber
        },
        mixins: [chartMixin],
        data() {
            return {
                debouncedFetchLifetime: debounce((this as any).fetchLifetimeV2, 200),
                selectedProfile: -1
            };
        },
        filters: {
            precision: function (value: string, digits: number) {
                let n = parseFloat(value).toFixed(digits);
                if (n != n) { // check if NaN
                    return '0'
                }
                return n
            },
            commas: function (value: string) {
                let returnString = value;
                let indexOfDecimal = returnString.indexOf(".");
                let startIndex = -1;

                if (indexOfDecimal === -1) // no decimals
                    startIndex = returnString.length - 3;
                else
                    startIndex = indexOfDecimal - 3;

                for (let i = startIndex; i > 0; i = i - 3) {
                    returnString = returnString.slice(0, i) + "," + returnString.slice(i);
                }

                return returnString;
            }
        },
        computed: {
            ...mapState([
                'componentKey',
                'highlight',
                'lifetimeHoursTotal',
                'parts',
                'profilesData',
                'profileList',
                'errorFetchingData',
                'isVac'
            ]),
            ...mapGetters([
                'relativeHumidityValid',
                'tempValid',
                'voltageValid',
                'workingTimeValid'
            ]),
            ...mapActions([
                'setPlotOptions'
            ])
        },
        updated() {
            this.$nextTick(function () {
                this.setLifetimeVoltageChart(this.selectedProfile);
                this.setLiftetimeTemperatureChart(this.selectedProfile);
            })
        },
        methods: {
            ...mapActions([
                'addCurrentFrequencyRow',
                'duplicateProfile',
                'fetchLifetimeV2',
                'incrementComponentKey',
                'removeProfile',
                'removeCurrentFrequencyRow',
                'setProfileMaxVoltage',
                'setProfileMaxVoltageDC',
                'setProfileMinTemp',
                'setProfileMaxTemp'
            ]),
            addCurrFreRow(index, i) {
                this.addCurrentFrequencyRow(index, i);
                this.fetchLifetimeV2();
            },
            removeCurrFreRow(index, i) {
                this.removeCurrentFrequencyRow(index, i);
                this.fetchLifetimeV2();
            },
            calculateOverlayVacMaxValue(profileIndex, value, maxValue, title) {
                if (title === "Overlay Vac (V)") {
                    const selectedVoltageDC = this.profileList[profileIndex].voltageDC.select;
                    return Math.min((this.parts[this.highlight].vdc - selectedVoltageDC) / Math.sqrt(2), selectedVoltageDC / Math.sqrt(2));
                } else {
                    return maxValue
                }
            },
            displayCharts(index) {
                if (this.selectedProfile === index) this.selectedProfile = -1;
                else this.selectedProfile = index;
            },
            setLiftetimeTemperatureChart(profileIndex) {
                if (profileIndex === -1) return;
                if (profileIndex === this.selectedProfile) {
                    let id = "lifetimeFrequencyChart" + profileIndex;
                    /*let canvas = <HTMLCanvasElement> document.getElementById(id);*/
                    /* const ctx = canvas.getContext('2d');*/
                    //@ts-ignore
                    const ctx = document.getElementById(id).getContext('2d');
                    const lifetimeFrequencyChart = new Chart(ctx!, {
                        type: 'line',
                        data: {
                            datasets: [
                                {
                                    borderColor: "rgba(240,181,16,0.7)",
                                    borderWidth: 3,
                                    data: this.profilesData[this.highlight][profileIndex].lifetimeVsTemperaturePlotPoints.points,
                                    pointStyle: 'circle',
                                    radius: 0,
                                    fill: false,
                                    label: `${this.profilesData[this.highlight][profileIndex].operatingRelativeHumidity}% R.H., ${this.isVac ? this.profileList[profileIndex].voltage.select : this.profileList[profileIndex].voltageDC.select} ${this.isVac ? 'VAC' : 'VDC'}`,
                                    showLine: true
                                }
                            ]
                        } as Chart.ChartData,
                        options: {
                            animation: {
                                duration: 0
                            },
                            onClick: (evt, item) => {
                            },
                            events: ['click'],
                            hover: {
                                animationDuration: 0,
                            },
                            title: {
                                display: true,
                                fontFamily: "sans-serif",
                                fontSize: 17,
                                position: "top",
                                text: "Lifetime (hrs) vs. Hot Spot Temperature at Fixed Relative Humidity and Voltage",
                            },
                            legend: {
                                display: true,
                                position: 'bottom',
                                labels: {
                                    usePointStyle: false
                                },
                            },
                            responsive: true,
                            maintainAspectRatio: false,
                            scales: {
                                xAxes: [
                                    {
                                        display: true,
                                        type: "linear",
                                        position: "bottom",
                                        scaleLabel: {
                                            display: true,
                                            labelString: "Temp",
                                            fontColor: "black",
                                            fontFamily: "sans-serif",
                                            fontSize: 16,
                                        },
                                        ticks: {
                                            callback: (value: number, index: number, arr: number[]) => {
                                                return value;
                                            },
                                        }
                                    }
                                ],
                                yAxes: [
                                    {
                                        display: true,
                                        type: "linear",
                                        position: "left",
                                        scaleLabel: {
                                            display: true,
                                            labelString: "Lifetime h",
                                            fontColor: "black",
                                            fontFamily: "sans-serif",
                                            fontSize: 16,
                                        },
                                        gridLines: {
                                            color: "light-grey",
                                        },
                                        ticks: {
                                            callback: (value: number, index: number, arr: number[]) => {
                                                return Math.round(value);
                                            },
                                        }
                                    }
                                ]
                            },
                            tooltips: {
                                enabled: false,
                                intersect: false, //show the tooltip even if not directly on a point
                                position: "fixedTopLeft",

                            },
                            plugins: {
                                crosshair: false
                            },
                        },
                    });
                }
            },
            setLifetimeVoltageChart(profileIndex) {
                if (profileIndex === -1) return;
                if (profileIndex === this.selectedProfile) {
                    let id = "lifetimeVoltageChart" + profileIndex;
                    //@ts-ignore
                    const lifetimeVoltageContext = document.getElementById(id).getContext('2d');
                    const lifetimeVoltageChart = new Chart(lifetimeVoltageContext!, {

                        type: 'line',
                        data: {
                            datasets: [
                                {
                                    borderColor: "rgba(240,181,16,0.7)",
                                    borderWidth: 3,
                                    data: this.profilesData[this.highlight][profileIndex].lifetimeVsVoltagePlotPoints,
                                    pointStyle: 'circle',
                                    radius: 0,
                                    fill: false,
                                    label: `${this.profilesData[this.highlight][profileIndex].operatingRelativeHumidity}% R.H., ${this.profilesData[this.highlight][profileIndex].hotSpotTemperature} °C`,
                                    showLine: true,
                                }
                            ]
                        } as Chart.ChartData,
                        options: {
                            animation: {
                                duration: 0
                            },
                            events: ['click'],
                            onClick: (evt, item) => {
                            },
                            hover: {
                                animationDuration: 0,
                            },
                            title: {
                                display: true,
                                fontFamily: "sans-serif",
                                fontSize: 17,
                                position: "top",
                                text: "Lifetime (hrs) vs. Voltage at Fixed Relative Humidity and Hot Spot Temperature",
                            },
                            legend: {
                                display: true,
                                position: 'bottom',

                                labels: {
                                    usePointStyle: false
                                }
                            },
                            responsive: true,
                            maintainAspectRatio: false,
                            scales: {
                                xAxes: [
                                    {
                                        display: true,
                                        type: "linear",
                                        position: "bottom",
                                        scaleLabel: {
                                            display: true,
                                            labelString: "Voltage",
                                            fontColor: "black",
                                            fontFamily: "sans-serif",
                                            fontSize: 16,
                                        },
                                        ticks: {
                                            callback: (value: number, index: number, arr: number[]) => {
                                                return value;
                                            },
                                        }
                                    }
                                ],
                                yAxes: [
                                    {
                                        display: true,
                                        type: "linear",
                                        position: "left",
                                        scaleLabel: {
                                            display: true,
                                            labelString: "Lifetime h",
                                            fontColor: "black",
                                            fontFamily: "sans-serif",
                                            fontSize: 16,
                                        },
                                        gridLines: {
                                            color: "light-grey",
                                        },
                                        ticks: {
                                            callback: (value: number, index: number, arr: number[]) => {
                                                return value;
                                            },
                                        }
                                    }
                                ]
                            },
                            tooltips: {
                                enabled: false,
                                intersect: false, //show the tooltip even if not directly on a point
                                position: "fixedTopLeft",
                            },
                            plugins: {
                                crosshair: false,
                            },
                        },
                    });
                }
            },
            async setupChart(index): Promise<void> {
                this.setLiftetimeTemperatureChart(index);
                this.setLifetimeVoltageChart(index);
            },
            updateProfileMinMax() {
                //this.setProfileMaxVoltage(this.parts[this.highlight].vac);
                //this.setProfileMaxVoltageDC(this.parts[this.highlight].vdc);
                this.setProfileMinTemp(this.parts[this.highlight].maxTemp);
                this.setProfileMinTemp(this.parts[this.highlight].minTemp);
            }
        },
        watch: {
            "highlight": {
                handler: function () {
                    this.updateProfileMinMax();
                }
            },
            profileList: {
                handler: function () {
                    if (this.parts.length > 0) {
                        /* this.debouncedFetchLifetime();*/
                    }
                },
                deep: true,
                immediate: false
            },
        }
    })
</script>

<style scoped>
    .chartErrorMessage {
        color: red;
        font-style: italic;
        text-align: center;
    }

    .klem-charts {
        display: flex;
    }

    .overlay-frequency-section {
        display: flex;
        justify-content: center;
    }

    .currentFreqChart {
        flex-grow: 1;
        margin: 10px 50px;
    }

    .hot-spot-data {
        margin-left: 20px;
    }

        .hot-spot-data p {
            line-height: normal;
            padding: 0px;
            margin: 0px;
        }

    .current-frequency-row {
        margin-left: -100px;
        margin-top: -10px;
        display: flex;
        justify-content: center;
    }

    .overlay-frequency-row {
        margin-right: 160px;
        margin-top: -10px;
        display: flex;
        justify-content: center;
    }

    .lifetimeCharts {
        min-width: 30vw;
        flex-grow: 1;
    }

    .film-static-data {
        background-color: gray;
    }

    .displayFilmCharts {
        display: flex;
        justify-content: flex-end;
    }

    .profileErrorMessage {
        color: red;
        font-style: italic;
        margin-bottom: 10px;
    }

    .errorMessage {
        color: red
    }

    @media screen and (max-width: 1205px) {
        .current-frequency-row {
            margin-left: 0px;
            margin-bottom:10px;
        }
        .hot-spot-data {
            margin-left: 0px;
        }


        .baseInputNumber .e-input {
            width: 100vw;
        }
        .klem-charts {
            display: block;
            overflow-x: auto;
        }
        .currentFreqChart {
            min-height: 30vh;
        }
    }
</style>
