const initialSeedState = {
    displayForm: false,
    confirmResetSeedPageRequired: false,
    resetSeedPage: false,
    displayDeleteModal: false,
    componentKey: 0,
    highlight: 0,
    parts: [
        {
            id: 0,
            instances: 0,
            capDisplay: "",
            capValue: 0.0,
            capType: "",
            dielectric: "-",
            kemetPn: "Combined",
            basePn: "Combined",
            maxTemp: 0,
            series: "",
            appDef: "",
            spicePn: "Combined",
            voltageRating: 0, //TODO: calculate based on minimum of all parts. perfect for getter
            ceProps: [] as UpdatedCapCeProps,
            filmProps: {} as FilmProps,
            hidden: false,
            //Parameters used in CalcZ
            param: {
                qty: {
                    title: "Qty",
                    select: 1,
                    min: 1,
                    max: 10,
                    step: 1,
                    disabled: true,
                    width: 4,
                } as InputNumber,
                bias: {
                    title: "Bias (V)",
                    select: 0,
                    min: 0,
                    max: 100, // TODO: same as voltage rating above
                    step: 1,
                    disabled: false,
                    width: 6,
                } as InputNumber,
                tempAmbient: {
                    title: "Amb. (°C)",
                    select: 25,
                    min: -55,
                    max: 155, // TODO: needs to be calculated, make a getter?
                    step: 1,
                    disabled: false,
                    width: 6,
                } as InputNumber,
            } as CapParam,
            //Parameter not used in CalcZ
            tolerance: {
                title: "Tolerance",
                select: "",
                disabled: true,
                options: [] as BaseDropdownOption[],
            } as InputDropdown,
            seedProps: [] as SeedProp[],
            displayAllCeProps: false,
            tcc: "",
            vcac: "",
        }
    ],
    //Keeping track of which chart legend icons have been used
    partStyleApplied: [0],
    partStyleAvailable: [19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1],
    //Shared parameters displayed up top
    shared: {
        dropdowns: {
            minFreq: {
                title: "Start",
                options: [
                    {
                        label: "100 Hz",
                        value: "100",
                        disabled: false,
                    } as BaseDropdownOption,
                    {
                        label: "1 kHz",
                        value: "1000",
                        disabled: false,
                    } as BaseDropdownOption,
                    {
                        label: "10 kHz",
                        value: "10000",
                        disabled: false,
                    } as BaseDropdownOption,
                    {
                        label: "100 kHz",
                        value: "100000",
                        disabled: false,
                    } as BaseDropdownOption,
                    {
                        label: "1 MHz",
                        value: "1000000",
                        disabled: false,
                    } as BaseDropdownOption,
                    {
                        label: "10 MHz",
                        value: "10000000",
                        disabled: false,
                    } as BaseDropdownOption,
                    {
                        label: "100 MHz",
                        value: "100000000",
                        disabled: false,
                    } as BaseDropdownOption,
                    {
                        label: "1 GHz",
                        value: "1000000000",
                        disabled: false,
                    } as BaseDropdownOption
                ],
                select: "100",
                filmSelect: "100",
                disabled: false,
                hidden: false,
            },
            maxFreq: {
                title: "End",
                options: [
                    {
                        label: "1 kHz",
                        value: "1000",
                        disabled: false,
                    } as BaseDropdownOption,
                    {
                        label: "10 kHz",
                        value: "10000",
                        disabled: false,
                    } as BaseDropdownOption,
                    {
                        label: "100 kHz",
                        value: "100000",
                        disabled: false,
                    } as BaseDropdownOption,
                    {
                        label: "1 MHz",
                        value: "1000000",
                        disabled: false,
                    } as BaseDropdownOption,
                    {
                        label: "10 MHz",
                        value: "10000000",
                        disabled: false,
                    } as BaseDropdownOption,
                    {
                        label: "100 MHz",
                        value: "100000000",
                        disabled: false,
                    } as BaseDropdownOption,
                    {
                        label: "1 GHz",
                        value: "1000000000",
                        disabled: false,
                    } as BaseDropdownOption,
                    {
                        label: "10 GHz",
                        value: "10000000000",
                        disabled: false,
                    } as BaseDropdownOption
                ],
                select: "10000000000",
                filmSelect: "10000000",
                disabled: false,
                hidden: false,
            }
        },
        inputs: {
            tempRise: {
                title: "Temp. Rise (°C)",
                select: 20,
                min: 1,
                max: 100,
                step: 1,
                disabled: false,
                hidden: false,
                width: 10
            } as InputNumber,
            res: {
                title: "Trace Res. (Ω)",
                select: 0,
                min: 0,
                max: 1000,
                step: 1,
                disabled: false,
                hidden: false,
                width: 10
            } as InputNumber,
            ind: {
                title: "Trace Ind. (nH)",
                select: 0,
                min: 0,
                max: 10000,
                step: 1,
                disabled: false,
                hidden: false,
                width: 10
            } as InputNumber,
            spiceFreq: {
                title: "Center Frequency",
                select: 10000,
                min: 100,
                max: 10000000000,
                step: 1,
                disabled: false,
                hidden: false,
                width: 16
            } as InputNumber,
            freqList: [
                {
                    title: "1",
                    select: 10000,
                    min: 100,
                    max: 10000000000,
                    step: 1,
                    disabled: false,
                    hidden: false,
                    width: 10,
                } as InputNumber,
                {
                    title: "2",
                    select: 50000,
                    min: 100,
                    max: 10000000000,
                    step: 1,
                    disabled: false,
                    hidden: false,
                    width: 10,
                } as InputNumber,
                {
                    title: "3",
                    select: 100000,
                    min: 100,
                    max: 10000000000,
                    step: 1,
                    disabled: false,
                    hidden: false,
                    width: 10,
                } as InputNumber,
                {
                    title: "4",
                    select: 500000,
                    min: 100,
                    max: 10000000000,
                    step: 1,
                    disabled: false,
                    hidden: false,
                    width: 11,
                } as InputNumber,
                {
                    title: "5",
                    select: 1000000,
                    min: 100,
                    max: 10000000000,
                    step: 1,
                    disabled: false,
                    hidden: false,
                    width: 11,
                } as InputNumber,
                {
                    title: "6",
                    select: 5000000,
                    min: 100,
                    max: 10000000000,
                    step: 1,
                    disabled: false,
                    hidden: false,
                    width: 11,
                } as InputNumber,
                {
                    title: "7",
                    select: 10000000,
                    min: 100,
                    max: 10000000000,
                    step: 1,
                    disabled: false,
                    hidden: false,
                    width: 11,
                } as InputNumber,
            ] as InputNumber[],
        },
        radios: {
            calculated: {
                title: "Calculated",
                options: ["Yes", "No"],
                select: "No",
                disabled: false,
                hidden: false
            } as InputRadio,
            combine: {
                title: "Combined",
                options: ["Yes", "No"],
                select: "No",
                disabled: false,
                hidden: false
            } as InputRadio,
            path: {
                title: "Path",
                options: ["Shunt", "Series"],
                select: "Shunt",
                disabled: false,
                hidden: false
            } as InputRadio,
            line: {
                title: "Line (Ω)",
                options: ["50", "75"],
                select: "50",
                disabled: false,
                hidden: false
            } as InputRadio,
            spiceFileFormat: {
                title: "File Format",
                options: ["CKT", "TXT", "DAT", "PRN", "CIR"],
                select: "CKT",
                disabled: false,
                hidden: false
            } as InputRadio,
        }
    }
} as SeedState;

export default initialSeedState;
