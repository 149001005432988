//// Front-end
// Query Parameter
export const ksimPnParam = "https://ksim3.kemet.com/?pn=";


//// Internal
// Plots
export const plotUrl = "plot";

export const pnUrl = "pn";
export const inductanceUrl = "inductance";
export const pacUrl="pac"
export const pacSeriesUrl = "pac/series"
// Film Lifetime
export const filmLifetime2BaseUrl = "/api/filmLifetime/";

// Logging
//TODO move all logging to backend
export const logPartUrl = "/log/part";
export const logSimulationUrl = "/log/simulation";

// ComponentEdge
export const ceParametersUrl = "/api/ce/parameters";


//// External
// ActiveDirectory
export const logoutRedirectUri = "https://ksim.kemet.com";
export const logoutUrl = `https://login.microsoftonline.com/1d536085-536e-4af5-949b-91c7481bb6a3/oauth2/v2.0/logout?post_logout_redirect_uri=${logoutRedirectUri}`;

// PDFs
//export const specUrl = "https://api.kemet.com/component-edge/download/specsheet";
export const CD_URL_BASE_PATH = "https://search.kemet.com/component-documentation/download/"
export const SPEC = "specsheet/"
export const DATASHEET = "datasheet/"
export const STEP = "step/"
export const ROHS = "rohs/"

// KEMET Main Website Part Search (when clicking on Part Number)
export const partDetailSearchUrl = "https://www.kemet.com/en/us/search.html?q=";
