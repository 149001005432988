const chartDefaults = {
    data: {
        datasets: [
            //yellow KSIM letters
            {
                backgroundColor: "rgba(0,73,144,1)",
                borderColor: "rgba(240,181,16,0.7)",
                borderWidth: 3,
                data: [
                    //K
                    { x: 20, y: 80 },
                    { x: 20, y: 20 },
                    { x: 20, y: 50 },
                    { x: 40, y: 80 },
                    { x: 20, y: 50 },
                    { x: 40, y: 20 },
                    //S
                    { x: 58, y: 20 },
                    { x: 71, y: 40 },
                    { x: 45, y: 60 },
                    { x: 68, y: 80 },
                    //I
                    { x: 78, y: 80 },
                    { x: 102, y: 80 },
                    { x: 90, y: 80 },
                    { x: 90, y: 20 },
                    { x: 78, y: 20 },
                    { x: 90, y: 20 },
                    { x: 90, y: 80 },
                    { x: 102, y: 80 },
                    { x: 87, y: 80 },
                    { x: 87, y: 20 },
                    { x: 78, y: 20 },
                    { x: 90, y: 20 },
                    { x: 90, y: 80 },
                    { x: 102, y: 80 },
                    { x: 93, y: 80 },
                    { x: 93, y: 20 },
                    { x: 78, y: 20 },
                    { x: 102, y: 20 },
                    //M
                    { x: 110, y: 20 },
                    { x: 117, y: 80 },
                    { x: 125, y: 20 },
                    { x: 132, y: 80 },
                    { x: 140, y: 20 }
                ],
                fill: false,
                label: "",
                showLine: true,
            }
        ]
    } as Chart.ChartData,
    options: {
        animation: {
            duration: 0
        },
        //events: ['mousemove', 'mouseout', 'mouseup', 'click', 'touchstart', 'touchmove'],
        events: ['mousemove', 'click'],
        //events: ['click'],
        //onHover: null,
        onClick: () => {
        },
        hover: {
            animationDuration: 0,
        },
        title: {
            display: false,
            fontFamily: "sans-serif",
            fontSize: 17,
            position: "top",
            text: "",
        },
        legend: {
            display: false,
            labels: {
                usePointStyle: false // show circles, triangles, squares...
            }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            xAxes: [
                {
                    display: true,
                    type: "linear",
                    position: "bottom",
                    scaleLabel: {
                        display: true,
                        labelString: "",
                        fontColor: "black",
                        fontFamily: "sans-serif",
                        fontSize: 16,
                    },
                    ticks: {
                        callback: (value: number, index: number, arr: number[]) => {
                            return "";
                        },
                    }
                }
            ],
            yAxes: [
                {
                    display: true,
                    type: "linear",
                    position: "left",
                    scaleLabel: {
                        display: true,
                        labelString: "",
                        fontColor: "black",
                        fontFamily: "sans-serif",
                        fontSize: 16,
                    },
                    gridLines: {
                        color: "light-grey", // 'light-grey' is default
                    },
                    ticks: {
                        callback: (value: number, index: number, arr: number[]) => {
                            return "";
                        },
                    }
                }
            ]
        },
        tooltips: {
            enabled: false,
            intersect: false, //show the tooltip even if not directly on a point
            position: "fixedTopRight",

        },
        plugins: {
            crosshair: false
        }
    },
    style: {
        height: `60vh`, // sets max height, responds to window size
        minHeight: "450px", // keeps the graph from getting too small
        position: "relative", // seems unnecessary?
        marginBottom: "2rem",
    }
};

export default chartDefaults;