<template>
    <div class="hm-empty l-place:center">
        <div>
            <p v-html="message" />
        </div>
        <svg class="hm-empty_svg">
            <use xlink:href="#empty"></use>
        </svg>
    </div>
</template>

<script lang="ts">
    import axios from 'axios';

    import Vue from 'vue';
    import {instance} from "@/util/Alerts";
    export default Vue.extend({
        data() {
            return {
                message: "",
            }
        },
        mounted() {

            instance.get('/auth/homeMessageRequest').then((response) => {
                this.message = response.data;
            });
        }
    })
</script>

<style lang="postcss">
    .hm-empty {
        width: 100%;
        height: 100%;
    }

    .hm-empty_svg {
        width: 20vw;
        min-width: 200px;
        height: 20vw;
        min-height: 200px;
    }
</style>