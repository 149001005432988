<template>
    <div class="ind-parts">
        <div v-if="parts.length > 0">
            <table class="ind-table">
                <thead class="ind-thead">
                    <tr class="ind-tr">
                        <th class="ind-th" title="Expand (click the arrow) to view more information.">Info</th>
                        <th class="ind-th" title="Chart legend icon">Legend</th>
                        <th class="ind-th" title="Size (width)">Size</th>
                        <th class="ind-th" title="Inductance">Ind.</th>
                        <th class="ind-th" title="Part Number">Part Number</th>
                        <th class="ind-th" title="Operating Frequency">Freq. (kHz)</th>
                        <th class="ind-th" title="Voltage Input">V<sub>in</sub></th>
                        <th class="ind-th" title="Voltage Output">V<sub>out</sub></th>
                        <th class="ind-th" title="Current Output">I<sub>out</sub></th>
                        <th class="ind-th" title="Ambient Temperature Celcius">Amb. (°C)</th>
                        <th class="ind-th" title="Click to duplicate part row.">Duplicate</th>
                        <th class="ind-th" title="Click to remove part row.">Remove</th>
                    </tr>
                </thead>
                <tbody class="ind-tbody"
                       :key="'indtable'+componentKey">
                    <template v-for="(part, index) in parts">

                        <tr :key="'indPart'+index"
                            :class="{ 'is-open' : infoOpen.includes(index + part.instances - 1)}"
                            class="ind-tr">

                            <!--Arrow Icon spans multiple rows & rendered by root part instance-->
                            <td title="Tip: press 'ESC' to close all info views."
                                class="ind-td"
                                v-if="part.instances >= 1"
                                :rowspan="part.instances"
                                @click="showInfo(index + part.instances - 1); setHighlight(index);">
                                <svg class="ind-icon ind-icon_expand">
                                    <use xlink:href="#arrow-right"></use>
                                </svg>
                            </td>

                            <!--Graph Legend Icon-->
                            <td class="ind-td"
                                @click="setHighlight(index)">
                                <svg class="ind-legend">
                                    <use :xlink:href="'#graph' + partStyleApplied[index]"></use>
                                </svg>
                            </td>

                            <!--Properties-->
                            <td class="ind-td"
                                @click="setHighlight(index)">
                                <strong v-if="highlight === index">{{part.size}}</strong>
                                <span v-else>{{part.size}}</span>
                            </td>
                            <td class="ind-td"
                                @click="setHighlight(index)">
                                <strong v-if="highlight === index">{{part.value | showUnit}}</strong>
                                <span v-else>{{part.value | showUnit}}</span>
                            </td>

                            <!--Part Num-->
                            <td class="ind-td"
                                @click="setHighlight(index)">
                                <a target="_blank" :href="partDetailSearchUrl + part.pn">
                                    <strong v-if="highlight === index">{{part.pn}}</strong>
                                    <span v-else>{{part.pn}}</span>
                                </a>
                            </td>

                            <!--Parameters-->
                            <td v-for="(input, index) in part.param"
                                :key="'inputs'+index" class="ind-td">
                                <div style="display: flex;justify-content:center;">
                                    <BaseInputNumber :value="input.select"
                                                     :min="input.min"
                                                     :max="input.max"
                                                     :step="input.step"
                                                     :width="input.width"
                                                     @change="(value) => input.select = value" />
                                </div>
                            </td>

                            <!--Actions-->
                            <td class="ind-td ind-actions">
                                <div class="l-inline:center l-box:squish-1">
                                    <span class="e-button:word cap-duplicate"
                                          css-icon
                                          v-if="part.instances > 0"
                                          @click="duplicatePart(index)"
                                          :title="'Duplicate Part '+part.pn"
                                          style="--loc-button-radius: 2rem;">
                                        +
                                    </span>
                                </div>
                            </td>
                            <td class="ind-td ind-actions">
                                <div class="l-inline:center l-box:squish-1">
                                    <span class="e-button:word cap-remove"
                                          css-icon
                                          @click="removePartByIndex(index)"
                                          :title="'Remove Part '+part.pn"
                                          style="--loc-button-radius: 2rem;">
                                        &times;
                                    </span>
                                </div>
                            </td>
                        </tr>

                        <!--Expanded Information from CE-->
                        <tr class="ind-tr ind-info"
                            :key="'indCeInfo'+index"
                            v-if="ceInfoIndexes.includes(index)">

                            <td class="ind-td" colspan=12>
                                <div class="ind-info_block" :class="{ 'is-open' : infoOpen.includes(index) }"
                                     v-if="!cePropsFound(part)">
                                    <strong>Additional information is not available for this part.</strong>
                                </div>
                                <div class="ind-info_block" :class="{ 'is-open' : infoOpen.includes(index) }"
                                     v-else="cePropsFound(part)">
                                    <div class="l-spaced:4 l-box:inset2" style="--loc-spaced-width: 20em;">
                                        <div v-for="(ceParam, index) in part.ceProps"
                                             :key="'params'+index+'first'"
                                             v-if="(ceParam.value !== '') && (ceParam.parameterName !== 'KSIM Support') && (ceParam.parameterName !== 'Unique PN') && (ceParam.step === '1')">
                                            <base-table-ce :title="ceParam.parameterName"
                                                           :key="'params'+index"
                                                           :value="ceParam.value" />
                                        </div>
                                    </div>
                                    <!--Downloads-->
                                    <base-table-downloads :specsheetUrl="specUrl + part.pn"
                                                          :datasheetUrl="dsUrl + part.pn + '.pdf'"
                                                          :stepUrl="stepUrl + part.pn + '.step'"
                                                          :RoHSUrl="rohsUrl + part.pn + '.pdf'" />

                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <div v-else class="l-place:center">
            <strong>
                Add a part to the simulation from the left sidebar.
            </strong>
        </div>
    </div>
</template>

<script lang="ts">
    import { linkMixin } from "../../../mixins/linkMixin";

    import { createNamespacedHelpers } from 'vuex';
    const { mapState, mapGetters, mapActions } = createNamespacedHelpers('calcL');

    import Vue from 'vue';
    export default Vue.extend({
        mixins: [linkMixin],
        data() {
            return {
                infoOpen: [] as number[],
            }
        },
        computed: {
            ...mapState([
                'highlight',
                'parts',
                'partStyleApplied',
                'inductorOptions',
                'componentKey'
            ]),
            ...mapGetters([
                'partsLength'
            ]),
            //Indexes of rows where expanded CE info should be shown
            ceInfoIndexes(): number[] {
                let idxs = [] as number[];

                for (let i = 0; i < this.partsLength; i++) {
                    if (this.parts[i].instances > 0) {
                        idxs.push(i + (this.parts[i].instances - 1));
                    }
                }

                return idxs;
            }
        },
        mounted() {
            document.body.addEventListener('keyup', e => {
                if (e.keyCode === 27) {
                    this.infoOpen = [];
                }
            })
        },
        methods: {
            ...mapActions([
                'duplicatePart',
                'removePartByIndex',
                'setHighlight',
                "incrementComponentKey"
            ]),
            showInfo: function (rowIndex: any) {
                //If already in list (being shown), remove from list
                if (this.infoOpen.includes(rowIndex)) {
                    const idx = this.infoOpen.findIndex(row => row === rowIndex);
                    this.infoOpen.splice(idx, 1);
                }
                // Else add to list
                else {
                    this.infoOpen.push(rowIndex);
                }
            },
            cePropsFound: function (part: InductorModel) {
                if (part.ceProps) {
                    return true
                }
                return false;
            },
        },
        filters: {
            /* Copied from function for generating tick marks in chart.js */
            showUnit: function (value: string, unit: string) {
                let val = parseFloat(value)
                const remain = val / (Math.pow(10, Math.floor(Math.log10(val))));

                // order of magnitude function:
                // https://gist.github.com/ajmas/4193ac6911f5445ced37
                /* note: already in uF, calculate for smaller values */
                var unitExponentsNumber = [-9, -6, -3, 0];
                var unitExponentsString = ['n', 'u', 'm', ''];

                var sign = (val < 0 ? -1 : 1);
                val = Math.abs(val);
                var i = 0; var idx = 0;
                for (i = unitExponentsNumber.length - 1; i >= 0; i--) {
                    if (val >= Math.pow(10, unitExponentsNumber[i])) {
                        idx = i;
                        break;
                    }
                }
                val = (val / Math.pow(10, unitExponentsNumber[idx]));
                val = val * sign;

                //When zoomed, only show 2 decimal places for axis ticks at
                //left and right edge of graph
                var decimal = val - Math.floor(val)
                if (decimal != 0) {
                    val = +val.toFixed(2)
                }
                return val + ' ' + unitExponentsString[idx] + 'H';
            },
            //TODO: provide as mixin? use the version that takes an integer as parameter?
            singlePrecision: function (value: string) {
                return parseFloat(value).toFixed(1);
            }
        },
        watch: {
            "partsLength": {
                handler: function (watch_var) {
                    // After updating everything, re-render table body with new values
                    this.incrementComponentKey();
                },
                deep: false,
                immediate: false
            },
        }
    });
</script>

<style>
    .ind-parts {
        margin-bottom: 2rem;
    }

    .ind-table {
        width: 100%;
        border-collapse: collapse;
        box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1);
    }

    .ind-tr {
        box-shadow: inset 1px 0 0 rgba(0, 0, 0, 0.1);
        transition: background-color 0.2s ease-out;
        &.is-open

    {
        background-color: hsla(206, 60%, 47%, 0.14);
    }

    }

    .cloned.is-open + .ind-tr {
        background-color: hsla(206, 60%, 47%, 0.14);
    }

    .ind-th,
    .ind-td {
        text-align: center;
        box-shadow: inset -1px 1px 0 rgba(0, 0, 0, 0.1);
        width: 1%;
        white-space: nowrap;
    }

    .ind-th {
        color: #fff;
        /* background-color: var(--color-main); */
        background-color: #3081c0;
    }

    .ind-legend {
        width: 1rem;
        height: 1rem;
    }

    .ind-td .l-field {
        margin-bottom: 0;
    }

    .ind-info {
        box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -8px 8px -8px rgba(0, 0, 0, 0.15), inset 0 8px 8px -8px rgba(0, 0, 0, 0.15);
        background-color: hsla(206, 60%, 47%, 0.03);
    }

    .ind-info_block {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
        will-change: max-height;
        &.is-open

    {
        max-height: 800px;
    }

    }

    .cloned.is-open + .ind-tr + .ind-tr .ind-info_block {
        max-height: 400px;
    }

    .ind-info_downloads {
        display: flex;
        justify-content: center;
        padding: 1rem;
        border-top: 1px solid var(--color-gray-lightest);
        .e-button\:word

    {
        padding: 0 1rem;
        color: var(--color-main);
        font-weight: normal;
    }

    }

    /* Bump specificity to override the font size of buttons */
    .ind-remove.ind-remove,
    .ind-duplicate.ind-duplicate {
        color: var(--color-main);
        font-weight: normal;
        font-size: 2rem;
        cursor: pointer;
    }

    .ind-icon {
        width: 1rem;
        height: 1rem;
        color: var(--color-main);
        transition: all 0.2s ease-out;
    }

    .ind-icon_expand {
        position: relative;
        top: 3px;
    }

    .is-open .ind-icon_expand {
        transform: rotate(90deg);
    }
    @media screen and (max-width: 1205px) {
        .ind-parts {
            overflow-x: auto;
        }
    }
</style>