<template>
    <div class="error-container">
        <h3>No results available for current parts.</h3>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue';


    export default Vue.extend({

    })
</script>

<style scoped>
    .error-container {
        position: absolute;
        background: rgba(255, 255, 255, 0.6);
        top: 0px;
        right: 0px;
        width: 100%;
        height: 100%;
    }

        .error-container h3 {
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            top: 50%;
        }

</style>