<template>
    <div>
        <div class="age-title">Ceramic Capacitor Aging: Class II & III</div>

        <!--Inputs-->
        <div class="e-panel:3 l-box:inset2">
            <div class="l-inline" style="--loc-inline-spacing: 1rem;">
                <BaseInputNumber v-for="input in inputs" :key="input.title"
                                 :title="input.title"
                                 :width="input.width"
                                 :min="input.min"
                                 :max="input.max"
                                 :step="input.step"
                                 :disabled="input.disabled"
                                 :value="input.select"
                                 @change="(value) => input.select = value" />
            </div>
        </div>

        <!--Outputs-->
        <div class="l-columns:21" style="--loc-columns-min: 450px;">
            <!--Plot-->
            <div class="e-panel:3 l-box:inset2 age-plot_outer">
                <div style="position: relative; width: 90%; margin: auto;">
                    <BasePlot :plot="plot" :style="chartStyle" />
                </div>
            </div>

            <!--Text-->
            <div class="e-panel:3 l-box:inset2 ">
                <div v-for="(output, index) in outputs" :key="index">
                    <label class="e-label">{{ index | capitalizeFirstChar }}:</label>
                    <span class="age-value">{{ output | doublePrecision }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    //Internal
    import { plotTick, plotTooltip } from "@/util/ChartJsHelpers";
    import pointStyles from "@/util/PointStyles";
    import { instance } from "@/util/Alerts";

    //State
    import { createNamespacedHelpers } from 'vuex';
    const { mapState, mapGetters, mapActions } = createNamespacedHelpers('aging');

    import Vue from 'vue';
    import { chartMixin } from "@/mixins/chartMixin";
    import BasePlot from "./../_base/base-plot";
    export default Vue.extend({
        components: {
            BasePlot
        },
        mixins: [chartMixin],
        data() {
            return {
            }
        },
        filters: {
            capitalizeFirstChar: function (value: string) {
                value = value.toString();
                return value.charAt(0).toUpperCase() + value.slice(1);
            },
            doublePrecision: function (value: string) {
                let n = parseFloat(value).toFixed(2);
                if (n !== n) { // if NaN
                    return '0'
                }
                return n
            }
        },
        computed: {
            ...mapState([
                'inputs',
                'outputs',
            ]),
            ...mapGetters([
                'postBody'
            ])
        },
        methods: {
            ...mapActions([
                'setOutputs'
            ]),
            fetchOutputs() {
                instance.post("/api/aging/", this.postBody).then((response: any) => { //can define a type for response
                    this.plotData = {} as Chart.ChartData;
                    this.plotData.datasets = [] as Chart.ChartDataSets[];

                    const outputs = {
                        week: response.data.week,
                        month: response.data.month,
                        year: response.data.year,
                        decade: response.data.decade,
                        user: response.data.user,
                    };
                    this.setOutputs(outputs);

                    if (response.data.plotData.length === 0) return;
                    const agingLegends = ["Nominal", "+%tol", "-%tol"];
                    for (let i = 0; i < response.data.plotData.length; i++) {

                        // Legend
                        const dataset = {
                            pointStyle: pointStyles[i].point,
                            pointRadius: pointStyles[i].radius,
                            pointBorderWidth: pointStyles[i].width,
                            backgroundColor: pointStyles[i].color[0],
                            borderColor: pointStyles[i].color[0],
                            rotation: pointStyles[i].rotation,
                            borderWidth: 2,
                            data: response.data.plotData[i],
                            fill: false,
                            interpolate: true,
                            label: agingLegends[i],
                            showLine: true,
                        };
                        this.plotData.datasets.push(dataset);
                    }
                    this.plotOptions.title.text = "Ceramic Capacitor Degredation Over Time";
                    this.plotOptions.legend.display = true;
                    this.plotOptions.scales.xAxes[0].scaleLabel.labelString = "Hours";
                    this.plotOptions.scales.xAxes[0].type = "linear";
                    this.plotOptions.scales.xAxes[0].ticks = {
                        autoSkip: false, minRotation: this.tickRotation, maxRotation: this.tickRotation,
                        callback: (value: number, index: number, arr: Array<number>) => {
                            return plotTick(value, index, arr, 0, [1, 2, 3, 4, 5, 6, 7, 8, 9], "Hrs");
                        },
                    };
                    this.plotOptions.scales.yAxes[0].scaleLabel.labelString = "Capacitance";
                    this.plotOptions.scales.yAxes[0].type = "logarithmic";
                    this.plotOptions.scales.yAxes[0].ticks = {
                        autoSkip: false, minRotation: this.tickRotation, maxRotation: this.tickRotation,
                        callback: (value: number, index: number, arr: number[]) => {
                            value = value * 1E-6; // convert to uF
                            return plotTick(value, index, arr, 0, [1, 2, 5], "F");
                        },
                    };
                    this.plotOptions.tooltips = {
                        intersect: false, mode: "index",
                        callbacks: {
                            title(tooltipItem: Array<Chart.ChartTooltipItem>, data: Chart.ChartData) {
                                return plotTooltip("Hours", tooltipItem[0].xLabel! as number, "Hrs", 1);
                            },
                            label(tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData) {
                                const value = tooltipItem.yLabel! as number * 1E-6; // convert to uF
                                return plotTooltip("Capacitance", value, "F", 1);
                            }
                        },
                    };
                });
            }
        },
        mounted() {
            this.fetchOutputs();
        },
        watch: {
            inputs: {
                handler: function (inputs) {
                    this.fetchOutputs();
                },
                deep: true,
            },
        }
    })
</script>

<style scoped>
    .age-title {
        font-size: 1.15rem;
        line-height: 3rem;
        margin-left: 0.5rem;
    }

    .age-outer {
        z-index: 10;
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        width: calc(100vw - 24.5rem);
        min-height: 500px;
    }

    .is-closed .age-outer {
        width: calc(100vw - 24.5rem + 360px);
    }
</style>
