<!-- src/components/simulation/Export.vue -->
<template>
    <div class="ex-export_group">
        <!--<label class="e-label" title="Various filetypes are available for export.">
            Export
        </label>-->
        <h3 class="e-sidebar_panelTitle">Export</h3>
        <BaseButton title="TODO"
                    v-for="button in buttons" :key="button.title" type=":word"
                    :disabled="button.disabled"
                    @baseButtonClick="clicked(button.title)">
            <svg class="ex-icon">
                <use :xlink:href="button.id"></use>
            </svg>
            &nbsp;{{button.title}}
        </BaseButton>
        <modal v-if="showSpiceModal" @close="showSpiceModal = false">
            <h2 slot="header">SPICE Model Export</h2>
            <div slot="body" style="display: inline-block;">
                <BaseRadio :title="shared.radios.spiceFileFormat.title"
                           :options="shared.radios.spiceFileFormat.options"
                           :value="shared.radios.spiceFileFormat.select"
                           :disabled="shared.radios.spiceFileFormat.disabled"
                           @change="(value) => shared.radios.spiceFileFormat.select = value" />
                <BaseInputNumber :title="shared.inputs.spiceFreq.title"
                                 :width="shared.inputs.spiceFreq.width"
                                 :min="shared.inputs.spiceFreq.min"
                                 :max="shared.inputs.spiceFreq.max"
                                 :step="shared.inputs.spiceFreq.step"
                                 :disabled="shared.inputs.spiceFreq.disabled"
                                 :value="shared.inputs.spiceFreq.select"
                                 @change="(value) => shared.inputs.spiceFreq.select = value" />
                <!--TODO: autofocus does not work?-->
                <p>
                    <span v-html="spicePreview" />
                </p>
                <button v-if="spiceDownloadEnabled" autofocus @click="spiceExport">
                    Download SPICE Model
                </button>
            </div>
            <div slot="footer">
                <p>tip: Press <b>'Esc'</b> to close</p>
            </div>
        </modal>
        <!-- TODO make base modal class that can be extended -->
        <!-- TODO allow ability to close when pressing "esc" -->
        <modal v-if="showCSVModal" @close="showCSVModal = false">
            <h2 slot="header">CSV Export</h2>
            <div slot="body">
                <p>
                    <span v-html="csvPreview" />
                </p>
                <BaseButton type=":main"
                            v-if="csvDownloadEnabled"
                            autofocus
                            v-on:baseButtonClick="csvExport">
                    Download CSV
                </BaseButton>
            </div>
            <div slot="footer">
                <p>tip: Press <b>'Esc'</b> to close</p>
            </div>
        </modal>
        <modal v-if="showImageModal" @close="showImageModal = false">
            <h2 slot="header">Image Export</h2>
            <div slot="body">
                <div>
                    <p>
                        Export the currently shown chart.
                    </p>
                    <p>
                        notes:
                        <ul style="margin-left: 2em;">
                            <b>Image export depends on the current size of your browser window.</b>
                            <li>
                                Expand your browser to full screen for best results.
                            </li>
                            <li>
                                You may close the left sidebar for a slight improvement.
                            </li>
                        </ul>
                    </p>
                    <button @click="imageExport">
                        Download Image
                    </button>
                </div>
            </div>
            <div slot="footer">
                <p>tip: Press <b>'Esc'</b> to close</p>
            </div>
        </modal>
        <modal v-if="showS2PModal" @close="showS2PModal = false">
            <h2 slot="header">S2P Export</h2>
            <div slot="body">
                <p>
                    <span v-html="s2pPreview" />
                </p>
                <button v-if="s2pDownloadEnabled" autofocus @click="s2pExport">
                    Download S2P
                </button>
            </div>
            <div slot="footer">
                <p>tip: Press <b>'Esc'</b> to close</p>
            </div>
        </modal>
    </div>
</template>

<script lang="ts">
    import * as htmlToImage from 'html-to-image';
    const FileSaver = require('file-saver');
    import { instance } from "@/util/Alerts";
    import html2canvas from "html2canvas";

    import Modal from "@/components/Modal.vue";

    import { createNamespacedHelpers } from "vuex";
    const { mapState, mapGetters, mapActions } = createNamespacedHelpers("calcZ");

    import Vue from 'vue';
    import pointStyles from "../../../util/PointStyles";
    export default Vue.extend({
        components: {
            Modal
        },
        data() {
            return {
                buttons: {
                    csv: {
                        title: "CSV",
                        disabled: false,
                        id: "#download"
                    },
                    spice: {
                        title: "SPICE",
                        disabled: false,
                        id: "#download"
                    },
                    s2p: {
                        title: "S2P",
                        disabled: false,
                        id: "#download"
                    },
                    image: {
                        title: "Image",
                        disabled: false,
                        id: "#download"
                    },
                },
                csvPreview: "",
                csvDownloadEnabled: false,
                s2pPreview: "",
                s2pDownloadEnabled: false,
                spicePreview: "",
                spiceDownloadEnabled: false,
                showCSVModal: false,
                showSpiceModal: false,
                showS2PModal: false,
                showImageModal: false,
                savedBlob: new Blob,
                contentDisposition: "",
            }
        },
        computed: {
            ...mapState([
                "highlight",
                'shared',
                'plotType',
                'parts',
                'partStyleApplied',
                'shared'
            ]),
            ...mapGetters([
                'body',
                'pnString'
            ])
        },
        mounted() {
            document.body.addEventListener('keyup', e => {
                if (e.keyCode === 27) {
                    this.showCSVModal = false;
                    this.showSpiceModal = false;
                    this.showS2PModal = false;
                    this.showImageModal = false;
                }
            })
        },
        methods: {
            clicked(buttonTitle: string) {
                if (buttonTitle === "CSV") {
                    if (this.parts.length == 1) {
                        this.csvPreview = "Please select a part first."
                        this.csvDownloadEnabled = false;
                    }
                    else {
                        this.fetchCSVPreview();
                        this.csvDownloadEnabled = true;
                    }
                    this.showCSVModal = true;
                }
                else if (buttonTitle === "SPICE") {
                    if (this.parts.length == 1) {
                        this.spicePreview = "Please select a part first."
                        this.spiceDownloadEnabled = false;
                    }
                    else {
                        this.fetchSpicePreview();
                        this.spiceDownloadEnabled = true;
                    }
                    this.showSpiceModal = true;
                }
                else if (buttonTitle === "S2P") {
                    if (this.parts.length == 1) {
                        this.s2pPreview = "Please select a part first."
                        this.s2pDownloadEnabled = false;
                    }
                    else {
                        this.fetchS2PPreview();
                        this.s2pDownloadEnabled = true;
                    }
                    this.showS2PModal = true;
                }
                else if (buttonTitle === "Image") {
                    this.showImageModal = true;
                } else {
                    alert("Magic button pressed.");
                }
            },
            fetchCSVPreview() {
                instance.post("/api/csv-preview/", this.body).then((response) => {
                    this.csvPreview = response.data
                });
            },
            fetchS2PPreview() {
                instance.post("/api/s2p-preview/", this.body).then((response) => {
                    this.s2pPreview = response.data
                });
            },
            fetchSpicePreview() {
                instance.post("/api/spice-preview/", this.body).then((response) => {
                    this.spicePreview = response.data
                });
            },
            // TODO: don't repeat yourself
            spiceExport() {
                instance.post("/api/spice/", this.body, { responseType: "blob" }).then((response) => {
                    this.savedBlob = new Blob([response.data], { type: response.data.type });
                    this.contentDisposition = this.pnString + "_SPICE.CKT"
                    this.downloadBlob(this.savedBlob, this.contentDisposition);
                    this.showSpiceModal = false;
                });
            },
            async csvExport() {
                await instance.post("/api/csv/", this.body, { responseType: "blob" }).then((response) => {
                    this.savedBlob = new Blob([response.data], { type: response.data.type });
                    this.downloadBlob(this.savedBlob, this.plotType.select.replace(",", "-") + "_" + this.pnString + ".csv");
                    this.showCSVModal = false;
                });
            },
            s2pExport() {
                instance.post("/api/s2p/", this.body, { responseType: "blob" }).then((response) => {
                    this.savedBlob = new Blob([response.data], { type: response.data.type });
                    this.contentDisposition = this.pnString + ".s2p";
                    this.downloadBlob(this.savedBlob, this.contentDisposition);
                    this.showS2PModal = false;
                });
            },

            async imageExport() {
                const fileName = this.plotType.select + "_" + this.pnString + ".png";

                let chart = document.getElementById("chart-to-download")! as HTMLCanvasElement;
                let icons = document.getElementsByClassName("cap-legend") as HTMLCollectionOf<HTMLElement>;
                let iconColors = new Array();

                if (this.shared.radios.combine.select === "Yes") {
                    for (let i = 0; i < icons.length; i++) {
                        let styleIndex = this.partStyleApplied[i];
                        iconColors.push(pointStyles[styleIndex].color[0]);
                    }
                } else {
                    for (let i = 1; i < icons.length + 1; i++) {
                        let styleIndex = this.partStyleApplied[i];
                        iconColors.push(pointStyles[styleIndex].color[0]);
                    }
                }

                await html2canvas(chart, {
                    backgroundColor: "white",
                    onclone: function (document) {
                        let infoRow = Array.from(document.getElementsByClassName("cap-table-info-row")! as HTMLCollectionOf<HTMLElement>);
                        infoRow.forEach(x => {
                            let cells = x as HTMLElement;
                            cells.style.color = "white";
                        });

                        let tableRow = document.getElementById("table-row")!;
                        let cells = tableRow.children as HTMLCollectionOf<HTMLElement>;
                        for (let i = 0; i < cells.length; i++) {
                            let cell = cells[i].style;
                            cell.height = "40px";
                            cell.lineHeight = "40px";
                        }
                        let iconCell = document.getElementsByClassName("icon-legend") as HTMLCollectionOf<HTMLCanvasElement>;
                        for (let i = 0; i < iconCell.length; i++) {
                            let iconReplacement = document.createElement("div");
                            iconReplacement.style.backgroundColor = iconColors[i];
                            iconReplacement.style.width = "20px";
                            iconReplacement.style.height = "20px";
                            iconReplacement.style.margin = "0 auto";
                            iconReplacement.style.marginTop = "-25px";
                            iconCell[i].append(iconReplacement);
                        }
                    }
                })
                    .then(function (canvas) {
                        canvas.toBlob((blob) => {
                            FileSaver.saveAs(blob, fileName)
                        })
                    });
                this.showImageModal = false;
            },
            downloadBlob(blob: Blob, contentDisposition: string) {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                let fileName = contentDisposition;
                if (contentDisposition) {
                    const fileNameMatch = contentDisposition.match(/filename=(.+)/)!;
                }
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url);
            },
        },
        watch: {
            highlight: {
                handler: function () {
                    //if (this.parts[this.highlight].capType !== "Film") {
                    //    this.buttons.spice.disabled = false;
                    //    this.buttons.s2p.disabled = false;
                    //    return
                    //}
                    //this.buttons.spice.disabled = true;
                    //this.buttons.s2p.disabled = true;
                }
            },
            "shared.radios.spiceFileFormat.select": {
                handler: function (watch_var) {
                    this.fetchSpicePreview();
                },
                deep: false,
                immediate: false
            },
            "shared.inputs.spiceFreq.select": {
                handler: function (watch_var) {
                    this.fetchSpicePreview();
                },
                deep: false,
                immediate: false
            },
        }
    })
</script>

<style scoped>
    .e-label {
        display: inline-block;
        margin-right: 1rem;
    }

    .ex-export_group {
        position: relative;
        display: flex;
        align-items: baseline;
        margin-top: auto;
        padding-top: 1rem;
        &::before

    {
        content: '';
        position: absolute;
        top: 0;
        left: -1.5rem;
        width: calc(100% + 3rem);
        height: 1px;
        background-color: var(--color-gray-lightest);
    }

    }

    .ex-icon {
        width: 1em;
        height: 1em;
        color: currentColor;
    }
</style>