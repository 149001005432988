<template>


  <div class="main">
    <table v-if="mouserPriceList.length>0" style="display: inline; width: 10%">
      <thead>
      <th colspan="2">
        <img style="width: 7.5rem;height: 3.75rem; " :src="require('@/img/mouser.png')"/>
      </th>
      </thead>
      <thead class="quantity_title">
      <th>Quantity</th>
      <th style="box-shadow: 0.625rem 0 0.313rem #888888;">Price</th>
      </thead>
      <tbody>
      <template v-for="(part, index) in mouserPriceList">
        <tr>
          <td style="border-right:0.06rem black solid ;">{{ part.quantity }}</td>
          <td >{{ "$" + part.price }}</td>
        </tr>
      </template>
      </tbody>
      <tr>
        <td colspan="2" style="color: green;">Stock :{{ mouserStock }}</td>
      </tr>
    </table>

    <table v-if="digiKeyPriceList.length>0" style="display: inline; width: 10%">
      <thead>
      <th colspan="2">
        <img style="width: 7.5rem;height: 3.75rem; " :src="require('@/img/digikey.png')"/>
      </th>
      </thead>
      <thead class="quantity_title">
      <th>Quantity</th>
      <th >Price</th>
      </thead>
      <tbody>
      <template v-for="(part, index) in digiKeyPriceList">
        <tr>
          <td style="border-right:0.06rem black solid ;">{{ part.quantity }}</td>
          <td >{{ "$" + roundTo(part.price, 2) }}</td>
        </tr>
      </template>
      </tbody>
      <tr>
        <td colspan="2" style="color: green;">Stock :{{ digiKeyStock }}</td>
      </tr>
    </table>
  </div>


</template>

<script lang="ts">
import Vue from 'vue';
import {mapActions} from 'vuex';

export default Vue.extend({
  methods: {
    ...mapActions([
      'setSortOrder'
    ]),
    roundTo: function (num, decimal) {
      return Math.round((num + Number.EPSILON) * Math.pow(10, decimal)) / Math.pow(10, decimal);
    },
  },
  props: {
    mouserPriceList: {
      type: Array,
      required: true
    },
    digiKeyPriceList: {
      type: Array,
      required: true
    },
    mouserStock: {
      type: Number,
      required: true
    },
    digiKeyStock: {
      type: Number,
      required: true
    },
  }
})
</script>

<style scoped>
.main {
  width: 80%;
  margin: 1.25rem;
  margin-left: 11.25rem;
}

table {
  border-spacing: 0;
  width: 100%;
}

tr {
  text-align: center;
}

td {
  text-align: inherit;
}

th {
  padding: 0.625rem;
}

table tbody td:nth-child(even) {
}

table tbody tr:nth-child(odd) {
  //background-color: #eee
}

.quantity_title {
  background-color: #205193;
  border-right: 0.06rem solid;
  color: white;
}

table thead th:first-child {
  border-radius: 0.313rem 0 0 0;
}

table thead th:last-child {
  border-radius: 0 0.313rem 0 0;
  border-right: 0 solid;
}

table tbody tr:last-child td:first-child {
  //border-radius: 0 0 0 0.313rem;
}

table tbody tr:last-child td:last-child {
  //border-radius: 0 0 0.313rem 0;
}
</style>