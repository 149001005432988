import { ActionContext } from "vuex";
import {instance} from "@/util/Alerts";
type CeramicContext = ActionContext<CeramicState, RootState>;

const baseUrl = "/api/ceramic/";

const state = (): CeramicState => ({
    statusSelect: "active",
    statusOptions: [
        { value: "active", label: "Active", disabled: false },
        { value: "inactive", label: "Inactive", disabled: false },
        { value: "de-active", label: "De-active", disabled: false },
        { value: "test", label: "Test", disabled: false }
    ] as BaseDropdownOption[],
    seriesSelect: "General Purpose Surface Mount (4V – 10kV)",
    seriesOptions: [] as BaseDropdownOption[],
    sizeSelect: "",
    sizeOptions: [] as BaseDropdownOption[],
    dielectricSelect: "",
    dielectricOptions: [] as BaseDropdownOption[],
    voltageSelect: "",
    voltageOptions: [] as BaseDropdownOption[],
    capacitorList: [] as CapacitorModel[],
});

const getters = {
};

const mutations = {
    setStatusSelect(state: CeramicState, payload: string) {
        state.statusSelect = payload;
    },
    setSeriesSelect(state: CeramicState, payload: string) {
        state.seriesSelect = payload;
    },
    setSizeSelect(state: CeramicState, payload: string) {
        state.sizeSelect = payload;
    },
    setDielectricSelect(state: CeramicState, payload: string) {
        state.dielectricSelect = payload;
    },
    setVoltageSelect(state: CeramicState, payload: string) {
        state.voltageSelect = payload;
    },
    setSeriesOptions(state: CeramicState, payload: BaseDropdownOption[]) {
        //Remove null series
        for (let i = 0; i < payload.length; i++) {
            if (!payload[i].value) {
                //console.log("removing null series");
                payload.splice(i, 1);
            }
        }
        state.seriesOptions = payload;
    },
    setSizeOptions(state: CeramicState, payload: BaseDropdownOption[]) {
        state.sizeOptions = payload;
    },
    setDielectricOptions(state: CeramicState, payload: BaseDropdownOption[]) {
        state.dielectricOptions = payload;
    },
    setVoltageOptions(state: CeramicState, payload: BaseDropdownOption[]) {
        state.voltageOptions = payload;
    },
    setCapacitors(state: CeramicState, payload: CapacitorModel[]) {
        state.capacitorList = payload;
    },
};

const actions = {
    setStatusSelect(context: CeramicContext, payload: string) {
        context.commit("setStatusSelect", payload);
    },
    setSeriesSelect(context: CeramicContext, payload: string) {
        context.commit("setSeriesSelect", payload);
    },
    setSizeSelect(context: CeramicContext, payload: string) {
        context.commit("setSizeSelect", payload);
    },
    setDielectricSelect(context: CeramicContext, payload: string) {
        context.commit("setDielectricSelect", payload);
    },
    setVoltageSelect(context: CeramicContext, payload: string) {
        context.commit("setVoltageSelect", payload);
    },
    getSeriesOptions(context: CeramicContext) {
        const url = baseUrl + "series";
        const body = {
            partStatus: context.state.statusSelect
        };
        instance.post(url, body).then((response) => {
            const options = [] as BaseDropdownOption[];
            for (let i = 0; i < response.data.length; i++) {
                const value = response.data[i];
                const label = response.data[i];
                options.push({
                    value,
                    label,
                    disabled: false
                });
            }
            context.commit("setSeriesOptions", options);
        });
    },
    getSizeOptions(context: CeramicContext) {
        if (context.state.statusSelect === "active" &&
            context.state.seriesSelect === ""
        ) return;
        const url = baseUrl + "size";
        const body = {
            series: context.state.seriesSelect,
            partStatus: context.state.statusSelect
        };
        instance.post(url, body).then((response) => {
            const options = [] as BaseDropdownOption[];
            for (let i = 0; i < response.data.length; i++) {
                const value = response.data[i];
                const label = response.data[i];
                options.push({
                    value,
                    label,
                    disabled: false
                });
            }
            context.commit("setSizeOptions", options);
        });
    },
    getDielectricOptions(context: CeramicContext) {
        if (
            context.state.statusSelect === "active" &&
            (context.state.seriesSelect === "" ||
                context.state.sizeSelect === "")
        ) return;
        const url = baseUrl + "dielectric";
        const body = {
            series: context.state.seriesSelect,
            size: context.state.sizeSelect,
            partStatus: context.state.statusSelect
        };
        instance.post(url, body).then((response) => {
            const options = [] as BaseDropdownOption[];
            for (let i = 0; i < response.data.length; i++) {
                const value = response.data[i];
                const label = response.data[i];
                options.push({
                    value,
                    label,
                    disabled: false
                });
            }
            context.commit("setDielectricOptions", options);
        });
    },
    getVoltageOptions(context: CeramicContext) {
        if (
            context.state.statusSelect === "active" &&
            (context.state.seriesSelect === "" ||
                context.state.sizeSelect === "" ||
                context.state.dielectricSelect === "")
        ) return;
        /* We can at least check for any of the body properties == "" and prevent
           an API call which we already know would return an empty response.
           We will need to check for empty responses, because empty responses
           can result from invalid selections (e.g. an invalid size selection
           due to the dropdowns still updating.
           Ideally, we create a flag for the component when all loading has finished.
           Need to implement some kind of loading indication anyways.
           */
        const url = baseUrl + "voltage";
        const body = {
            series: context.state.seriesSelect,
            size: context.state.sizeSelect,
            dielectric: context.state.dielectricSelect,
            partStatus: context.state.statusSelect
        };
        instance.post(url, body).then((response) => {
            const options = [] as BaseDropdownOption[];
            for (let i = 0; i < response.data.length; i++) {
                // To match SQL query we need 6 decimal points.
                const value = response.data[i].toFixed(6).toString();
                const label = response.data[i].toString();
                options.push({
                    value,
                    label,
                    disabled: false
                });
            }
            context.commit("setVoltageOptions", options);
        });
    },
    getCapacitors(context: CeramicContext) {
        if (
            context.state.statusSelect === "active" &&
            (context.state.seriesSelect === "" ||
                context.state.sizeSelect === "" ||
                context.state.dielectricSelect === "" ||
                context.state.voltageSelect === "")
        ) return;
        /* We can at least check for any of the body properties == "" and prevent
           an API call which we already know would return an empty response.
           We will need to check for empty responses, because empty responses
           can result from invalid selections (e.g. an invalid size selection
           due to the dropdowns still updating.
           Ideally, we create a flag for the component when all loading has finished.
           Need to implement some kind of loading indication anyways.
           */
        const url = baseUrl + "capacitance";
        const body = {
            series: context.state.seriesSelect,
            size: context.state.sizeSelect,
            dielectric: context.state.dielectricSelect,
            voltage: context.state.voltageSelect,
            partStatus: context.state.statusSelect
        };
        instance.post(url, body).then((response) => {
            let capacitorList = [] as CapacitorModel[];
            for (let i = 0; i < response.data.length; i++) {
                // TODO: verify tolerance options are correct for these dielectrics with Wilmer
                let tolerance = {} as InputDropdown;
                switch (response.data[i].dielectric) {
                    case "C0G":
                        tolerance = {
                            title: "Tolerance",
                            select: "K",
                            disabled: false,
                            hidden: false,
                            options: [
                                { value: "B", label: ".1pF", disabled: false },
                                { value: "C", label: ".25pF", disabled: false },
                                { value: "D", label: ".5pF", disabled: false },
                                { value: "F", label: "1%", disabled: false },
                                { value: "G", label: "2%", disabled: false },
                                { value: "J", label: "5%", disabled: false },
                                { value: "K", label: "10%", disabled: false },
                                { value: "M", label: "20%", disabled: false }
                            ],
                        };
                        break;
                    case "U2J":
                        tolerance = {
                            title: "Tolerance",
                            select: "K",
                            disabled: false,
                            hidden: false,
                            options: [
                                { value: "F", label: "1%", disabled: false },
                                { value: "G", label: "2%", disabled: false },
                                { value: "J", label: "5%", disabled: false },
                                { value: "K", label: "10%", disabled: false },
                                { value: "M", label: "20%", disabled: false }
                            ],
                        };
                        break;
                    case "X5R":
                        tolerance = {
                            title: "Tolerance",
                            select: "K",
                            disabled: false,
                            hidden: false,
                            options: [
                                { value: "K", label: "10%", disabled: false },
                                { value: "M", label: "20%", disabled: false }
                            ],
                        };
                        break;
                    case "X7R":
                        tolerance = {
                            title: "Tolerance",
                            select: "K",
                            disabled: false,
                            hidden: false,
                            options: [
                                { value: "J", label: "5%", disabled: false },
                                { value: "K", label: "10%", disabled: false },
                                { value: "M", label: "20%", disabled: false }
                            ],
                        };
                        break;
                    case "X8R":
                        tolerance = {
                            title: "Tolerance",
                            select: "K",
                            disabled: false,
                            hidden: false,
                            options: [
                                { value: "B", label: ".1pF", disabled: false },
                                { value: "C", label: ".25pF", disabled: false },
                                { value: "D", label: ".5pF", disabled: false },
                                { value: "F", label: "1%", disabled: false },
                                { value: "G", label: "2%", disabled: false },
                                { value: "J", label: "5%", disabled: false },
                                { value: "K", label: "10%", disabled: false },
                                { value: "M", label: "20%", disabled: false }
                            ],
                        };
                        break;
                    case "Y5V":
                        tolerance = {
                            title: "Tolerance",
                            select: "M",
                            disabled: false,
                            hidden: false,
                            options: [
                                { value: "M", label: "20%", disabled: false },
                                { value: "Z", label: "+80%/-20%", disabled: false }
                            ],
                        };
                        break;
                    case "Z5U":
                        tolerance = {
                            title: "Tolerance",
                            select: "M",
                            disabled: false,
                            hidden: false,
                            options: [
                                { value: "M", label: "20%", disabled: false },
                                { value: "Z", label: "+80%/-20%", disabled: false }
                            ],
                        };
                        break;
                }
                capacitorList.push({
                    id: response.data[i].id,
                    instances: 1,
                    capDisplay: response.data[i].capDisplay,
                    dielectric: response.data[i].dielectric,
                    maxTemp: response.data[i].maxTemp,
                    kemetPn: response.data[i].kemetPn,
                    basePn: response.data[i].basePn,
                    series: response.data[i].series,
                    appDef: response.data[i].appDef,
                    spicePn: response.data[i].spicePn,
                    capType: response.data[i].capType,
                    capValue: response.data[i].capValue,
                    voltageRating: response.data[i].voltageRating,
                    ceProps: response.data[i].ceProps,
                    hidden: false,
                    param: response.data[i].param,
                    tolerance: tolerance,
                    filmProps: {} as FilmProps,
                    seedProps: [] as SeedProp[], 
                    displayAllCeProps: false,
                    tcc: response.data[i].tcc,
                    vcac: response.data[i].vcac,
                });
            }
            context.commit("setCapacitors", capacitorList);
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
