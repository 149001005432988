<template>
    <div class="e-panel:3 l-box:inset2" v-if="bannerImage != ''">
        <a target="_blank" :href="bannerLink"><img v-if="bannerImage !== ''" class="footer" :src="bannerImage" /></a>
    </div>
</template>

<script lang="ts">
    import axios from "axios";

    import Vue from "vue";
    import {instance} from "@/util/Alerts";

    export default Vue.extend({
        inheritAttrs: false,
        data() {
            return {
                bannerImage: "",
                bannerLink: ""
            }
        },
        mounted() {
            instance.get("/auth/bannerImageRequest/").then((response) => {
                (this as any).bannerImage = response.data;
            });
            instance.get("/auth/bannerLinkRequest/").then((response) => {
                (this as any).bannerLink = response.data;
            });
        }
    })
</script>

<style scoped>
</style>