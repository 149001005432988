<template>
  <div class="l-box:inset2">
    <div class="l-inline" style="--loc-inline-spacing: 1rem;">
      <div class="optional_input">
        <h2>Maximum Land Pattern</h2>
        <PacInputNumber style="display: inline; width: 81%;"
                        v-model.number="v1Input"
                        placeholder="V1 (mm)        *Optional"
                        class="pac_input"
                        @change="(value) => powerArrayRequest.v1 = value"/>
        <PacInputNumber style="display: inline; width: 81%;"
                        v-model.number="v2Input"
                        placeholder="V2 (mm)        *Optional"
                        class="pac_input"
                        @change="(value) => powerArrayRequest.v2 = value"/>

      </div>
      <div class="optional_input2">
        <h2>Series</h2>
        <BaseInputString style="display: inline; width: 81%;"
                         :value="seriesInput"
                         placeholder="e.g. KC-Link     *Optional"
                         class="pac_input"
                         @change="(value) => addToSeriesArray(value)"/>
      </div>


    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import {createNamespacedHelpers} from "vuex";
import PacInputNumber from "@/components/powerArrayCalculator/PacInputNumber.vue";

const {mapState, mapActions} = createNamespacedHelpers("pac");

export default Vue.extend({
  components: {PacInputNumber},
  data() {
    return {
      v1Input: "",
      v2Input: "",
      seriesInput: "",
    }
  },
  computed: {
    ...mapState([
      'powerArrayRequest',
    ]),
  },
  methods: {
    ...mapActions([]),
    addToSeriesArray(series) {
      this.powerArrayRequest.series = series.split(",")
    }
  }
})
</script>

<style scoped>
.optional_input2 {
  margin-left: 5rem;
}

.optional_input {
  margin-left: 2.5rem;
}

.pac_input {
  width: calc(100% - 2rem);

}
</style>
