<template>
    <div>
        <label v-if="title" class="e-label" id="title">
            {{title}}
        </label>
        <select v-model="local_value"
                @change="$emit('change', local_value)"
                class="e-select"
                :disabled="disabled">
            <option v-for="(item, index) in options"
                    :key="index"
                    :value="item.value"
                    :label="item.label"
                    :disabled="item.disabled">
                {{item.label}}
            </option>
        </select>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        inheritAttrs: false,
        props: {
            value: {
                type: String,
                required: true
            },
            title: {
                type: String,
                required: false
            },
            options: {
                type: Array,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        // Re-write local_value as a computed property?
        data() {
            return {
                local_value: this.value
            }
        },
        watch: {
            options: {
                // If old selection not an option, pick the first new option
                handler: function (options) {
                    let old_selection_invalid = true; // Assume invalid
                    // If we find currently selected item, selection is valid
                    for (let i = 0; i < options.length; i++) {
                        if (this.local_value === options[i]['value']) {
                            old_selection_invalid = false;
                        }
                    }
                    // If invalid, just pick the first one
                    if (old_selection_invalid) {
                        if (options.length > 0) {
                            this.local_value = options[0]['value'];
                            //This is working, but the $emit doesn't occur when we programatically
                            //set the value here, it only emits when a new selection is made with the mouse
                            //Thus, we need to do this here manually for the change
                            //to propagate up when the selection changes *without* a click
                        }
                        else {
                            this.local_value = "";
                        }
                        this.$emit('change', this.local_value)
                    }
                }
            }
        },
    })
</script>

<style scoped>
</style>