// ReSharper disable once UnusedLocalImport
import Chart from "chart.js"; // Don't know why VS is saying this is not used

const state = {
    inputs: {
        nominal: {
            title: "Nominal (uF)",
            select: 22,
            min: 0.00,
            max: 1000.00,
            step: 0.01,
            disabled: false,
            hidden: false,
            width: 6,
        } as InputNumber,
        hoursReferee: {
            title: "Referee (hrs)",
            select: 48,
            min: 1,
            max: 100000,
            step: 1,
            disabled: false,
            hidden: false,
            width: 8,
        } as InputNumber,
        factor: {
            title: "Factor",
            select: 3,
            min: 1,
            max: 100,
            step: 1,
            disabled: false,
            hidden: false,
            width: 4,
        } as InputNumber,
        tolerance: {
            title: "Tolerance (%)",
            select: 10,
            min: 0.0,
            max: 20.0,
            step: 0.1,
            disabled: false,
            hidden: false,
            width: 6,
        } as InputNumber,
        hoursUser: {
            title: "User (hrs)",
            select: 87600,
            min: 1,
            max: 100000,
            step: 1,
            disabled: false,
            hidden: false,
            width: 8,
        } as InputNumber,
    },
    outputs: {
        week: 0,
        month: 0,
        year: 0,
        decade: 0,
        user: 0,
    },
};

const getters = {
    postBody: (state: any) => {
        return {
            nominal: state.inputs.nominal.select,
            hoursReferee: state.inputs.hoursReferee.select,
            factor: state.inputs.factor.select,
            tolerance: state.inputs.tolerance.select,
            hoursUser: state.inputs.hoursUser.select,
        }
    },
};

const mutations = {
    setOutputs(state: { outputs: AgingCalculations; }, payload: AgingCalculations) {
        state.outputs = payload;
    },
    addPlotDataset(state: any, payload: Object) {
        state.plotData.datasets.push(payload);
    },
    clearPlotDatasets(state: any) {
        state.plotData.datasets = [] as any[];
    },
    setPlotOptions(state: any, payload: Object) {
        state.plotOptions = payload;
    }
};

const actions = {
    setOutputs(context: any, payload: AgingCalculations) {
        context.commit("setOutputs", payload);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
