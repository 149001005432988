/**
 * Add a capacitor.
 * @param state State.
 * @param part CapacitorModel of part to be added.
 */
export function addCapacitor(state: CalcZState | SeedState, payload: CapacitorModel) {
    // Make a copy of CapacitorModel so that we aren't messing with the Parts List in the sidebar
    state.parts.push(JSON.parse(JSON.stringify(payload)));

    //assign style index
    const styleIndex = state.partStyleAvailable.pop() as number;
    state.partStyleApplied.push(styleIndex);

    // Enable Combined option if we have enough parts
    if (state.parts.length >= 3) // combined + 2 more
        state.shared.radios.combine.disabled = false;

    //disable inputs for added part if combined
    if (state.shared.radios.combine.select === "Yes") {
        state.parts[state.parts.length - 1].param.tempAmbient.disabled = true;
        state.parts[state.parts.length - 1].param.bias.disabled = true;
    }
    //highlight part added
    state.highlight = state.parts.length - 1;
    state.componentKey++;
};

/**
 * Duplicate a capacitor.
 * @param state State.
 * @param partIndex Index of part to be duplicated.
 */
export function duplicatePart(state: CalcZState | CalcLState, partIndex: number) {
    // Insert clone after part to be duplicated
    const clone = JSON.parse(JSON.stringify(state.parts[partIndex]));
    clone.instances = 0;
    // TS thinks these parameters are invalid for arr.splice... they are valid.
    state.parts.splice(partIndex + 1, 0, clone);

    //Increment root part
    state.parts[partIndex].instances++;

    //Maintain graph styles for new part
    const styleIndex = state.partStyleAvailable.pop() as number;
    state.partStyleApplied.splice(partIndex + state.parts[partIndex].instances - 1, 0, styleIndex);

    //TODO: move componentKeys into individual components instead of have one global
    state.componentKey++;
};

export function removeCapacitor(state: CalcZState | SeedState, index: number) {
    if (index === 0) {
        // The Combined part forever lives at position 0 (legacy artifact of CalcZ).
        // And so, the parts table here also reserves index 0 for Combined.
        // We can't delete Combined part, but we can disable it.
        state.shared.radios.combine.select = "No";
        for (let i = 0; i < state.parts.length; i++) {
            state.parts[i].param.bias.disabled = false;
            state.parts[i].param.tempAmbient.disabled = false;
        }
        state.highlight = 1;
        //console.log("Combined part will never be deleted! Never!!!");
        //state.componentKey++; //force render hack
        return;
    } else {
        // If highlight last part, decrement highlight to prevent invalid index
        if (state.highlight === state.parts.length - 1) state.highlight--;

        // If highlight combined and only 2 parts, set highlight to index 1
        // ReSharper disable TsResolvedFromInaccessibleModule
        if (state.highlight === 0 && state.parts.length === 3) state.highlight = 1;
        // ReSharper restore TsResolvedFromInaccessibleModule

        // Handling duplicates (update # of instances)
        if (state.parts[index].instances > 0) { // If root part
            if (state.parts[index].instances > 1) { // and if any duplicates
                // Set subsequent part to have correct # of instances
                state.parts[index + 1].instances = state.parts[index].instances - 1;
            }
        } else { // not root part 
            // Look for root from beginning of list (we'll run into roots first)
            for (let i = 0; i < state.parts.length; i++) {
                // When we find it, decrement the # of instances
                if (state.parts[i].basePn === state.parts[index].basePn) {
                    state.parts[i].instances--;
                    break;
                }
            }
        }

        // Recycle style
        state.partStyleAvailable.push(state.partStyleApplied[index]);
        state.partStyleApplied.splice(index, 1);

        // Remove part
        state.parts.splice(index, 1);

        // Disable Combined option if we no longer have enough parts
        if (state.parts.length <= 2) {
            state.shared.radios.combine.disabled = true;
            state.shared.radios.combine.select = "No";
        }

        state.componentKey++; // Re-render Table and Shared Parameters
    }
};
