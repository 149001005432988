enum ApiLabel {
    Y_Value_DC,
    Y_Value_AC,
    Y_Value_Core,
    Y_Value_Total
}

interface ApiLabelsSpec {
    label: string,
    value: string,
    shape: string,
    pointRadius: number,
    pointWidth: number
}

export function getApiSpec(apiLabel: ApiLabel): ApiLabelsSpec {
    switch (apiLabel) {
        case ApiLabel.Y_Value_DC:
            return {
                label: "DC", value: "y_value_dc",
                shape: "rect", pointRadius: 5, pointWidth: 1
            };
        case ApiLabel.Y_Value_AC:
            return {
                label: "AC", value: "y_value_ac",
                shape: "cross", pointRadius: 5, pointWidth: 2
            };
        case ApiLabel.Y_Value_Core:
            return {
                label: "Core", value: "y_value_core",
                shape: "star", pointRadius: 6, pointWidth: 1
            };
        case ApiLabel.Y_Value_Total:
            return {
                label: "Total", value: "y_value_total",
                shape: "circle", pointRadius: 4, pointWidth: 1
            };
    }
}

export default ApiLabel;