﻿
<template>
    <div>
        <div class="e-sidebar_links">
            <div v-show="(this.$route.name !== '') && validRoute">

                <div class="e-sidebar_link"
                     v-for="(route, index) in routes"
                     :key="'routerLink'+index">
                    <RouterLink v-if="(route.name !== 'Share') &&
                          (route.name !== 'Sim') &&
                          (route.name !== 'Power Array Calculator') &&
                          (route.name !== 'Admin') &&
                          (route.name !== 'Seed') &&
                          (route.name !== 'Release Info') &&
                          (route.name !== 'Login') &&
                          (route.name !== 'Help') &&
                          (route.name !== 'KLEM 3.0') &&
                          (route.name !== 'Inductor Simulation')"
                                :title="route.description"
                                :to="route.path">{{ route.name }}</RouterLink>
                </div>
<!--                <RouterLink title='Power Array Calculator'-->
<!--                            to="/power-array-calculator">-->
<!--                    Power Array Calculator-->
<!--                </RouterLink>-->
                <div>Film Lifetime</div>
                <RouterLink class="sub-category-link"
                            title='KLEM 3.0'
                            to="/film-lifetime">
                    KLEM 3.0
                </RouterLink>
                <a class="sub-category-link"
                   href="#"
                   title='KLEM 3.0 Tutorial'                 
                   @click="readFile">
                    <strong>Tutorial</strong> KLEM
                </a>
                <RouterLink title='Inductor Simulation'
                            to="/inductor-simulation">
                    Inductor Simulation
                </RouterLink>
            </div>
        </div>

        <!--Parts Lists-->
        <CapacitorSelect class="sidebar_outer" v-if="(this.$route.name === 'Capacitor Simulation') || (this.$route.name === 'Seed')" v-show="(this.$route.name === 'Capacitor Simulation') || (this.$route.name === 'Seed')" />
        <FilmSelectV2 class="sidebar_outer" v-if="this.$route.name === 'KLEM 3.0'" v-show="this.$route.name === 'KLEM 3.0'" />
        <InductorSelect class="sidebar_outer" v-if="this.$route.name === 'Inductor Simulation'" v-show="this.$route.name === 'Inductor Simulation'" />
        <PacInput class="sidebar_outer" v-if="this.$route.name === 'Power Array Calculator'" v-show="this.$route.name === 'Power Array Calculator'" />
    </div>
</template>

<!-- TODO: The following commented out code will be the new/updated sidebar in future. Please do not remove. -->
<!--<template>
    <div>
        <div class="root-accordian" v-for="(route, index) in sidebarRoutes" :key="index">
            <BaseToolbarAccordion v-if="route.name === 'Lifetime Analysis'" :title=route.name :open=false>
                <div class="child-accordian" v-for="(child, index) in route.children" :key="index">
                    <BaseSubAccordion v-if="child.name === 'Film'" :title="child.name">
                        <div class="child-accordian" v-for="(filmChild, i) in child.children" :key="i">
                            <RouterLink :to="filmChild.link">{{ filmChild.name }}</RouterLink>
                        </div>
                    </BaseSubAccordion>
                    <RouterLink :to="child.link">{{ child.name }}</RouterLink>
                </div>
            </BaseToolbarAccordion>
            <BaseToolbarAccordion v-else :title=route.name :open=false>
                <div class="child-accordian" v-for="(child, index) in route.children" :key="index">
                    <RouterLink v-if="(route.name !== 'Worked Examples')" :to="child.link">{{ child.name }}</RouterLink>
                    <a target="_blank" :href="child.link" v-else>{{ child.name }}</a>
                </div>
            </BaseToolbarAccordion>
        </div>-->
<!--Parts Lists-->
<!--<CapacitorSelect class="sidebar_outer" v-show="(this.$route.name === 'Capacitor Simulation') || (this.$route.name === 'Seed')" />
        <FilmSelect class="sidebar_outer" v-show="this.$route.name === 'Film Lifetime'" />
        <FilmSelectV2 class="sidebar_outer" v-show="this.$route.name === 'Film Lifetime 2.0'" />
        <InductorSelect class="sidebar_outer" v-show="this.$route.name === 'Inductor Simulation'" />

    </div>
</template>-->

<script lang="ts">
    import { routes } from './../routes'
    import { sidebarRoutes } from './../util/SidebarUrls'
    import { instance } from "@/util/Alerts";

    import CapacitorSelect from './capacitor/parts/CapacitorSelect.vue'
    import FilmSelectV2 from './filmLifetimeV2/FilmSelect.vue'
    import InductorSelect from './inductor/parts/InductorSelect.vue'

    import Vue from 'vue';
    import CapacitorInput from "@/components/capacitor/parts/CapacitorInput.vue";
    import PacInput from "@/components/powerArrayCalculator/PacInput.vue";
    export default Vue.extend({
        components: {
          PacInput,
            CapacitorSelect,
            FilmSelectV2,
            InductorSelect,
          CapacitorInput
        },
        data() {
          return {
                routes,
                sidebarRoutes,
/*                pdfRoute: pdfUrl*/
            }
        },
        computed: {
            // Used to prevent showing sidebar if going to an invalid route
            // Can remove once out of beta. Right now only used to prevent users from finding tools which aren't released
            validRoute: function () {
                let routeFound = false;
                for (let i = 0; i < routes.length; i++) {
                    if (this.$route.path == routes[i].path) routeFound = true;
                }
                return routeFound;
            }
        },
        methods: {
            readFile() {
                instance.get("/api/filmLifetime/instructions", { responseType: 'blob'}).then((response) => {
                    const blob = new Blob([response.data], { type: "application/pdf"});                  
                    const fileURL = URL.createObjectURL(blob)
                    window.open(fileURL)
                })
                return false;
            }
        }
    })
</script>

<style>
    .child-accordian {
        margin-left: 10px;
    }

    .root-accordian {
        margin-bottom: 10px;
    }

    .sidebar_outer {
        width: calc(100% + 1rem);
        /* Set max-height on content to make sure it does not push the footer down */
        /*max-height: calc(100vh - 300px);*/
        margin: 0 0 1rem;
        overflow: hidden auto;
    }

    .e-sidebar_links {
        margin-bottom: 1rem;
    }

    .sub-category-link {
        display: block;
        font-size: 14px;
        margin-left: 16px;
    }
</style>