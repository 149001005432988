import {instance} from "@/util/Alerts";
import { ActionContext } from "vuex";
type FilmContext = ActionContext<FilmState, RootState>;

const baseUrl = "/api/film/";

const state = (): FilmState => ({
    statusSelect: "active",
    statusOptions: [
        { value: "active", label: "Active", disabled: false },
        { value: "inactive", label: "Inactive", disabled: false },
        { value: "de-active", label: "De-active", disabled: false },
        { value: "test", label: "Test", disabled: false }
    ] as BaseDropdownOption[],
    seriesSelect: "",
    seriesOptions: [] as BaseDropdownOption[],
    sizeSelect: "",
    sizeOptions: [] as BaseDropdownOption[],
    voltageSelect: "",
    voltageOptions: [] as BaseDropdownOption[],
    capacitorList: [] as CapacitorModel[]
});

const getters = {
};

const mutations = {
    setStatusSelect(state: FilmState, payload: string) {
        state.statusSelect = payload;
    },
    setSeriesSelect(state: FilmState, payload: string) {
        state.seriesSelect = payload;
    },
    setSizeSelect(state: FilmState, payload: string) {
        state.sizeSelect = payload;
    },
    setVoltageSelect(state: FilmState, payload: string) {
        state.voltageSelect = payload;
    },
    setSeriesOptions(state: FilmState, payload: BaseDropdownOption[]) {
        state.seriesOptions = payload;
    },
    setSizeOptions(state: FilmState, payload: BaseDropdownOption[]) {
        state.sizeOptions = payload;
    },
    setVoltageOptions(state: FilmState, payload: BaseDropdownOption[]) {
        state.voltageOptions = payload;
    },
    setCapacitors(state: FilmState, payload: CapacitorModel[]) {
        state.capacitorList = payload;
    },
};

const actions = {
    setStatusSelect(context: FilmContext, payload: string) {
        context.commit("setStatusSelect", payload);
    },
    setSeriesSelect(context: FilmContext, payload: string) {
        context.commit("setSeriesSelect", payload);
    },
    setSizeSelect(context: FilmContext, payload: string) {
        context.commit("setSizeSelect", payload);
    },
    setVoltageSelect(context: FilmContext, payload: string) {
        context.commit("setVoltageSelect", payload);
    },
    async getSeriesOptions(context: FilmContext) {
        const url = baseUrl + "series";
        const body = {
            partStatus: context.state.statusSelect
        };
        instance.post(url, body).then((response) => {
            const options = [] as BaseDropdownOption[];
            for (let i = 0; i < response.data.length; i++) {
                const value = response.data[i]["style"];
                const label = response.data[i]["description"];
                options.push({
                    value,
                    label: value + ": " + label,
                    disabled: false
                } as BaseDropdownOption);
            }
            context.commit("setSeriesOptions", options);
        });
    },
    getSizeOptions(context: FilmContext) {
        if (context.state.statusSelect === "active" &&
            context.state.seriesSelect === ""
        ) return;
        const url = baseUrl + "size";
        const body = {
            series: context.state.seriesSelect,
            partStatus: context.state.statusSelect
        };
        instance.post(url, body).then((response) => {
            const options = [] as BaseDropdownOption[];
            for (let i = 0; i < response.data.length; i++) {
                const value = response.data[i];
                const label = response.data[i];
                options.push({
                    value,
                    label,
                    disabled: false
                });
            }
            context.commit("setSizeOptions", options);
        });
    },
    getVoltageOptions(context: FilmContext) {
        if (context.state.statusSelect === "active" &&
            (context.state.seriesSelect === "" ||
                context.state.sizeSelect === "")
        ) return;
        /* We can at least check for any of the body properties == "" and prevent
           an API call which we already know would return an empty response.
           We will need to check for empty responses, because empty responses
           can result from invalid selections (e.g. an invalid size selection
           due to the dropdowns still updating.
           Ideally, we create a flag for the component when all loading has finished.
           Need to implement some kind of loading indication anyways.
           */
        const url = baseUrl + "voltage";
        const body = {
            series: context.state.seriesSelect,
            size: context.state.sizeSelect,
            partStatus: context.state.statusSelect
        };
        instance.post(url, body).then((response) => {
            const options = [] as BaseDropdownOption[];
            for (let i = 0; i < response.data.length; i++) {
                // To match SQL query we need 6 decimal points.
                const value = response.data[i].toFixed(6).toString();
                const label = response.data[i].toString();
                options.push({
                    value,
                    label,
                    disabled: false
                });
            }
            context.commit("setVoltageOptions", options);
        });
    },
    getCapacitors(context: FilmContext) {
        if (
            context.state.statusSelect === "active" &&
            (context.state.seriesSelect === "" ||
                context.state.sizeSelect === "" ||
                context.state.voltageSelect === "")
        ) return;
        /* We can at least check for any of the body properties == "" and prevent
           an API call which we already know would return an empty response.
           We will need to check for empty responses, because empty responses
           can result from invalid selections (e.g. an invalid size selection
           due to the dropdowns still updating.
           Ideally, we create a flag for the component when all loading has finished.
           Need to implement some kind of loading indication anyways.
           */
        const url = baseUrl + "capacitance";
        const body = {
            series: context.state.seriesSelect,
            size: context.state.sizeSelect,
            voltage: context.state.voltageSelect,
            partStatus: context.state.statusSelect
        };
        instance.post(url, body).then((response) => {
            const capacitorList = [] as CapacitorModel[];
            for (let i = 0; i < response.data.length; i++) {
                capacitorList.push({
                    id: response.data[i].id,
                    instances: 1,
                    capDisplay: response.data[i].capDisplay,
                    dielectric: response.data[i].dielectric,
                    maxTemp: response.data[i].maxTemp,
                    kemetPn: response.data[i].kemetPn,
                    basePn: response.data[i].basePn,
                    series: response.data[i].series,
                    appDef: response.data[i].appDef,
                    spicePn: response.data[i].spicePn,
                    capType: response.data[i].capType,
                    capValue: response.data[i].capValue,
                    voltageRating: response.data[i].voltageRating,
                    ceProps: [] as UpdatedCapCeProps,
                    hidden: false,
                    param: response.data[i].param,
                    tolerance: {
                        title: "Tolerance",
                        select: "n/a",
                        disabled: true,
                        hidden: false,
                        options: [
                            { value: "n/a", label: "n/a", disabled: false },
                        ],
                    } as InputDropdown,
                    filmProps: {} as FilmProps,
                    seedProps: [] as SeedProp[],
                    displayAllCeProps: false,
                    tcc: response.data[i].tcc,
                    vcac: response.data[i].vcac,
                });
            }
            context.commit("setCapacitors", capacitorList);
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
