<template>
    <div>
        <h3 class="e-sidebar_panelTitle">Part Number Input</h3>
        <div style="display: inline-flex;">
            <BaseInputString style="display: inline; width: 81%;"
                             placeholder="e.g. C0201C100K8GAC"
                             :value="textInput"
                             class="cap-part_input"
                             @keyup.native.enter="addPart"
                             @change="(value) => textInput = value" />
            <button title="Click to add the entered part number."
                    class="e-button"
                    @click="addPart"
                    style="margin-top: 4px;">
                Add
            </button>
        </div>
    </div>
</template>

<script lang="ts">
    import { createNamespacedHelpers } from 'vuex';
    const { mapState, mapActions } = createNamespacedHelpers('calcZ');

    import Vue from 'vue';
    export default Vue.extend({
        data() {
            return {
                textInput: "",
            }
        },
        methods: {
            ...mapActions([
                'addCapacitorByPartNumber'
            ]),
            addPart() {
                if (this.textInput === "") {
                    alert("Please enter a part number.");
                    return;
                }
                const where = this.$route.path.split("/")[1];
                switch (where) {
                    case "seed":
                        this.$store.dispatch("seed/addCapacitorByPartNumber", { partNumber: this.textInput, method: "textBox" });
                        break;
                    case "capacitor-simulation":
                        this.addCapacitorByPartNumber({
                            partNumber: this.textInput,
                            method: "textBox"
                        });
                        break;
                    default:
                        console.log('default case in CapacitorInput')
                        break;
                };
            }
        }
    })
</script>

<style scoped>
    .e-sidebar_type,
    .e-label,
    .cap-part_input {
        width: calc(100% - 2rem);
        margin-right: 1rem;
        margin-left: 1rem;
    }
    .e-button {
        padding: 0 8px;
    }
</style>