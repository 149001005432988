<template>
    <div style="color: red">
        <slot></slot>
    </div>

</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({

    })
</script>

<style scoped>
</style>
