<template>
    <div>
        <label v-if="title" class="e-label" id="title">
            {{title}}
        </label>
        <ul class="e-selector unstyled" :class="selectorState">
            <li v-for="(item, index) in options"
                    :key="index"
                    :value="item.value"
                    :disabled="item.disabled"
                    class="e-selector_item"
                    :class="selected === item.value ? 'is-selected' : ''"
                    @click="openSelect(item.value)"
            ><img class="e-selector_img" :src="item.image" />{{item.label}}</li>
        </ul>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        inheritAttrs: false,
        props: {
            value: {
                type: String,
                required: true
            },
            title: {
                type: String,
                required: false
            },
            options: {
                type: Array,
                required: true
            },
            selected: {
                type: String,
                required: false
            }
        },
        data() {
            return {
                selectorState: 'is-closed'
            }
        },
        methods: {
            openSelect: function(val:any){
                switch ((this as any).selectorState) {
                    case 'is-open':
                        (this as any).selectorState = 'is-closed';
                        this.$emit('change', val);
                        break;
                    case 'is-closed':
                        (this as any).selectorState = 'is-open';
                        break;
                }

            }
        }
    })
</script>

<style scoped>
.e-selector {
    --border-radius: 3px;
    position: relative;

    display: block;
    width: 100%;
    min-height: var(--size-input);

    overflow: hidden;

    color: var(--color-text);
    font-weight: 400;
    font-size: var(--size);
    white-space: nowrap;
    text-overflow: ellipsis;


    background-color: var(--color-white);
    background-clip: padding-box;

    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border: 2px solid var(--color-gray-lighter);
    border-radius: var(--border-radius);
}

.e-selector::after {
    content: '';

    position: absolute;
    top: 1rem;
    right: 0.75rem;

    width: 0;
    height: 0;

    transition: opacity 0.15s 0.15s;
    opacity: 1;
    border-style: solid;
    border-width: 8px 5px 0;
    border-color: #000 transparent transparent;
}

.e-selector_item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    height: var(--size-input);
    padding-right: var(--size-1);
    padding-left: var(--size-1);
    overflow: hidden;

    transition: height 0.15s ease-out;
    cursor: pointer;
}

.e-selector_item + .e-selector_item {
    box-shadow: 0 -2px 0 var(--color-gray-lighter);
}

.e-selector_img {
    width: 3rem;
    height: 100%;
    margin-right: 1rem;
    mix-blend-mode: darken;
}

.is-open .e-selector_item {
    height: var(--size-input);
}

.is-open.e-selector::after {
    opacity: 0;

    transition: opacity 0.15s;
}

.is-closed .e-selector_item:not(.is-selected) {
    height: 0;
}

.is-selected {
    height: var(--size-input);

    /* background-color: rgba(255, 191, 17, 0.185); */
}

</style>