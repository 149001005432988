export const defaultProfile = {
    voltage: {
        title: "Vac (V)",
        select: 240.00,
        min: 0.00,
        max: 1000.00,
        step: 0.01,
        disabled: false,
        width: 8,
        isRequired: true
    } as FilmInputNumber,
    voltageDC: {
        title: "Vdc (V)",
        select: 240.00,
        min: 0.00,
        max: 1500.00,
        step: 0.01,
        disabled: false,
        width: 8,
        isRequired: true
    } as FilmInputNumber,
    fundamentalFrequency: {
        title: "Fund. Freq. (Hz)",
        select: 50.00,
        min: 50.00,
        max: 400.00,
        step: 1.0,
        disabled: false,
        width: 13,
    } as InputNumber,
    tempAmbient: {
        title: "Amb. (°C)",
        select: 50.00,
        min: -55.00,
        max: 155.00,
        step: 0.01,
        disabled: false,
        width: 8,
        isRequired: true
    } as FilmInputNumber,
    relativeHumidity: {
        title: "Rel. Humid. (%)",
        select: 20.00,
        min: 2.00,
        max: 100.00,
        step: 0.01,
        disabled: false,
        width: 10,
    } as InputNumber,
    operatingPercentage: {
        title: "Working Time (%)",
        select: 50.0000,
        min: 0.0000,
        max: 100.0000,
        step: 0.0001,
        disabled: false,
        width: 10,
    } as InputNumber,
    overlayVac:
        {
            overlayVac: {
                title: "Overlay Vac (V)",
                select: 150,
                min: 0.00,
                max: 400,
                step: 1,
                disabled: false,
                width: 8,
            } as InputNumber,
            frequency: {
                title: "Frequ. (Hz)",
                select: 50,
                min: 50,
                max: 400,
                step: 1,
                disabled: false,
                width: 8
            } as InputNumber,
        },
    currentFrequency: [
        {
            current: {
                title: "Current (A)",
                select: 0.50,
                min: 0.00,
                max: 200.00,
                step: 0.01,
                disabled: false,
                width: 8,
            } as InputNumber,
            frequency: {
                title: "Frequ. (kHz)",
                select: 10,
                min: 0.1,
                max: 1000,
                step: 1,
                disabled: false,
                width: 8
            } as InputNumber,
        }
    ] as Array<FilmLifetimeV2CurrentFrequency>
} as FilmLifetimeV2Profile;