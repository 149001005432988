<template>
    <div>
        <BaseAccordion :title="'Aluminum'">
            <BaseDropdown v-if="this.$route.path === '/seed'"
                          title="Part Status"
                          :options="statusOptions"
                          v-model="statusSelect"
                          @change="setStatusSelect" />
            <base-dropdown title="Series"
                           v-model="seriesSelect"
                           :options="seriesOptions"
                           @change="setSeriesSelect" />
            <BaseDropdown title="Size"
                          v-model="sizeSelect"
                          :options="sizeOptions"
                          @change="setSizeSelect" />
            <BaseDropdown title="Voltage Rating (V)"
                          v-model="voltageSelect"
                          :options="voltageOptions"
                          @change="setVoltageSelect" />
        </BaseAccordion>
        <BaseAccordion title="Available Parts" class="e-parts">
            <CapacitorResults :items="capacitorList" />
        </BaseAccordion>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue';

    import CapacitorResults from './CapacitorResults.vue';

    import { createNamespacedHelpers } from 'vuex';
    const { mapState, mapActions } = createNamespacedHelpers('aluminum');

    export default Vue.extend({
        components: {
            CapacitorResults,
        },
        mounted() {
            this.getSeriesOptions();
        },
        computed: {
            ...mapState([
                'statusSelect',
                'statusOptions',
                'seriesSelect',
                'seriesOptions',
                'sizeSelect',
                'sizeOptions',
                'voltageSelect',
                'voltageOptions',
                'capacitorList'
            ])
        },
        methods: {
            ...mapActions([
                'setStatusSelect',
                'setSeriesSelect',
                'setSizeSelect',
                'setVoltageSelect',
                'getSeriesOptions',
                'getSizeOptions',
                'getVoltageOptions',
                'getCapacitors'
            ]),
        },
        watch: {
            statusSelect() {
                this.getSeriesOptions();
                this.getSizeOptions();
                this.getVoltageOptions();
                this.getCapacitors();
            },
            seriesSelect() {
                this.getSizeOptions();
                this.getVoltageOptions();
                this.getCapacitors();
            },
            sizeSelect() {
                this.getVoltageOptions();
                this.getCapacitors();
            },
            voltageSelect() {
                this.getCapacitors();
            }
        },
    })
</script>

<style>
</style>