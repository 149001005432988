// Vuex & TypeScript
import { ActionContext } from "vuex";
type FilmLifetimeV2Context = ActionContext<FilmLifetimeV2State, RootState>;

// Communication with aliens 
import  { AxiosResponse } from "axios";
import { filmLifetime2BaseUrl, ceParametersUrl } from "@/util/Urls";

// Tool specific
import { simulationPartMaximum } from "@/util/SimulationPartMaximums";
import { filmLifetimeExtremes } from "@/util/CE/ParameterNames";
import { defaultProfile } from "@/util/FilmLifetime/DefaultProfileV2";

// Shared between tools
import {instance, maxPartsAlert} from "@/util/Alerts";
// Variables accessible within component
const state = (): FilmLifetimeV2State => ({
    // Parts Filtering & Results
    seriesSelect: "F862 V054",
    seriesOptions: [] as BaseDropdownOption[],
    pitchSelect: "15",
    pitchOptions: [] as Array<BaseDropdownOption>,
    voltageSelect: "310",
    voltageOptions: [] as BaseDropdownOption[],
    capacitorList: [] as FilmLifetimeV2CapacitorModel[],
    // Current Parts
    parts: [] as Array<FilmLifetimeV2CapacitorModel>,
    highlight: 0,
    currentFrequencyList: [] as Array<FilmLifetimeV2CurrentFrequency>,
    // Calculations
    profileList: [
        JSON.parse(JSON.stringify(defaultProfile)),
        JSON.parse(JSON.stringify(defaultProfile))
    ] as Array<FilmLifetimeV2Profile>,
    lifetimeHoursTotal: [] as Array<number>,
    profilesData: [] as Array<Array<FilmLifetime2ProfileResponse>>,
    // Rendering hack
    componentKey: 0,
    isVac: true,
    errorFetchingData: false,
    voltageType: {
        title: "Voltage Type",
        options: ["Vac", "Vdc"],
        select: "Vac",
        disabled: false,
        hidden: false,
    } as InputRadio
});

const getters = {
    request: (state: FilmLifetimeV2State) => {
        const theProfileList = [] as FilmLifetimeV2ProfileRequest[];
        return {
            parts: state.parts,
            profileList: theProfileList,
        } as FilmLifetimeV2Request;
    },
    workingTimeValid: (state: FilmLifetimeV2State) => {
        let sum = 0.0;
        for (let n = 0; n < state.profileList.length; n++) {
            sum += state.profileList[n].operatingPercentage.select;
        }
        return (sum === 100.0);
    },
    voltageValid: (state: FilmLifetimeV2State) => {
        if (state.isVac) {
            for (let n = 0; n < state.profileList.length; n++) {
                if (state.profileList[n].voltage.select > state.profileList[n].voltage.max)
                    return false;
            }
        }
        if (!state.isVac) {
            for (let n = 0; n < state.profileList.length; n++) {
                if (state.profileList[n].voltageDC.select > state.profileList[n].voltageDC.max)
                    return false;
            }
        }
        return true;
    },
    tempValid: (state: FilmLifetimeV2State) => {
        for (let n = 0; n < state.profileList.length; n++) {
            if (
                state.profileList[n].tempAmbient.select > state.parts[state.highlight].maxTemp
                && state.profileList[n].tempAmbient.select > state.parts[state.highlight].minTemp
            ) {
                return false;
            }
        }
        return true;
    },
    relativeHumidityValid: (state: FilmLifetimeV2State) => {
        for (let n = 0; n < state.profileList.length; n++) {
            if (state.profileList[n].relativeHumidity.select > 100.0 || state.profileList[n].relativeHumidity.select < 2.0) {
                return false;
            }
        }
        return true;
    }
};

const mutations = {
    setCeDetails(state: FilmLifetimeV2State, payload: { pn: string, ceProps: ComponentEdgeParameterDto[] }) {
        for (let i = 0; i < state.parts.length; i++) {
            if (state.parts[i].kemetPn === payload.pn) { // Find the cap
                state.parts[i].ceProps = payload.ceProps; // and add the details
            }
        }
    },
    setDisplayAllCeProps(state: FilmLifetimeV2State, index: number) {
        let currentValue = state.parts[index].displayAllCeProps;
        state.parts[index].displayAllCeProps = !currentValue;
    },
    /* Parts Filtering & Results */
    setSeriesSelect(state: FilmLifetimeV2State, payload: string) {
        state.seriesSelect = payload;
    },
    setPitchSelect(state: FilmLifetimeV2State, payload: string) {
        state.pitchSelect = payload;
    },
    setVoltageType(state: FilmLifetimeV2State, payload: string) {
        state.voltageType.select = payload;
    },
    setVoltageSelect(state: FilmLifetimeV2State, payload: string) {
        state.voltageSelect = payload;
    },
    setSeriesOptions(state: FilmLifetimeV2State, payload: BaseDropdownOption[]) {
        state.seriesOptions = payload;
    },
    setPitchOptions(state: FilmLifetimeV2State, payload: BaseDropdownOption[]) {
        state.pitchOptions = payload;
    },
    setVoltageOptions(state: FilmLifetimeV2State, payload: BaseDropdownOption[]) {
        state.voltageOptions = payload;
    },
    setVoltageTypeOptions(state: FilmLifetimeV2State, payload: FilmLifetimeV2VoltageTypeResponse) {
        state.voltageType.select = payload.voltageType;
        state.voltageType.disabled = payload.disableVdc;
    },
    /* Part Selection */
    setCapacitors(state: FilmLifetimeV2State, payload: FilmLifetimeV2CapacitorModel[]) {
        state.capacitorList = payload;
    },
    addCapacitor(state: FilmLifetimeV2State, payload: FilmLifetimeV2CapacitorModel) {
        state.parts = [...state.parts, payload];
        state.highlight = state.parts.length - 1;
    },
    removeCapacitor(state: FilmLifetimeV2State, index: number) {
        // If highlight is on last part, set valid highlight before removing
        if (state.highlight === (state.parts.length - 1)) state.highlight--;

        // Remove single part at index
        state.parts.splice(index, 1);
    },
    setHighlight(state: FilmLifetimeV2State, index: number) {
        state.highlight = index;
    },

    /* Calculations */
    setLifetimeHoursTotal(state: FilmLifetimeV2State, payload: Array<number>) {
        state.lifetimeHoursTotal = payload;
    },
    setProfileData(state: FilmLifetimeV2State, payload: Array<Array<FilmLifetime2ProfileResponse>>) {
        state.profilesData = payload;
    },
    addCurrentFrequencyRow(state: FilmLifetimeV2State, index: Array<string>) {
        if (state.profileList[index[0]].currentFrequency.length === 5) {
            alert("You cannot add any additional rows.");
            return;
        }

        let defaultCurrentFrequency =
            {
                current: {
                    title: "Current (A)",
                    select: 0.50,
                    min: 0.00,
                    max: 200.00,
                    step: 0.0001,
                    disabled: false,
                    width: 8,
                } as InputNumber,
                frequency: {
                    title: "Frequ. (kHz)",
                    select: 10,
                    min: 0.1,
                    max: 1000,
                    step: 1,
                    disabled: false,
                    width: 8
                } as InputNumber,
            } as FilmLifetimeV2CurrentFrequency;
        state.profileList[index[0]].currentFrequency.push(defaultCurrentFrequency);
        state.componentKey++;
    },
    removeCurrentFrequencyRow(state: FilmLifetimeV2State, index: Array<string>) {
        state.profileList[index[0]].currentFrequency.splice(index[1], 1);
        state.componentKey++;
    },
    duplicateProfile(state: FilmLifetimeV2State, index: number) {
        const profileToDuplicate = state.profileList[index];
        if (profileToDuplicate.voltage.select == null) {
            profileToDuplicate.voltage.select = 0;
        }
        const duplicatedProfile = JSON.parse(JSON.stringify(profileToDuplicate)); // js creates a reference by default
        state.profileList.splice(index, 0, duplicatedProfile);
        state.profileList[index].operatingPercentage.select /= 2.0;
        state.profileList[index + 1].operatingPercentage.select /= 2.0;

        const dataToDuplicate = state.profilesData[state.highlight][index];
        const duplicatedData = JSON.parse(JSON.stringify(dataToDuplicate)); // js creates a reference by default
        
        state.profilesData[state.highlight].splice( index, 0, duplicatedData);
        state.componentKey++;
    },
    removeProfile(state: FilmLifetimeV2State, index: number) {
        // Keep operating percentage total = 100
        if (index === 0) {
            state.profileList[index + 1].operatingPercentage.select +=
                state.profileList[index].operatingPercentage.select;
        } else {
            state.profileList[index - 1].operatingPercentage.select +=
                state.profileList[index].operatingPercentage.select;
        }

        state.profileList.splice(index, 1);
        state.componentKey++;
    },
    setApplicationType(state: FilmLifetimeV2State, payload: boolean) {
        state.isVac = payload;
    },
    setErrorStatus(state: FilmLifetimeV2State, hasError: boolean) {
        state.errorFetchingData = hasError;
    },
    setProfileMaxVoltage(state: FilmLifetimeV2State, data: FilmMaxVoltageRequest) {
        state.profileList = state.profileList.map((profile, index) => ({
            ...profile,
            voltage: {
                ...profile.voltage,
                max: index === data.profileIndex ? data.maxVoltage : state.profileList[index].voltage.max
            }
        }));
    },
    setProfileMaxOverlayVac(state: FilmLifetimeV2State, payload: { profileIndex: number, max: number }) {
        state.profileList[payload.profileIndex].overlayVac.overlayVac.max = payload.max;
    },
    setProfileMaxVoltageDC(state: FilmLifetimeV2State, data: FilmMaxVoltageRequest) {
        state.profileList = state.profileList.map((profile, index) => ({
            ...profile,
            voltageDC: {
                ...profile.voltageDC,
                max: index === data.profileIndex ? data.maxVoltage : state.profileList[index].voltageDC.max
            }
        }));
    },
    setProfileMinTemp(state: FilmLifetimeV2State, minTemp: number) {
        state.profileList.forEach(profile => profile.tempAmbient.min = minTemp);
    },
    setProfileMaxTemp(state: FilmLifetimeV2State, maxTemp: number) {
        state.profileList.forEach(profile => profile.tempAmbient.max = maxTemp);
    },
    setPartExtremes(state: FilmLifetimeV2State,
                    payload: {
                        partIndex: number,
                        minTemp: number, maxTemp: number, maxVoltageAc: number,
                    }
    ) {
        state.parts[payload.partIndex].minTemp = payload.minTemp;
        state.parts[payload.partIndex].maxTemp = payload.maxTemp;
        state.parts[payload.partIndex].maxVoltageAc = payload.maxVoltageAc;
    },
    updateProfileExtremes(state: FilmLifetimeV2State) {
        let minTemp = -999;
        let maxTemp = 999;
        let maxVoltageAc = 1500;

        // Find weakest extremes
        for (let i = 0; i < state.parts.length; i++) {
            const part = state.parts[i];
            if (part.minTemp > minTemp) minTemp = part.minTemp;
            if (part.maxTemp < maxTemp) maxTemp = part.maxTemp;
            if (part.maxVoltageAc < maxVoltageAc) maxVoltageAc = part.maxVoltageAc;
        }

        // Set for all profiles
        for (let i = 0; i < state.profileList.length; i++) {
            state.profileList[i].tempAmbient.min = minTemp;
            state.profileList[i].tempAmbient.max = maxTemp;
            state.profileList[i].voltage.max = maxVoltageAc;
        }
    },

    /* Force re-render */
    incrementComponentKey(state: FilmLifetimeV2State) {
        state.componentKey = state.componentKey + 1;
    },
};

const actions = {
    addCurrentFrequencyRow(context: FilmLifetimeV2Context, payload: Array<string>) {
        context.commit("addCurrentFrequencyRow", payload);
    },
    removeCurrentFrequencyRow(context: FilmLifetimeV2Context, payload: Array<string>) {
        context.commit("removeCurrentFrequencyRow", payload);
    },
    setDisplayAllCeProps(context: FilmLifetimeV2Context, index) {
        context.commit("setDisplayAllCeProps", index);
    },
    addCapacitorByPartNumber(
        context: FilmLifetimeV2Context,
        payload: { partNumber: string; method: string }
    ) {
        // Lookup part
        instance
            .post("api/filmLifetime/part", {
                partNumber: payload.partNumber,
                method: payload.method,
                session: window.localStorage.getItem("uuid"),
            })
            .then((response) => {
                let partExists = false; // Assume part not in list to begin
                if (context.state.parts != null) { // If list not empty, let's search it
                    for (let i = 0; i < context.state.parts.length; i++) { // Check each part
                        if (context.state.parts[i].kemetPn === response.data.kemetPn) { // Identify based on part number
                            partExists = true; // If match, we won't add part below
                        }
                    }
                }
                if (!partExists) {
                    context.dispatch("fetchCeProps", (payload.partNumber, "film"));
                    var part = response.data;
                    part.ceProps = [] as ComponentEdgeParameterDto[];

                    // Add to table
                    context.dispatch("addCapacitor", part as FilmLifetimeV2CapacitorModel);

                    //// Hit CE for temperature and voltage limits
                    context.dispatch("fetchPartExtremes",
                        { partNumber: payload.partNumber, partIndex: context.state.parts.length - 1 }
                    );
                }
            });
    },
    // Available Parts Filtering & Results
    setSeriesSelect(context: FilmLifetimeV2Context, payload: string) {
        context.commit("setSeriesSelect", payload);
    },
    setPitchSelect(context: FilmLifetimeV2Context, payload: string) {
        context.commit("setPitchSelect", payload);
    },
    setVoltageSelect(context: FilmLifetimeV2Context, payload: string) {
        context.commit("setVoltageSelect", payload);
    },
    setVoltageType(context: FilmLifetimeV2Context, payload: string) {
        context.commit("setVoltageType", payload);
    },
    getSeriesOptions(context: FilmLifetimeV2Context) {
        const url = filmLifetime2BaseUrl + "series";
        instance.get(url).then((response) => {
            const options = [] as BaseDropdownOption[];
            for (let i = 0; i < response.data.length; i++) {
                const value = response.data[i];
                const label = response.data[i];
                options.push({
                    value,
                    label,
                    disabled: false
                } as BaseDropdownOption);
            }
            context.commit("setSeriesOptions", options);
        });
    },
    getPitchOptions(context: FilmLifetimeV2Context) {
        const url = filmLifetime2BaseUrl + "pitch";
        const body = { series: context.state.seriesSelect, voltageType: context.state.voltageType.select };
        instance.post(url, body).then((response) => {
            const options = [] as BaseDropdownOption[];
            for (let i = 0; i < response.data.length; i++) {
                const value = response.data[i].toString();
                const label = response.data[i].toString();
                options.push({
                    value,
                    label,
                    disabled: false
                } as BaseDropdownOption);
            }
            context.commit("setPitchOptions", options);
        });
    },
    getVoltageTypeOptions(context: FilmLifetimeV2Context) {
        if (context.state.seriesSelect === "" ||
            context.state.pitchSelect === ""
        ) return;
        const url = filmLifetime2BaseUrl + "voltageType";
        const body = {
            series: context.state.seriesSelect,
            pitch: context.state.pitchSelect,
            voltageType: context.state.voltageType.select
        };
        instance.post(url, body).then((response) => {
            if (response.data.length === 0) {
                return;
            } else {
                const disableVdc = response.data.disableVdc;
                const disableVac = response.data.disableVac;
                if (disableVdc) {
                    context.commit("setVoltageTypeOptions", { voltageType: "Vac", disableVdc: true } as FilmLifetimeV2VoltageTypeResponse)
                    context.commit("setVoltageType", "Vac");
                } else if (disableVac) {
                    context.commit("setVoltageTypeOptions", { voltageType: "Vdc", disableVdc: true } as FilmLifetimeV2VoltageTypeResponse)
                    context.commit("setVoltageType", "Vdc");
                }
                else {
                    context.commit("setVoltageTypeOptions", { voltageType: context.state.voltageType.select, disableVdc: false } as FilmLifetimeV2VoltageTypeResponse)
                }
            }
        });
    },
    getVoltageOptions(context: FilmLifetimeV2Context) {
        if (context.state.seriesSelect === "" ||
            context.state.pitchSelect === ""
        ) return;
        /* We can at least check for any of the body properties == "" and prevent
           an API call which we already know would return an empty response.
           We will need to check for empty responses, because empty responses
           can result from invalid selections (e.g. an invalid size selection
           due to the dropdowns still updating.
           Ideally, we create a flag for the component when all loading has finished.
           Need to implement some kind of loading indication anyways.
           */
        const url = filmLifetime2BaseUrl + "voltage";
        const body = {
            series: context.state.seriesSelect,
            pitch: context.state.pitchSelect,
            voltageType: context.state.voltageType.select
        };
        instance.post(url, body).then((response) => {
            if (response.data.length === 0) {
                return;
            } else {
                const options = [] as BaseDropdownOption[];
                for (let i = 0; i < response.data.length; i++) {
                    // To match SQL query we need 6 decimal points.
                    const value = response.data[i].toString();
                    const label = response.data[i].toString();
                    options.push({
                        value,
                        label,
                        disabled: false
                    } as BaseDropdownOption);
                }
                context.commit("setVoltageOptions", options);
            }
        });
    },
    getCapacitors(context: FilmLifetimeV2Context) {
        if (context.state.seriesSelect === "" ||
            context.state.pitchSelect === "" ||
            context.state.voltageSelect === ""
        ) return;
        /* We can at least check for any of the body properties == "" and prevent
           an API call which we already know would return an empty response.
           We will need to check for empty responses, because empty responses
           can result from invalid selections (e.g. an invalid size selection
           due to the dropdowns still updating.
           Ideally, we create a flag for the component when all loading has finished.
           Need to implement some kind of loading indication anyways.
           */
        const url = filmLifetime2BaseUrl + "capacitance";
        const body = {
            series: context.state.seriesSelect,
            pitch: context.state.pitchSelect,
            voltageType: context.state.voltageType.select,
            voltage: context.state.voltageSelect
        };
        instance.post(url, body).then((response: AxiosResponse<Array<FilmLifetimeV2CapacitorModel>>) => {
            if (response.data.length === 0) {
                return;
            } else {
                const tolerance = {
                    title: "Tolerance",
                    select: "K",
                    disabled: false,
                    hidden: false,
                    options: [
                        { value: "K", label: "10%", disabled: false },
                        { value: "M", label: "20%", disabled: false }
                    ],
                } as InputDropdown;

                const capacitorList = [] as FilmLifetimeV2CapacitorModel[];
                for (let i = 0; i < response.data.length; i++) {
                    capacitorList.push({
                        series: response.data[i].series,
                        capValue: response.data[i].capValue,
                        pitch: response.data[i].pitch,
                        vac: response.data[i].vac,
                        kemetPn: response.data[i].kemetPn,
                        wildcardPn: response.data[i].wildcardPn,
                        rth: response.data[i].rth,
                        relativeTime: response.data[i].relativeTime,
                        maxTemp: response.data[i].maxTemp,
                        status: response.data[i].status,
                        theta1: response.data[i].theta1,
                        theta2: response.data[i].theta2,
                        theta3: response.data[i].theta3,
                        theta4: response.data[i].theta4,
                        theta5: response.data[i].theta5,
                        theta6: response.data[i].theta6,
                        theta7: response.data[i].theta7,
                        theta8: response.data[i].theta8,
                        theta9: response.data[i].theta9,
                        theta10: response.data[i].theta10,
                        theta11: response.data[i].theta11,
                        theta12: response.data[i].theta12,
                        theta13: response.data[i].theta13,
                        theta14: response.data[i].theta14,
                        esrCorrection30: response.data[i].esrCorrection30,
                        esrCorrection60: response.data[i].esrCorrection60,
                        average: response.data[i].average,
                        average1: response.data[i].average1,
                        average2: response.data[i].average2,
                        vmod: response.data[i].vmod,
                        tempStatic1: response.data[i].tempStatic1,
                        tempStatic2: response.data[i].tempStatic2,
                        tempStatic3: response.data[i].tempStatic3,
                        tempStatic4: response.data[i].tempStatic4,
                        tempStatic5: response.data[i].tempStatic5,
                        relativeHumidityStatic1: response.data[i].relativeHumidityStatic1,
                        relativeHumidityStatic2: response.data[i].relativeHumidityStatic2,
                        relativeHumidityStatic3: response.data[i].relativeHumidityStatic3,
                        relativeHumidityStatic4: response.data[i].relativeHumidityStatic4,
                        relativeHumidityStatic5: response.data[i].relativeHumidityStatic5,
                        voltageVacStatic1: response.data[i].voltageVacStatic1,
                        voltageVacStatic2: response.data[i].voltageVacStatic2,
                        voltageVacStatic3: response.data[i].voltageVacStatic3,
                        voltageVacStatic4: response.data[i].voltageVacStatic4,
                        voltageVacStatic5: response.data[i].voltageVacStatic5,
                        lifetimeStatic1: response.data[i].lifetimeStatic1,
                        lifetimeStatic2: response.data[i].lifetimeStatic2,
                        lifetimeStatic3: response.data[i].lifetimeStatic3,
                        lifetimeStatic4: response.data[i].lifetimeStatic4,
                        lifetimeStatic5: response.data[i].lifetimeStatic5,
                        relativeTimeTwo: response.data[i].relativeTimeTwo,
                        maxRatedLifetime: response.data[i].maxRatedLifetime,
                        maxSelfHeating: response.data[i].maxSelfHeating,
                        minTemp: response.data[i].minTemp,
                        ratedTemp: response.data[i].ratedTemp,
                        v70: response.data[i].v70,
                        v85: response.data[i].v85,
                        v105: response.data[i].v105,
                        v115: response.data[i].v115,
                        v125: response.data[i].v125,
                        v135: response.data[i].v135,
                        hasDcModel: response.data[i].hasDcModel,
                        vdc: response.data[i].vdc,
                        tolerance: tolerance,
                        displayAllCeProps: false,
                        ceProps: [] as ComponentEdgeParameterDto[],
                        statusDC: response.data[i].statusDC
                    } as FilmLifetimeV2CapacitorModel);
                }
                context.commit("setCapacitors", capacitorList);
            }
        });
    },

    // Part Selection
    addCapacitor(context: FilmLifetimeV2Context, payload: FilmLifetimeV2CapacitorModel) {
        if (context.state.parts.length < simulationPartMaximum) {
            let partExists = false; // Assume part not in list to begin
            if (context.state.parts != null) { // If list not empty, let's search it
                for (let i = 0; i < context.state.parts.length; i++) { // Check each part
                    if (context.state.parts[i].kemetPn === payload.kemetPn) { // Identify based on part number
                        partExists = true; // If match, we won't add part below
                    }
                }
            }
            if (!partExists) {
                // Add to table
                context.commit("addCapacitor", payload);
                context.dispatch("fetchCeProps", (payload));

                // Hit CE for temperature and voltage limits
                context.dispatch("fetchPartExtremes",
                    { partNumber: payload.kemetPn, partIndex: context.state.parts.length - 1 }
                );
            }
        } else {
            alert(maxPartsAlert(simulationPartMaximum));
        }
    },
    fetchCeProps(context: FilmLifetimeV2Context, payload: { kemetPn: string, capType: string }) {
        instance.post("/api/ce/params/", { partNumber: payload.kemetPn, capType: "Film" } as ComponentEdgeParameterRequest)
            .then((response) => {
                let pn = payload.kemetPn;
                context.commit("setCeDetails", { pn, ceProps: response.data });
            });
    },
    fetchPartExtremes(context: FilmLifetimeV2Context, payload: { partNumber: string, partIndex: number }) {
        instance.post(ceParametersUrl, {
            partNumber: payload.partNumber,
            parameterNames: [
                filmLifetimeExtremes.minTemp,
                filmLifetimeExtremes.maxTemp,
                filmLifetimeExtremes.maxVoltageAc
            ]
        } as ComponentEdgeParameterRequest)
            .then((response: ComponentEdgeParameterResponse) => {
                // Use retrieved values for the new part
                context.commit("setPartExtremes",
                    {
                        partIndex: payload.partIndex,
                        minTemp: parseFloat(response.data.filter(param => param.parameterName === filmLifetimeExtremes.minTemp)[0].value),
                        maxTemp: context.state.parts[context.state.highlight].maxTemp,
                        maxVoltageAc: parseFloat(response.data.filter(param => param.parameterName === filmLifetimeExtremes.maxVoltageAc)[0].value),
                    });

                // Ensure profile extremes are valid for all parts
                context.commit("updateProfileExtremes");
            });
    },
    removeCapacitor(context: FilmLifetimeV2Context, partNumber: string) {
        for (let i = 0; i < context.state.parts.length; i++) {
            // Remote part with matching pn
            if (context.state.parts[i].kemetPn === partNumber) {
                // Remove part by index
                context.commit("removeCapacitor", i);

                // Update profile extremes without part
                /*                context.commit("updateProfileExtremes");*/

                // Re-render table
                context.commit("incrementComponentKey");
                break;
            }
        }
    },
    setErrorStatus(context: FilmLifetimeV2Context, hasError: boolean) {
        context.commit("setErrorStatus", hasError);
    },
    setHighlight(context: FilmLifetimeV2Context, index: number) {
        context.commit("setHighlight", index);
    },
    setApplicationType(context: FilmLifetimeV2Context, payload: boolean) {
        context.commit("setApplicationType", payload);
    },
    setProfileMaxVoltage(context: FilmLifetimeV2Context, vMax: number) {
        context.commit("setProfileMaxVoltage", vMax);
    },
    setProfileMaxOverlayVac(context: FilmLifetimeV2Context, payload: { profileIndex: number, max: number }) {
        context.commit("setProfileMaxOverlayVac", payload);
    },
    setProfileMaxVoltageDC(context: FilmLifetimeV2Context, data: FilmMaxVoltageRequest) {
        context.commit("setProfileMaxVoltageDC", data);
    },
    setProfileMinTemp(context: FilmLifetimeV2Context, tMin: number) {
        context.commit("setProfileMinTemp", tMin);
    },
    setProfileMaxTemp(context: FilmLifetimeV2Context, tMax: number) {
        context.commit("setProfileMaxTemp", tMax);
    },
    /* Calculations */
    async fetchLifetimeV2(context: FilmLifetimeV2Context) {
        let profiles = context.state.profileList;
        let profilesRequest: FilmLifetimeV2ProfileRequest[] = [];

        profiles.forEach(profiles => {
            let profile = {
                voltage: profiles.voltage.select === null ? 0 : profiles.voltage.select,
                voltageDc: profiles.voltageDC.select === null ? 0 : profiles.voltageDC.select,
                fundamentalFrequency: profiles.fundamentalFrequency.select,
                tempAmbient: profiles.tempAmbient.select.toString() === null ? 0 : profiles.tempAmbient.select,
                relativeHumidity: profiles.relativeHumidity.select,
                operatingPercentage: profiles.operatingPercentage.select,
                currentFrequency: [],
                overlayVac: {
                    overlayVac: profiles.overlayVac.overlayVac.select.toString() === "" ? 0 : profiles.overlayVac.overlayVac.select,
                    frequency: profiles.overlayVac.frequency.select.toString() === "" ? 0 : profiles.overlayVac.frequency.select
                }
            } as FilmLifetimeV2ProfileRequestToBackend;

            profiles.currentFrequency.forEach(currentFrequencyList => {
                profile.currentFrequency.push({
                    current: currentFrequencyList.current.select.toString() === "" ? 0 : currentFrequencyList.current.select,
                    frequency: currentFrequencyList.frequency.select.toString() === "" ? 0 : currentFrequencyList.frequency.select ?? 0
                } as FilmLifetimeV2Current);
            });
            profilesRequest.push(profile as FilmLifetimeV2ProfileRequest);
        });

        let overlayVacError = 0;
        profiles.forEach(profile => {
            if ((profile.overlayVac.overlayVac.max < 0 && profile.overlayVac.overlayVac.select > 0) || (profile.overlayVac.overlayVac.max > 0 && profile.overlayVac.overlayVac.select > profile.overlayVac.overlayVac.max)) {
                overlayVacError++;
            }
        });

        if (overlayVacError > 0) return;

        await instance.post("/api/filmLifetime/", {
            parts: context.getters.request.parts,
            profileList: profilesRequest,
            isVac: context.state.isVac,
            session: window.localStorage.getItem('uuid')
        }).then((response: any) => {
            context.commit("setErrorStatus", false);
            context.commit("setLifetimeHoursTotal", response.data.lifetimeHoursTotal);
            context.commit("setProfileData", response.data.profileList);
            context.commit("setProfileMaxTemp", context.state.parts[context.state.highlight].maxTemp)
            for (var i = 0; i < response.data.profileList.length; i++) {
                for (var profileListIndex = 0; i < response.data.profileList[i].length; profileListIndex++) {
                    !context.state.isVac
                        ? context.commit("setProfileMaxVoltageDC", { maxVoltage: response.data.profileList[i][profileListIndex].maxOperatingVoltage, partIndex: i, profileIndex: profileListIndex } as FilmMaxVoltageRequest)
                        : response.data.profileList.forEach(p => context.commit("setProfileMaxVoltage", { maxVoltage: response.data.profileList[i][profileListIndex].maxOperatingVoltage, partIndex: i, profileIndex: profileListIndex } as FilmMaxVoltageRequest))
                }
            }
        }).catch((error) => {
            if (error.statusCode === 500) {
                context.commit("setErrorStatus", true);
            } else {
                context.commit("setErrorStatus", false);
            }
        });
    },
    addNewProfile(context: FilmLifetimeV2Context) {
        context.commit("addNewProfile");
    },
    duplicateProfile(context: FilmLifetimeV2Context, index: number) {
        context.commit("duplicateProfile", index);
    },
    removeProfile(context: FilmLifetimeV2Context, index: number) {
        if (context.state.profileList.length === 1) {
            alert("Cannot remove the only profile.");
            return;
        }
        context.commit("removeProfile", index);
    },

    /* Force render */
    incrementComponentKey(context: FilmLifetimeV2Context) {
        context.commit("incrementComponentKey");
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
