<template>
    <div v-if="(parts.length > 0) && ((isVac && parts[highlight].status && parts[highlight].status === 'ACTIVE') || (!isVac && parts[highlight].statusDC && parts[highlight].statusDC === 'ACTIVE')) " :key="highlight">
        <ErrorMessage>
        </ErrorMessage>
        <!--Lifetime Hours-->
        <ErrorMessage v-if="errorFetchingData">
            <h3>Error fetching data, please try again. If this error continues please reload the page.</h3>
        </ErrorMessage>
        <div v-if="workingTimeValid && voltageValid && tempValid && relativeHumidityValid && !errorFetchingData">
            <div v-if="lifetimeHoursTotal.length > 0">
                <h2>
                    <span v-if="isVac">Operating Lifetime Expectancy [ΔC/C=-20%]:</span>
                    <span v-else>Operating Lifetime Expectancy [ΔC/C=-10%]:</span>
                    {{ lifetimeHoursTotal[highlight] | precision(0) | commas }} hrs --
                    {{ lifetimeHoursTotal[highlight] / (24*356) | precision(1) | commas }} yrs
                </h2>
                <i>note: Maximum Rated Lifetime is limited to {{parts[highlight].maxRatedLifetime.toLocaleString("en-US")}} hours.</i>
            </div>
        </div>
        <!--Error Indication-->
        <ErrorMessage v-else-if="!workingTimeValid && voltageValid && tempValid && relativeHumidityValid">
            <h2>The <u>Working Time (%)</u> must add to 100.</h2>
        </ErrorMessage>
        <ErrorMessage v-else-if="workingTimeValid && !voltageValid && tempValid && relativeHumidityValid && (parts[highlight].status === 'ACTIVE' || parts[highlight].statusDC === 'ACTIVE')">
            <h2>A profile exceeds the maximum rated <u>voltage</u> for this part.</h2>
        </ErrorMessage>
        <ErrorMessage v-else-if="workingTimeValid && voltageValid && !tempValid && relativeHumidityValid">
            <h2>A profile exceeds the maximum rated <u>temperature</u> for this part.</h2>
        </ErrorMessage>
        <ErrorMessage v-else-if="workingTimeValid && voltageValid && tempValid && !relativeHumidityValid">
            <h2>A profile exceeds the maximum <u>relative humidity</u> for this part.</h2>
        </ErrorMessage>
    </div>
</template>

<script lang="ts">
import { createNamespacedHelpers } from 'vuex';
import ErrorMessage from './ErrorMessage.vue';
const { mapState, mapGetters } = createNamespacedHelpers('filmLifetimeV2');

import Vue from 'vue';

export default Vue.extend({
  components: {
    ErrorMessage
  },
  computed: {
    ...mapState([
      'componentKey',
      'highlight',
      'lifetimeHoursTotal',
      'parts',
      'errorFetchingData',
      'isVac',
      'hasDcModel'
    ]),
    ...mapGetters([
      'relativeHumidityValid',
      'tempValid',
      'voltageValid',
      'workingTimeValid'
    ])
  },
  filters: {
    capitalizeFirstChar: function (value: string) {
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    precision: function (value: string, digits: number) {
      let n = parseFloat(value).toFixed(digits);
      if (n != n) { // check if NaN
        return '0'
      }
      return n
    },
    commas: function (value: string) {
      let returnString = value;
      let indexOfDecimal = returnString.indexOf(".");
      let startIndex = -1;

      if (indexOfDecimal === -1) // no decimals
        startIndex = returnString.length - 3;
      else
        startIndex = indexOfDecimal - 3;

      for (let i = startIndex; i > 0; i = i - 3) {
        returnString = returnString.slice(0, i) + "," + returnString.slice(i);
      }

      return returnString;
    }
  },
})
</script>

<style scoped>
</style>
