import Vue from "vue";
import {CD_URL_BASE_PATH, DATASHEET, ROHS, SPEC, STEP} from "@/util/Urls";
export const linkMixin = Vue.extend({
    data() {
        return {
            //PDFs
            specUrl: CD_URL_BASE_PATH+SPEC,
            dsUrl: CD_URL_BASE_PATH + DATASHEET,
            stepUrl: CD_URL_BASE_PATH+STEP,
            rohsUrl: CD_URL_BASE_PATH+ROHS,
            //Part Detail Search
            //partDetailSearchUrl: "https://www.kemet.com/en/us/search.html?q=",
            partDetailSearchUrl: "https://www.kemet.com/en/us/capacitors/ceramic/product/",
            //URL PN Parameter
            ksimPnParam: "https://ksim3.kemet.com/?pn="
        }
    },
    computed: {
    },
    methods: {
    }
});