// Modules
//// Capacitor Simulation 
import calcZ from "./modules/calcZ"
import ceramic from "./modules/ceramic"
import polymer from "./modules/polymer"
import tantalum from "./modules/tantalum"
import aluminum from "./modules/aluminum"
import film from "./modules/film"
//// Ceramic Aging
import aging from "./modules/aging"
//// Film Lifetime
//// Film Lifetime V2
import filmLifetimeV2 from "./modules/filmLifetimeV2"
//// Cap. from Power
import capFromPower from "./modules/capFromPower"
//// Inductor Simulation 
import calcL from "./modules/calcL"
import inductor from "./modules/inductor"
//// Seed Gen 
import seed from "./modules/seed"
import pac from "./modules/pac";

import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
export default new Vuex.Store({
    modules: {
        // Capacitor Simulation 
        calcZ,
        ceramic,
        polymer,
        tantalum,
        aluminum,
        // Ceramic Aging
        aging,
        film,
        // Film Lifetime V2 
        filmLifetimeV2,
        // Cap. from Power
        capFromPower,
        // Inductor Simulation 
        calcL,
        inductor,
        // Seed
        seed,
        pac,
    }
});
