<template>
    <table class="it-table" v-if="items.length > 0" :key="componentKey">
        <tr class="it-table_row" v-for="(item, index) in items" :key="index">
            <input type="checkbox"
                   class="e-checkbox"
                   :id="`inductance-${index}`"
                   :checked="inPartsList(item.pn)"
                   :value="item"
                   @change="changePart($event, item)" />
            <label class="e-label it-table_item"
                   :for="`inductance-${index}`">

                <span class="it-table_value">{{ item.value | showUnit }}</span>
                <span class="it-table_part">{{ item.pn }}</span>
            </label>
        </tr>
    </table>
</template>

<script lang="ts">
    /* InductorResults is separate component from CapacitorResults because API
     * returns data differently than SPICE db.
     * Per Andy's comment about doing API calls on the backend instead of front-end...
     * this would allow us to return the data in a more consistent fashion.
     * The same is true for doing API calls on the back-end for every plot type...
     * Since some will use the local CalcZ.dll and some will use the API,
     * by making the calls on the back-end, we can return the data consistently
     * to the front-end regardless of where it comes from. */

    import { createNamespacedHelpers } from 'vuex';
    const { mapState, mapActions } = createNamespacedHelpers('calcL');

    import Vue from 'vue'
    export default Vue.extend({
        inheritAttrs: false,
        data() {
            return {
                lastClicked: '',
            }
        },
        props: {
            items: {
                type: Array,
                required: true
            },
        },
        computed: {
            ...mapState([
                'parts',
                'componentKey'
            ])
        },
        methods: {
            ...mapActions([
                'addPart',
                'removePartByPn'
            ]),
            //TODO: create this common function in a mixin
            changePart: function (e: any, item: InductorModel) {
                if (e.target.checked) {
                    this.addPart(item);
                } else {
                    this.removePartByPn(item.pn);
                }
            },
            //TODO: create this common function in a mixin
            inPartsList: function (partNumber: string) {
                for (let i = 0; i < this.parts.length; i++) {
                    if (partNumber === this.parts[i].pn) {
                        return true;
                    }
                }
                return false;
            }
        },
        filters: {
            // Copied from function for generating tick marks in chart.js
            // Order of Magnitude function: https://gist.github.com/ajmas/4193ac6911f5445ced37
            showUnit: function (value: string) {
                let val = parseFloat(value)

                // Note: already in uF, calculate for smaller values
                var unitExponentsNumber = [-9, -6, -3, 0];
                var unitExponentsString = ['n', 'u', 'm', ''];

                var sign = (val < 0 ? -1 : 1); // Determine if negative
                val = Math.abs(val);
                var i = 0; var idx = 0;

                // Find correct order of magnitude
                for (i = unitExponentsNumber.length - 1; i >= 0; i--) {
                    if (val >= Math.pow(10, unitExponentsNumber[i])) {
                        idx = i;
                        break;
                    }
                }

                // Divide by order of magnitude
                val = (val / Math.pow(10, unitExponentsNumber[idx]));
                val = val * sign;

                // Limit to two decimal points
                var decimal = val - Math.floor(val)
                if (decimal != 0) {
                    val = +val.toFixed(2)
                }

                // Return a string using the order of magnitude prefix
                return val + ' ' + unitExponentsString[idx] + 'H';
            }
        }
    })
</script>

<style scoped>
    /* Inductance Table (it-) */

    .it-table {
        width: 100%;
    }

    .it-table_row,
    .it-table_item {
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        width: 100%;
        font-weight: normal;
        font-size: 0.95em;
    }

    .it-table_value {
        flex: 0 0 6ch;
    }

    .it-table_part::before {
        content: '-';
        padding-right: 1ch;
    }
</style>