import { render, staticRenderFns } from "./base-input-number.vue?vue&type=template&id=228267de&scoped=true"
import script from "./base-input-number.vue?vue&type=script&lang=ts"
export * from "./base-input-number.vue?vue&type=script&lang=ts"
import style0 from "./base-input-number.vue?vue&type=style&index=0&id=228267de&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "228267de",
  null
  
)

export default component.exports