import pointStyles from "./PointStyles";

export const unitExponentsNumber = [-12, -9, -6, -3, 0, 3, 6, 9];
export const unitExponentsString = ["p", "n", "u", "m", "", "k", "M", "G"];
export const exponentPrefixes: { [key: string]: string; } = {
    "-12": "p",
    "-9": "n",
    "-6": "u",
    "-3": "m",
    "0": "",
    "3": "k",
    "6": "M",
    "9": "G"
}

export function calculateSciNotationExponentIndex(value: number) {
    let idx = 0;
    value = Math.abs(value);
    for (let i = unitExponentsNumber.length - 1; i >= 0; i--) {
        if (value >= Math.pow(10, unitExponentsNumber[i])) {
            idx = i; break;
        }
    }
    return idx;
}

export function calculateSciNotationExponent(value: number, low = -15, high = 15) {
    value = Math.abs(value);
    for (let i = high; i >= low; i -= 3) {
        const floor = Math.pow(10, i);
        if (value >= floor) {
            return i;
        }
    }
    return -1;
}

function firstDigitOfNum(n: number) {
    // ReSharper disable once TsNotResolved
    const digit = n / (Math.pow(10, Math.floor(Math.log10(n))));
    if (digit !== digit) /*if NaN*/
        return 0;
    return Math.round(digit);
}

export function normalizedToExponent(value: number, exponent: number) {
    const norm = Math.abs(value) / Math.pow(10, exponent); //e.g. 1, 10, 100
    return norm;
}

export function unitConversion(value: number, unit: string, precision: number = 1) {
    // order of magnitude function:
    // https://gist.github.com/ajmas/4193ac6911f5445ced37

    const sign = (value < 0 ? -1 : 1);
    value = Math.abs(value);
    let idx = 0;
    for (let i = unitExponentsNumber.length - 1; i >= 0; i--) {
        if (value >= Math.pow(10, unitExponentsNumber[i])) {
            idx = i;
            break;
        }
    }
    value = (value / Math.pow(10, unitExponentsNumber[idx]));
    value = value * sign;

    // Only show decimal if there is one (e.g. don't show 10.0 kHz, just show 10 kHz)
    const decimal = value - Math.floor(value);
    if (decimal !== 0) {
        value = +value.toFixed(precision);
    }

    return value + " " + unitExponentsString[idx] + unit;
};

export function plotTick(
    value: /*potential tick*/ number,
    index: /*value index in arr*/ number,
    arr: /*values*/ number[],
    trailingDigits: /*useful when zoomed*/ number = 1,
    digits: /*whitelist first digits*/ number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    unit: string = "unit"
) {
    const firstDigit = firstDigitOfNum(value);

    // If 0 return without magnitude
    if (firstDigit === 0) return `0 ${unit}`;

    // Only return tick when at start & end or if firstDigit is in digits
    // ReSharper disable once TsResolvedFromInaccessibleModule
    if (!(digits.includes(firstDigit) || index === 0 || index === arr.length - 1))
        return "";

    // Find tick exponent and prefix: e.g. 1000 (k), 1000000 (M), etc
    const tickExponent = calculateSciNotationExponent(value);
    const tickPrefix = exponentPrefixes[`${tickExponent}`];

    // "Normalize" tick relative to exponent value 
    let tick = normalizedToExponent(value, tickExponent);

    // If too close to end ticks, don't return a tick
    const differenceRatio = 0.1; // Increase to widen "dead zone" where ticks are not shown close to ends
    const startDifferenceRatio = Math.abs(value - arr[0]) / arr[0];
    const endDifferenceRatio = Math.abs(value - arr[arr.length - 1]) / arr[arr.length - 1];
    if (
        (startDifferenceRatio < differenceRatio) && (startDifferenceRatio > 0)
        || (endDifferenceRatio < differenceRatio) && (endDifferenceRatio > 0)
    ) return "";

    // Trim digits after decimal
    let trimmedTick = "";
    // At edges
    if (value === arr[0] || value === arr[arr.length - 1]) {
        const decimalThreshold = 0.1; // If sufficiently close to integer, don't show
        const numEdgeDecimals = 1; // Num decimals to show, if showing 

        // Tick in question
        const roundedTick = Math.round(tick);
        const difference = Math.abs(roundedTick - tick);

        if (difference < decimalThreshold)
            trimmedTick = tick.toFixed(0);
        else
            trimmedTick = tick.toFixed(numEdgeDecimals);
    }
    // All points
    else {
        trimmedTick = tick.toFixed(trailingDigits);
    }

    return trimmedTick + " " + tickPrefix + unit;
};

export function plotTooltip(label: string, value: number, unit: string, trailingDigits: number = 2, digitWidth: number = 100) {
    let idx = 0;
    for (let i = unitExponentsNumber.length - 1; i >= 0; i--) {
        if (value >= Math.pow(10, unitExponentsNumber[i])) {
            idx = i;
            break;
        }
    }
    value = (value / Math.pow(10, unitExponentsNumber[idx]));

    let digits = 0;
    // Only show one digit if low value, otherwise, it is useless information to process for user
    if (value > -digitWidth && value < digitWidth)
        digits = trailingDigits;
    else if (value <= -digitWidth || value >= digitWidth)
        digits = 1;

    var l = label.split("-");
    if (l.length === 2 && l[1].trim() === "Inductance" && value === 0) {
        return "";
    }

    return label + ": "
        + value.toFixed(digits) + " " + unitExponentsString[idx] + unit;
};

export function createDatasets(plotSeries: PlotSeries[], appliedStyles: number[], highlight: number, combine: boolean, calculated = false, twoAxes = false) {
    // If calculated enabled, there are 4 series per part
    const numDatasetsPerPart = calculated ? 4 : 2;

    // If combined enabled, start at index 0, otherwise skip first series
    const startIndex = combine ? 0 : numDatasetsPerPart;

    // Skip data points so that point icons don't overlap
    const pointSkipModulus = 2;

    // Clear datasets before adding 
    const datasets = [] as any[];

    // Create a dataset for each series
    for (let i = startIndex; i < plotSeries.length; i++) {

        // Plot points
        let data = plotSeries[i].points;
        // Skip some points
        if (pointSkipModulus > 1) {
            data = data.filter((element, index) => {
                return index % pointSkipModulus === 0;
            });
        }
        
        // Legend text
        const label = plotSeries[i].kemetPn + " - " + plotSeries[i].yLabel;

        // Legend icon
        const partTableIndex = Math.floor(i / numDatasetsPerPart);
        const pointStyleIndex = appliedStyles[partTableIndex];

        // Legend order
        const order = (highlight === partTableIndex) ? 0 : partTableIndex + 1;

        // Line width
        const borderWidth = (highlight === partTableIndex) ? 10 : 1;

        // Opacity: determine primary or secondary opacity
        const opacityIndex = (i % 2 === 0) ? 0 : 1;

        // Build ChartJs dataset
        const dataset = {
            backgroundColor: pointStyles[pointStyleIndex].color[opacityIndex],
            borderColor: pointStyles[pointStyleIndex].color[opacityIndex],
            borderWidth,
            data,
            fill: false,
            interpolate: true,
            label,
            order,
            pointStyle: pointStyles[pointStyleIndex].point,
            pointRadius: pointStyles[pointStyleIndex].radius,
            pointBorderWidth: pointStyles[pointStyleIndex].width,
            rotation: pointStyles[pointStyleIndex].rotation,
            showLine: true,
        };

        // When two axes: datasets needs `yAxisID` property to match ChartJs options.yAxis[n].id property
        if (twoAxes) {
            // Default axisId for all plots except below
            let axisId = plotSeries[i].yLabel;

            // Special case: Capacitance & Inductance chart when Calculated enabled
            if (axisId === "Calculated Cap") axisId = "Capacitance";
            if (axisId === "Calculated ESL") axisId = "Inductance";

            // @ts-ignore
            dataset["yAxisID"] = axisId;
        }

        datasets.push(dataset);

    }

    return datasets;
}
