<template>
    <div>
        <div class="cap-title">K-LEM: Life Expectancy Model v3.0</div>
        <div class="e-panel:3 l-box:inset2 cap-plot_outer">
            <ToggleButtons />
            <div class="errorMessage" v-if="parts.length > 0 && (!isVac && (parts[highlight].statusDC != 'ACTIVE') && (parts[highlight].statusDC != 'STATIC'))">
                <span>DC model is not available for this part</span>
            </div>
            <div class="errorMessage" v-if="parts.length > 0 && (isVac && parts[highlight].status != 'ACTIVE' && parts[highlight].status != 'STATIC')">
                <span>AC model is not available for this part</span>
            </div>
            <div v-if="parts.length > 0 && ((isVac && (parts[highlight].status === 'ACTIVE' || parts[highlight].status === 'STATIC' )) || (!isVac && (parts[highlight].statusDC === 'ACTIVE' || parts[highlight].statusDC === 'STATIC')))">
                <FilmStaticData v-if="parts[highlight].status == 'STATIC' && isVac || (parts[highlight].statusDC === 'STATIC' && !isVac)" />

                <FilmActiveData v-if="((parts[highlight].status == 'ACTIVE' && isVac) || (!isVac && parts[highlight].hasDcModel))" />
            </div>

            <br />
            <!--Results-->
            <Results v-if="(parts.length > 0) && ((parts[highlight].status !== 'STATIC' && isVac) || (!isVac && parts[highlight].hasDcModel))" />

            <br />
            <!--Parts Table-->
            <FilmTable :key="'filmTable'+componentKey" />
        </div>
    </div>
</template>

<script lang="ts">
    import { createNamespacedHelpers } from 'vuex';
    const { mapState, mapActions } = createNamespacedHelpers('filmLifetimeV2');

    import FilmTable from './FilmTable.vue';
    import FilmStaticData from './FilmStaticData.vue';
    import FilmActiveData from './FilmActiveData.vue';
    import Results from './Results.vue';
    import Vue from 'vue';
    import ToggleButtons from "@/components/filmLifetimeV2/Togglebuttons.vue";

    export default Vue.extend({
        components: {
            FilmTable,
            FilmStaticData,
            FilmActiveData,
            Results,
            ToggleButtons,
        },
        data() {
            return {
            };
        },
        computed: {
            ...mapState([
                'componentKey',
                'highlight',
                'parts',
                'hasDcModel',
                'isVac'
            ])
        },
        mounted() {
            const partNumber = this.$route.query.part;
            if (partNumber) {
                // Add parts & restore plot
                const distributor = this.$route.query.disty;
                const method = distributor ? "disty-" + distributor : "URL";
                this.addCapacitorByPartNumber({
                    partNumber,
                    method,
                });
                // Close sidebar if link from distributor
                if (distributor) {
                    var sidebar = document.getElementsByClassName(
                        "sidebar_toggle"
                    )[0] as HTMLElement;
                    sidebar.click();
                }
            }
        },
        methods: {
            ...mapActions([
                'fetchLifetimeV2',
                'addCapacitorByPartNumber'
            ]),
        },
        watch: {
            parts: {
                handler: function () {
                    if (this.parts.length > 0) {
                        if (this.parts[this.highlight].maxTemp === null) {
                            return;
                        }
                        this.fetchLifetimeV2();
                    }
                },
                deep: true,
                immediate: false
            }
        }
    })
</script>

<style scoped>
    .errorMessage {
        color: red;
        font-style: italic;
        margin-bottom: 10px;
        font-size: 1.5em;
    }
</style>
