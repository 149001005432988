<template>
    <div class="baseInputText l-field">
        <label :title="name" class="e-label">
            {{title}}
            <span v-if="isRequired">*</span>
        </label>
        <input
               type="text"
               class="e-input"
               :placeholder="placeholder"
               :disabled="disabled"
               :style="baseStyle"
               :width="width"
               v-model="local_value"
               :required="isRequired ? true : false"
               @change="$emit('change', local_value)"
               @input="$emit('input', local_value)"
        />
    </div>
</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        inheritAttrs: false,
        props: {
            isRequired: {
                type: Boolean,
                required: false,
                default: false
            },
            value: {
                type: String,
                required: true
            },
            //Not required
            title: {
                type: String,
                required: false 
            },
            width: {
                type: Number,
                required: false,
                default: 50
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false 
            },
            placeholder: {
                type: String,
                required: false,
                default: "Enter some text"
            },
            name: {
                type: String,
                required: false
            }
        },
        data() {
            return {
                local_value: this.value,
                baseStyle: { '--width': this.width + 'px'} // use in CSS
            }
        },
    })
</script>

<style scoped>
    .baseInputText {
        text-align: center;
    }


    .e-input {
        width: var(--size3);

        appearance: textfield;

        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
    }

</style>