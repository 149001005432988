<template>
    <div>
        <BaseAccordion :title="'Polymer'">
            <BaseDropdown v-if="this.$route.path === '/seed'"
                          title="Part Status"
                          :options="statusOptions"
                          v-model="statusSelect"
                          @change="setStatusSelect" />
            <BaseDropdown
                       title="Series"
                       v-model="seriesSelect"
                       :options="seriesOptions"
                       @change="setSeriesSelect"/>
            <BaseDropdown
                        title="Size"
                        v-model="sizeSelect"
                        :options="sizeOptions"
                        @change="setSizeSelect"/>
            <BaseDropdown
                        title="Termination"
                        v-model="terminationSelect"
                        :options="terminationOptions"
                        @change="setTerminationSelect"/>
            <BaseDropdown
                        title="Voltage Rating (V)"
                        v-model="voltageSelect"
                        :options="voltageOptions"
                        @change="setVoltageSelect"/>
        </BaseAccordion>
        <BaseAccordion title="Available Parts" class="e-parts">
            <CapacitorResults :items="capacitorList"/>
        </BaseAccordion>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue';

    import CapacitorResults from './CapacitorResults.vue';

    import { createNamespacedHelpers } from 'vuex';
    const { mapState, mapActions } = createNamespacedHelpers('polymer');

    export default Vue.extend({ //with TypeScript we need to do this every time?
        components: {
            CapacitorResults,
        },
        mounted() {
            this.getSeriesOptions();
        },
        computed: {
            ...mapState([
                'statusSelect',
                'statusOptions',
                'seriesSelect',
                'seriesOptions',
                'sizeSelect',
                'sizeOptions',
                'terminationSelect',
                'terminationDefaults',
                'terminationOptions',
                'voltageSelect',
                'voltageOptions',
                'capacitorList'
            ]),
        },
        methods: {
            ...mapActions([
                'setStatusSelect',
                'setSeriesSelect',
                'setSizeSelect',
                'setTerminationSelect',
                'setVoltageSelect',
                'getSeriesOptions',
                'getSizeOptions',
                'getTerminationOptions',
                'getVoltageOptions',
                'getCapacitors'
            ]),
        },
        watch: {
            statusSelect() {
                this.getSeriesOptions();
                this.getSizeOptions();
                this.getTerminationOptions();
                this.getVoltageOptions();
                this.getCapacitors();
            },
            seriesSelect() {
                this.getSizeOptions();
                this.getTerminationOptions();
                this.getVoltageOptions();
                /* seriesSelect maybe doesn't need to trigger this because the
                 * BaseDropdowns themselves will in turn update the sizeSelect
                 * or voltageSelect, etc. which will then re-trigger getCapacitors()*/
                this.getCapacitors();
            },
            sizeSelect() {
                this.getVoltageOptions();
                this.getCapacitors();
            },
            terminationSelect() {
                this.getCapacitors();
            },
            voltageSelect() {
                this.getCapacitors();
            }
        },
    })
</script>

<style>

</style>