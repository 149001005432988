<template>
    <div class="loading-container-outer">
        <div class="loading-container-inner">
            <vue-loading type="beat" color="#3081c0" :size="{ width: '75px', height: '75px' }"></vue-loading>
        </div>
        <p>Loading...</p>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue';
    import { VueLoading } from 'vue-loading-template';

    export default Vue.extend({
        components: {
            VueLoading
        },
    })
</script>

<style scoped>
    .loading-container-outer {
        position: absolute;
        background: rgba(255, 255, 255, 0.6);
        top: 0px;
        right: 0px;
        width: 100%;
        height: 100%;
        z-index: 1;
        animation: popin 0.75s;

        @keyframes popin {
            0% {
                opacity: 0;
            }

            49% {
                opacity: 0;
            }

            50% {
                opacity: 1;
            }
        }
    }

        .loading-container-outer p {
            text-align: center;
            font-size: 22px;
        }

    .loading-container-inner {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 150px;
        width: 150px;
        top: 50%;
        transform: translateY(-50%);
        margin: 0 auto;
        z-index: 2;
    }
</style>