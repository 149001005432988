<template>
    <div class="l-box:inset2">
        <div class="l-inline" style="--loc-inline-spacing: 1rem;">

            <!--Plot Type Dropdown-->
            <BaseDropdown v-if="(capacitorType === 'film' && this.parts.length <= 1) || (isAllFilm && this.parts.length > 1)"
                          :title="plotType.title"
                          :options="plotType.options.filter(x => filmCharts.includes(x.label))"
                          v-model="plotType.select"
                          @change="(value) => (plotType.select = value)" />

            <BaseDropdown v-else-if="(this.parts.some(part => seriesNotAvailableForCurrentVoltage.includes(part.series)))"
                          :title="plotType.title"
                          :options="plotType.options.filter(x => (x.label != 'Current & Voltage'))"
                          v-model="plotType.select"
                          @change="(value) => (plotType.select = value)" />

            <BaseDropdown v-else
                          :title="plotType.title"
                          :options="plotType.options"
                          v-model="plotType.select"
                          @change="(value) => (plotType.select = value)" />

            <!--Shared Inputs-->
            <BaseRadio v-for="radio in shared.radios"
                       :key="radio.title"
                       v-if="!radio.hidden"
                       :title="radio.title"
                       :options="radio.options"
                       :disabled="radio.disabled"
                       v-model="radio.select"
                       @change="(value) => (radio.select = value)" />

            <div v-if="plotType.select === 'MultFreq'">
                <div class="l-inline"
                     style="--loc-inline-spacing: 1rem;">

                    <BaseInputNumber v-for="input in shared.inputs"
                                     :key="input.title"
                                     v-if="!Array.isArray(input) && !input.hidden"
                                     :title="input.title"
                                     :width="input.width"
                                     :min="input.min"
                                     :max="input.max"
                                     :step="input.step"
                                     :disabled="input.disabled"
                                     v-model="input.select"
                                     @change="(value) => (input.select = value)" />

                </div>
            </div>
        </div>

        <!--Freq Inputs for TempRise vs Ripple Current @ Mult Freq-->
        <div v-if="plotType.select === 'MultFreq'">
            <div class="l-inline"
                 style="--loc-inline-spacing: 1rem;">
                <BaseInputNumber v-for="input in shared.inputs.freqList"
                                 :key="input.title"
                                 :title="'Freq #' + input.title"
                                 :width="input.width"
                                 :min="input.min"
                                 :max="input.max"
                                 :step="input.step"
                                 :disabled="input.disabled"
                                 :value="input.select"
                                 @change="(value) => (input.select = value)" />
            </div>
        </div>
        <div v-else>
            <div class="l-inline"
                 style="--loc-inline-spacing: 0.5rem;">
                <BaseDropdown v-for="dropdown in shared.dropdowns"
                              :key="dropdown.title"
                              v-if="!dropdown.hidden && isAllFilm"
                              :title="dropdown.title"
                              :disabled="dropdown.disabled"
                              :options="dropdown.options"
                              width="10"
                              v-model="dropdown.filmSelect"
                              @change="(value) => (dropdown.filmSelect = value)" />
                <BaseDropdown v-for="dropdown in shared.dropdowns"
                              :key="dropdown.title"
                              v-if="!dropdown.hidden && !isAllFilm"
                              :title="dropdown.title"
                              :disabled="dropdown.disabled"
                              :options="dropdown.options"
                              width="10"
                              v-model="dropdown.select"
                              @change="(value) => (dropdown.select = value)" />

                <div class="l-inline"
                     style="--loc-inline-spacing: 0.5rem;"
                     v-if="plotType.select !== 'MultFreq'">

                    <BaseInputNumber v-for="input in shared.inputs"
                                     :key="input.title"
                                     v-if="!Array.isArray(input) && !input.hidden && parts[highlight].capType !== 'Film'"
                                     :title="input.title"
                                     :width="input.width"
                                     :min="input.min"
                                     :max="input.max"
                                     :step="input.step"
                                     :disabled="input.disabled"
                                     v-model="input.select"
                                     @change="(value) => (input.select = value)" />

                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue from "vue";
    import { createNamespacedHelpers } from "vuex";
    const { mapState, mapGetters, mapActions } = createNamespacedHelpers("calcZ");

    export default Vue.extend({
        computed: {
            ...mapState(["shared", "parts", "plotType", "highlight", "capacitorType", "componentKey"]),
            ...mapGetters(["filmSeriesR75Exists", "validPlots"]),
        },
        data() {
            return {
                filmCharts: ["Impedance & ESR", "Capacitance & Inductance", "Current & Voltage", "SPICE Model", "Temp. Rise vs. Ripple Current"],
                c44FilmCharts: ["Impedance & ESR", "Capacitance & Inductance", "SPICE Model"],
                seriesNotAvailableForCurrentVoltage: ["C44H", "C44U-M", "C44P-R", "AC Rated (CAN Series)"],
                isAllFilm: false,
            };
        },
        beforeMount() {
            let partsArrayCopy = [...this.parts];
            partsArrayCopy.shift();
            let allFilmParts = partsArrayCopy.every((p) => p.capType === "Film") && partsArrayCopy.length > 0 || (this.parts.length === 1 && this.capacitorType === "film");
            this.isAllFilm = allFilmParts;
        },
        methods: {
            ...mapActions([
                "setShared",
                "setHighlight",
                "setPartsList",
                "incrementComponentKey",
                "resetPlotType"
            ]),
            allFilmParts() {
                let partsArrayCopy = [...this.parts];
                partsArrayCopy.shift();
                let allFilmParts = partsArrayCopy.every((p) => p.capType === "Film") && partsArrayCopy.length > 0 || (this.parts.length === 1 && this.capacitorType === "film");
                this.isAllFilm = allFilmParts;
            },
            hideAllInputParameters(parameters: CalcZParameters) {
                parameters.inputs.ind.hidden = true;
                parameters.inputs.res.hidden = true;
                parameters.inputs.tempRise.hidden = true;
                parameters.inputs.spiceFreq.hidden = true;
                parameters.radios.calculated.hidden = true;
                parameters.radios.combine.hidden = true;
                parameters.radios.path.hidden = true;
                parameters.radios.line.hidden = true;
                parameters.radios.spiceFileFormat.hidden = true;
                return parameters;
            },
        },
        watch: {
            "shared.radios.combine.select": {
                handler(watch_var) {
                    let partList: Array<CapacitorModel> = JSON.parse(
                        JSON.stringify(this.parts)
                    );

                    if (watch_var === "Yes") {
                        this.setHighlight(0);
                        for (let i = 1; i < partList.length; i++) {
                            partList[i].param.bias.disabled = true;
                            partList[i].param.tempAmbient.disabled = true;
                        }
                    } else {
                        this.setHighlight(1);
                        for (let i = 1; i < partList.length; i++) {
                            partList[i].param.bias.disabled = false;
                            partList[i].param.tempAmbient.disabled = false;
                        }
                    }

                    this.setPartsList(partList);

                    this.incrementComponentKey();
                },
                deep: false,
                immediate: false,
            },
            "plotType.select": {
                handler(watch_variable) {
                    // Make copy and set entire object at end
                    let tempShared = JSON.parse(
                        JSON.stringify(this.shared)
                    ) as CalcZParameters;

                    // Hide all dynamic inputs
                    tempShared = this.hideAllInputParameters(tempShared);

                    // Show inputs based on plot type
                    switch (watch_variable) {
                        case "Imp,ESR":
                            tempShared.inputs.res.hidden = false;
                            tempShared.inputs.ind.hidden = false;
                            tempShared.inputs.tempRise.hidden = false;
                            tempShared.radios.combine.hidden = false;
                            break;
                        case "Cap,Ind":
                            tempShared.inputs.res.hidden = false;
                            tempShared.inputs.ind.hidden = false;
                            tempShared.inputs.tempRise.hidden = false;
                            tempShared.radios.calculated.hidden = false;
                            tempShared.radios.combine.hidden = false;
                            break;
                        case "I,V":
                            tempShared.inputs.res.hidden = false;
                            tempShared.inputs.ind.hidden = false;
                            tempShared.inputs.tempRise.hidden = false;
                            tempShared.radios.combine.hidden = false;
                            break;
                        case "Aging":
                            if (this.highlight == 0) this.setHighlight(1);
                            tempShared.radios.combine.select = "No";
                            break;
                        case "S11":
                            tempShared.inputs.res.hidden = false;
                            tempShared.inputs.ind.hidden = false;
                            tempShared.inputs.tempRise.hidden = false;
                            tempShared.radios.combine.hidden = false;
                            tempShared.radios.path.hidden = false;
                            tempShared.radios.line.hidden = false;
                            break;
                        case "S21":
                            tempShared.inputs.res.hidden = false;
                            tempShared.inputs.ind.hidden = false;
                            tempShared.inputs.tempRise.hidden = false;
                            tempShared.radios.combine.hidden = false;
                            tempShared.radios.path.hidden = false;
                            tempShared.radios.line.hidden = false;
                            break;
                        case "Spice":
                            if (this.highlight == 0) this.setHighlight(1);
                            tempShared.radios.combine.select = "No";
                            tempShared.inputs.res.hidden = false;
                            tempShared.inputs.ind.hidden = false;
                            tempShared.inputs.spiceFreq.hidden = false;
                            break;
                        case "Vbias":
                            if (this.highlight == 0) this.setHighlight(1);
                            tempShared.radios.combine.select = "No";
                            break;
                        case "MultFreq":
                            if (this.highlight == 0) this.setHighlight(1);
                            tempShared.radios.combine.select = "No";
                            tempShared.inputs.res.hidden = false;
                            tempShared.inputs.ind.hidden = false;
                            tempShared.inputs.tempRise.hidden = false;
                            break;
                        case "TCC":
                            if (this.highlight == 0) this.setHighlight(1);
                            tempShared.radios.combine.select = "No";
                            break;
                        case "VCAC":
                            if (this.highlight == 0) this.setHighlight(1);
                            tempShared.radios.combine.select = "No";
                            break;
                        default:
                            break;
                    }
                    this.setShared(tempShared);
                },
                deep: false,
                immediate: true,
            },
            "capacitorType": {
                handler(watch_variable) {
                    this.allFilmParts();
                },
                deep: false,
                immediate: true,
            },
            "parts.length": {
                handler(watch_variable) {
                    this.allFilmParts();
                    if (this.plotType.select === "I,V" && this.parts.some(part => this.seriesNotAvailableForCurrentVoltage.includes(part.series))) {
                        this.resetPlotType();
                    }
                }, deep: true,
                immediate: true
            },
            filmSeriesR75Exists: {
                handler(watch_var) {
                    // instead of defining the logic here, i could just create an action and have the watcher simply call that
                    // then, the logic for everything isn't separated, and this component just hoooks stuff up together, and doesn't define anything new
                    for (let i = 0; i < this.plotType.options.length; i++) {
                        if (watch_var) {
                            // if R75 exists
                            if (
                                !(
                                    this.plotType.options[i].value === "Imp,ESR" ||
                                    this.plotType.options[i].value === "Cap,Ind" ||
                                    this.plotType.options[i].value === "I,V" ||
                                    this.plotType.options[i].value === "MultFreq"
                                )
                            ) {
                                // disable everything except the plot types above
                                this.plotType.options[i].disabled = true; //Turn stuff on again for R75 things here.
                            }
                        } else {
                            // re-enable
                            this.plotType.options[i].disabled = false;
                        }
                        this.incrementComponentKey; // disabling these properties doesn't update the dropdown, do this explicitly
                    }
                },
                deep: false,
                immediate: true,
            },
            validPlots: {
                handler(watch_var) {
                    for (let i = 0; i < this.plotType.options.length; i++) {
                        this.plotType.options[i].disabled = false; // assume valid
                        if (!watch_var.includes(this.plotType.options[i].value)) {
                            // if not in list of valid plots
                            this.plotType.options[i].disabled = true; // disable
                        }
                    }
                    this.incrementComponentKey;
                },
                deep: true,
                immediate: true,
            },
        },
    });
</script>

<style scoped>
    .flex-row {
        display: flex;
        flex-direction: row; /* this is the default */
    }
</style>
