<template>
  <div>
    <div style="margin-left: 1rem">
    </div>

    <div v-if="screenWidth<500">
      <button
          v-if="maxCap==0||minCap==0||acCurrent==0||acVoltage==0||frequency==0
            ||arrayParallel==0||arraySeries==0"
          disabled
          title="Click or Press Enter to calculate."
          @click="getPacTable"
          style="margin-top: 2.5rem;margin-bottom: 2.5rem; margin-left: 2.5rem; height: 3rem;width: 10rem; ;border-radius: 3.125rem;"
          ref="btn">
        Calculate
      </button>

      <button v-else
              title="Click or Press Enter to calculate."
              class=""
              @click="getPacTable"
              style="margin-top: 2.5rem;margin-bottom: 2.5rem;
                       margin-left: 2.5rem; background-color:dodgerblue;height: 3rem;width: 10rem; color: white; border-radius: 3.125rem;">
        Calculate
      </button>
    </div>
    <h3 style="margin-left: 1rem; display: inline">Capacitance:</h3>
    <select v-model="capUnit" class="e-select" style="display: inline; margin-left: 1.25rem">
      <option selected label="nF" value="nf"/>
      <option label="uF" value="uf"/>
      <option label="pF" value="pf"/>
    </select>

    <div style="display: inline;">
      <a style="margin-left: 1.25rem; font-weight: bold; margin-right: 5.6rem">Min</a>
      <a style="margin-left: 0.62rem; font-weight: bold">Max</a>
    </div>
    <div style="display: inline;">
      <PacInputNumber style="display: inline-flex; width: 5rem;"
                      placeholder=""
                      required="true"
                      v-model.number="minInput"
                      class="cap_input_L"
                      @input="(value)=>setMinCap(value)"
      />
      <span v-if="minInput == ''" class="required_input_cap_L">  </span>
      <!--        <span  class="required_nF_L"></span>-->

      <PacInputNumber style="display: inline-flex; width: 5rem;"
                      placeholder=""
                      required="true"
                      v-model.number="maxInput"
                      class="cap_input_R"
                      @input="(value) => setMaxCap(value)"
      />
      <span v-if="maxInput == ''" class="required_input_cap_R">  </span>
      <!--        <span  class="required_nF_R"></span>-->

    </div>
    <h3 style="margin-left: 1rem">AC:</h3>
    <div style="display: inline;">
      <a style="margin-left: 1.25rem; font-weight: bold; margin-right: 3.75rem">Voltage</a>
      <a style="margin-left: 0.62rem; margin-right: 1.56rem; font-weight: bold">Current</a>
    </div>
    <div style="display: inline">
      <PacInputNumber style="display: inline-flex; width: 3.75rem;"
                      placeholder=""
                      required="true"
                      v-model.number="acVoltageInput"
                      class="ac_input_L"
                      @input="(value) => setAcVoltage(value)"
      />
      <span v-if="acVoltageInput == ''" class="required_input_volt_L">  </span>
      <span class="required_vrms_L"></span>
      <PacInputNumber style="display: inline-flex; width: 3.75rem;"
                      placeholder=""
                      required="true"
                      v-model.number="acCurrentInput"
                      class="cap_input_R"
                      @input="(value) => setAcCurrent(value)"/>
      <span v-if="acCurrentInput == ''" class="required_input_cap_R">  </span>
      <span class="required_vrms_R"></span>
    </div>
    <h3 class="e-sidebar_panelTitle">Application Frequency:</h3>
    <div style="display: inline-flex;">
      <PacInputNumber style="display: inline-flex; width: 5rem;"
                      placeholder=""
                      required="true"
                      v-model.number="frequencyInput"
                      class="pac_input"
                      @input="(value) => setFrequency(value)"/>
      <span v-if="frequencyInput == ''" class="required_input">  </span>
      <!--        <span  class="required_kHz_L"></span>-->
      <select v-model="freqUnit" class="e-select_feq" style="margin-top: 0.313rem; margin-left: 1.25rem">
        <option label="Hz" value="hz"/>
        <option selected label="kHz" value="khz"></option>
        <option label="MHz" value="mhz"/>
        <option label="GHz" value="ghz"/>
        <option label="THz" value="thz"/>
      </select>
    </div>

    <h3 style="margin-left: 1rem">Maximum Array Dimensions:</h3>
    <div style="display: inline;">
      <a style="margin-left: 1.25rem; font-weight: bold; margin-right: 4.375rem">Series</a>
      <a style="margin-left: 0.62rem; font-weight: bold">Parallel</a>
    </div>
    <div style="display: inline;">
      <PacInputNumber style="display: inline-flex; width: 5rem;"
                      required="required"
                      placeholder=""
                      v-model.number="arraySeriesInput"
                      class="dimensions_input_L"
                      @input="(value) => setArraySeries(value)"/>
      <span v-if="arraySeriesInput == ''" class="required_input_pattern_L">  </span>
      <PacInputNumber style="display: inline-flex; width: 5rem;"
                      required="true"
                      placeholder=""
                      v-model.number="arrayParallelInput"
                      class="cap_input_R"
                      @input="(value) => setArrayParallel(value)"/>
      <span v-if="arrayParallelInput == ''" class="required_input_pattern_R">  </span>

    </div>
    <h3 class="e-sidebar_panelTitle">Case Size :</h3>
    <h5 style="margin-left: 1rem;color:green">(Optional)</h5>
    <h4 style="margin-left: 1rem">
      <input type="checkbox" @click="caseSizeSelectAll" v-model="caseSizeAllSelected">
      <a style="color: blue; margin-left:0.62rem">All</a>
      <tr v-for="caseSize in caseSizeCheckboxes">
        <td><input type="checkbox" v-model="selectedCaseSize" @change="caseSizeSelect" :value="caseSize"></td>
        <a style="margin-left: 0.62rem">{{ caseSize }}</a>
      </tr>
    </h4>

    <h3 style="margin-left:1rem;margin-top:2rem;">Maximum Land Pattern:</h3>
    <h5 style="margin-left: 1rem; color:green">(Optional)</h5>
    <div style="display: inline;">
      <a style="margin-left: 1.25rem; font-weight: bold; margin-right: 7.5rem">X</a>
      <a style="margin-left: 0; font-weight: bold;margin-right: 0.62rem">Y</a>
    </div>
    <div style="display: inline;">
      <PacInputNumber style="display: inline-flex; width: 3.75rem;"
                      required="required"
                      placeholder=""
                      v-model.number="v1"
                      class="pattern_input_L"
                      @input="(value) => setV1(value)"/>
      <span class="required_v1_L"></span>

      <PacInputNumber style="display: inline-flex; width: 3.75rem;"
                      required="true"
                      placeholder=""
                      v-model.number="v2"
                      class="cap_input_R"
                      @input="(value) => setV2(value)"/>
      <span class="required_v1_R"></span>

    </div>
    <img style="width: 85%; margin-bottom: 0.93rem" :src="require('@/img/Array.jpg')"/>

    <h3 class="e-sidebar_panelTitle">Series :</h3>
    <h5 style="margin-left: 1rem;color:green">(Optional)</h5>
    <h4 style="margin-left: 1rem">
      <input type="checkbox" @click="seriesSelectAll" v-model="seriesAllSelected">
      <a style="color: blue; margin-left:0.62rem">All</a>
      <tr v-for="serie in seriesCheckboxes">
        <td><input type="checkbox" v-model="selectedSeries" @change="seriesSelect" :value="serie.id"></td>
        <a style="margin-left: 0.62rem">{{ serie.id }}</a>
      </tr>
    </h4>

    <!--      <div style="display: inline-flex;">-->
    <!--        <BaseInputString style="display: inline; width: 81%;"-->
    <!--                         :value="seriesInput"-->
    <!--                         placeholder="e.g. KC-Link,Automotive "-->
    <!--                         class="pac_input"-->
    <!--                         @input="(value) => setSeries(value)" />-->
    <!--      </div>-->
    <!--      <BaseDropdown style="width: 80%; margin-left: 20px"-->
    <!--                    title=""-->
    <!--                    v-model="series"-->
    <!--                    :options="seriesOptions"-->
    <!--                    @change="(value) => setSeries(value)"/>-->
    <h3 class="e-sidebar_panelTitle" title="Only results using this part number(s) are displayed."
        style="margin-top: 0.62rem;">Part Number :
      <h5 style="margin-left: 0.06rem;color:green; font-size: 0.81rem">(Optional)</h5>
      <h5 style="margin-left: 0.06rem;font-size: 0.81rem;">(Multiple PN separated by ",")</h5>
      <div style="display: inline-flex;">
        <BaseInputString style="display: inline; width: 81%;"
                         placeholder="e.g. C0201C100K8GAC"
                         :value="partnumbers"
                         class="cap-part_input"
                         @input="(value) => setPartNumber(value)  "
        />
      </div>
    </h3>

  </div>
</template>

<script lang="ts">

import {createNamespacedHelpers} from 'vuex';
import Vue from 'vue';
import BaseInputNumberHorizontal from "@/components/_base/base-input-number-horizontal.vue";
import PacInputString from "@/components/powerArrayCalculator/PacInputNumber.vue";
import PacInputNumber from "@/components/powerArrayCalculator/PacInputNumber.vue";

const {mapState, mapActions} = createNamespacedHelpers('pac');

export default Vue.extend({
  components: {PacInputNumber, PacInputString, BaseInputNumberHorizontal},
  data() {
    return {
      seriesAllSelected: true,
      caseSizeAllSelected: true,
      selectedSeries: [],
      selectedCaseSize: [],
      seriesCheckboxes: [
        {"id": "HV C0G", "name": "HV C0G"},
        {"id": "KC-LINK", "name": "KC-LINK"},
        {"id": "KC-LINK with KONNEKT", "name": "KC-LINK with KONNEKT"},
      ],
      caseSizeCheckboxes: ["1206", "1210", "1812", "2220", "3640"],
      partNumberInput: "",
      minInput: "",
      maxInput: "",
      acVoltageInput: "",
      acCurrentInput: "",
      frequencyInput: "",
      arraySeriesInput: 10,
      arrayParallelInput: 10,
      v1Input: "",
      v2Input: "",
      seriesInput: "",
      screenWidth:document.body.clientWidth,
    }
  },
  computed: {
    ...mapState([
      'powerArrayResults',
      'powerArrayRequest',
      'searchResult',
      'maxCap',
      'minCap',
      'acCurrent',
      'acVoltage',
      'frequency',
      'arraySeries',
      'arrayParallel',
      'v1',
      'v2',
      'series',
      'partnumbers',
      'capUnit',
      'freqUnit',
      'seriesOptions',
      'isLoading',
      'caseSize'
    ]),
  },
  watch: {
    "screenWidth":{
     handler:function (val){
     }
    },
    "selectedSeries.length": {
      handler: function () {
        if (this.selectedSeries.length == 0) {
          this.seriesAllSelected = true
        }
      }
    },
    "selectedCaseSize.length": {
      handler: function () {
        if (this.selectedCaseSize.length == 0) {
          this.caseSizeAllSelected = true
        }
      }
    },
    "selectedSeries": {
      handler: function () {
        this.setSeries(this.selectedSeries)
        if (this.series.length == 0) {
          this.setSeries(["HV C0G", "KC-LINK", "KC-LINK KONNEKT"])
        }
      }
    },
    "selectedCaseSize": {
      handler: function () {
        this.setCaseSize(this.selectedCaseSize)
        if (this.selectedCaseSize.length == 0) {
          this.setCaseSize(["1206", "1210", "1812", "2220", "3640"])
        }
      }
    }
  },
  methods: {
    ...mapActions([
      'getPowerArrayCalculatorResults',
      'clearPowerArrayResults',
      'setSearchReault',
      'setMinCap',
      'setMaxCap',
      'setAcCurrent',
      'setAcVoltage',
      'setFrequency',
      'setArrayParallel',
      'setArraySeries',
      'setV2',
      'setV1',
      'setSeries',
      'setPartNumber',
      'setCapUnit',
      'setFreqUnit',
      'getSeriesOptions',
      'setIsLoading',
      'setCaseSize',
    ]),
    seriesSelectAll: function () {
      this.selectedSeries = [];
      this.seriesAllSelected = !this.seriesAllSelected

      if (this.seriesAllSelected) {
        for (let series in this.seriesCheckboxes) {
          this.selectedSeries.push(this.seriesCheckboxes[series].id.toString());
          this.setSeries(this.selectedSeries)
        }
      }
    },
    caseSizeSelectAll: function () {
      this.selectedCaseSize = [];
      this.caseSizeAllSelected = !this.caseSizeAllSelected

      if (this.caseSizeAllSelected) {
        for (let caseSize in this.caseSizeCheckboxes) {
          this.selectedCaseSize.push(this.caseSizeCheckboxes[caseSize].toString());
          this.setCaseSize(this.selectedCaseSize)
        }
      }
    },
    seriesSelect: function () {
      this.seriesAllSelected = false;
    },
    caseSizeSelect: function () {
      this.caseSizeAllSelected = false;
    },
        getPacTable() {
          if (this.minInput === ""|| this.maxInput === "" ||this.acCurrentInput === "" || this.acVoltageInput === ""
              ||this.frequencyInput=== ""||this.arrayParallelInput=== "") {
            alert("Please enter all * Parameters in the left sidebar boxes.")
            return;
          }
          const pnString :string = this.partnumbers
          if(this.v1Input===""){
            this.v1Input=undefined
          }
          if(this.v2Input===""){
            this.v2Input=undefined
          }


          const pacRequest : PacRequestModel = {
            minCap: this.minInput,
            maxCap: this.maxInput,
            acVoltage: this.acVoltageInput,
            acCurrent: this.acCurrentInput,
            frequency: this.frequencyInput,
            arraySeries: this.arraySeriesInput,
            arrayParallel: this.arrayParallelInput,
            partnumbers: pnString.split(","),
            v1: this.v1Input,
            v2: this.v2Input,
            // series: seriesString.split(","),
            series: this.series,
            capUnit: this.capUnit,
            freqUnit: this.freqUnit,
            caseSize: this.caseSize,
          }
           this.getPowerArrayCalculatorResults(pacRequest);
        }
  },
  created() {
    this.getSeriesOptions()
  },
  mounted() {
    this.screenWidth =document.body.clientWidth
    window.onresize=()=>{
      return (()=>{
        this.screenWidth = document.body.clientWidth
      })()
    }
  }

})


</script>

<style scoped>

.cap-part_input {
  width: calc(100% - 2rem);
  margin-right: 1rem;
  margin-left: 0.125rem;
}

.pac_input {
  margin-right: 1rem;
  margin-left: 1rem;
}

.cap_input_L {
  margin-right: 10.9rem;
  margin-left: -10rem;
}

.cap_input_R {
  margin-right: 0;
  margin-left: -8.1rem;
}

.dimensions_input_L {
  margin-right: 11.2rem;
  margin-left: -11.9rem;
}

.ac_input_L {
  margin-right: 12.2rem;
  margin-left: -13.1rem;
}

.pattern_input_L {
  margin-right: 12.5rem;
  margin-left: -9.7rem;
}

.required_input::after {
  content: " *";
  color: #e32;
  position: absolute;
  margin: 0.18rem 0 0 -1.88rem;
  font-size: xx-large;
  padding: 0 0.3rem 0 0;
}



.required_input_cap_L::after {
  content: " *";
  color: #e32;
  position: absolute;
  margin: 1.25rem 1.25rem 0 -12.2rem;
  font-size: xx-large;
  padding: 0 0.31rem 0 0;
}

.required_input_volt_L::after {
  content: " *";
  color: #e32;
  position: absolute;
  margin: 1.25rem 1.25rem 0 -13.1rem;
  font-size: xx-large;
  padding: 0 0.31rem 0 0;
}

.required_input_pattern_L::after {
  content: " *";
  color: #e32;
  position: absolute;
  margin: 1.25rem 1.25rem 0 -12.5rem;
  font-size: xx-large;
  padding: 0 0.31rem 0 0;
}

.required_v1_L::after {
  content: "(mm)";
  color: black;
  position: absolute;
  margin: 1.75rem 1.25rem 0 -11.875rem;
  font-size: medium;
  padding: 0 0.31rem 0 0;
}

.required_v1_R::after {
  content: "(mm)";
  color: black;
  position: absolute;
  margin: 1.75rem 1.25rem 0 0.62rem;
  font-size: medium;
  padding: 0 0.31rem 0 0;
}

.required_vrms_L::after {
  content: "(Vrms)";
  color: black;
  position: absolute;
  margin: 1.75rem 1.25rem 0 -11.56rem;
  font-size: medium;
  padding: 0 0.313rem 0 0;
}



.required_vrms_R::after {
  content: "(Arms)";
  color: black;
  position: absolute;
  margin: 1.75rem 1.25rem 0 0.62rem;
  font-size: medium;
  padding: 0 0.313rem 0 0;
}



.required_input_cap_R::after {
  content: " *";
  color: #e32;
  position: absolute;
  margin: 1.25rem 1.25rem 0 -1.25rem;
  font-size: xx-large;
  padding: 0 0.313rem 0 0;
}

.required_input_pattern_R::after {
  content: " *";
  color: #e32;
  position: absolute;
  margin: 1.25rem 1.25rem 0 -1.25rem;
  font-size: xx-large;
  padding: 0 0.313rem 0 0;
}

.e-sidebar_panelTitle {
  margin-bottom: 0;
}


.e-select {
  display: block;
  width: 20%;
  height: var(--size-input);
  padding-right: var(--size3);
  padding-left: var(--size-1);

  color: var(--color-text);
  font-weight: 400;
  font-size: var(--size);
  line-height: var(--size-input);

  background-color: var(--color-white);

  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="%23111a1c" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"%3E%3Cpath d="M6 9l6 6 6-6"/%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: calc(100% - 1ch) 50%;
  background-clip: padding-box;
  scrollbar-width: initial;

  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0.06rem solid var(--color-gray-lighter);
  border-radius: var(--border-radius);

}

.e-select_feq {
  display: block;
  width: 40%;
  height: var(--size-input);
  padding-right: var(--size3);
  padding-left: var(--size-1);

  color: var(--color-text);
  font-weight: 400;
  font-size: var(--size);
  line-height: var(--size-input);

  background-color: var(--color-white);

  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="%23111a1c" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"%3E%3Cpath d="M6 9l6 6 6-6"/%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: calc(100% - 1ch) 50%;
  background-clip: padding-box;
  scrollbar-width: initial;

  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0.06rem solid var(--color-gray-lighter);
  border-radius: var(--border-radius);

}
</style>