<template>
    <div>
        <label v-if="title" class="e-label" id="title">
            {{title}}*
        </label>
        <select v-model="local_value"
                @change="$emit('change', local_value)"
                class="e-select"
                required>
            <option v-for="(option, index) in options"
                    :key="index"
                    :value="option"
                    :label="option"
                    :disabled="disabled"
             >{{option}}</option>
        </select>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        inheritAttrs: false,
        props: {
            value: {
                type: String,
                required: true
            },
            title: {
                type: String,
                required: false
            },
            options: {
                type: Array,
                required: true
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        data() {
            return {
                local_value: this.value
            }
        },
    })
</script>

<style scoped>
</style>