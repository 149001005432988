import axios from "axios";

export function maxPartsAlert(num: number) {
    return `A maximum of ${num} part(s) is allowed.\nPlease remove the current part before adding a new one.\n(click the 'X' on right side of the Parts Table`;
}

export const uri =
    process.env.NODE_ENV === "development"
        ? "http://localhost:80"
        : window.location.protocol+ "//" + window.location.host;


export const instance = axios.create({
    baseURL: uri
});
