<template>
  <div>
    <table class="cap-table" v-if="items.length > 0":key="componentKey">
      <tr class="cap-table_row" v-for="(item, index) in items" :key="index">
        <input
            type="checkbox"
            class="e-checkbox"
            :id="`filmLifetimeV2-${index}`"
            :checked="inPartsList(item.kemetPn)"
            :value="item"
            @change="changePart($event, item)"/>
        <label
            class="e-label cap-table_item"
            :for="`filmLifetimeV2-${index}`">

          <span class="cap-table_value">{{ item.capValue / 1E6 | showUnit }}</span>
          <span class="cap-table_part">{{ item.kemetPn }}</span>
        </label>
      </tr>
    </table>
  </div>
</template>

<script lang="ts">
import { filterMixin } from "@/mixins/filterMixin";

import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('filmLifetimeV2');

import Vue from 'vue'
export default Vue.extend({
  inheritAttrs: false,
  mixins: [filterMixin],
  data() {
    return {
      lastClicked: '',
    }
  },
  props: {
    items: {
      type: Array,
      required: true
    },
  },
  computed: {
    ...mapState([
      'parts',
      'componentKey'
    ])
  },
  methods: {
    ...mapActions([
      'addCapacitor',
      'removeCapacitor'
    ]),
    changePart: function (e: any, part: CapacitorModel) {
      if (e.target.checked) {
        this.addCapacitor(part);
      } else {
        this.removeCapacitor(part.kemetPn);
      }
    },
    inPartsList: function (partNumber: string) {
      for (let i = 0; i < this.parts.length; i++) {
        //TODO: add basePn prop to FilmLifetimeCapacitorModel
        if (partNumber === this.parts[i].kemetPn) {
          return true;
        }
      }
      return false;
    }
  }
})
</script>

<style scoped>
/* Capacitance Table (cap) */
.cap-table {
  width: 100%;
}

.cap-table_row,
.cap-table_item {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  width: 100%;

  font-weight: normal;
  font-size: 0.95em;
}

.cap-table_value {
  flex: 0 0 6ch;
}

.cap-table_part::before {
  content: '-';

  padding-right: 1ch;
}
</style>