<template>
    <div class="baseRadio l-field">
        <label class="e-label">{{title}}</label>
        <div class="e-checkbox_inline">
            <template v-for="(option, index) in options">
                <input :key="'checkboxInput'+title+option.value+index"
                       class="e-checkbox"
                       type="checkbox"
                       :id="title+option.value"
                       :value="option.value"
                       :disabled="disabled"
                       v-model="local_value"
                       @change="$emit('change', local_value)"
                       />
                <!--<input :key="'checkboxInput'+title+option.value+index"
                       class="e-checkbox"
                       type="checkbox"
                       :id="title+option.value"
                       :disabled="disabled"
                       @change="$emit('input', $event.target.checked)"
                       />-->

                <!--input id must match label for-->
                <label :key="'checkboxLabel'+title+option.value+index"
                       class="e-label"
                       :for="title+option.value">
                    {{option.label}}
                </label>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        inheritAttrs: false,
        props: {
            value: {
                type: Array,
                required: true
            },
            options: {
                type: Array,
                required: true
            },
            // Not required
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },
            title: {
                type: String,
                required: false,
                default: 'title'
            },
        },
        data() {
            return {
                local_value: this.value
            }
        },
    })
</script>

<style scoped>
</style>