<template>
    <div class="ind-info_downloads">
        <a class="e-button:word" style="--loc-button-radius: 2rem;" :href="specsheetUrl" target="_blank" title="Specsheet">
            <svg class="cap-icon">
                <use xlink:href="#specs"></use>
            </svg>
            &nbsp; Specsheet
        </a>
        <a class="e-button:word" style="--loc-button-radius: 2rem;" :href="datasheetUrl" target="_blank" title="Datasheet">
            <svg class="cap-icon">
                <use xlink:href="#datasheet"></use>
            </svg>
            &nbsp; Datasheet
        </a>
        <a class="e-button:word" style="--loc-button-radius: 2rem;" :href="stepUrl" target="_blank" title="Step File">
            <svg class="cap-icon">
                <use xlink:href="#step"></use>
            </svg>
            &nbsp; Step File
        </a>
        <a class="e-button:word" style="--loc-button-radius: 2rem;" :href="RoHSUrl" target="_blank" title="RoHS Document">
            <svg class="cap-icon">
                <use xlink:href="#rohs"></use>
            </svg>
            &nbsp; RoHS Document
        </a>

    </div>
</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        inheritAttrs: false,
        props: {
            specsheetUrl: {
                type: String,
                required: true
            },
            datasheetUrl: {
                type: String,
                required: true
            },
            stepUrl: {
                type: String,
                required: true
            },
            RoHSUrl: {
                type: String,
                required: true
            },
        },
    })
</script>

<style scoped>

</style>