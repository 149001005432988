<template>
    <div>
        <div class="button-row">
            <input v-show="false" class="file-selector" type="file" ref="file" accept=".csv" @change="readFile($event.target.files)">
            <button type="button" class="e-button:word" @click="$refs.file.click()">
                <svg class="ex-icon">
                    <use :xlink:href="'#file'"></use>
                </svg>&nbsp;Overlay
            </button>
            <BaseButton :disabled="!enableCeButton" type=":word" @baseButtonClick="loadCeProps">
                <svg class="ex-icon">
                    <use :xlink:href="'#download'"></use>
                </svg>
                &nbsp;Get CE Props
            </BaseButton>
            <button type="submit" class="e-button:word" form="seed-form">
                <svg class="ex-icon">
                    <use :xlink:href="'#save'"></use>
                </svg>
                &nbsp;Upload to Database
            </button>
            <BaseButton type=":word" @baseButtonClick="createJSONFile">
                <svg class="ex-icon">
                    <use :xlink:href="'#share'"></use>
                </svg>
                &nbsp;Export Data
            </BaseButton>
            <BaseButton type=":word" @baseButtonClick="displayModal">
                <svg class="ex-icon">
                    <use :xlink:href="'#refresh'"></use>
                </svg>
                &nbsp;Restart
            </BaseButton>
        </div>
    </div>
</template>

<script lang="ts">
    //External
    import Papa from 'papaparse';

    //Internal
    import Columns from './definitions';

    //State
    import { createNamespacedHelpers } from 'vuex';
    const { mapState, mapActions } = createNamespacedHelpers('seed');

    type CSVFile = {
        newPartNumber: string;
        plotImpedance: object[];
        plotESR: object[];
    }

    import Vue from 'vue';
    export default Vue.extend({
        props: ['enableCeButton'],
        data() {
            return {
                csvFile: {
                    newPartNumber: "",
                    plotImpedance: [],
                    plotESR: [],
                } as CSVFile,
                cePropsEnabled: false
            }
        },
        computed: {
            ...mapState([
                'highlight',
                'parts',
            ]),
        },
        methods: {
            ...mapActions([
                'updateSimulationData',
                'removeCapacitorByPn', 
                'setDisplayDeleteModal'
            ]),
            checkOverlayPartType(fileName) {
                //checks if the overlaid part type matches the current selected part type, based off the csv file name.
                switch (this.parts[this.highlight].capType) {
                    case 'Tantalum':
                        if (fileName[0] !== 'T') {
                            return false;
                        }
                        break;
                    case 'Aluminum Electrolytic':
                        if (fileName[0] !== 'A') {
                            return false;
                        }
                        break;
                    case 'Aluminum':
                        if (fileName[0] !== 'A') {
                            return false;
                        }
                        break;
                    case 'Ceramic':
                        if (fileName[0] !== 'C' && fileName[0] !== 'L' && fileName[0] !== '0') {
                            return false;
                        }
                        break;
                    default:
                        return true;
                }
                return true;
            },
            createJSONFile() {
                let fileData = {};
                this.parts[this.highlight].seedProps.forEach((element) => fileData[element.name] = element.value);
                const blob = new Blob([JSON.stringify(fileData)]);
                const link = document.createElement('a');
                const url = window.URL.createObjectURL(blob);
                link.href = url;
                let fileName = this.parts[this.highlight].seedProps.find(p => p.name === "KEMET_Part_Number").value;
                link.setAttribute('download', `${fileName}.json`);
                document.body.appendChild(link);
                link.click();
            },
            degreesToRadians(degrees) {
                let pi = Math.PI;
                return degrees * (pi / 180);
            },
            displayModal() {
                this.setDisplayDeleteModal(true);
            },
            loadCeProps() {
                this.$emit('on-setLoadCeProps', true);
            },
            async readFile(file) {
                this.$emit('clear-error');
                if (file.length < 1) {
                    return;
                }
                this.csvFile.plotImpedance = [];
                this.csvFile.plotESR = [];
                const allowedformats = ["csv"];
                let name = file[0].name;
                let samePartType = await this.checkOverlayPartType(name);
                if (!samePartType) {
                    this.$emit("handle-error", "The part type of the csv file and the selected part type must match.");
                    return;
                } else {
                    this.csvFile.newPartNumber = name.replace(/(-.*)*.csv$/, "");
                    this.setStyle(name);
                    this.updateSimulationData({ title: "Base KEMET Part number", output: this.csvFile.newPartNumber });
                    const fileExt = name.split(".").pop().toLowerCase();
                    if (!allowedformats.includes(fileExt)) {
                        this.$emit('handle-error', 'File must be csv.')
                        throw new Error("file must be csv.");
                    }
                    let reader = new FileReader();
                    reader.readAsText(file[0]);
                    reader.onload = async (event) => {
                        if (!event.target) {
                            return;
                        }
                        const csvData = event.target.result;
                        const data = await Papa.parse(csvData, { dynamicTyping: true, skipEmptyLines: true });
                        for (let value in data.data) {
                            let tempImpedance;
                            let convertedPhase;
                            for (let i = 0; i < data.data[value].length; i++) {
                                //check if the csv file has 3 columns
                                if (data.data[value].length < 3) {
                                    this.$emit("handle-error", "The file must have 3 columns.");
                                    return;
                                } else if (data.data[value].length > 3) {
                                    this.$emit("handle-error", "The file can only have 3 columns.");
                                    return;
                                }
                                switch (i) {
                                    case Columns.Frequency:
                                        break;
                                    case Columns.Impedance:
                                        tempImpedance = data.data[value][i];
                                        break;
                                    case Columns.Phase:
                                        convertedPhase = this.degreesToRadians(data.data[value][i]);
                                        break;
                                    default:
                                        console.log('error')
                                }
                            }
                            this.csvFile.plotImpedance.push({
                                x: data.data[value][Columns.Frequency],
                                y: data.data[value][Columns.Impedance],
                            });
                            this.csvFile.plotESR.push({
                                x: data.data[value][Columns.Frequency],
                                y: (tempImpedance * Math.cos(convertedPhase))
                            });
                        }
                        this.$emit('on-overlay', this.csvFile.plotImpedance, this.csvFile.plotESR);
                    }
                }
            },
            setStyle(fileName) {
                //sets style for all parts based off of the csv file name
                //sets case size and series for aluminum/tantalum parts based off of csv file name
                let style = this.parts[this.highlight].seedProps.find(p => p.name === "Style").value;
                let filePrefix = fileName.slice(0, 3);
                switch (this.parts[this.highlight].capType) {
                    case 'Tantalum':
                    case 'Aluminum':
                        style = fileName.slice(0, 5);
                        let caseSize = style.slice(4, 5);
                        let componentSeries = style.slice(0, 4);
                        this.updateSimulationData({ title: "Case size", output: caseSize });
                        this.updateSimulationData({ title: "Component series", output: componentSeries });
                        break;
                    case 'Film':
                        let substring = fileName.slice(0, 3);
                        let matchingCharacter = this.csvFile.newPartNumber[this.csvFile.newPartNumber.length - 3];
                        switch (substring) {
                            case "R74":
                                if (matchingCharacter === "H") {
                                    style = "R74H"
                                } else {
                                    style = "R74"
                                }
                                break;
                            case "R75":
                                if (matchingCharacter === "H") {
                                    style = "R75H"
                                } else {
                                    style = "R75"
                                }
                                break;
                            case "R76":
                                if (matchingCharacter === "H") {
                                    style = "R76H"
                                } else {
                                    style = "R76"
                                }
                                break;
                            case "R41":
                                style = "R41T"
                                break;
                            case "C44":
                                style = "C44P R"
                                break;
                            case "F86":
                                style = "F862"
                                break;
                            case "C4AF":
                                style = "C4AF"
                                break;
                            default:
                                style = fileName.slice(0, 3);
                        }
                        break;
                    case 'Ceramic':
                        if (fileName[0] === 'C') {
                            if (filePrefix !== 'CAN' && filePrefix !== 'CKC') {
                                style = fileName.slice(0, 5);
                            }
                        } else if (fileName[0] === 'L') {
                            if (filePrefix === 'L1X') {
                                let appendStyle = fileName.slice(4, 6);
                                style = 'L1x_' + appendStyle;
                            } else {
                                style = fileName.slice(0, 5);
                            }
                        }
                        break;
                    case 'Aluminum Electrolytic':
                        style = fileName.slice(0, 4);
                        break;
                }
                this.updateSimulationData({ title: "Capacitor series", output: style });
            }
        }
    });
</script>

<style lang="css" scoped>
    .button-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    [class*='e-button']:active, [class*='e-button']:focus {
        background-size: 180%;
    }
</style>