// External
import axios from "axios";

// Internal
import { unitConversion } from "@/util/ChartJsHelpers";
import {
    addCapacitor,
    duplicatePart,
    removeCapacitor,
} from "@/store/util/PartsMutations";
import { logPartUrl, pnUrl } from "@/util/Urls";

// Vuex
import { ActionContext } from "vuex";
import { instance } from "@/util/Alerts";
type CalcZContext = ActionContext<CalcZState, RootState>;

// Specific to tool
const maxParts = 11; // 10 + combined

const state = (): CalcZState => ({
    componentKey: 0,
    capacitorType: "",
    // note about highlight: When we add first part, highlight moves to 1.
    // Why not just start it off at 1? Well, when the app loads, the highlight still needs to be a valid index.
    // The "Combined" part is still in the list, so it must start off at 0 because
    //the highlight is used on the backend for some plot types to determine which part in the plot list to use.
    // TODO: The backend routes which only need a single part should probably just take an individual part instead of a list and using a highlight to determine which one.
    // TODO: when John implements the Spice and TempRise@MultFreq plots in the CalcZ API, we will probably have to pass an individual part number
    highlight: 0,
    parts: [
        {
            id: 0,
            instances: 0,
            capDisplay: "",
            capValue: 0.0,
            capType: "",
            dielectric: "-",
            kemetPn: "Combined",
            basePn: "Combined",
            maxTemp: 155,
            series: "",
            appDef: "",
            spicePn: "Combined",
            voltageRating: 0,
            ceProps: [] as UpdatedCapCeProps,
            filmProps: {} as FilmProps,
            hidden: false,
            //Parameters used in CalcZ
            param: {
                qty: {
                    title: "Qty",
                    select: 1,
                    min: 1,
                    max: 10,
                    step: 1,
                    disabled: true,
                    width: 4,
                } as InputNumber,
                bias: {
                    title: "Bias (V)",
                    select: 0,
                    min: 0,
                    max: 100,
                    step: 0.1,
                    disabled: false,
                    width: 6,
                } as InputNumber,
                tempAmbient: {
                    title: "Amb. (°C)",
                    select: 25,
                    min: -55,
                    max: 155,
                    step: 1,
                    disabled: false,
                    width: 6,
                } as InputNumber,
            } as CapParam,
            //Parameter not used in CalcZ
            tolerance: {
                title: "Tolerance",
                select: "",
                disabled: true,
                options: [] as BaseDropdownOption[],
            } as InputDropdown,
            seedProps: [] as SeedProp[],
            displayAllCeProps: false,
            tcc: "",
            vcac: "",
        },
    ],
    //Keeping track of which chart legend icons have been used
    partStyleApplied: [0],
    partStyleAvailable: [
        19,
        18,
        17,
        16,
        15,
        14,
        13,
        12,
        11,
        10,
        9,
        8,
        7,
        6,
        5,
        4,
        3,
        2,
        1,
    ],
    //Available plot types
    plotType: {
        title: "Chart Type",
        options: [
            {
                label: "Impedance & ESR",
                value: "Imp,ESR",
                disabled: false,
            } as BaseDropdownOption,
            {
                label: "Capacitance & Inductance",
                value: "Cap,Ind",
                disabled: false,
            } as BaseDropdownOption,
            {
                label: "Current & Voltage",
                value: "I,V",
                disabled: false,
            } as BaseDropdownOption,
            //{ label: "Ceramic Aging", value: "Aging", disabled: false } as BaseDropdownOption,
            { label: "S11", value: "S11", disabled: false } as BaseDropdownOption,
            { label: "S21", value: "S21", disabled: false } as BaseDropdownOption,
            {
                label: "SPICE Model",
                value: "Spice",
                disabled: false,
            } as BaseDropdownOption,
            //{ label: "Multiple DC Voltages/Temps", value: "MultDC", disabled: false } as BaseDropdownOption,
            {
                label: "Capacitance vs. Vbias (DC)",
                value: "Vbias",
                disabled: false,
            } as BaseDropdownOption,
            {
                label: "Temp. Rise vs. Ripple Current",
                value: "MultFreq",
                disabled: false,
            } as BaseDropdownOption,
            {
                label: "Capacitance Change vs. Temperature",
                value: "TCC",
                disabled: false,
            } as BaseDropdownOption,
            {
                label: "Capacitance Change vs. AC Voltage",
                value: "VCAC",
                disabled: false,
            } as BaseDropdownOption,
        ],
        select: "Imp,ESR",
        disabled: false,
        hidden: false,
    },
    //Shared parameters displayed up top
    shared: {
        dropdowns: {
            minFreq: {
                title: "Start",
                options: [
                    {
                        label: "100 Hz",
                        value: "100",
                        disabled: false,
                    } as BaseDropdownOption,
                    {
                        label: "1 kHz",
                        value: "1000",
                        disabled: false,
                    } as BaseDropdownOption,
                    {
                        label: "10 kHz",
                        value: "10000",
                        disabled: false,
                    } as BaseDropdownOption,
                    {
                        label: "100 kHz",
                        value: "100000",
                        disabled: false,
                    } as BaseDropdownOption,
                    {
                        label: "1 MHz",
                        value: "1000000",
                        disabled: false,
                    } as BaseDropdownOption,
                    {
                        label: "10 MHz",
                        value: "10000000",
                        disabled: false,
                    } as BaseDropdownOption,
                    {
                        label: "100 MHz",
                        value: "100000000",
                        disabled: false,
                    } as BaseDropdownOption,
                    {
                        label: "1 GHz",
                        value: "1000000000",
                        disabled: false,
                    } as BaseDropdownOption
                ],
                select: "10000",
                filmSelect: "100",
                disabled: false,
                hidden: false,
            },
            maxFreq: {
                title: "End",
                options: [
                    {
                        label: "1 kHz",
                        value: "1000",
                        disabled: false,
                    } as BaseDropdownOption,
                    {
                        label: "10 kHz",
                        value: "10000",
                        disabled: false,
                    } as BaseDropdownOption,
                    {
                        label: "100 kHz",
                        value: "100000",
                        disabled: false,
                    } as BaseDropdownOption,
                    {
                        label: "1 MHz",
                        value: "1000000",
                        disabled: false,
                    } as BaseDropdownOption,
                    {
                        label: "10 MHz",
                        value: "10000000",
                        disabled: false,
                    } as BaseDropdownOption,
                    {
                        label: "100 MHz",
                        value: "100000000",
                        disabled: false,
                    } as BaseDropdownOption,
                    {
                        label: "1 GHz",
                        value: "1000000000",
                        disabled: false,
                    } as BaseDropdownOption,
                    {
                        label: "10 GHz",
                        value: "10000000000",
                        disabled: false,
                    } as BaseDropdownOption
                ],
                select: "10000000000",
                filmSelect: "10000000",
                disabled: false,
                hidden: false,
            }
        },
        inputs: {
            res: {
                title: "Trace Res. (Ω)",
                select: 0,
                min: 0,
                max: 1000,
                step: 0.001,
                disabled: false,
                hidden: false,
                width: 12,
            } as InputNumber,
            ind: {
                title: "Trace Ind. (H)",
                select: 0,
                min: 0,
                max: 10000,
                step: 0.000001,
                disabled: false,
                hidden: false,
                width: 12,
            } as InputNumber,
            tempRise: {
                title: "Rise (°C)",
                select: 20,
                min: 0,
                max: 100,
                step: 0.1,
                disabled: false,
                hidden: false,
                width: 8,
            } as InputNumber,
            spiceFreq: {
                title: "Center Frequency",
                select: 10000,
                min: 100,
                max: 10000000000,
                step: 1,
                disabled: false,
                hidden: false,
                width: 16,
            } as InputNumber,
            freqList: [
                //TODO: These two never show up in response data?
                //{
                //    title: "1",
                //    select: 1000,
                //    min: 100,
                //    max: 10000000000,
                //    step: 1,
                //    disabled: false,
                //    hidden: false,
                //    width: 10
                //} as InputNumber,
                //{
                //    title: "2",
                //    select: 5000,
                //    min: 100,
                //    max: 10000000000,
                //    step: 1,
                //    disabled: false,
                //    hidden: false,
                //    width: 10
                //} as InputNumber,
                {
                    title: "1",
                    select: 10000,
                    min: 100,
                    max: 10000000000,
                    step: 1,
                    disabled: false,
                    hidden: false,
                    width: 10,
                } as InputNumber,
                {
                    title: "2",
                    select: 50000,
                    min: 100,
                    max: 10000000000,
                    step: 1,
                    disabled: false,
                    hidden: false,
                    width: 10,
                } as InputNumber,
                {
                    title: "3",
                    select: 100000,
                    min: 100,
                    max: 10000000000,
                    step: 1,
                    disabled: false,
                    hidden: false,
                    width: 10,
                } as InputNumber,
                {
                    title: "4",
                    select: 500000,
                    min: 100,
                    max: 10000000000,
                    step: 1,
                    disabled: false,
                    hidden: false,
                    width: 11,
                } as InputNumber,
                {
                    title: "5",
                    select: 1000000,
                    min: 100,
                    max: 10000000000,
                    step: 1,
                    disabled: false,
                    hidden: false,
                    width: 11,
                } as InputNumber,
                {
                    title: "6",
                    select: 5000000,
                    min: 100,
                    max: 10000000000,
                    step: 1,
                    disabled: false,
                    hidden: false,
                    width: 11,
                } as InputNumber,
                {
                    title: "7",
                    select: 10000000,
                    min: 100,
                    max: 10000000000,
                    step: 1,
                    disabled: false,
                    hidden: false,
                    width: 11,
                } as InputNumber,
            ] as InputNumber[],
        },
        radios: {
            combine: {
                title: "Combined",
                options: ["Yes", "No"],
                select: "No",
                disabled: true,
                hidden: false,
            } as InputRadio,
            calculated: {
                title: "Calculated",
                options: ["Yes", "No"],
                select: "No",
                disabled: false,
                hidden: false,
            } as InputRadio,
            path: {
                title: "Path",
                options: ["Shunt", "Series"],
                select: "Shunt",
                disabled: false,
                hidden: false,
            } as InputRadio,
            line: {
                title: "Line (Ω)",
                options: ["50", "75"],
                select: "50",
                disabled: false,
                hidden: false,
            } as InputRadio,
            spiceFileFormat: {
                title: "File Format",
                options: ["CKT", "TXT", "DAT", "PRN", "CIR"],
                select: "CKT",
                disabled: false,
                hidden: false,
            } as InputRadio,
        },
    },
});

const getters = {
    body: (state: CalcZState) => {
        const freqList = [] as number[];
        for (let i = 0; i < state.shared.inputs.freqList.length; i++) {
            freqList.push(state.shared.inputs.freqList[i].select);
        }
        return {
            calculated: state.shared.radios.calculated.select,
            combine: state.shared.radios.combine.select,
            freqList: freqList,
            highlight: state.highlight,
            ind: state.shared.inputs.ind.select,
            line: state.shared.radios.line.select,
            path: state.shared.radios.path.select,
            parts: state.parts,
            plotType: state.plotType.select,
            res: state.shared.inputs.res.select,
            spiceFileFormat: state.shared.radios.spiceFileFormat.select,
            spiceFreq: state.shared.inputs.spiceFreq.select,
            start: state.shared.dropdowns.minFreq.select,
            stop: state.shared.dropdowns.maxFreq.select,
            tempRise: state.shared.inputs.tempRise.select,
        } as PlotRequestModel;
    },
    filmSeriesR75Exists: (state: CalcZState) => {
        for (let i = 0; i < state.parts.length; i++)
            if (state.parts[i].series === "R75") return true;
        return false;
    },
    individualParameters: (state: CalcZState) => {
        // Used to trigger watcher instead of deep watcher on parts array
        let s = "";
        for (let i = 0; i < state.parts.length; i++) {
            s += state.parts[i].param.qty.select + ",";
            s += state.parts[i].param.bias.select + ",";
            s += state.parts[i].param.tempAmbient.select + ";";
        }
        return s;
    },
    partsListLength: (state: CalcZState) => {
        return state.parts.length;
    },
    pnString: (state: CalcZState) => {
        // array of pn strings w/o addt'l info
        let s = "";
        for (let i = 1; i < state.parts.length; i++) {
            s += state.parts[i].kemetPn;
            if (i != state.parts.length - 1) s += "-";
        }
        return s;
    },
    pnStrings: (state: CalcZState) => {
        // array of pn strings w/o addt'l info
        const arr = [] as string[];
        for (let i = 0; i < state.parts.length; i++)
            arr.push(state.parts[i].kemetPn);
        return arr;
    },
    toleranceList: (state: CalcZState) => {
        // array of pn strings w/o addt'l info
        const arr = [] as string[];
        for (let i = 0; i < state.parts.length; i++)
            arr.push(state.parts[i].tolerance.select);
        return arr;
    },
    validPlots: (state: CalcZState) => {
        let valid = state.plotType.options.map((p) => p.value); // list of all plots

        // Check if TCC/VCAC valid
        let tccValid = false;
        let vcacValid = false;
        for (const part of state.parts) {
            if (part.tcc !== "" && part.tcc !== null) tccValid = true;
            if (part.vcac !== "" && part.vcac !== null) vcacValid = true;
        }
        if (!tccValid) valid = valid.filter((plot) => plot !== "TCC");
        if (!vcacValid) valid = valid.filter((plot) => plot !== "VCAC");

        return valid;
    },
};

const mutations = {
    addCapacitor,
    duplicatePart,
    saveSeedProp(state: CalcZState, seedProps: SeedProp[]) {
        state.parts[state.highlight].seedProps = seedProps;
    },
    setCapacitorDetails(
        state: CalcZState,
        payload: { basePn: string; ceProps: UpdatedCapCeProps }
    ) {
        for (let i = 0; i < state.parts.length; i++) {
            if (state.parts[i].basePn === payload.basePn) {
                // Find the cap
                state.parts[i].ceProps = payload.ceProps; // and add the details
            }
        }
    },
    setCapacitorType(state: CalcZState, payload: string) {
        state.capacitorType = payload;
    },
    removeCapacitor,
    setHighlight(state: CalcZState, partIndex: number) {
        state.highlight = partIndex;
    },
    setDisplayAllCeProps(state: CalcZState, index: number) {
        let currentValue = state.parts[index].displayAllCeProps;
        state.parts[index].displayAllCeProps = !currentValue;
    },
    setShared(state: CalcZState, payload: CalcZParameters) {
        state.shared = payload;
    },
    incrementComponentKey(state: CalcZState) {
        state.componentKey++;
    },
    setPartsList(state: CalcZState, payload: CapacitorModel[]) {
        state.parts = payload;
    },
    updateCombinedPart(
        state: CalcZState,
        payload: {
            voltageRating: number;
            capValue: number;
            capDisplay: string;
            maxTemp: number;
            combinedDielectric: string;
        }
    ) {
        state.parts[0].capDisplay = payload.capDisplay;
        state.parts[0].capValue = payload.capValue;
        state.parts[0].voltageRating = payload.voltageRating;
        state.parts[0].maxTemp = payload.maxTemp;
        state.parts[0].dielectric = payload.combinedDielectric;
    },
    updateTolerances(
        state: CalcZState,
        payload: { partIndex: number; toleranceChar: string }
    ) {
        function setCharAt(str: string, index: number, chr: string) {
            if (index > str.length - 1) return str;
            return str.substr(0, index) + chr + str.substr(index + 1);
        }

        // TODO: update tolerance char positions for all ceramic series
        let ceramicToleranceCharPosition = 9;
        if (state.parts[payload.partIndex].capType === "Ceramic") {
            switch (state.parts[payload.partIndex].appDef) {
                case "MIL-C-PRF":
                    ceramicToleranceCharPosition = 11;
                    break;
                default:
                    ceramicToleranceCharPosition = 9;
                    break;
            }
        }
        const tantalumToleranceCharPosition = 8;
        if (state.parts[payload.partIndex].capType === "Ceramic") {
            state.parts[payload.partIndex].kemetPn = setCharAt(
                state.parts[payload.partIndex].kemetPn,
                ceramicToleranceCharPosition,
                payload.toleranceChar
            );
        } else if (
            state.parts[payload.partIndex].capType === "Aluminum" ||
            state.parts[payload.partIndex].capType === "Tantalum"
        ) {
            state.parts[payload.partIndex].kemetPn = setCharAt(
                state.parts[payload.partIndex].kemetPn,
                tantalumToleranceCharPosition,
                payload.toleranceChar
            );
        }
        // else: do nothing for Film and AlumElec
    },
    updatePartMaximums(
        state: CalcZState,
        payload: { partIndex: number; voltageRating: number; maxTemp: number }
    ) {
        state.parts[payload.partIndex].param.bias.max = payload.voltageRating;
        state.parts[payload.partIndex].param.tempAmbient.max = payload.maxTemp;
    },
    updateEnabledSimulationInputs(state: CalcZState) {
        if (state.shared.radios.combine.select === "Yes") {
            for (let i = 1; i < state.parts.length; i++) {
                state.parts[i].param.bias.disabled = true;
                state.parts[i].param.tempAmbient.disabled = true;
            }
        } else {
            for (let i = 1; i < state.parts.length; i++) {
                state.parts[i].param.bias.disabled = false;
                state.parts[i].param.tempAmbient.disabled = false;
            }
        }
        state.componentKey++;
    },
    resetPlotType(state: CalcZState) {
        state.plotType.select = "Imp,ESR";
    }
};

const actions = {
    // Used when adding from Parts Available list in sidebar
    addCapacitor(context: CalcZContext, payload: CapacitorModel) {
        if (context.state.parts.length < maxParts) {
            // Check if it exists *before* adding the part (for first time add of parts)
            let partExists = false; // Assume part not in list to begin
            // If match, we won't log or load CE props below
            if (context.state.parts != null) {
                // If list not empty, let's search it
                for (let i = 0; i < context.state.parts.length; i++) {
                    // Check each part
                    if (context.state.parts[i].basePn === payload.basePn) {
                        // Identify based on part number
                        partExists = true;
                    }
                }
            }

            context.commit("addCapacitor", payload);
            context.dispatch("updatePartMaximums");
            context.dispatch("updateCombinedPart");

            if (!partExists) {
                // Log the part being added
                instance.post(logPartUrl, {
                    partNumber: payload.basePn,
                    method: "fromList",
                    found: true,
                    session: window.localStorage.getItem("uuid"),
                });
                // Load ComponentEdge properties
                context.dispatch("fetchCeProps", payload);
            }
        } else {
            alert(`You cannot add any more parts. The maximum is ${maxParts - 1}.`);
            context.commit("incrementComponentKey"); // trigger the CapacitorResults to render again so that the box doesn't get checked
        }
    },
    // Used when adding from the Part Search text input
    addCapacitorByPartNumber(
        context: CalcZContext,
        payload: { partNumber: string; method: string }
    ) {
        // If parts list not full
        if (context.state.parts.length < maxParts) {
            // Lookup part
            instance
                .post(pnUrl, {
                    partNumber: payload.partNumber,
                    method: payload.method,
                    session: window.localStorage.getItem("uuid"),
                })
                .then((response) => {
                    // If response is string: it's an error
                    if (typeof response.data === "string") alert(response.data);
                    // Part was found
                    else {
                        // Add to table
                        response.data.displayAllCeProps = false;
                        context.commit("addCapacitor", response.data);
                        // Update Combined part
                        context.dispatch("updatePartMaximums");
                        context.dispatch("updateCombinedPart");
                        // Load ComponentEdge properties
                        context.dispatch("fetchCeProps", response.data);
                    }
                });
        } // Parts list is full
        else
            alert(`You cannot add any more parts. The maximum is ${maxParts - 1}.`);
    },
    duplicatePart(context: CalcZContext, payload: number) {
        if (context.state.parts.length < maxParts) {
            context.commit("duplicatePart", payload);
        } else {
            alert(`You cannot add any more parts. The maximum is ${maxParts - 1}.`);
        }
    },
    fetchCeProps(context: CalcZContext, payload: CapacitorModel) {
        instance
            .post("/api/ce/params/", {
                partNumber: payload.kemetPn,
                capType: payload.capType,
            } as ComponentEdgeParameterRequest)
            .then((response) => {
                context.commit("setCapacitorDetails", {
                    basePn: payload.basePn,
                    ceProps: response.data,
                });
            });
    },
    incrementComponentKey(context: CalcZContext) {
        context.commit("incrementComponentKey");
    },
    // Used when removing from Parts Available list in sidebar
    removeCapacitorByPn(context: CalcZContext, basePn: string) {
        // Start at end, delete root part last
        for (let i = context.state.parts.length - 1; i >= 0; i--) {
            if (context.state.parts[i].basePn === basePn) {
                context.commit("removeCapacitor", i);
                context.dispatch("updatePartMaximums");
                context.dispatch("updateCombinedPart");
                break;
            }
        }
    },
    // Used when removing from table
    removeCapacitorByIndex(context: CalcZContext, index: number) {
        context.commit("removeCapacitor", index);
        context.dispatch("updatePartMaximums");
        context.dispatch("updateCombinedPart");
        context.dispatch("updateEnabledSimulationInputs");
    },
    setCapacitorType(context: CalcZContext, payload: string) {
        context.commit("setCapacitorType", payload);
    },
    setDisplayAllCeProps(context: CalcZContext, index) {
        context.commit("setDisplayAllCeProps", index);
    },
    setHighlight(context: CalcZContext, payload: number) {
        context.commit("setHighlight", payload);
    },
    setShared(context: CalcZContext, payload: CalcZParameters) {
        // TODO: always in combined mode by default? or combined simply overwrites bias and tempRise?
        if (payload.radios.combine.select === "Yes") {
            context.commit("setHighlight", 0); // Combined is reserved index 0 in CalcZ
        } else {
            // If user changed: combine.select = 'No'
            if (context.getters.highlight === 0) {
                // ...and highlight still 'Combined'...
                context.commit("setHighlight", 1); // Highlight first part. Combined is not even shown.
            }
        }
        // Finally, update the shared parameters
        context.commit("setShared", payload);
    },
    setPartsList(context: CalcZContext, payload: CapacitorModel[]) {
        context.commit("setPartsList", payload);
    },
    updateCombinedPart(context: CalcZContext) {
        // Don't update combined part if only 1 part
        if (context.state.parts.length <= 2) return;

        // Initial variables
        let maxVoltage = 100000000.0;
        let maxTemp = 100000000.0;
        let sumCapacitance = 0.0;
        const firstDielectric = context.state.parts[1].dielectric;
        let sameDielectric = true;

        for (let i = 1; i < context.state.parts.length; i++) {
            // Voltage Input
            if (context.state.parts[i].voltageRating < maxVoltage) {
                maxVoltage = context.state.parts[i].voltageRating;
            }
            // Max Temp Input
            if (context.state.parts[i].maxTemp < maxTemp)
                maxTemp = context.state.parts[i].maxTemp;
            // Capacitance
            sumCapacitance += context.state.parts[i].capValue;
            // Dielectric
            if (context.state.parts[i].dielectric !== firstDielectric)
                sameDielectric = false;
        }

        const combinedDielectric = sameDielectric ? firstDielectric : "-";

        const cap = sumCapacitance / 1e12; // cap values in Spice table are in pF
        const capDisplay = unitConversion(cap, "F");
        // Update table properties
        context.commit("updateCombinedPart", {
            voltageRating: maxVoltage,
            capValue: sumCapacitance,
            capDisplay,
            maxTemp,
            combinedDielectric,
        });
        // Update simulation parameter maximums from table properties
        context.dispatch("updatePartMaximums");
    },
    updateTolerances(context: CalcZContext) {
        for (let i = 1; i < context.state.parts.length; i++) {
            // start at 1, skip Combined
            const t = context.state.parts[i].tolerance.select;
            context.commit("updateTolerances", { partIndex: i, toleranceChar: t });
        }
    },
    updatePartMaximums(context: CalcZContext) {
        for (let i = 0; i < context.state.parts.length; i++) {
            const v = context.state.parts[i].voltageRating;
            const t = +context.state.parts[i].maxTemp;
            context.commit("updatePartMaximums", {
                partIndex: i,
                voltageRating: v,
                maxTemp: t,
            });
        }
    },
    updateEnabledSimulationInputs(context: CalcZContext) {
        context.commit("updateEnabledSimulationInputs");
    },
    resetPlotType(context: CalcZContext) {
        context.commit("resetPlotType");
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
