<template>
  <div id="simulation-container">
    <div class="cap-title" style="color: black">Power Array Calculator</div>

    <div class="e-panel:3 l-box:inset2 cap-plot_outer" >
      <div style="display: inline-flex;  ">
        <button
            v-if="maxCap==0||minCap==0||acCurrent==0||acVoltage==0||frequency==0
            ||arrayParallel==0||arraySeries==0"
            disabled
            title="Click or Press Enter to calculate."
            class="e-button"
            @click="getPacTable"
            style="margin-top: 2.5rem;margin-bottom: 2.5rem; margin-left: 2.5rem;width: 15%;  ;border-radius: 3.125rem;"
            ref="btn">
          Calculate
        </button>

        <button v-else
                title="Click or Press Enter to calculate."
                class="e-button"
                @click="getPacTable"
                style="margin-top: 2.5rem;margin-bottom: 2.5rem;
                       margin-left: 2.5rem; background-color:dodgerblue; color: white;width: 15%; border-radius: 3.125rem;">
          Calculate
        </button>

        <div style=" align-self:center;left: 20%; position: relative">
          <td style="text-align: center; font-size: x-large">
            <label style="margin-left: 1rem;cursor: pointer;">
              <input type="radio" id="stock" v-model="radioCheck" value="all" @change="setStockFilter(radioCheck)"/>
              All
            </label>
          </td>
          <td style="text-align: center; font-size: x-large;">
            <label style="margin-left: 1rem;cursor: pointer;">
              <input type="radio" id="stock" v-model="radioCheck" value="yes" @change="setStockFilter(radioCheck)"/>
              In Stock
            </label>
          </td>
        </div>
        <div v-if="powerArrayResults.length>0" class="input-box" style=" align-self:center;left: 40%;">
          <BaseInputString class="searchBar" placeholder="Search Part Number" v-model="searchBar"
                           @input="(value) => setSearchInput(value)"/>
          <!--          <button class="button" @click="search">Search</button>-->
        </div>

      </div>

      <div style="display: inline;">



      <vue-loading v-if="isLoading" type="beat" color="#ffd700"
                   :size="{ width: '6.25rem', height: '6.25rem' }"></vue-loading>

      <PacTable v-if="!isLoading" id="cap-table-image"/>

      </div>
    </div>
  </div>
</template>

<script lang="ts">

// State
import {createNamespacedHelpers} from "vuex";
// Components
import PacTable from "./PacTable.vue";
import PacOptionalInputBox from "./PacOptionalInputBox.vue";

// Local
import Vue from "vue";
// Import stylesheet
const {mapState, mapGetters, mapActions} = createNamespacedHelpers("pac");


export default Vue.extend({

  components: {
    PacOptionalInputBox,
    PacTable,
  }, data() {
    return {
      //   partNumberInput:"",
      //   minInput:125,
      // maxInput:130,
      // acVoltageInput:800,
      // acCurrentInput:43,
      // frequencyInput:100,
      // arraySeriesInput:20,
      // arrayParallelInput:20,
      // v1Input:"",
      // v2Input:"",
      // seriesInput:"",
      radioCheck: "all",
      searchBar: "",
    }
  },
  computed: {
    ...mapState([
      'powerArrayResults',
      'powerArrayRequest',
      'searchResult',
      'maxCap',
      'minCap',
      'acCurrent',
      'acVoltage',
      'frequency',
      'arraySeries',
      'arrayParallel',
      'v1',
      'v2',
      'series',
      'partnumbers',
      'capUnit',
      'freqUnit',
      'isLoading',
      'stockFilter',
      'caseSize',
      "searchInput",
    ]),
  }, methods: {
    ...mapActions([
      'getPowerArrayCalculatorResults',
      'clearPowerArrayResults',
      'setSearchReault',
      'setStockFilter',
      'setSeries',
      'setSearchInput',

    ]),
    search() {
      console.log(this.searchInput)
      // this.setSearchInput(this.searchBar)
    },
    getPacTable() {
      const pnString: string = this.partnumbers
      if (this.v1 === "") {
        this.v1 = undefined
      }
      if (this.v2 === "") {
        this.v2 = undefined
      }

      const pacRequest: PacRequestModel = {
        minCap: this.minCap,
        maxCap: this.maxCap,
        acVoltage: this.acVoltage,
        acCurrent: this.acCurrent,
        frequency: this.frequency,
        arraySeries: this.arraySeries,
        arrayParallel: this.arrayParallel,
        partnumbers: pnString.split(","),
        v1: this.v1,
        v2: this.v2,
        series: this.series,
        capUnit: this.capUnit,
        freqUnit: this.freqUnit,
        caseSize: this.caseSize,
      }
      this.getPowerArrayCalculatorResults(pacRequest);
    }
  }, mounted() {
    document.body.addEventListener('keydown', e => {
      if (e.keyCode === 13) {
        this.getPacTable()
      }
    });
  },

});
</script>

<style scoped>
.graph-error-msg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cap-plot_outer {
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: calc(100vw - 23.5rem);
  min-height: 31.25rem;
  margin-top: 0.625rem;
  overflow: auto;
}

.is-closed .cap-plot_outer {
  width: calc(100vw - 24.5rem + 22.5rem);
}

.reset-zoom {
  position: relative;
  top: -3rem;
  left: 1rem;
}

.e-button {
  border-radius: 3.125rem;
  padding: 1.56rem 3.75rem;
}

table {
  width: 100%;
}

.input-box {
  position: relative;
  height: 3.5rem;
  max-width: 56.25rem;
  width: 13rem;
  background: #fff;
  margin: 0 2.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.313rem 1.25rem rgba(0, 0, 0, 0.1);

}

.input-box i,
.input-box .button {
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  width: 18%;
}

.input-box i {
  left: 2.5rem;
  font-size: 1.875rem;
  color: #707070;
}

.input-box input {
  height: 100%;
  width: 90%;
  outline: none;
  font-size: 1.12rem;
  font-weight: 400;
  border: none;
  padding: 0 1.25rem 0 0.8rem;
  background-color: transparent;
}

.input-box .button {
  right: 2.5rem;
  font-size: 1rem;
  font-weight: 400;
  color: #fff;
  border: none;
  padding: 0.56rem 0.313rem;
  border-radius: 0.375rem;
  background-color: #4070f4;
  cursor: pointer;
}

.input-box .button:active {
  transform: translateY(-50%) scale(0.98);
}

.searchBar {
  height: 3.5rem;
}

</style>
