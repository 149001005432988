<template>
    <div class="e-sidebar_panel">
        <CapacitorInput />
        <h3 class="e-sidebar_panelTitle">Part Selection</h3>
        <label class="e-label">Type</label>
        <Selector class="e-sidebar_type"
                  v-model="selected"
                  :options="options"
                  :selected="selected"
                  @change="(value) => setSelectedType(value)" />

        <Component :is="selected" />
    </div>
</template>

<script lang="ts">
    import Aluminum from './Aluminum.vue';
    import Ceramic from './Ceramic.vue';
    import Polymer from './Polymer.vue';
    import Tantalum from './Tantalum.vue';
    import Film from './Film.vue';
    import CapacitorInput from './CapacitorInput.vue';
    import Selector from '@/components/Selector.vue';

    import { createNamespacedHelpers } from "vuex";
    const {mapActions } = createNamespacedHelpers("calcZ");

    const ceramicImage =  require('@/img/ceramic.jpg');
    const polymerImage =   require('@/img/polymer.jpg');
    const tantalumImage =   require('@/img/tantalum.jpg');
    const filmImage =   require('@/img/film.jpg');
    const aluminumImage =   require('@/img/aluminum.jpg');

    import Vue from 'vue';
    export default Vue.extend({
        components: {
            CapacitorInput,
            Selector,
            Aluminum,
            Ceramic,
            Polymer,
            Tantalum,
            Film
        },
        data() {
            return {
                selected: "ceramic",
                options: [
                    { value: "ceramic", label: "Ceramic", image: ceramicImage },
                    { value: "polymer", label: "Polymer", image: polymerImage },
                    { value: "tantalum", label: "Tantalum", image: tantalumImage },
                    { value: "film", label: "Film", image: filmImage }
                    //{ value: "aluminum", label: "Aluminum Electrolytic", image: aluminumImage }
                ],
            }
        },
        methods: {
            ...mapActions([
                "setCapacitorType",
            ]),
            setSelectedType(capacitorType) {
                this.setCapacitorType(capacitorType);
                this.selected = capacitorType;
            }
        }
    })
</script>

<style scoped>
    .e-sidebar_type,
    .e-label {
        width: calc(100% - 2rem);
        margin-right: 1rem;
        margin-left: 1rem;
    }
</style>