import { render, staticRenderFns } from "./base-chart-error-message.vue?vue&type=template&id=303dd0ce&scoped=true"
import script from "./base-chart-error-message.vue?vue&type=script&lang=ts"
export * from "./base-chart-error-message.vue?vue&type=script&lang=ts"
import style0 from "./base-chart-error-message.vue?vue&type=style&index=0&id=303dd0ce&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "303dd0ce",
  null
  
)

export default component.exports