<template>
  <div>
    <div class="welcome-msg">
      <div class="cap-title">Seed Generator</div>
      <div>
        <span class="admin-name" v-if="name">Logged in as: {{ name }}</span>
        <span><BaseButton type=":word" @baseButtonClick="logout">Logout</BaseButton></span>
      </div>
    </div>
    <div class="e-panel:3 l-box:inset2 cap-plot_outer">
      <span v-if="parts.length === 1" class="startupMsg">To begin, please select an existing part.</span>
      <div v-if="parts.length > 1" class="admin-buttons">
        <Buttons @clear-error="clearErrMsg"
                 @handle-error="handleErrorMessage"
                 on-submit="onsubmit"
                 @on-overlay="onOverlay"
                 @on-setLoadCeProps="setLoadCeProps"
                 :enableCeButton="enableCeButton"/>
      </div>
      <p v-if="errorMsg !== ''" class="err">Error: {{ errorMsg }}</p>
      <BasePlot :plot="plot" :style="chartStyle"/>
      <Sliders v-if="displayForm" @change="onChangeSimulation"/>
      <form id="seed-form" @submit.prevent="uploadDataToDatabase">
        <SeedForm
            @change="onChangeSimulation"
            :name="name"
            :previousUpdateStatus="previousUpdateStatus"
            :previousEditor="previousEditor"
            v-if="displayForm"
            :key="'seedForm' + updateState"/>
      </form>
    </div>
    <modal v-if="displayDeleteModal" @close="setDisplayDeleteModal(false)">
      <h2 slot="header">Remove Current Part?</h2>
      <div slot="body">
        <p>Are you sure you want to remove the current part? Doing so will lose any edited data.</p>
        <BaseButton type=":word" @baseButtonClick="removeAndClose">Yes</BaseButton>
        <BaseButton type=":word" @baseButtonClick="setDisplayDeleteModal(false)">No</BaseButton>
      </div>
    </modal>
    <modal v-if="partSavedToDb" @close="partSavedToDb=false">
      <h2 slot="header">Part Saved</h2>
      <div slot="body">
        <p>The part was successfully saved to the database.</p>
        <div class="part-review-container">
          <h4>Updated Part</h4>
          <div class="part-review">
            <div class="part-review-column" v-for="(key, value) in savedPart">
              <p><b>{{ value }}:</b> {{ key || "null" }}</p>
            </div>
          </div>
        </div>
        <p>Would you like to reset the page?</p>
        <BaseButton type=":word" @baseButtonClick="removeAndClose">Yes</BaseButton>
        <BaseButton type=":word" @baseButtonClick="partSavedToDb=false">Keep Editing</BaseButton>
      </div>
    </modal>
    <modal class="ce-modal" v-if="loadCeProps" @close="setLoadCeProps(false)">
      <h2 slot="header">Component Edge Props</h2>
      <div slot="body">
        <template v-if="seedCe.msg">
          <p><strong>Error:</strong> No matching part found.</p>
        </template>
        <template v-else>
          <template v-if="!seedCe.msg && seedCe.data.type.value !== parts[highlight].capType">
            <p><strong>Error:</strong> The capacitor type of the Base Kemet Part Number and the capacitor type of the
              selected part must match.</p>
            <p><strong>Capacitor Type related to Base Kemet Part Number:</strong> {{ seedCe.foundPartType }}</p>
            <p><strong>Capacitor of the selected Part:</strong> {{ parts[highlight].capType }}</p>
          </template>
          <template v-else>
            <p>Searched for part number <b>{{ partNum }}</b> and found part number <b>{{ seedCe.foundPartNumber }} </b>
            </p>
            <p>Match Quality: {{ seedCe.matchQuality }}%</p>
            <hr/>
            <form id="seed-ce-form" @submit.prevent="saveSeedCeProps">
              <template v-for="(prop) in seedCe.data">
                <div v-if="prop.value !== ''" class="seed-ce-modal">
                  <div class="modal-checkbox">
                    <input type="checkbox"
                           checked
                           :id="prop.title"
                           :value="prop.value"
                           @change="addToSelectedCeProps($event, prop)"/>
                    <label :for="`prop.title`">
                      <strong>{{ prop.title }}:</strong> {{ prop.value }}
                    </label>
                  </div>
                </div>
              </template>
            </form>
            <button type="submit" class="e-button submit-ce-props" form="seed-ce-form" slot="footer"> Use Selected
              Values
            </button>
          </template>
        </template>
      </div>
    </modal>
  </div>
</template>

<script lang="ts">
// External
import axios, {AxiosResponse} from 'axios';
// ReSharper disable once UnusedLocalImport
import Chart from "chart.js";

// Internal
import {chartMixin} from "@/mixins/chartMixin";
import pointStyles from "@/util/PointStyles";
import {plotUrl} from "@/util/Urls";

// Components
import CapacitorTable from "@/components/capacitor/simulation/CapacitorTable.vue";
import Buttons from "@/components/seed/Buttons.vue";
import SeedForm from "@/components/seed/SeedForm.vue";
import Sliders from "@/components/seed/Sliders.vue";
import Modal from "@/components/Modal.vue";

// State
import {createNamespacedHelpers} from 'vuex';
import Vue from "vue";
import {instance} from "@/util/Alerts";
import BasePlot from "@/components/_base/base-plot";

const {mapState, mapGetters, mapActions} = createNamespacedHelpers('seed');

export default Vue.extend({
  mixins: [chartMixin],
  data() {
    return {
      errorMsg: "",
      loadCeProps: false,
      seedCe: {},
      selectedCeProps: [],
      enableCeButton: false,
      updateState: 0,
      partNum: "",
      confirmRemove: false,
      name: "",
      previousUpdateStatus: "",
      previousEditor: "",
      partSavedToDb: false,
      savedPart: {}
    }
  },
  components: {
    CapacitorTable,
    Buttons,
    SeedForm,
    Sliders,
    Modal,
    BasePlot,
  },
  mounted() {
    // Fetch name and re-direct if not authorized
    this.fetchName();

    // Adding part via url param
    if (this.$route.query.pn) {
      this.addCapacitorByPartNumber({partNumber: this.$route.query.pn, method: "URL-seed"});
    }
    if (this.parts.length === 1) return; // don't update if only combined part
    this.getPlotData(); // when restoring state
  },
  computed: {
    ...mapState([
      'componentKey',
      'displayDeleteModal',
      'displayForm',
      'highlight',
      'parts',
      'partStyleApplied',
      'partStyleAvailable',
      'resetSeedPage'
    ]),
    ...mapGetters([
      'body',
    ]),
  },
  methods: {
    ...mapActions([
      'addCapacitorByPartNumber',
      'incrementComponentKey',
      'loadSeedProp',
      'removeCapacitorByPn',
      'setConfirmResetSeedPageRequired',
      'setDisplayDeleteModal',
      'setDisplayForm',
      'setHighlight',
      'setResetSeedPage',
      'setShared',
      'updateSimulationData'
    ]),
    addToSelectedCeProps(e, props) {
      if (e.target.checked) {
        this.selectedCeProps.push({title: props.title, value: props.value});
      } else {
        for (let i = 0; i < this.selectedCeProps.length; i++) {
          if (this.selectedCeProps[i].title === props.title) {
            this.selectedCeProps.splice([i], 1);
          }
        }
      }
    },
    clearErrMsg() {
      this.errorMsg = "";
    },
    async fetchName() {
      await instance.get("/ad/name")
          .then(response => {
            this.name = response.data;
          });
    },
    getPlotData() {
      const url = plotUrl;
      (this as any).clickResetZoom();
      this.initializePlot();
      instance.post(url, this.body).then((response: AxiosResponse<PlotResponseModel>) => {
        //Filters out startup plot dataset(k-sim)
        //On paramerter changes, removes current plotted data and replots with updated data
        let existingData = (this as any).plotData.datasets;
        existingData = existingData.filter(d => d.label !== "" && d.label.indexOf("Overlay") !== -1);
        (this as any).plotData = {} as Object;
        (this as any).plotData.datasets = existingData;

        // Datasets
        for (let i = 0; i < response.data.seriesList.length; i++) {
          if (response.data.request.combine === "No" && i < 2) {
            continue;
          }

          // Assign styleIndex
          let partListIndex = Math.floor(i / 2);
          let partStyleIndex = this.partStyleApplied[partListIndex];

          // Legend Index: assign solid or transparent coloring for dataset
          let legendIndex = 0;
          if (i % 2 === 0) /* even */ {
            legendIndex = 0; // e.g. Impedance
          } else { /* odd */
            legendIndex = 1; // e.g. ESR
          }

          // Legend
          let pnString = response.data.seriesList[i].kemetPn;
          let legendString = response.data.seriesList[i].yLabel;

          // Order
          let orderIndex = partListIndex + 1;
          if (this.highlight === partListIndex) {
            orderIndex = 0;
          }

          // Each `chart.js` datasets contains:
          let dataset = {
            pointStyle: pointStyles[partStyleIndex + 3].point,
            pointRadius: pointStyles[partStyleIndex + 3].radius,
            pointBorderWidth: pointStyles[partStyleIndex + 3].width,
            backgroundColor: pointStyles[partStyleIndex + 3].color[legendIndex],
            borderColor: pointStyles[partStyleIndex + 3].color[legendIndex],
            rotation: pointStyles[partStyleIndex + 3].rotation,
            borderWidth: 12,
            data: response.data.seriesList[i].points,
            fill: false,
            interpolate: true,
            label: pnString + " - " + legendString,
            //label: this.parts[this.highlight].seedProps[0].value + " - " + legendString,
            showLine: true,
            order: orderIndex
          } as Chart.ChartDataSets;
          (this as any).plotData.datasets.push(dataset);
        }

        /////////////////////////
        /* updatePlotOptions() */
        /////////////////////////
        let currentOptions = JSON.parse(JSON.stringify((this as any).plotOptions));

        // Title
        currentOptions.title.text = response.data.title;
        currentOptions.title.display = true;

        // Legend
        currentOptions.legend.display = true;

        // X-Axis
        currentOptions.scales.xAxes[0].scaleLabel.labelString = response.data.xLabel;
        currentOptions.scales.xAxes[0].type = "logarithmic";
        currentOptions.scales.xAxes[0].ticks = {
          autoSkip: false,
          // This callback preserves the autoSkip function of a logarithmic axis
          callback: (value: number, index: number, arr: Array<number>) => {
            // ReSharper disable once TsNotResolved
            const remain = value / (Math.pow(10, Math.floor(Chart.helpers.log10(value))));

            if (remain === 1 || index === 0 || index === arr.length - 1) {
              // order of magnitude function:
              // https://gist.github.com/ajmas/4193ac6911f5445ced37
              const unitExponentsNumber = [0, 3, 6, 9];
              const unitExponentsString = ["", "k", "M", "G"];

              const sign = (value < 0 ? -1 : 1);
              value = Math.abs(value);
              let idx = 0;
              for (let i = unitExponentsNumber.length - 1; i >= 0; i--) {
                if (value >= Math.pow(10, unitExponentsNumber[i])) {
                  idx = i;
                  break;
                }
              }
              value = (value / Math.pow(10, unitExponentsNumber[idx]));
              value = value * sign;

              //When zoomed, only show 2 decimal places for axis ticks at
              //left and right edge of graph
              const decimal = value - Math.floor(value);
              if (decimal !== 0) {
                value = +value.toFixed(1);
              }

              return value + " " + unitExponentsString[idx] + response.data.xUnit;
            } else {
              return "";
            }
          },
          minRotation: 45,
          maxRotation: 45
        };

        // Y-Axis
        currentOptions.scales.yAxes[0].scaleLabel.labelString = response.data.seriesList[0].yLabel + ", " + response.data.seriesList[1].yLabel;
        currentOptions.scales.yAxes[0].display = true;
        currentOptions.scales.yAxes[0].type = "logarithmic";
        currentOptions.scales.yAxes[0].ticks = {
          autoSkip: false,
          // This callback preserves the autoSkip function of a logarithmic axis
          callback: (value: number, index: number, arr: Array<number>) => {
            // ReSharper disable once TsNotResolved
            const remain = value / (Math.pow(10, Math.floor(Chart.helpers.log10(value))));

            if (remain === 1 || index === 0 || index === arr.length - 1) {
              // order of magnitude function:
              // https://gist.github.com/ajmas/4193ac6911f5445ced37
              const unitExponentsNumber = [-3, 0, 3, 6, 9];
              const unitExponentsString = ["m", "", "k", "M", "G"];

              const sign = (value < 0 ? -1 : 1);
              value = Math.abs(value);
              let idx = 0;
              for (let i = unitExponentsNumber.length - 1; i >= 0; i--) {
                if (value >= Math.pow(10, unitExponentsNumber[i])) {
                  idx = i;
                  break;
                }
              }
              value = (value / Math.pow(10, unitExponentsNumber[idx]));
              value = value * sign;

              //When zoomed, only show 2 decimal places for axis ticks at
              //left and right edge of graph
              const decimal = value - Math.floor(value);
              if (decimal !== 0) {
                value = +value.toFixed(2);
              }
              return value + " " + unitExponentsString[idx] + response.data.seriesList[0].yUnit;
            } else {
              return "";
            }
          },
          minRotation: 45,
          maxRotation: 45
        };

        // If the previous plot had two axes, remove the second one
        if (currentOptions.scales.yAxes.length === 2) currentOptions.scales.yAxes.pop();

        currentOptions.tooltips = {
          intersect: false,    //show the tooltip even if not directly on a point
          mode: "index", //must be interpolate for crosshair plugin
          //position: 'custom',
          callbacks: {
            // ReSharper disable once UnusedParameter
            // Keep unused parameter for now, in case needed later.
            title(tooltipItem: Array<Chart.ChartTooltipItem>, data: Chart.ChartData) {
              let title = response.data.xLabel + ": ";

              let value = tooltipItem[0].xLabel!;
              const unitExponentsNumber = [0, 3, 6, 9];
              const unitExponentsString = ["", "k", "M", "G"];

              value = +value;

              let idx = 0;
              for (let i = unitExponentsNumber.length - 1; i >= 0; i--) {
                if (value >= Math.pow(10, unitExponentsNumber[i])) {
                  idx = i;
                  break;
                }
              }
              value = (value / Math.pow(10, unitExponentsNumber[idx]));
              value = value.toFixed(1);

              title += value + " " + unitExponentsString[idx] + response.data.xUnit;
              return title;
            },
            label(tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData) {
              let label = data.datasets![tooltipItem.datasetIndex!].label || "";

              let value = tooltipItem.yLabel!;
              const unitExponentsNumber = [-3, 0, 3, 6, 9];
              const unitExponentsString = ["m", "", "k", "M", "G"];

              value = +value;

              let idx = 0;
              for (let i = unitExponentsNumber.length - 1; i >= 0; i--) {
                if (value >= Math.pow(10, unitExponentsNumber[i])) {
                  idx = i;
                  break;
                }
              }
              value = (value / Math.pow(10, unitExponentsNumber[idx]));
              label += ": " + value.toFixed(1);
              label += " " + unitExponentsString[idx] + response.data.seriesList[0].yUnit;

              return label;
            }
          },
        };

        currentOptions.plugins = {
          crosshair: {
            line: {
              color: '#F66',  // crosshair line color
              width: 1        // crosshair line width
            },
            sync: {
              enabled: false,            // enable trace line syncing with other charts
              group: 1,                 // chart group
              suppressTooltips: false   // suppress tooltips when showing a synced tracer
            },
            zoom: {
              enabled: true,                                      // enable zooming
              zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box
              zoomboxBorderColor: '#48F',                         // border color of zoom box
              zoomButtonText: 'Reset Zoom',                       // reset zoom button text
              zoomButtonClass: 'reset-zoom',                      // reset zoom button class
            },
            callbacks: {
              beforeZoom: function (start: any, end: any) {                  // called before zoom, return false to prevent zoom
                return true;
              },
              afterZoom: function (start: any, end: any) {                   // called after zoom
              }
            }
          }
        };

        (this as any).plotOptions = currentOptions;
        (this as any).chartStyle = {
          height: `60vh`, // sets max height, responds to window size
          minHeight: '450px', // keeps the graph from getting too small
          position: 'relative', // seems unnecessary?
          marginBottom: '2rem',
        };
      });
    },
    handleErrorMessage(errorMsg) {
      this.errorMsg = errorMsg;
    },
    logout() {
      instance.get("/auth/logout").finally(() => {
            window.location.href = instance + "/";
          }
      )
    },
    onOverlay(plotImpedance, plotESR) {
      this.setLoadCeProps(true);
      //checks if existing overlay- if yes, remove existing and add new overlay plot data
      const existingData = (this as any).plotData.datasets.length > 2
          ? (this as any).plotData.datasets.slice(2)
          : (this as any).plotData.datasets;

      (this as any).plotData = {} as Object;
      (this as any).plotData.datasets = existingData;

      for (let argument in arguments) {
        let label = argument === "0" ? 'Overlay: Impedance' : 'Overlay: ESR';
        let dataset = {
          pointStyle: pointStyles[argument].point,
          pointRadius: pointStyles[argument].radius,
          pointBorderWidth: pointStyles[argument].width,
          backgroundColor: pointStyles[argument].color[argument],
          borderColor: pointStyles[argument].color[argument],
          rotation: pointStyles[argument].rotation,
          borderWidth: 5,
          data: arguments[argument],
          fill: false,
          interpolate: true,
          label,
          showLine: true,
          order: 1
        } as Chart.ChartDataSets;
        (this as any).plotData.datasets.unshift(dataset)
      }
      //remove ceProps from previous part
      this.seedCe = {};
      this.updateState++;
    },
    onChangeNonSimulationdata(title, output) {
      this.updateSimulationData({title, output});
    },
    onChangeSimulation(title, output) {
      if (title === "RC Ladder multipliers") {
        let formId = document.getElementById("seed-form");
        //@ts-ignore
        if (formId.checkValidity()) {
          this.updateSimulationData({title, output});
          this.updateSimulationData({title: "Steps in the RC ladder", output: output.split(",").length});
          this.getPlotData();
        }
      } else {
        this.updateSimulationData({title, output});
        this.getPlotData();
      }
    },
    removeAndClose() {
      this.errorMsg = "";
      this.setResetSeedPage(true);
      this.setDisplayDeleteModal(false);
      this.enableCeButton = false;
      this.previousUpdateStatus = "";
      this.previousEditor = "";
      this.partSavedToDb = false;
    },
    saveSeedCeProps() {
      for (let obj in this.selectedCeProps) {
        this.updateSimulationData({title: this.selectedCeProps[obj].title, output: this.selectedCeProps[obj].output})
      }
      this.loadCeProps = false;
      this.updateState++;
      this.selectedCeProps = [];
    },
    async setLoadCeProps(data) {
      this.selectedCeProps = [];
      if (data) {
        const url = "/api/ce/seed";
        this.partNum = this.parts[this.highlight].seedProps.find(p => p.name === "KEMET_Part_Number").value;
        let response = await axios.post(url, {partNumber: this.partNum});
        this.seedCe = response.data;
        for (let key in this.seedCe.data) {
          if (!this.seedCe.data.hasOwnProperty(key)) continue;
          let obj = this.seedCe.data[key];
          this.selectedCeProps.push({title: obj.title, output: obj.value})
        }
      }
      this.loadCeProps = data;
    },
    setName() {
      if (this.parts[this.highlight].seedProps.find(p => p.name === "Update_Status").value) {
        const status = this.parts[this.highlight].seedProps.find(p => p.name === "Update_Status").value;
        const nameRegex = /^([^:])+/g;
        const msgRegex = /[^\:]*$/g;
        let foundMsg = status.match(msgRegex);
        let foundName = status.match(nameRegex);
        if (foundMsg) {
          //@ts-ignore
          this.previousUpdateStatus = msgRegex.exec(status)[0];
        }
        if (foundName) {
          // @ts-ignore
          this.previousEditor = nameRegex.exec(status)[0];
        }
      }
      this.onChangeNonSimulationdata("Status as of last update", this.name);
    },
    async uploadDataToDatabase() {
      const url = "/api/seed/addPart";
      let seedProps = this.parts[this.highlight].seedProps;
      this.errorMsg = "";
      let seedPart = {};
      seedProps.forEach(prop => seedPart[prop.name] = prop.value);
      instance.post(url, seedPart)
          .then((response) => {
            this.partSavedToDb = true;
            this.savedPart = response.data;
          })
          .catch((error) => {
            this.errorMsg = error.response.data.Message;
          });
    },
  },
  watch: {
    "parts.length": {
      handler: function (watch_var) {
        (this as any).getPlotData();
      }
    },
    "resetSeedPage": {
      handler: function () {
        if (this.resetSeedPage == true) {
          this.removeCapacitorByPn(this.parts[this.highlight].basePn);
          this.plotData = {} as Chart.ChartData;
          this.plotData.datasets = [] as any[];
          this.setResetSeedPage(false);
          this.setDisplayDeleteModal(false);
          this.setDisplayForm(false);
        }
      },
      deep: true,
    },
    "plotData.datasets.length": {
      handler: function () {
        if (this.plotData.datasets.length > 2) {
          this.enableCeButton = true;
        }
      }
    },
    "displayDeleteModal": {
      handler: function () {
      },
      deep: true
    },
    "displayForm": {
      handler: function () {
        if (this.displayForm) {
          this.setName();
        }
      }
    }
  },
})
</script>

<style>

.part-review-container {
  margin: 0 auto;
  border: 1px solid var(--color-gray-lighter);
}

.part-review-container h4 {
  padding: 5px;
  width: 100%;
  background-color: #3081c0;
  margin-bottom: 10px;
  color: white;
}

.part-review {
  overflow-y: auto;
  height: 360px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.part-review-column {
  padding: 0 10px;
  width: 50%;
  margin: 5px 0;
}

.part-review-column p {
  margin: 0px;
}

.ce-modal p {
  margin: 8px
}

.submit-ce-props {
  margin: 15px;
}

.seed-ce-modal {
  display: inline-flex;
}

.modal-checkbox {
  display: inline-flex;
  align-items: baseline;
  margin: 5px 15px;
}

.modal-checkbox label {
  margin-left: 10px;
}

.fade-enter-active, .fade-leave-active {
  transition-duration: 0.3s;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.startupMsg {
  display: inline-block;
  width: 100%;
  font-weight: bold;
  padding: 10px 0;
  line-height: 30px;
  background: var(--color-main-dark);
  color: white;
  text-align: center;
  margin-bottom: 20px;
}

.err {
  color: red;
  text-align: center;
}

.seed-button {
  margin-right: 10px;
}

.admin-buttons {
  display: flex;
  display: inline-flex;
  margin-bottom: 5px;
}

.cap-title {
  font-size: 1.15rem;
  line-height: 3rem;
  margin-left: 0.5rem;
}

.cap-plot_outer {
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  width: calc(100vw - 24.5rem);
  min-height: 500px;
}

.ce-props {
  padding: 10px;
}

.is-closed .cap-plot_outer {
  width: calc(100vw - 24.5rem + 360px);
}

[class*='e-button']:active, [class*='e-button']:focus {
  background-size: 180%;
}

.welcome-msg {
  display: flex;
  justify-content: space-between;
}

.admin-name {
  line-height: 3rem;
  margin-right: 0.5rem;
}
</style>