<!-- src/components/simulation/base/BaseButton.vue -->
<template>
        <button type="button"
                :class="`e-button${this.type}`"
                :disabled="disabled"
                @click="baseButtonClick"
        >
        <slot>{{ title }}</slot>
        </button>
</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        inheritAttrs: false,
        props: {
            title: {
                type: String,
                required: false
            },
            type: {
                type: String,
                required: false,
                default: ''
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        methods: {
            baseButtonClick() {
                this.$emit('baseButtonClick');
            }
        }
    })
</script>

<style scoped>
</style>