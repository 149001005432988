//@ts-ignore
import Chart from "chart.js";

const green = {
    data: [{ x: 0, y: 25 }, { x: 50, y: 25 },],
    pointRadius: 1,
    backgroundColor: "rgba(0,255,0,0.1)",
    hideInLegendAndTooltip: true,
    hidden: false,
    showLine: true,
    fill: true
} as Chart.ChartDataSets;
const yellow = {
    data: [{ x: 0, y: 50 }, { x: 50, y: 50 },],
    pointRadius: 1,
    backgroundColor: "rgba(255,255,0,0.1)",
    hideInLegendAndTooltip: true,
    hidden: false,
    showLine: true,
    fill: true
} as Chart.ChartDataSets;
const red = {
    data: [{ x: 0, y: 60 }, { x: 50, y: 60 },],
    pointRadius: 1,
    backgroundColor: "rgba(255,0,0,0.1)",
    hideInLegendAndTooltip: true,
    hidden: false,
    showLine: true,
    fill: true
} as Chart.ChartDataSets;

export const multFreqBackground = {
    green,
    red,
    yellow
};
