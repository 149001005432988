<template>


  <div class="info-table">
    <h1 style=" width: 30%; ; color: #17A2B8">{{ partNumber }}</h1>
    <hr style="width: 35%; margin-left: 0">
    <div style="float: left">
      <h3 style="font-size: medium; width: 5%; font-weight: bold; color: gray">Series </h3>
      <h3 style="text-align: left; ">{{ series }}</h3>
    </div>
    <div style="float: left; margin-left: 4rem">
      <h3 style="font-size: medium; width: 2%; color: gray">Capacitance (nF)</h3>
      <h3 style="text-align: left; ">{{ arrayCapacitance }}</h3>
    </div>
  </div>


</template>

<script lang="ts">
import Vue from 'vue';
import {mapActions} from 'vuex';

export default Vue.extend({
  methods: {
    ...mapActions([
      'setSortOrder'
    ]),
    roundTo: function (num, decimal) {
      return Math.round((num + Number.EPSILON) * Math.pow(10, decimal)) / Math.pow(10, decimal);
    },
  },
  props: {
    partNumber: {
      type: String,
      required: true
    },
    series: {
      type: String,
      required: true
    },
    arrayCapacitance: {
      type: Number,
      required: true
    },
  }
})
</script>

<style scoped>
.info-table {
  width: 80%;
}

table {
  border-spacing: 0;
  width: 5%;
  empty-cells: show;
}

tr {
  text-align: center;
}

td {
  text-align: inherit;
  font-size: medium;
}

th {
  padding: 0.625rem;
}

table tbody td:nth-child(even) {
}

table tbody tr:nth-child(odd) {
  //background-color: #eee
}



table tbody tr:last-child td:first-child {
  //border-radius: 0 0 0 5px;
}

table tbody tr:last-child td:last-child {
  //border-radius: 0 0 5px 0;
}
</style>