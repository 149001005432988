<template>
    <div>
        <table class="cap-table" v-if="items.length > 0" :key="currentRoute+',capResults,'+sKey+cKey">
            <tr class="cap-table_row" v-for="(item, index) in items" :key="index">
                <input type="checkbox"
                       @click="checkRoute($event)"
                       class="e-checkbox"
                       :id="`capacitance-${index}`"
                       :checked="inPartsList(item.basePn)"
                       :value="item"
                       @change="changePart($event, item)" />
                <label class="e-label cap-table_item"
                       :for="`capacitance-${index}`">
                    <span class="cap-table_value">{{ item.capDisplay }}</span>
                    <span class="cap-table_part">{{ item.kemetPn }}</span>
                </label>
            </tr>
        </table>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue'

    export default Vue.extend({
        inheritAttrs: false,
        data() {
            return {
                currrentParts: [] as CapacitorModel[],
                currentNamespace: '',
                currentKey: 0,
            }
        },
        props: {
            items: {
                type: Array,
                required: true
            },
        },
        created() {
        },
        mounted() {
        },
        computed: {
            currentRoute() {
                return this.$route.path;
            },
            sKey() {
                return this.$store.state.seed.componentKey;
            },
            cKey() {
                return this.$store.state.calcZ.componentKey;
            },
        },
        methods: {
            // Used when removing part from list on Seed page to trigger modal instead of unchecking box immediately
            checkRoute(e) {
                if (this.currentRoute == "/seed" && !e.target.checked && this.$store.state.seed.confirmResetSeedPageRequired) {
                    e.preventDefault();
                    this.$store.dispatch(this.currentNamespace + '/setDisplayDeleteModal', true);
                } else {
                    return;
                }
            },
            handleAddPart(part: CapacitorModel) {
                this.$store.dispatch(this.currentNamespace + '/addCapacitor', part);
                if (this.currentNamespace === "seed") {
                    this.$store.dispatch(this.currentNamespace + '/loadSeedProp');
                }
            },
            handleRemovePart(basePn: string) {
                this.$store.dispatch(this.currentNamespace + '/removeCapacitorByPn', basePn);
            },
            changePart: function (e: any, part: CapacitorModel) {
                if (e.target.checked) {
                    this.handleAddPart(part);
                } else {
                    if (this.currentRoute == "/seed") {
                        this.$store.dispatch(this.currentNamespace + '/setDisplayDeleteModal', true)
                    } else {
                        this.handleRemovePart(part.basePn);
                    }
                }
            },
            inPartsList: function (partNumber: string) {
                switch (this.currentRoute) {
                    case "/seed":
                    case "/seed/":
                        this.currentParts = this.$store.state.seed.parts;
                        break;
                    case "/capacitor-simulation":
                    case "/capacitor-simulation/":
                        this.currentParts = this.$store.state.calcZ.parts;
                        break;
                    default:
                        //console.log('default CapResults')
                        break;
                }
                if (!(this.currentParts === undefined)) {
                    for (let i = 0; i < this.currentParts.length; i++) {
                        if (partNumber === this.currentParts[i].basePn) {
                            return true;
                        }
                    }
                    return false;
                }
                else {
                    return false;
                }
            },
        },
        watch: {
            currentRoute: {
                handler: function (currentRoute: string) {
                    switch (currentRoute) {
                        case "/seed":
                        case "/seed/":
                            this.currentNamespace = 'seed';
                            break;
                        case "/capacitor-simulation":
                        case "/capacitor-simulation/":
                            this.currentNamespace = 'calcZ';
                            break;
                        default:
                            //console.log('default CapResults')
                            break;
                    }
                },
                immediate: true
            }
        },
    })
</script>

<style>
    #modal {
        position: absolute;
    }

        #modal div .modal-container {
            width: inherit;
            border-radius: 0;
        }

    .modal-button-row {
        text-align: center;
    }

    .cap-table {
        width: 100%;
    }

    .cap-table_row,
    .cap-table_item {
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        width: 100%;
        font-weight: normal;
        font-size: 0.95em;
    }

        .cap-table_item.cloned {
            font-weight: 700;
        }

    .cap-table_value {
        flex: 0 0 6ch;
    }

    .cap-table_part::before {
        content: '-';
        padding-right: 1ch;
    }
</style>