<template>
    <div class="cap-parts">
        <!--TODO: use .hidden prop on CapacitorModel to show the part or not instead of specific logic for CalcZ-->
        <div v-if="parts.length > 1">
            <table class="cap-table" id="table-container">
                <thead class="cap-thead">
                    <tr class="cap-tr" id="table-row">
                        <th class="cap-th" title="Expand (click the arrow) to view more information." data-html2canvas-ignore>Info</th>
                        <th class="cap-th" title="Chart legend icon">Legend</th>
                        <th class="cap-th" title="Capacitance Value Tolerance Variation">Tolerance</th>
                        <th class="cap-th" title="Part Number">Part Number</th>
                        <th class="cap-th" title="Capacitance">Cap.</th>
                        <th class="cap-th" title="Maximum Voltage Rating (DC, except certain Series designed for AC)">V<sub>DC</sub>*</th>
                        <th class="cap-th" title="Temperature Coefficient Class (or Dielectric)">Dielectric</th>
                        <th class="cap-th" title="Simulation Parameter: Quantity">Qty.</th>
                        <th class="cap-th" title="Simulation Parameter: Bias Voltage.">Bias (V)</th>
                        <th class="cap-th" title="Simulation Parameter: Ambient Temperature Celcius">Amb. (°C)</th>
                        <th class="cap-th" title="Click to duplicate part row." data-html2canvas-ignore>Add</th>
                        <th class="cap-th" title="Click to remove part row." data-html2canvas-ignore>Remove</th>
                    </tr>
                </thead>
                <tbody class="cap-tbody">

                    <!--Except combined part alone-->
                    <!--TODO use hidden property on CapacitorModel to determine if part should be hidden-->
                    <template v-for="(part, index) in parts"
                              v-if="!((shared.radios.combine.select === 'No') && (index === 0))">
                        <tr :class="{ 'is-open' : infoOpen.includes(index + part.instances - 1)}"
                            class="cap-tr cap-table-info-row"
                            :key="'capTableRow'+index">

                            <!--Arrow Icon spans multiple rows & rendered on root part instance-->
                            <td title="Tip: press 'ESC' to close all info views."
                                data-html2canvas-ignore
                                class="cap-td"
                                v-if="part.instances >= 1"
                                :rowspan="part.instances"
                                @click="showInfo(index + part.instances - 1); setHighlight(index);">
                                <svg class="cap-icon cap-icon_expand">
                                    <use xlink:href="#arrow-right"></use>
                                </svg>
                            </td>
                            <!--for Combined Part show a "-" -->
                            <td v-if="index===0"
                                style="text-align: center;"
                                rowspan="1"
                                class="cap-td" 
                                data-html2canvas-ignore>
                                -
                            </td>

                            <!--Graph Legend Icon-->
                            <td class="cap-td icon-legend"
                                @click="setHighlight(index)">
                                <svg class="cap-legend">
                                    <use :xlink:href="'#graph' + partStyleApplied[index]"></use>
                                </svg>
                            </td>

                            <!--Tolerance Dropdown-->
                            <td class="cap-td">
                                <BaseDropdown title=""
                                              :options="part.tolerance.options"
                                              :disabled="part.tolerance.disabled"
                                              v-model="part.tolerance.select"
                                              @change="(value) => part.tolerance.select = value" />
                            </td>

                            <!--Part Num-->
                            <td class="cap-td"
                                @click="setHighlight(index)">
                                <a target="_blank" :href="partDetailSearchUrl + (getPn(part) ? getPn(part) : part.kemetPn)">
                                    <strong v-if="highlight === index">{{part.kemetPn}}</strong>
                                    <span v-else>{{part.kemetPn}}</span>
                                </a>
                            </td>

                            <!--Table Properties-->
                            <td class="cap-td"
                                @click="setHighlight(index)">
                                <strong v-if="highlight === index">{{part.capDisplay}}</strong>
                                <span v-else>{{part.capDisplay}}</span>
                            </td>
                            <td class="cap-td"
                                @click="setHighlight(index)">
                                <strong v-if="highlight === index">{{part.voltageRating | singlePrecision}}</strong>
                                <span v-else>{{part.voltageRating | singlePrecision}}</span>
                            </td>
                            <td class="cap-td"
                                @click="setHighlight(index)">
                                <strong v-if="highlight === index">{{part.dielectric}}</strong>
                                <span v-else>{{part.dielectric}}</span>
                            </td>

                            <!--Simulation Parameters-->
                            <td class="cap-td"
                                v-for="(input, index) in part.param"
                                :key="'input'+index">
                                <div style="display: flex;justify-content:center;">
                                    <BaseInputNumber title=""
                                                     :width="input.width"
                                                     :min="input.min"
                                                     :max="parseInt(input.max)"
                                                     :step="input.step"
                                                     :disabled="input.disabled"
                                                     :value="input.select"
                                                     @change="(value) => input.select = value" />
                                </div>
                            </td>

                            <!--Actions-->
                            <td class="cap-td cap-actions" data-html2canvas-ignore>
                                <div class="l-inline:center l-box:squish-1">
                                    <span class="e-button:word cap-duplicate"
                                          css-icon
                                          v-if="part.instances > 0"
                                          @click="duplicatePart(index)"
                                          :title="'Duplicate Part '+part.kemetPn"
                                          style="--loc-border-radius: 0.5rem;">
                                        +
                                    </span>
                                </div>
                            </td>
                            <td class="cap-td cap-actions" data-html2canvas-ignore>
                                <div class="l-inline:center l-box:squish-1">
                                    <span class="e-button:word cap-remove"
                                          css-icon
                                          @click="removeCapacitorByIndex(index)"
                                          :title="'Remove Part '+part.kemetPn"
                                          style="--loc-button-radius: 2rem;">
                                        &times;
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <!--Expanded Information from CE-->
                        <tr class="cap-tr cap-info"
                            :key="'capCeInfo'+index"
                            v-if="ceInfoIndexes.includes(index)" data-html2canvas-ignore>
                            <!--Create row for CE underneath last child instance-->
                            <td class="cap-td" colspan="100%">
                                <div class="cap-info_block" :class="{ 'is-open' : infoOpen.includes(index) }"
                                     v-if="!cePropsFound(part)">
                                    <strong>Additional information is not available for this part.</strong>
                                </div>                
                                <div class="cap-info_block"
                                     :class="{ 'is-open' : infoOpen.includes(index) }"
                                     v-else="cePropsFound(part)">
                                    <!--CE Props-->
                                    <!-- Display 1st level of CE Props -->
                                    <div class="l-spaced:4" v-if="!part.displayAllCeProps">
                                        <div v-for="(ceParam, index) in part.ceProps"
                                             :key="'params'+index+'first'"
                                             v-if="(ceParam.value !== '') && (ceParam.parameterName !== 'KSIM Support') && (ceParam.parameterName !== 'Unique PN') && (ceParam.step === '1')">
                                            <base-table-ce :title="ceParam.parameterName"
                                                           :key="'params'+index"
                                                           :value="ceParam.value" />
                                        </div>
                                    </div>
                                    <!-- Display 2nd level of CE Props -->
                                    <div class="l-spaced:4" v-else>
                                        <div v-for="(ceParam, index) in part.ceProps"
                                             :key="'params'+index+'all'"
                                             v-if="(ceParam.value !== '') && (ceParam.parameterName !== 'KSIM Support') && (ceParam.parameterName !== 'Unique PN') && ceParam.step === '1'">
                                            <base-table-ce :title="ceParam.parameterName"
                                                           :key="'params'+index"
                                                           :value="ceParam.value" />
                                        </div>

                                        <div v-for="(ceParam, index) in part.ceProps"
                                             :key="'params'+index+'all'"
                                             v-if="(ceParam.value !== '') && (ceParam.parameterName !== 'KSIM Support') && (ceParam.parameterName !== 'Unique PN') && ceParam.step === '2'">
                                            <base-table-ce :title="ceParam.parameterName"
                                                           :key="'params'+index"
                                                           :value="ceParam.value" />
                                        </div>
                                    </div>
                                    <BaseButton class="displayCeButton" type=":main" @baseButtonClick="callDisplayAllCeProps(index)">{{part.displayAllCeProps ? 'Display Less' : 'Display More'}}</BaseButton>
                                    <!--Downloads-->
                                    <base-table-downloads :specsheetUrl="specUrl + getPn(part)"
                                                          :datasheetUrl="dsUrl + getPn(part) + '.pdf'"
                                                          :stepUrl="stepUrl + getPn(part) + '.step'"
                                                          :RoHSUrl="rohsUrl + getPn(part) + '.pdf'" />
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <!--If no parts-->
        <div v-else class="l-place:center">
            <strong>
                Add a part to the simulation from the left sidebar.
            </strong>
        </div>
    </div>
</template>

<script lang="ts">
    // External
    import axios from 'axios';
    // Internal
    import { linkMixin } from "@/mixins/linkMixin";

    //Vuex state
    import { createNamespacedHelpers } from 'vuex';
    const { mapState, mapGetters, mapActions } = createNamespacedHelpers('calcZ');

    import Vue from 'vue';
    import {instance} from "@/util/Alerts";
    export default Vue.extend({
        mixins: [linkMixin],
        data() {
            return {
                infoOpen: [] as number[],
            }
        },
        computed: {
            ...mapState([
                'highlight',
                'parts',
                'partStyleApplied',
                'shared',
                'componentKey',
                'displayAllCeProps'
            ]),
            ...mapGetters([
                'toleranceList',
            ]),
            //Indexes of rows where expanded CE info should be shown
            ceInfoIndexes(): number[] {
                let idxs = [] as number[];
                for (let i = 0; i < this.parts.length; i++)
                    if (this.parts[i].instances > 0) //if at a root part
                        //add position further down based on how many instances
                        idxs.push(i + (this.parts[i].instances - 1));
                return idxs;
            },
        },
        filters: {
            // TODO: this is used a lot of places, use mixin?
            singlePrecision: function (value: string) {
                let v = parseFloat(value).toFixed(1);
                if (v.slice(-1) === "0") { // only show decimal if it's not a zero
                    return v.slice(0, -2)
                }
                return v
            },
        },
        methods: {
            ...mapActions([
                'setHighlight',
                'removeCapacitorByIndex',
                'duplicatePart',
                'setShared',
                'incrementComponentKey',
                'setDisplayAllCeProps',
                'setPartsList',
                'updateTolerances',
                'updateCombinedPart',
                'updatePartMaximums',
            ]),
            getPn: function (part: CapacitorModel) {
                if (part.ceProps.length > 0) {
                    let partNum = part.ceProps.filter(obj => {
                        return obj.parameterName === "Unique PN"
                    })[0].value;
                    return partNum;
                }
                else {
                    return "";
                }
            },

            // Keep track of which indexes are open
            showInfo: function (rowIndex: any) {
                //If already in list (being shown), remove from list
                if (this.infoOpen.includes(rowIndex)) {
                    const idx = this.infoOpen.findIndex(row => row === rowIndex);
                    this.infoOpen.splice(idx, 1);
                }
                // Else add to list
                else {
                    this.infoOpen.push(rowIndex);
                }
            },
            cePropsFound: function (part: CapacitorModel) {
                const entries = Object.entries(part.ceProps);
                if (part.kemetPn === 'Combined') {
                    return false;
                }
                for (let i = 0; i < entries.length; i++) { // search through all CE props
                    if (entries.length > 1) {
                        return true;
                    }
                }
                return false;
            },
            callDisplayAllCeProps: function (index) {
                this.setDisplayAllCeProps(index);
            }
        },
        mounted() {
            // Escape to close all expanded info
            document.body.addEventListener('keyup', e => {
                if (e.keyCode === 27) {
                    this.infoOpen = [];
                }
            });
            instance.get("/auth/bannerImageRequest/").then((response) => {
                (this as any).bannerImage = response.data;
            });
        },
        watch: {
            toleranceList: {
                handler: function (watch_var) {
                    this.updateTolerances();
                },
                deep: true, //array
                immediate: false
            },
            "parts.length": {
                handler: function (watch_var) {
                    // Update Combined option based on # of parts
                    let tempShared = JSON.parse(JSON.stringify(this.shared)) as CalcZParameters;
                    if (watch_var === 1) {
                        tempShared.radios.combine.select = "No";
                        tempShared.radios.combine.disabled = true;
                    } else if (watch_var === 2) {
                        tempShared.radios.combine.select = "No";
                        tempShared.radios.combine.disabled = true;
                    } else if (watch_var > 2) {
                        tempShared.radios.combine.disabled = false;
                    }
                    (this as any).setShared(tempShared);
                    // Update the voltageRating and maxTemp properties of the combined part
                    this.updateCombinedPart();
                    // Sets the maximums for inputs of each individual part
                    this.updatePartMaximums();
                    // After updating everything, re-render table body with new values
                    this.incrementComponentKey();
                },
                deep: false,
                immediate: false
            },
        }
    });
</script>

<style>
    .cap-parts {
        margin-bottom: 2rem;
    }

    .cap-table {
        width: 100%;
        border-collapse: collapse;
        box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1);
    }

    .cap-tr {
        box-shadow: inset 1px 0 0 rgba(0, 0, 0, 0.1);
        transition: background-color 0.2s ease-out;
        &.is-open

    {
        background-color: hsla(206, 60%, 47%, 0.14);
    }

    }

    .cloned.is-open + .cap-tr {
        background-color: hsla(206, 60%, 47%, 0.14);
    }

    .cap-th,
    .cap-td {
        box-shadow: inset -1px 1px 0 rgba(0, 0, 0, 0.1);
        width: 1%;
        white-space: nowrap;
    }

    .cap-th {
        color: #fff;
        /* background-color: var(--color-main); */
        background-color: #3081c0;
    }

    .cap-legend {
        width: 1rem;
        height: 1rem;
    }

    .cap-td .l-field {
        margin-bottom: 0;
    }

    .cap-info {
        box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -8px 8px -8px rgba(0, 0, 0, 0.15), inset 0 8px 8px -8px rgba(0, 0, 0, 0.15);
        background-color: hsla(206, 60%, 47%, 0.03);
    }

    .cap-info_block {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease-out;
        will-change: max-height;
        &.is-open

    {
        margin: 10px 0;
        max-height: 1000px;
    }

    }

    .cloned.is-open + .cap-tr + .cap-tr .cap-info_block {
        max-height: 1000px;
    }

    .l-field {
        text-align: center;
    }

    .cap-info_downloads {
        display: flex;
        justify-content: center;
        padding: 1rem;
        border-top: 1px solid var(--color-gray-lightest);
        .e-button\:word

    {
        padding: 0 1rem;
        color: var(--color-main);
        font-weight: normal;
    }

    }

    .cap-remove.cap-remove,
    .cap-duplicate.cap-duplicate {
        color: var(--color-main);
        font-weight: normal;
        font-size: 2rem;
        cursor: pointer;
    }

    .cap-icon {
        width: 1rem;
        height: 1rem;
        color: var(--color-main);
        transition: all 0.2s ease-out;
    }

    .cap-icon_expand {
        position: relative;
        top: 3px;
    }

    .is-open .cap-icon_expand {
        transform: rotate(90deg);
    }

    .displayCeButton {
        margin: 10px 0px;
    }

    @media screen and (max-width: 1205px) {
        .cap-parts {
            overflow-x: auto;
        }
    }
</style>