import axios from "axios";

import { ActionContext } from "vuex";
import { instance } from "@/util/Alerts";
type CapFromPowerContext = ActionContext<CapFromPowerState, RootState>;

const state = (): CapFromPowerState => ({
    inputs: {
        required: {
            power: {
                title: "Power (kW)",
                select: 22,
                min: 0.001,
                max: 1000.0,
                step: 0.001,
                disabled: false,
                hidden: false,
                width: 8,
            } as InputNumber,
            frequency: {
                title: "Frequency (kHz)",
                select: 100,
                min: 1,
                max: 10000,
                step: 1,
                disabled: false,
                hidden: false,
                width: 8,
            } as InputNumber,
            voltageMax: {
                title: "Vmax (V)",
                select: 400.0,
                min: 1.0,
                max: 1000.0,
                step: 0.1,
                disabled: false,
                hidden: false,
                width: 8,
            } as InputNumber,
            ripplePercentage: {
                title: "Ripple (%)",
                select: 10.0,
                min: 0.1,
                max: 100.0,
                step: 0.1,
                disabled: false,
                hidden: false,
                width: 6,
            } as InputNumber,
        },
        optional: {
            chipCap: {
                title: "Cap. (uF)",
                select: 0.22,
                min: 0.1,
                max: 1000.0,
                step: 0.01,
                disabled: false,
                hidden: false,
                width: 8,
            } as InputNumber,
            chipL: {
                title: "Length (mm)",
                select: 10.0,
                min: 0.1,
                max: 100,
                step: 0.1,
                disabled: false,
                width: 6,
            } as InputNumber,
            chipW: {
                title: "Width (mm)",
                select: 10.8,
                min: 0.1,
                max: 100.0,
                step: 0.1,
                disabled: false,
                hidden: false,
                width: 6,
            } as InputNumber,
            chipWeight: {
                title: "Weight (mg)",
                select: 10.0,
                min: 0.1,
                max: 100.0,
                step: 0.1,
                disabled: false,
                hidden: false,
                width: 6,
            } as InputNumber
        }
    },
    outputs: {
        capacitance: {
            title: "Calculated Capacitance",
            value: 0,
            unit: "uF",
        },
        numChips: {
            title: "Number of Chips",
            value: 0,
            unit: "",
        },
        totalArea: {
            title: "Total Area",
            value: 0,
            unit: "mm^2",
        },
        totalWeight: {
            title: "Total Weight",
            value: 0,
            unit: "mg",
        }
    }
});

const getters = {
    postBody: (state: CapFromPowerState) => { // what should type be?
        return {
            frequency: state.inputs.required.frequency.select,
            power: state.inputs.required.power.select,
            voltageMax: state.inputs.required.voltageMax.select,
            ripplePercentage: state.inputs.required.ripplePercentage.select,
            chipCap: state.inputs.optional.chipCap.select,
            chipL: state.inputs.optional.chipL.select,
            chipW: state.inputs.optional.chipW.select,
            chipWeight: state.inputs.optional.chipWeight.select,
        }
    }
};

const mutations = {
    setOutputs(state: CapFromPowerState, payload: PowerCalculations) {
        state.outputs = payload;
    }
};

const actions = {
    getOutputs(context: CapFromPowerContext) { // what should type be?
        instance.post("/api/power/", context.getters.postBody).then((response: any) => {
            context.commit("setOutputs", response.data);
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
