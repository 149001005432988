<template>
  <div class="e-sidebar_panel">
    <PacInputBox/>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';


import PacInputBox from './PacInputBox.vue';


import {createNamespacedHelpers} from 'vuex';

const {mapState, mapActions} = createNamespacedHelpers('pac');

export default Vue.extend({
  components: {
    PacInputBox,
  },
  data() {
    return {}
  },

})
</script>

<style scoped>
.e-sidebar_panelTitle {
  padding: 0 1rem;
}
</style>