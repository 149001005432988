<template>
    <div>
        <div class="pow-title">Capacitance from Power</div>

        <!--Inputs-->
        <h3 class="pow-title">Inputs</h3>
        <div class="e-panel:3 l-box:inset2"
             style="width: 100%">
            <!--Required-->
            <div>
                <h4>Required</h4>
                <div style="margin: 10px "></div>
                <div class="l-inline" style="--loc-inline-spacing: 0.7rem;">
                    <BaseInputNumber v-for="input in inputs.required" :key="input.title"
                                     :title="input.title"
                                     :width="input.width+4"
                                     :min="input.min"
                                     :max="input.max"
                                     :step="input.step"
                                     :disabled="input.disabled"
                                     :value="input.select"
                                     @change="(value) => input.select = value" />
                </div>
                <img :src='require("../../img/cap_from_power-calculation.png")' />
            </div>
            <div style="margin: 20px"></div>
            <br />
            <!--Optional-->
            <div>
                <h4>Optional: Individual Chip Properties</h4>
                <div style="margin: 10px "></div>
                <div class="l-inline" style="--loc-inline-spacing: 0.7rem;">
                    <BaseInputNumber v-for="input in inputs.optional" :key="input.title"
                                     :title="input.title"
                                     :width="input.width+3"
                                     :min="input.min"
                                     :max="input.max"
                                     :step="input.step"
                                     :disabled="input.disabled"
                                     :value="input.select"
                                     @change="(value) => input.select = value" />
                </div>
                <img :src='require("../../img/cap_from_power-chip_dimension.png")' />
            </div>
        </div>

        <!--Outputs-->
        <h3 class="pow-title">Outputs</h3>
        <div class="e-panel:3 l-box:inset2">
            <div v-for="(output, index) in outputs" :key="index">
                <label class="e-label">{{ output.title }}:</label>
                <div class="pow-value">{{ output.value | doublePrecision }} <span v-html="output.unit"></span></div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    // State
    import { createNamespacedHelpers } from 'vuex';
    const { mapState, mapActions } = createNamespacedHelpers('capFromPower');

    import Vue from 'vue';
    export default Vue.extend({
        filters: {
            doublePrecision: function (value: string) {
                return parseFloat(value).toFixed(2);
            }
        },
        computed: {
            ...mapState([
                'inputs',
                'outputs'
            ])
        },
        methods: {
            ...mapActions([
                'getOutputs'
            ])
        },
        watch: {
            inputs: {
                handler: function (inputs) {
                    this.getOutputs()
                },
                deep: true,
                immediate: true
            }
        }
    });
</script>

<style scoped>
    img {
        width: 33%;
        display: block;
    }

    .pow-title {
        font-size: 1.15rem;
        line-height: 3rem;
        margin-left: 0.5rem;
    }

    .pow-title-inner {
        font-size: 1.15rem;
        line-height: 3rem;
        margin-left: 0.5rem;
    }

    .pow-outer {
        z-index: 10;
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        width: calc(100vw - 24.5rem);
        min-height: 500px;
    }

    .is-closed .pow-outer {
        width: calc(100vw - 24.5rem + 360px);
    }
</style>