import Vue from "vue";
export const filterMixin = Vue.extend({
    filters: {
        // https://gist.github.com/ajmas/4193ac6911f5445ced37
        showUnit(value: string) {
            // Assumes positive value
            let val = parseFloat(value);


            const unitExponentsNumber = [-12, -9, -6, -3, 0, 3, 6];
            const unitExponentsString = ["p", "n", "u", "m", "", "k", "M"];

            let idx = 0;
            for (let i = unitExponentsNumber.length - 1; i >= 0; i--) {
                if (val >= Math.pow(10, unitExponentsNumber[i])) {
                    idx = i;
                    break;
                }
            }
            val = (val / Math.pow(10, unitExponentsNumber[idx]));

            const decimal = val - Math.floor(val);
            if (decimal !== 0) {
                val = +val.toFixed(2);
            }
            return val + " " + unitExponentsString[idx] + "F";
        },

    }
});