<template>
    <div class="e-accordion" :class="accordionOpen ? '' : 'e-accordion-is-closed'">
        <div class="e-accordion_header" @click="toggleAccordion">
            <span class="e-accordion_title">{{title}}</span>
            <svg class="e-accordion_icon">
                <use xlink:href="#arrow-up"></use>
            </svg>
        </div>
        <div class="e-accordion_body">
            <slot></slot>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue'
    export default Vue.extend({
        name: 'accordion',
        props: {
            title: {
                type: String,
                required: false
            },
            open: {
                type: Boolean,
                required: false,
                default: true
            }
        },
        data() {
            return {
                accordionOpen: this.open
            }
        },
        methods: {
            toggleAccordion: function () {
                this.accordionOpen = !this.accordionOpen;
            }
        }
    })
</script>

<style lang="postcss">
    .e-accordion {
    }

        .e-accordion + .e-accordion::before {
            content: '';
            position: relative;
            display: block;
            height: 1px;
            margin-top: 0.75rem;
            background-color: var(--color-gray-lightest);
        }

    .e-accordion_header {
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: 0.5em;
        padding: 0 1rem;
        cursor: pointer;
        &:hover

    {
        color: var(--color-main-lighter);
    }

    }

    .e-accordion_title {
        font-weight: 500;
        font-size: 1.25rem;
    }

    .e-accordion_icon {
        width: 1.5rem;
        height: 1.5rem;
        margin-left: auto;
        transform: rotate(0);
        transition: transform 0.25s linear;
        will-change: transform;
    }

    .e-accordion-is-closed .e-accordion_icon {
        transform: rotate(180deg);
    }

    .e-accordion_body {
        /* max-height: 500px; */
        padding: 0 1rem;
        overflow-y: hidden;
        transition: max-height 0.25s ease-out, opacity 0.25s 0.1s ease-in;
        opacity: 1;
    }

    .e-accordion-is-closed .e-accordion_body {
        opacity: 0;
        max-height: 0;
    }
</style>