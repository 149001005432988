<template>
    <canvas id="base-chart-id" ref="baseChart"></canvas>
</template>

<script>
    import Vue from "vue";
    import Chart from "chart.js";
    import crosshair from "@/util/chartjs-plugin-crosshair";
    import { cloneDeep } from 'lodash'

    export default {
        inheritAttrs: false,
        props: {
            data: {
                type: Object,
                required: true,
            },
            options: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                _chart: null,
                isMounted: false,
            };
        },
        created() { },
        mounted() {
            var ctx = document.getElementById("base-chart-id").getContext("2d");
            this._chart = new Chart(ctx, {
                type: "scatter",
                data: this.data,
                options: this.options,
                plugins: [crosshair],
            });
        },
        methods: {
            /*    clickResetZoom() {
              var x = document.getElementsByClassName("reset-zoom");
              if (x.length > 0) {
                const resetButton = x[0];
                resetButton.click();
              }
            }, */
        },
        watch: {
            data: {
                deep: false,
                immediate: false,
                handler: function () {
                    /*         $("#base-chart-id").remove();
                    $("#base-chart-container").append(
                      '<canvas id="base-chart-id" ref="baseChart"><canvas>'
                    ); */

                    // document.getElementById("base-chart-container").innerHTML = "&nbsp;";
                    /*         document.getElementById("base-chart-container").innerHTML =
                      '<canvas id="base-chart-id" ref="baseChart"><canvas>'; */

                    /*         var element = document.getElementById("base-chart-id");
                    debugger;
                    element.parentNode.removeChild(element);

                    document
                      .getElementById("base-chart-container")
                      .append('<canvas id="base-chart-id" ref="baseChart"></canvas>'); */
                    // this.clickResetZoom();

                    if (this._chart) this._chart.destroy();
                    var ctx = document.getElementById("base-chart-id").getContext("2d");
                    //let opts = this._chart.options;
                    this._chart = new Chart(ctx, {
                        type: "scatter",
                        data: this.data,
                        options: this.options,
                        plugins: [crosshair],
                    });
                },
            },
            options: {
                deep: false,
                immediate: false,
                handler: function () {

                    if (this._chart) this._chart.destroy();
                    var ctx = document.getElementById("base-chart-id").getContext("2d");
                    //let opts = this._chart.options;
                    this._chart = new Chart(ctx, {
                        type: "scatter",
                        data: this.data,
                        options: this.options,
                        plugins: [crosshair],
                    });

                },
            },
        },
    };
</script>

<style></style>
