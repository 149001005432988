<template>
    <div>
        <div>
            <h1>Admin Page</h1>
            <div>
                <div>
                    <label>Enter a new home page message:</label>
                    <textarea autofocus rows="10" cols="78" maxlength="1000" v-model="homeString" placeholder="Type something!" />
                </div>
                <form @submit.prevent="submitHomeString">
                    <button type="submit">Submit</button>
                </form>
            </div>
        </div>
        <br />
        <div>
            <label for="footer-img">Footer Image</label>
            <div v-if="!footerImage">
                <input type="file" id="footer-img" name="f-img" accept="image/*" @change="onFooterFileChange">
                <br />
                <button @click="clearFooterImage" type="submit">Clear Footer Image</button>
            </div>
            <div v-else>
                <img :src="footerImage" />
                <button @click="removeFooterImage">Remove Footer Image</button>
                <button @click="submitFooterImage" type="submit">Submit</button>
            </div>
        </div>
        <br />
        <div>
            <div>
                <label for="footer-link">Footer Link</label>
                <input type="text" id="footer-link" name="footer-link" v-model="footerLink"/>
                <button @click="submitFooterLink" type="submit">Submit</button>
            </div>
        </div>
        <br />
        <div>
            <label for="banner-img">Banner Image</label>
            <div v-if="!bannerImage">
                <input type="file" id="banner-img" name="banner-img" accept="image/*" @change="onBannerFileChange">
                <br />
                <button @click="clearBannerImage" type="submit">Clear Banner Image</button>
            </div>
            <div v-else>
                <img :src="bannerImage" />
                <button @click="removeBannerImage">Remove Banner Image</button>
                <button @click="submitBannerImage" type="submit">Submit</button>
            </div>
        </div>
        <br />
        <div>
            <div>
                <label for="banner-link">Banner Link</label>
                <input type="text" id="banner-link" name="banner-link" v-model="bannerLink"/>
                <button @click="submitBannerLink" type="submit">Submit</button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import axios from "axios";
    import Vue from 'vue';
    export default Vue.extend({
        data() {
            return {
                homeString: "",
                footerImage: "",
                footerLink: "",
                bannerImage: "",
                bannerLink: "",
                dummy: "",
            }
        },
        methods: {
            submitHomeString() {
                axios.post("/auth/homeString/", { homeString: this.homeString }).then((response) => {
                    alert(response.data);
                });
            },
            submitFooterImage() {
                axios.post("/auth/footerImageUpload/", { footerImage: this.footerImage }).then((response) => {
                    alert(response.data);
                });
            },
            submitFooterLink() {
                axios.post("/auth/footerLinkUpload/", { footerLink: this.footerLink }).then((response) => {
                    alert(response.data);
                });
            },
            clearFooterImage() {
                axios.post("/auth/footerImageUpload/", { footerImage: "" }).then((response) => {
                    alert(response.data);
                });
            },
            submitBannerImage() {
                axios.post("/auth/bannerImageUpload/", { bannerImage: this.bannerImage }).then((response) => {
                    alert(response.data);
                });
            },
            submitBannerLink() {
                axios.post("/auth/bannerLinkUpload/", { bannerLink: this.bannerLink }).then((response) => {
                    alert(response.data);
                });
            },
            clearBannerImage() {
                axios.post("/auth/bannerImageUpload/", { bannerImage: "" }).then((response) => {
                    alert(response.data);
                });
            },
            removeFooterImage: function (e) {
                this.footerImage = '';
            },
            removeBannerImage: function (e) {
                this.bannerImage = '';
            },
            onFooterFileChange(e) {
                var files = e.target.files || e.dataTransfer.files;
                if (!files.length)
                    return;
                this.createFooterImage(files[0]);
            },
            createFooterImage(file) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.footerImage = e!.target!.result!.toString();
                };
                reader.readAsDataURL(file);
            },
            onBannerFileChange(e) {
                var files = e.target.files || e.dataTransfer.files;
                if (!files.length)
                    return;
                this.createBannerImage(files[0]);
            },
            createBannerImage(file) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.bannerImage = e!.target!.result!.toString();
                };
                reader.readAsDataURL(file);
            },
        }
    })
</script>

<style lang="postcss">
    img {
        width: 33%;
        /*margin: auto;*/
        display: block;
        /*margin-bottom: 10px;*/
    }

    /*.hm-empty {
        width: 100%;
        height: 100%;
    }

    .hm-empty_svg {
        width: 20vw;
        min-width: 200px;
        height: 20vw;
        min-height: 200px;
    }*/
</style>