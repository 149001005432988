<template>
    <div class="e-sidebar_panel">
        <h3 class="e-sidebar_panelTitle">Part Selection</h3>
        <BaseAccordion :title="'Chip'">
            <BaseDropdown title="Series"
                          v-model="seriesSelect"
                          :options="seriesOptions"
                          @change="(value) => setSeriesSelect(value)"/>
            <BaseDropdown title="Size"
                          v-model="sizeSelect"
                          :options="sizeOptions"
                          @change="(value) => setSizeSelect(value)" />
        </BaseAccordion>

        <!--Maybe we have a basic and advanced table? or, there are three
            types of tables. One for the part select, and then a basic and
            advanced in the Simulation.-->
        <BaseAccordion :title="'Available Parts'" class="e-parts">
            <InductorResults :items="inductors" />
        </BaseAccordion>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue';

    import InductorResults from './InductorResults.vue';

    import { createNamespacedHelpers } from 'vuex';
    const { mapState, mapActions } = createNamespacedHelpers('inductor');

    export default Vue.extend({
        components: {
            InductorResults,
        },
        data() {
            return {
            }
        },
        computed: {
            ...mapState([
                'seriesSelect',
                'seriesOptions',
                'sizeSelect',
                'sizeOptions',
                'inductors'
            ]),
        },
        methods: {
            ...mapActions([
                'setSeriesSelect',
                'setSizeSelect',
                'getSeriesOptions',
                'getSizeOptions',
                'getInductors'
            ]),
        },
        created() {
            this.getSeriesOptions();
        },
        watch: {
            seriesSelect: {
                handler: function (watch_variable) {
                    if (watch_variable != "") {
                        this.getSizeOptions();
                        this.getInductors();
                    }
                },
                immediate: true,
                deep: false
            },
            sizeSelect: {
                handler: function (watch_variable) {
                    if (watch_variable != "") {
                        this.getInductors();
                    }
                },
                immediate: false,
                deep: false
            },
        },
    })
</script>

<style>
    .e-sidebar_panelTitle {
        padding: 0 1rem;
    }
</style>