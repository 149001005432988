import { cloneDeep } from "lodash";

import chartDefaults from "@/util/ChartDefaults";

import Vue from "vue";
export const chartMixin = Vue.extend({
    data() {
        return {
            defaultChartStyle: chartDefaults.style,
            defaultPlotData: chartDefaults.data as Chart.ChartData,
            defaultPlotOptions: ((chartDefaults.options) as any) as Chart.ChartOptions,

            chartStyle: {},
            plotData: {} as Chart.ChartData,
            plotOptions: {} as Chart.ChartOptions,

            tickRotation: 35,
        }
    },
    created() {
        this.resetPlot();
    },
    computed: {
        plot(): any {
            return {
                data: this.plotData as Chart.ChartData,
                options: this.plotOptions as Chart.ChartOptions
            }
        }
    },
    methods: {
        // bandaid: zoom is reset when chart is updated, but button remains
        clickResetZoom(): void {
            var x: HTMLCollection = document.getElementsByClassName("reset-zoom");
            if (x.length > 0) {
                const resetButton = x[0] as HTMLButtonElement;
                resetButton.click();
            }
        },
        // Used on creation & if no parts
        resetPlot(): void {
            this.plotData = cloneDeep(this.defaultPlotData);
            this.plotOptions = cloneDeep(this.defaultPlotOptions);
            this.chartStyle = cloneDeep(this.defaultChartStyle);
        },
        // Properties common to all plot types
        initializePlot(): void {
            this.clickResetZoom();
            this.chartStyle = cloneDeep(this.defaultChartStyle);

            this.plotOptions.legend.display = true;
            this.plotOptions.legend.labels.usePointStyle = true;
            this.plotOptions.title.display = true;
            this.plotOptions.scales.xAxes[0].display = true;
            this.plotOptions.scales.yAxes[0].display = true;
            this.plotOptions.tooltips.enabled = false;
            this.plotOptions.tooltips.position = "fixedTopRight";

            this.plotOptions.plugins = {
                crosshair: {
                    line: {
                        color: "#F66", // crosshair line color
                        width: 1.3     // crosshair line width
                    },
                    sync: {
                        enabled: false,         // enable trace line syncing with other charts
                        group: 1,               // chart group
                        suppressTooltips: false // suppress tooltips when showing a synced tracer
                    },
                    zoom: {
                        enabled: true,                                   // enable zooming
                        zoomBackgroundColor: "rgba(66,133,244,0.2)",     // background color of zoom box
                        zoomBorderColor: "#48F",                         // border color of zoom box
                        zoomButtonText: "Reset Zoom",                    // reset zoom button text
                        zoomButtonClass: "reset-zoom e-button",          // reset zoom button class
                    },
                    callbacks: {
                        beforeZoom(start: any, end: any) {
                            return true;
                        },
                        afterZoom(start: any, end: any) {
                        }
                    }
                }
            };
        },
    }
});