import Home from "./components/Home.vue"
import ReleaseInfo from "./components/general/ReleaseInfo.vue"
import Share from "./components/Share.vue"
import Login from "./components/Login.vue"
import Admin from "./components/Admin.vue"
import Seed from "./components/Seed.vue"
import CapacitorSimulation from "./components/capacitor/simulation/CalcZ.vue"
import CeramicAging from "./components/aging/CeramicAging.vue"
import FilmLifetimeV2 from "./components/filmLifetimeV2/FilmLifetime.vue"
import CapacitanceFromPower from "./components/capFromPower/Power.vue"
import InductorSimulation from "./components/inductor/simulation/CalcL.vue"
import PowerArrayCalculator from "@/components/powerArrayCalculator/PowerArrayCalculator.vue";
import {instance} from "@/util/Alerts";

export const routes = [
    {
        name: "Admin",
        path: "/admin",
        meta: {
            auth: "ROLE_ADMIN"
        },
        component: Admin
    },
    {
        name: "Home",
        path: "/",
        component: Home
    },
    {
        name: "Release Info",
        path: "/release-info",
        component: ReleaseInfo
    },
    {
        name: "Capacitor Simulation",
        path: "/capacitor-simulation",
        component: CapacitorSimulation,
        description: "CalcZ"
    },
    {
        name: "Capacitance from Power",
        path: "/capacitance-from-power",
        component: CapacitanceFromPower,
        description: "Determine required capacitance based on power requirements."
    },
    {
        name: "Ceramic Capacitor Aging",
        path: "/ceramic-aging",
        component: CeramicAging
    },
    {
        name: "Power Array Calculator",
        path: "/power-array-calculator",
        component: PowerArrayCalculator
    },
    {
        name: "KLEM 3.0",
        path: "/film-lifetime",
        component: FilmLifetimeV2,
        description: "KLEM 3.0"
    },
    {
        name: "Help",
        path: "/help",
        component: Home
    },
    {
        name: "Inductor Simulation",
        path: "/inductor-simulation",
        component: InductorSimulation,
        description: "CalcL"
    },
    {
        name: "Login",
        path: "/login",
        component: Login
    },
    {
        name: "Seed",
        path: "/seed",
        //meta: {
        //    auth: 'ROLE_ADMIN'
        //},
        component: Seed,
        beforeEnter : async (to, from, next) =>{
            await instance.get("/ad/name")
                .then(response => {
                    if (response.data === "") {
                        window.location.href = `${window.location.origin}/`;
                    } else {
                        next();
                    }
                })
                .catch((error) => {
                    //// Assumption: error is always unauthorized
                    console.log('unauthorized')
                    console.log(error);
                    //// Hack because redirection is failing when AuthorizeSeed returns false
                    window.location.href = `${window.location.origin}/`;
                });
        }
    },
    {
        name: "Share",
        path: "/share/:id",
        component: Share
    },
    // Catch-all for invalid routes
    {
        name:"Sim",
        path: "/",
        redirect: "/",
    }
];