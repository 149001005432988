<template>
    <div>
        <BaseAccordion :title="'Film'">
            <BaseDropdown title="Series"
                          v-model="seriesSelect"
                          :options="seriesOptions"
                          @change="setSeriesSelect" />
            <BaseDropdown title="Lead Spacing (mm)"
                          v-model="pitchSelect"
                          :options="pitchOptions"
                          @change="setPitchSelect" />

            <label class="e-label">{{voltageType.title}}</label>
            <div v-for="(option, index) in voltageType.options" class="e-radio_inline">
                <input :key="index"
                       :disabled="voltageType.disabled"
                       class="e-radio"
                       type="radio"
                       :id="option"
                       :value="option"
                       v-model="voltageType.select" />
                <label class="e-label" :for="option">{{option}}</label>
            </div>
            <BaseDropdown :title="voltageType.select === 'Vac' ? 'Vac (V)' : 'VDC'"
                          v-model="voltageSelect"
                          :options="voltageOptions"
                          @change="setVoltageSelect" />
        </BaseAccordion>
        <BaseAccordion :title="'Available Parts'">
            <FilmResults :items="capacitorList" />
        </BaseAccordion>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import FilmResults from './FilmResults.vue';

import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('filmLifetimeV2');

export default Vue.extend({
  components: {
    FilmResults
  },
  mounted() {
    this.getSeriesOptions();
  },
  computed: {
    ...mapState([
      'seriesSelect',
      'seriesOptions',
      'pitchSelect',
      'pitchOptions',
      'voltageSelect',
      'voltageOptions',
      'capacitorList',
      'voltageType',
    ])
  },
  methods: {
    ...mapActions([
      'setSeriesSelect',
      'getSeriesOptions',
      'setPitchSelect',
      'getPitchOptions',
      'setVoltageSelect',
      'getVoltageOptions',
      'getCapacitors',
      'setVoltageType',
      'getVoltageTypeOptions',
      'incrementComponentKey'
    ])
  },
  watch: {
    inputs /* TODO: as aType */: {
      handler: function (inputs /* simply specify type here... */) {

      },
      deep: false,
      immediate: false
    },
    seriesSelect: {
      handler: function (watch_var: string) {
        this.getPitchOptions();
        this.getVoltageTypeOptions();
        this.getVoltageOptions();
        this.getCapacitors();
      },
      deep: true,
      immediate: true
    },
    pitchSelect: {
      handler: function (watch_var: string) {
        this.getVoltageTypeOptions();
        this.getVoltageOptions();
        this.getCapacitors();
      },
      deep: true,
      immediate: true
    },
    voltageType: {
      handler: function (watch_var: string) {
        this.getVoltageOptions();
        this.getCapacitors();
      },
      deep: true,
      immediate: true
    },
    voltageSelect: {
      handler: function (watch_var: string) {
        this.getCapacitors();
      },
      deep: true,
      immediate: true
    },
  },
})
</script>

<style>
</style>