import { render, staticRenderFns } from "./CapacitorSelect.vue?vue&type=template&id=20e1692c&scoped=true"
import script from "./CapacitorSelect.vue?vue&type=script&lang=ts"
export * from "./CapacitorSelect.vue?vue&type=script&lang=ts"
import style0 from "./CapacitorSelect.vue?vue&type=style&index=0&id=20e1692c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20e1692c",
  null
  
)

export default component.exports