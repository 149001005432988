<template>
    <div class="ce-value">
        <label class="e-label">{{title}}</label>
        <p>{{value}}</p>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        inheritAttrs: false,
        props: {
            value: {
                type: String,
                required: true
            },
            title: {
                type: String,
                required: false
            },
        },
    })
</script>

<style scoped>
    .ce-value {
        white-space: initial;
    }
</style>