<template>
    <div class="container">
        <!--HEADER-->
        <div class="header">
            <!--LEFT-->
            <RouterLink title="Home" to="/" style="text-decoration: none; color: inherit;">
                <h3 style="min-width:50px">
                    K-SIM
                    <transition name="slide-fade" mode="out-in">
                        <span :key="version">{{version}}</span>
                    </transition>
                </h3>
            </RouterLink>
            <div style="margin-left: 30px; width: 50px; color: #1c4a8e" title="V: 3.0.7.15">.</div>

            <!--CENTER-->
            <div style="margin-left: auto;">
                <a :href="feedbackUrl" target="_blank" rel="noopener noreferrer"
                   style="color: white; text-decoration: none;">
                    <h3>Click Here to Submit Feedback and Bugs</h3>
                </a>
            </div>

            <!--RIGHT-->
            <ShareButton />
        </div>

        <!--MAIN-->
        <div class="content" :class="{'is-closed': sidebarClosed}">
            <!--SIDEBAR-->
            <div>
                <div class="sidebar_toggle"
                     title="Click to toggle the sidebar between open and closed."
                     @click="sidebarClosed = !sidebarClosed">
                    <svg class="e-icon">
                        <use xlink:href="#arrow-left"></use>
                    </svg>
                </div>
                <div class="sidebar">
                    <div class="sidebar_content">
                        <!--LOGO-->
                        <!-- <svg class="logo"><use xlink:href="#kemet-logo" /></svg> -->
                        <div style="margin-top: 0.5rem; margin-bottom: 0.5rem;">
                            <a href="https://www.kemet.com" target="_blank">
                                <img class="logo" :src="require('./img/Yageo_logo.png')" />
                            </a>
                        </div>
                        <!--SIDEBAR CONTENT-->
                        <SideNavigation />
                    </div>
                </div>
            </div>

            <!--ACTIVE "ROUTE" (or, COMPONENT)-->
            <div class="main">
                <!--prevent chart from recreation when switching routes-->
                <KeepAlive>
                    <RouterView />
                </KeepAlive>
                <BaseBanner />
            </div>
        </div>

        <!--FOOTER-->
        <div class="footer" style="display: inline-block">
            <a :href="footerLink" target="_blank">© 2021 KEMET Corporation ·&nbsp;</a>
            <RouterLink title="Release Info" to="/release-info" style="color: white;">Release Info ·&nbsp;</RouterLink>
            <a :href="privacyLink" target="_blank">Privacy ·&nbsp;</a>
            <a :href="termsLink" target="_blank">Terms of Use ·&nbsp;</a>
            <a :href="infoLink" target="_blank">Do Not Sell My Info ·&nbsp;</a>
            <a :href="tcLink" target="_blank">Terms & Conditions ·&nbsp;</a>
            <a :href="cookieLink" target="_blank">Cookies Policy ·&nbsp;</a>
            <a :href="disclaimerLink" target="_blank">Disclaimer</a>
        </div>
        <Icons />
    </div>
</template>

<script lang="ts">
    import { v4 as uuidv4 } from 'uuid';

    import SideNavigation from "@/components/SideNavigation.vue";
    // @ts-ignore
    import Icons from "/src/components/Icons.vue";
    import ShareButton from "@/components/ShareButton.vue";


    import Vue from "vue";
    import VueRouter from "vue-router";
    import { routes } from "@/routes";
    import BaseButton from "@/components/_base/base-button.vue";
    import BaseAccordion from "@/components/_base/base-accordion.vue";
    import BaseDropdown from "@/components/_base/base-dropdown.vue";
    import BaseInputString from "@/components/_base/base-input-string.vue";
    import BaseBanner from "@/components/_base/base-banner.vue";
    import { instance } from "@/util/Alerts";
    import BaseInputNumber from "@/components/_base/base-input-number.vue";
    import BaseChart from "@/components/_base/base-chart.vue";
    import BaseTableCe from "@/components/_base/base-table-ce.vue";
    import BaseTableDownloads from "@/components/_base/base-table-downloads.vue";
    import BaseRadio from "@/components/_base/base-radio.vue";
    import BaseInputSlider from "@/components/_base/base-input-slider.vue";
    Vue.use(VueRouter)
    //register components

    Vue.component('BaseInputNumber', BaseInputNumber);
    Vue.component('BaseChart', BaseChart);
    Vue.component('BaseTableCe', BaseTableCe);
    Vue.component('BaseTableDownloads', BaseTableDownloads);
    Vue.component('BaseRadio', BaseRadio);
    Vue.component('BaseButton', BaseButton);
    Vue.component('BaseAccordion', BaseAccordion);
    Vue.component('BaseDropdown', BaseDropdown);
    Vue.component('BaseInputString', BaseInputString);
    Vue.component('BaseInputSlider', BaseInputSlider);
    Vue.component('BaseBanner', BaseBanner);

    const router = new VueRouter({
        routes,
        mode: "history"
    });

    export default Vue.extend({
        router,
        components: {
            SideNavigation,
            Icons,
            ShareButton
        },
        created() {
            window.addEventListener("resize", () => { this.sidebarClosed = false});
        },
        data() {
            return {
                sidebarClosed: false,
                feedbackUrl: 'https://www.wrike.com/form/eyJhY2NvdW50SWQiOjI1MDc3OTIsInRhc2tGb3JtSWQiOjI5NzY5N30JNDczNTY1Nzc4ODQ3NQk1ZDdmZDVjYTQ2NTIzMmE4OTJjNjJjMjg5NmFjMjhiZWQxYzcwOGFiOGUwMTZhODY4Mzg2NjkxNjBkZTlkMWE4',
                KsimClassicURL: 'http://ksim.kemet.com',
                footerImage: "",
                footerLink: "https://www.kemet.com",
                privacyLink: "https://www.kemet.com/en/us/about/privacy-policy.html",
                termsLink: "https://www.kemet.com/en/us/about/terms-of-use.html",
                infoLink: "https://www.kemet.com/en/us/about/do-not-sell-my-personal-information-opt-out-form.html",
                tcLink: "https://www.kemet.com/en/us/about/terms-and-conditions.html",
                cookieLink: "https://www.kemet.com/en/us/about/cookies-policy.html",
                version: "3",
                releaseInfoLink: "",
                disclaimerLink: "https://www.kemet.com/en/us/about/k-sim-disclaimer.html"
            }
        },
        methods: {
            enableAskBeforeLeaving() {
                window.onbeforeunload = function () {
                    return true;
                };
            },
        },
        mounted() {
            // Footer
            instance.get("/auth/footerImageRequest/").then((response) => { (this as any).footerImage = response.data; });
            instance.get("/auth/footerLinkRequest/").then((response) => { (this as any).footerLink = response.data; });

            // Version number
            instance.get("/api/release/").then((response) => {
                (this as any).version = response.data.releases[0].version;
            });

            // If url parameter "pn": re-direct to Capacitor Simulation
            if (this.$route.query.pn) {
                this.$router.push({
                    path: '/capacitor-simulation',
                    query: this.$route.query
                });
            }

            // Store uuid to identify user sessions
            if (!window.localStorage.getItem('uuid')) {
                window.localStorage.setItem('uuid', uuidv4());
            }
        }
    });
</script>

<style lang="css">
    body {
        margin: 0;
        padding: 0;
    }

    .footer a {
        color: white;
    }

    .container { /*contains header,side,main,footer*/
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }

    .header {
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
        height: 4rem;
        padding-right: 2rem;
        padding-left: 2rem;
        color: #f4f4f4;
        background-color: hsl(222, 39%, 59%);
        background-image: linear-gradient(135deg, #1c4a8e 220px, #205193 220px, #205193 240px, #2564a4 240px, #2564a4 260px, #3081c0 260px);
    }

    .content { /*sidebar & main*/
        position: relative;
        display: block;
        flex: 1 1 auto;
        align-items: stretch;
        height: 100%;
        background-color: #f4f4f4;
    }

    .logo {
        width: 60%;
        max-width: 200px;
    }

    .sidebar {
        position: absolute;
        float: left;
        width: 341px;
        height: 100%;
        min-height: calc(100vh - 96px);
        overflow: hidden auto; /* set overflow to hidden (or auto) to fully collapse sidebar */
        background-color: #f4f4f4;
        background-image: linear-gradient(to right, #f0f0f0, #fff);
        transition: transform 0.25s ease-out; /* transition sidebar when closing/opening */
        will-change: transform;
        border-right: 1px solid #ccc;
        box-shadow: 4px 0 8px rgba(0, 0, 0, 0.2);
    }

    .sidebar_content {
        box-sizing: border-box; /* make sure to set box-sizing:border-box to avoid scrollbars */
        width: 341px; /* set sidebar content width to keep from re-flowing */
        padding: 0.25rem 1rem;
    }

    .sidebar_toggle {
        position: absolute; /* position toggle button absolutely to remove from regular flow of document */
        left: 340px; /* width of sidebar minus the width of the toggle button */
        z-index: 5; /* set z-index of toggle button */
        /* position toggle button icon in center */
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        /* set height and width of toggle button */
        height: 100%;
        background-color: #f1f5f8;
        border-radius: 3px 0 0 3px;
        border: 2px solid #fbc328;
        transition: transform 0.25s ease-out; /* transition toggle button when closing */
        will-change: transform;
        cursor: pointer;
        opacity: 0.6;
    }

    /* arrow icon */
    .e-icon {
        width: 1.25rem;
        height: 1.25rem;
        color: #111111;
    }

    .main {
        margin: 0 auto;
        margin-left: 340px;
        padding: 0 20px 0 20px;
        transition: margin 0.25s ease-out; /* transition main when closing/opening */
        will-change: margin;
    }

    .is-closed .sidebar {
        transform: translateX(-350px);
    }

    .is-closed .main {
        margin-left: 0;
    }

    .is-closed .sidebar_toggle {
        background-color: #fff;
        transform: translateX(-340px); /* translate toggle button to edge of viewport. sidebar width - toggle button width */
    }

    .is-closed .e-icon {
        transform: rotate(180deg); /* rotate arrow icon when sidebar is closed */
    }

    .footer {
        display: flex;
        align-items: center;
        height: 2rem;
        margin-top: auto;
        padding-right: 2rem;
        padding-left: 2rem;
        color: #fff;
        background-color: var(--color-main);
    }

    .ex-icon {
        width: 1em;
        height: 1em;
        color: currentColor;
    }

    .slide-fade-enter-active {
        transition: all .3s ease;
    }

    .slide-fade-leave-active {
        transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active for <2.1.8 */ {
        transform: translateX(10px);
        opacity: 0;
    }
    @media screen and (max-width: 1205px) {
        .sidebar_toggle {
            visibility: hidden;
            display: none;
        }

        .main {
            margin-left: 0px;
            padding: 0px;
        }

        .sidebar_content {
            width: 100%;
            padding: 0;
        }
        [class*='e-panel'] {
            position: sticky;
        }
        .sidebar {
            width: 100%;
            position: relative;
            box-shadow: none;
            min-height: 0;
        }
        .e-sidebar_links {
            padding-left: 1rem;
        }
        .e-sidebar_panel {
            padding-right: 1rem;
        }
        .e-panel\:3 {
            width: 100%;
            overflow:hidden;
        }
        .l-box\:inset2 {
            padding: var(--size1)
        }
        .ex-export_group {
            text-align: center;           
        }
        .ex-export_group h3 {
            display: none;
        }
        .modal-container {
            overflow-y: auto;
            height: 90vh;
        }
        .footer {
            height: 100%
        }
        .klem-profile [class*=l-inline] {
            display: block;
        }
        .klem-profile .e-input {
            width: 100%;
        }
        .hide-on-mobile {
            display: none;       
        }
        .profile-header {
            display: flex;
            justify-content: space-between;                
        }
        .add-remove-profile {
            display:flex;
        }
        .hot-spot-data {
            margin-left:0px;
        }

        .overlay-frequency-section .base-input-number-horizontal {
            display: block;
        }
        .header {
            font-size:12px;
            line-height: 18px;
            padding-left: 1rem;
            padding-right: 0px;
        }
    }
    @media screen and (min-width: 1206px) {
        .display-on-mobile {
            display: none;
        }
    }
</style>