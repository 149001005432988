<template>
    <div class="baseRadio l-field">
        <label class="e-label">{{title}}</label>
        <div class="e-radio_inline">
            <template v-for="(option, index) in options">
                <input :key="index"
                    class="e-radio"
                    type="radio"
                    :id="title + option"
                    :value="option"
                    :disabled="disabled"
                    v-model="local_value"
                    @change="$emit('change', local_value)"
                />
                <label class="e-label" :for="title + option">{{option}}</label>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        inheritAttrs: false,
        props: {
            value: {
                type: String,
                required: true
            },
            options: {
                type: Array,
                required: true
            },
            // Not required
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },
            title: {
                type: String,
                required: false 
            },
        },
        data() {
            return {
                local_value: this.value
            }
        },
    })
</script>

<style scoped>

</style>