// External
import { Scatter } from "vue-chartjs";
import { Component, Prop, Watch } from "vue-property-decorator";
import Vue from "vue";

// Internal
import crosshair from "@/util/chartjs-plugin-crosshair";

export interface IPlot {
    data: Chart.ChartData,
    options: Chart.ChartOptions,
}
interface BasePlot extends Vue {
    renderChart(data: Chart.ChartData, options: Chart.ChartOptions): void;
}

// @ts-ignore
@Component({ extends: Scatter })
class BasePlot extends Vue<Scatter> {
    @Prop({ required: true })
    plot!: IPlot;

    // Data
    isMounted = false;

    created() {
// ReSharper disable once TsResolvedFromInaccessibleModule
        this.addPlugin(crosshair);
    }

    mounted() {
        // don't call renderChart in watcher before mounted
        this.isMounted = true;
        this.renderChart(this.plot.data, this.plot.options);
    }

    @Watch("plot", { deep: false, immediate: false })
    onPlotChanged(value: IPlot, oldValue: IPlot) {
        if (this.isMounted) {
            this.renderChart(this.plot.data, this.plot.options);
        }
    }
}

export default BasePlot;
