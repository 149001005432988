<template>
    <div>
        <div class="ind-title">
            Inductor Simulation
        </div>

        <div class="e-panel:3 l-box:inset2 ind-plot_outer">
            <!--Inputs-->
            <div class="l-spaced:4">
                <BaseDropdown :title="plotType.title"
                              :options="plotType.options"
                              v-model="plotType.select"
                              @change="(value) => plotType.select = value" />
                <div v-if="plotType.select === 'split_power_loss'">
                    <BaseCheckbox :title="lossCurves.title"
                                  :options="lossCurves.options"
                                  v-model="lossCurves.select"
                                  @change="(value) => lossCurves.select = value" />
                </div>
            </div>

            <!--Plot-->
            <div>
                <!-- Line breaks inside div for Firefox? -->
                <div>
                    <br />
                </div>
                <div :style="chartStyle">
                  <base-loading-indicator v-if="pendingRequests"></base-loading-indicator>
                  <BaseChart id="BasePlot"
                             :data="plot.data" :options="plot.options"
                             :style="chartStyle"/>
                </div>
                <div>
                    <br />
                </div>
            </div>

            <!--Parts Table-->
            <InductorTable :key="'inductorTable'+componentKey" />

            <!--File Exports-->
            <Export :plotData="plotData" :plotType="plotType" />

            <!--Note-->
            <div>
                <center>
                    note: Results are for buck topology.
                    <img :src='require("../../../img/buck.png")' />
                </center>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    // External
    import axios, { AxiosResponse } from 'axios';
    import debounce from 'lodash.debounce';
    import Chart from "chart.js";

    // State
    import { createNamespacedHelpers } from 'vuex';
    const { mapState, mapGetters, mapActions } = createNamespacedHelpers('calcL');

    // Components
    import InductorTable from './InductorTable.vue';
    import Export from './Export.vue';

    // Plot helpers
    Chart.Tooltip.positioners.cursor = function (elements, position) { return { x: position.x, y: (position.y >= 0) ? position.y : 0 } };
    import { chartMixin } from "@/mixins/chartMixin";
    import pointStyles from "@/util/PointStyles";
    import inductorPointStyles from "@/util/InductorPointStyles";

    // Local
    import { logSimulationUrl } from "@/util/Urls"
    import ApiLabel, { getApiSpec } from './definitions';
    const NumPartsListIsEmpty = 0;
    import Vue from 'vue';
    import {instance} from "@/util/Alerts";
    import BaseCheckbox from "@/components/_base/base-checkbox.vue";
    export default Vue.extend({
        mixins: [chartMixin], // resetPlot(), initializePlot()
        data() {
            return {
                debouncedSetupChart: debounce((this as any).setupChart, 100) as Function,
                tickRotation: 35,
                partPlots: [],
                cannotDisplayGraph: false,
                pendingRequests: 0
            }
        },
        mounted() {
            this.debouncedSetupChart() // restore state on load
        },
        components: {
            BaseCheckbox,
            InductorTable,
            Export
        },
        computed: {
            // Connect vuex to component
            ...mapState([
                'highlight',
                'parts',
                'partStyleApplied',
                'plotType',
                'lossCurves',
                'componentKey'
            ]),
            ...mapGetters([
                'partsLength',
                'bodyArray',
                'individualParameters'
            ]),
        },
        methods: {
            ...mapActions([
                'setPlotOptions',
            ]),
            // Plot Helpers
            async fetchApiData(i) {
                //TODO logging could happen in backend if we make request to orbweaver from our personal backend
                if (i === 0) { //inductor makes separate requests to Orbweaver API, only log once
                    instance.post(logSimulationUrl, {
                        simulation: "inductor",
                        request: JSON.stringify(this.bodyArray),
                        valid: true,
                        session: window.localStorage.getItem('uuid')
                    });
                }

                const response = await instance.post("/"+this.plotType.select, this.bodyArray[i]);
                return response;
            },
            addDatasetPoints(data, dataset, yValue) {
                /* Add points to individual dataset */
                dataset.data = [];
                for (let j = 0; j < data.values.length; j++) {
                    let x = data.values[j]["x_value"];
                    let y = data.values[j][yValue];
                    let pt = { x, y };
                    dataset.data.push(pt);
                }
                return dataset.data;
            },
            setChartStyles() {
                this.clickResetZoom();

                for (let i = 0; i < this.partPlots.length; i++) {
                    let pnIndex = i;
                    const styleIndex = this.partStyleApplied[pnIndex];
                    const data = this.partPlots[i];

                    let xAxisLabel = data.x_axis_label.toString();
                    let yAxisLabel = data.y_axis_label.toString();
                    let yAxisUnit = yAxisLabel.slice(-2, -1); //neg. idx treated as (len-idx)
                    let xAxisUnit = xAxisLabel.slice(-2, -1); //neg. idx treated as (len-idx)
                    if (yAxisUnit === "C") yAxisUnit = "℃";

                    //////////////////
                    //createDatasets()
                    //////////////////

                    let pnString = this.parts.length > 0 ? this.parts[pnIndex].pn : "";

                    // Highlight
                    let highlightWidth = 1;
                    let transparencyIndex = 1;
                    if (this.highlight === pnIndex) {
                        highlightWidth = 3;
                        transparencyIndex = 0;
                    }

                    /* The Inductor API returns data differently, and as such, the
                     * points overlap more and we don't need as big of a radius.
                     * In the future, maybe define different styles for inductor.
                     */
                    let radiusOffset = 1;

                    if (this.plotType.select === "split_power_loss") {
                        for (const curve of this.lossCurves.select) {
                            let n = parseInt(curve);
                            let label = getApiSpec(n).label;

                            let dataset = {
                                pointStyle: getApiSpec(n).shape,
                                pointRadius: getApiSpec(n).pointRadius,
                                pointBorderWidth: getApiSpec(n).pointWidth,
                                backgroundColor: inductorPointStyles[styleIndex].color[transparencyIndex],
                                borderColor: inductorPointStyles[styleIndex].color[transparencyIndex],
                                rotation: inductorPointStyles[styleIndex].rotation,
                                borderWidth: highlightWidth,
                                data: [] as any[],
                                fill: false,
                                interpolate: true,
                                label: `${pnString} - ${label}`,
                                showLine: true,
                            };

                            dataset.data = [];
                            /* Add points to individual dataset */
                            let yValue = getApiSpec(n).value;
                            dataset.data = this.addDatasetPoints(data, dataset.data, yValue);
                            this.plotData.datasets.push(dataset);
                        }
                    } else {
                        let dataset = {
                            pointStyle: styleIndex != undefined ? pointStyles[styleIndex].point : "",
                            pointRadius: styleIndex != undefined ? pointStyles[styleIndex].radius - radiusOffset : "",
                            pointBorderWidth: styleIndex != undefined ? pointStyles[styleIndex].width : "",
                            backgroundColor: styleIndex != undefined ? pointStyles[styleIndex].color[0] : "",
                            borderColor: styleIndex != undefined ? pointStyles[styleIndex].color[0] : "",
                            rotation: styleIndex != undefined ? pointStyles[styleIndex].rotation : "",
                            borderWidth: highlightWidth,
                            data: [] as any[],
                            fill: false,
                            interpolate: true,
                            label: pnString,
                            showLine: true,
                        };
                        dataset.data = [];

                        /* Add points to individual dataset */
                        let yValue = "y_value";
                        dataset.data = this.addDatasetPoints(data, dataset.data, yValue);
                        this.plotData.datasets.push(dataset);
                    }

                    // I'm sure there is a better way to copy an object?
                    let currentOptions = JSON.parse(JSON.stringify(this.plotOptions));

                    // Title
                    let newTitle = "";
                    for (let k = 0; k < this.plotType.options.length; k++) {
                        if (this.plotType.select === this.plotType.options[k].value) {
                            newTitle = this.plotType.options[k].label;
                        }
                    }
                    currentOptions.title.text = newTitle;

                    // Legend
                    currentOptions.legend.display = true;

                    // X-Axis
                    currentOptions.scales.xAxes[0].scaleLabel.labelString = xAxisLabel;
                    currentOptions.scales.xAxes[0].type = "linear";
                    currentOptions.scales.xAxes[0].ticks = {
                        autoSkip: false,
                        // This callback preserves the autoSkip function of a logarithmic axis
                        callback: (value: number, index: number, arr: Array<number>) => {
                            // order of magnitude function:
                            // https://gist.github.com/ajmas/4193ac6911f5445ced37
                            var unitExponentsNumber = [0, 3, 6, 9];
                            var unitExponentsString = ["", "k", "M", "G"];

                            var sign = (value < 0 ? -1 : 1);
                            value = Math.abs(value);
                            var idx = 0;
                            for (let i = unitExponentsNumber.length - 1; i >= 0; i--) {
                                if (value >= Math.pow(10, unitExponentsNumber[i])) {
                                    idx = i;
                                    break;
                                }
                            }
                            value = (value / Math.pow(10, unitExponentsNumber[idx]));
                            value = value * sign;

                            //When zoomed, only show 2 decimal places for axis ticks at
                            //left and right edge of graph
                            var decimal = value - Math.floor(value);
                            if (decimal !== 0) {
                                value = +value.toFixed(2);
                            }

                            return value + " " + unitExponentsString[idx] + xAxisUnit;
                        },
                        minRotation: this.tickRotation,
                        maxRotation: this.tickRotation
                    };

                    // Y-Axis
                    currentOptions.scales.yAxes[0].scaleLabel.labelString = yAxisLabel;
                    currentOptions.scales.yAxes[0].type = "linear";
                    currentOptions.scales.yAxes[0].ticks = {
                        autoSkip: false, minRotation: this.tickRotation, maxRotation: this.tickRotation,
                        // This callback preserves the autoSkip function of a logarithmic axis
                        callback: (value: number, index: number, arr: Array<number>) => {
                            var unitExponentsNumber = [-9, -6, -3, 0, 3, 6, 9];
                            var unitExponentsString = ["n", "u", "m", "", "k", "M", "G"];

                            var sign = (value < 0 ? -1 : 1);
                            value = Math.abs(value);
                            var idx = 0;
                            for (let i = unitExponentsNumber.length - 1; i >= 0; i--) {
                                if (value >= Math.pow(10, unitExponentsNumber[i])) {
                                    idx = i;
                                    break;
                                }
                            }
                            value = (value / Math.pow(10, unitExponentsNumber[idx]));
                            value = value * sign;

                            //When zoomed, only show 2 decimal places for axis ticks at
                            //left and right edge of graph
                            var decimal = value - Math.floor(value);
                            if (decimal !== 0) {
                                value = +value.toFixed(2);
                            }
                            return value + " " + unitExponentsString[idx] + yAxisUnit;
                        },
                    };

                    this.chartStyle = {
                        height: `60vh`, // sets max height, responds to window size
                        minHeight: '690px', // keeps the graph from getting too small
                        position: 'relative', // seems unnecessary?
                        marginBottom: '2rem',
                    };

                    currentOptions.tooltips = {
                        intersect: false,    //show the tooltip even if not directly on a point
                        mode: 'interpolate', //must be interpolate for crosshair plugin
                        position: 'cursor',
                        callbacks: {
                            // ReSharper disable once UnusedParameter
                            // Keep unused parameter for now, in case needed later.
                            title(tooltipItem: Array<Chart.ChartTooltipItem>, data: Chart.ChartData) {
                                let title = "Current: ";

                                let value = tooltipItem[0].xLabel!;
                                const unitExponentsNumber = [-9, -6, -3, 0, 3, 6, 9];
                                const unitExponentsString = ["n", "u", "m", "", "k", "M", "G"];

                                value = +value;

                                let idx = 0;
                                for (let i = unitExponentsNumber.length - 1; i >= 0; i--) {
                                    if (value >= Math.pow(10, unitExponentsNumber[i])) {
                                        idx = i;
                                        break;
                                    }
                                }
                                value = (value / Math.pow(10, unitExponentsNumber[idx]));
                                value = value.toFixed(1);

                                title += value + " " + unitExponentsString[idx] + "A";
                                return title;
                            },
                            // ReSharper disable once UnusedParameter
                            // Keep unused parameter for now.
                            label(tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData) {
                                // @ts-ignore
                                //if (isNaN(data.datasets![tooltipItem.datasetIndex!].interpolatedValue)) { // don't show NaNs in tooltip
                                //    return "";
                                //}

                                let label = data.datasets![tooltipItem.datasetIndex!].label || "";

                                let value = tooltipItem.yLabel!;
                                const unitExponentsNumber = [-12, -9, -6, -3, 0, 3, 6, 9];
                                const unitExponentsString = ["p", "n", "u", "m", "", "k", "M", "G"];

                                value = +value;

                                let idx = 0;
                                for (let i = unitExponentsNumber.length - 1; i >= 0; i--) {
                                    if (value >= Math.pow(10, unitExponentsNumber[i])) {
                                        idx = i;
                                        break;
                                    }
                                }
                                value = (value / Math.pow(10, unitExponentsNumber[idx]));
                                label += ": " + value.toFixed(2);
                                label += " " + unitExponentsString[idx] + yAxisUnit;
                                return label;
                            }
                        },
                    };

                    currentOptions.plugins = {
                        crosshair: {
                            line: {
                                color: '#F66',  // crosshair line color
                                width: 1        // crosshair line width
                            },
                            sync: {
                                enabled: true,            // enable trace line syncing with other charts
                                group: 1,                 // chart group
                                suppressTooltips: false   // suppress tooltips when showing a synced tracer
                            },
                            zoom: {
                                enabled: true,                                      // enable zooming
                                zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',     // background color of zoom box
                                zoomboxBorderColor: '#48F',                         // border color of zoom box
                                zoomButtonText: 'Reset Zoom',                       // reset zoom button text
                                zoomButtonClass: 'reset-zoom e-button',                      // reset zoom button class
                            },
                            callbacks: {
                                beforeZoom: function (start: any, end: any) {                  // called before zoom, return false to prevent zoom
                                    return true;
                                },
                                afterZoom: function (start: any, end: any) {                   // called after zoom
                                }
                            }
                        }
                    };
                    this.plotOptions = currentOptions;
                }
            },
            // Plot update
            async setupChart(): Promise<void> {
                // When no parts, show KSIIIM logo
                if (this.parts.length === NumPartsListIsEmpty) {
                    this.resetPlot(); // mixin
                    return;
                }

                // Make request for each part
                this.pendingRequests = true;
                let requests = [] as any[];
                for (let i = 0; i < this.bodyArray.length; i++) requests.push(this.fetchApiData(i));

                // Save responses
                const responses = await Promise.all(requests);
                this.partPlots = [];
                for (const response of responses) this.partPlots.push(response.data);
                this.pendingRequests = false;

                // Refresh plot
                this.initializePlot(); // mixin
                this.plotData.datasets = [] as any[];
                this.setChartStyles(); // uses partPlots
            }
        },
        watch: {
            // All these watchers for updating plot
            "parts.length": { handler: function () { this.debouncedSetupChart(); }, },
            "plotType.select": { handler: function () { this.debouncedSetupChart(); }, },
            individualParameters: { handler: function () { this.debouncedSetupChart(); }, },
            "lossCurves.select": {
                deep: true, immediate: true,
                handler: function () {
                    this.plotData.datasets = [] as any[];
                    this.setChartStyles();
                },
            },
            highlight: {
                handler: function () {
                    this.plotData.datasets = [] as any[];
                    this.setChartStyles();
                },
            },
        },
    })
</script>

<style scoped>
    img {
        width: 33%;
        display: block;
    }

    .ind-title {
        font-size: 1.15rem;
        line-height: 3rem;
        margin-left: 0.5rem;
    }

    .ind-plot_outer {
        z-index: 10;
        display: flex;
        flex-direction: column;
        align-self: flex-start;
        width: calc(100vw - 24.5rem);
        min-height: 500px;
    }

    .is-closed .ind-plot_outer {
        width: calc(100vw - 24.5rem + 360px);
    }

    @media screen and (max-width: 1205px) {
        .ind-plot_outer {
            width: 100%;
        }
        .is-closed .ind-plot_outer {
            width: 100%;
        }
    }
</style>
