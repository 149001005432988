import Vue from 'vue'
import App from './App.vue'
import store from '@/store';
import "@/Content/main.css";
import vueLoading from 'vue-loading-template'
import VueSimpleAlert from "vue-simple-alert";

Vue.use(VueSimpleAlert);
Vue.use(vueLoading);

new Vue({
    store,
    render: h => h(App)
}).$mount('#app')
