var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"e-sidebar_links"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:((this.$route.name !== '') && _vm.validRoute),expression:"(this.$route.name !== '') && validRoute"}]},[_vm._l((_vm.routes),function(route,index){return _c('div',{key:'routerLink'+index,staticClass:"e-sidebar_link"},[((route.name !== 'Share') &&
                          (route.name !== 'Sim') &&
                          (route.name !== 'Power Array Calculator') &&
                          (route.name !== 'Admin') &&
                          (route.name !== 'Seed') &&
                          (route.name !== 'Release Info') &&
                          (route.name !== 'Login') &&
                          (route.name !== 'Help') &&
                          (route.name !== 'KLEM 3.0') &&
                          (route.name !== 'Inductor Simulation'))?_c('RouterLink',{attrs:{"title":route.description,"to":route.path}},[_vm._v(_vm._s(route.name))]):_vm._e()],1)}),_c('div',[_vm._v("Film Lifetime")]),_c('RouterLink',{staticClass:"sub-category-link",attrs:{"title":"KLEM 3.0","to":"/film-lifetime"}},[_vm._v(" KLEM 3.0 ")]),_c('a',{staticClass:"sub-category-link",attrs:{"href":"#","title":"KLEM 3.0 Tutorial"},on:{"click":_vm.readFile}},[_c('strong',[_vm._v("Tutorial")]),_vm._v(" KLEM ")]),_c('RouterLink',{attrs:{"title":"Inductor Simulation","to":"/inductor-simulation"}},[_vm._v(" Inductor Simulation ")])],2)]),((this.$route.name === 'Capacitor Simulation') || (this.$route.name === 'Seed'))?_c('CapacitorSelect',{directives:[{name:"show",rawName:"v-show",value:((this.$route.name === 'Capacitor Simulation') || (this.$route.name === 'Seed')),expression:"(this.$route.name === 'Capacitor Simulation') || (this.$route.name === 'Seed')"}],staticClass:"sidebar_outer"}):_vm._e(),(this.$route.name === 'KLEM 3.0')?_c('FilmSelectV2',{directives:[{name:"show",rawName:"v-show",value:(this.$route.name === 'KLEM 3.0'),expression:"this.$route.name === 'KLEM 3.0'"}],staticClass:"sidebar_outer"}):_vm._e(),(this.$route.name === 'Inductor Simulation')?_c('InductorSelect',{directives:[{name:"show",rawName:"v-show",value:(this.$route.name === 'Inductor Simulation'),expression:"this.$route.name === 'Inductor Simulation'"}],staticClass:"sidebar_outer"}):_vm._e(),(this.$route.name === 'Power Array Calculator')?_c('PacInput',{directives:[{name:"show",rawName:"v-show",value:(this.$route.name === 'Power Array Calculator'),expression:"this.$route.name === 'Power Array Calculator'"}],staticClass:"sidebar_outer"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }