const primary = 0.9;
const secondary = 0.6;

const pointStyles = [
    // Colors from: https://flatuicolors.com/palette/au
    /* Some colors could still be modified slightly to show up better and
     have more contrast against other colors. */

    //Dark Grey Line 45deg (index 0 always reserved for Combined in CalcZ)
    {
        point: "line",
        color: [`rgba(83, 92, 104,${primary})`, `rgba(149, 175, 192,1.0)`], // secondary opacity hardcoded
        width: 3,
        radius: 3.7,
        rotation: -45
    },
    //Yellow Circle
    {
        point: "circle",
        color: [`rgba(249, 202, 36,${primary})`, `rgba(246, 229, 141, ${secondary})`],
        width: 0.01, // using 0 results in default = 1.7
        radius: 3.5, // for solid shapes: just set radius
        rotation: 0
    },
    //Kemet Blue Line 45deg
    {
        point: "line",
        color: [`rgba(63, 72, 204,${primary})`, `rgba(63, 72, 204,${secondary})`],
        width: 3,
        radius: 3.7,
        rotation: -45
    },
    //Red Square
    {
        point: "rect",
        color: [`rgba(235, 77, 75,${primary})`, `rgba(255, 121, 121,${secondary})`],
        width: 0.01,
        radius: 4.0,
        rotation: 0
    },
    //Green Cross
    {
        point: "cross",
        color: [`rgba(106, 176, 76,${primary})`, `rgba(186, 220, 88,${secondary})`],
        width: 1.7, // width is useful for cross: dash, line, star
        radius: 4,
        rotation: 0
    },
    //Teal Square 45deg
    {
        point: "rect",
        color: [`rgba(34, 166, 179,${primary})`, `rgba(126, 214, 223,${secondary})`],
        width: 1.7,
        radius: 2.8,
        rotation: 45
    },
    //Pink Star
    {
        point: "star",
        color: [`rgba(190, 46, 221,${primary})`, `rgba(224, 86, 253,${secondary})`],
        width: 0.7,
        radius: 4.5,
        rotation: 0
    },
    //Orange Triangle 180deg
    {
        point: "triangle",
        color: [`rgba(240, 147, 43,${primary})`, `rgba(255, 190, 118,${secondary})`],
        width: 0.01,
        radius: 4,
        rotation: 180
    },
    //Brown Cross 45deg
    {
        point: "cross",
        color: [`rgba(139, 69, 19,${primary})`, `rgba(139, 69, 19,${secondary})`],
        width: 2.3,
        radius: 4,
        rotation: 60
    },
    //Purple Triangle
    {
        point: "triangle",
        color: [`rgba(75,0,130,${primary})`, `rgba(75,0,130,${secondary})`],
        width: 0.01,
        radius: 4.5,
        rotation: 0
    },
    //Dark Grey Line horiz
    {
        point: "line",
        color: [`rgba(19, 15, 40,${primary})`, `rgba(38, 41, 97,${secondary})`],
        width: 3,
        radius: 4,
        rotation: 0
    },
    //Light Grey Line 45deg
    {
        point: "line",
        color: [`rgba(83, 92, 104,${primary})`, `rgba(149, 175, 192,${secondary})`],
        width: 3,
        radius: 3.7,
        rotation: -45
    },
    //Yellow Circle
    {
        point: "circle",
        color: [`rgba(249, 202, 36,${primary})`, `rgba(246, 229, 141, ${secondary})`],
        width: 0.01, // using 0 results in default = 1.7
        radius: 3.5, // for solid shapes: just set radius
        rotation: 0
    },
    //Purple
    {
        point: "triangle",
        color: [`rgba(72, 52, 212,${primary})`, `rgba(104, 109, 224,${secondary})`],
        width: 0.01,
        radius: 4.5,
        rotation: 0
    },
    //Red
    {
        point: "rect",
        color: [`rgba(235, 77, 75,${primary})`, `rgba(255, 121, 121,${secondary})`],
        width: 0.01,
        radius: 4.0,
        rotation: 0
    },
    //Green
    {
        point: "cross",
        color: [`rgba(106, 176, 76,${primary})`, `rgba(186, 220, 88,${secondary})`],
        width: 1.7, // width is useful for cross: dash, line, star
        radius: 4,
        rotation: 0
    },
    //Dark Grey
    {
        point: "line",
        color: [`rgba(19, 15, 64,${primary})`, `rgba(38, 41, 97,${secondary})`],
        width: 3,
        radius: 4,
        rotation: 0
    },
    //Teal
    {
        point: "rect",
        color: [`rgba(34, 166, 179,${primary})`, `rgba(126, 214, 223,${secondary})`],
        width: 1.7,
        radius: 2.8,
        rotation: 45
    },
    //Pink
    {
        point: "star",
        color: [`rgba(190, 46, 221,${primary})`, `rgba(224, 86, 253,${secondary})`],
        width: 0.7,
        radius: 4.5,
        rotation: 0
    },
    //Orange
    {
        point: "triangle",
        color: [`rgba(240, 147, 43,${primary})`, `rgba(255, 190, 118,${secondary})`],
        width: 0.01,
        radius: 4,
        rotation: 180
    }
];

export default pointStyles;