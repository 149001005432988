<template>
    <div class="film-toggle-buttons">
        <p>Application: </p>
        <button class="button-toggle"
                @click="toggle"
                :class="[showVac ? 'active' : '']">
            Vac
        </button>
        <button class="button-toggle"
                @click="toggle"
                :class="[!showVac ? 'active' : '']">
            Vdc
        </button>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue';
    import { createNamespacedHelpers } from 'vuex';
    const { mapActions } = createNamespacedHelpers('filmLifetimeV2');

    export default Vue.extend({
        data() {
            return {
                showVac: true,
            };
        },
        methods: {
            ...mapActions(['setApplicationType', 'fetchLifetimeV2']),
            toggle() {
                this.showVac = !this.showVac;
                this.setApplicationType(this.showVac);
                this.fetchLifetimeV2();
            }
        }
    })
</script>

<style scoped>
    .button-toggle {
        border: none;
        padding: 10px;
        margin: 0;
    }
        .button-toggle:first-of-type {
            border-radius: 5% 0% 0% 5%;
            margin-left: 10px;
        }
        .button-toggle:nth-child(3) {
            border-radius: 0% 5% 5% 0%;
        }
    .film-toggle-buttons {
        margin: 0px 0px 15px 0px;
        display: flex;
        justify-content: flex-start;
        color: #293D82;
    }
        .film-toggle-buttons p {
            color: black;
        }
    .active {
        background-color: #293D82;
        color: white;
        font-weight: bold;
    }
</style>