<template>
  <div class="cap-parts">
    <div v-if="parts.length > 0">
      <table class="cap-table">
        <!--Header-->
        <thead class="cap-thead">
            <tr class="cap-tr">
                <th class="cap-th" title="Click the arrow below to view more information for a part.">Info</th>
                <th class="cap-th" title="Part Number">Part Number</th>
                <th class="cap-th" title="Series: A group of similar parts.">Series</th>
                <th class="cap-th" title="Lead Spacing (aka Pitch): Distance between pins (millimeters).">Pitch (mm)</th>
                <th class="cap-th" title="Capacitance">Cap.</th>
                <th class="cap-th" title="Maximum AC Voltage Rating. V_ac value assumes ~60 Hz sinusoidal signal.">
                    <span v-if="isVac">V<sub>AC</sub></span>
                    <span v-if="!isVac">V<sub>DC</sub></span>
                </th>

                <th class="cap-th" title="Minimum Ambient Temperature (Celcius)">Min (°C)</th>
                <th class="cap-th" title="Maximum Ambient Temperature (Celcius)">Max (°C)</th>
                <th class="cap-th" title="Estimated Lifetime (Years)">Life (Yrs)</th>
                <th class="cap-th" title="Click X to remove part.">Remove</th>
            </tr>
        </thead>
        <!--Rows-->
        <tbody class="cap-tbody">
        <template v-for="(part, index) in parts">
          <tr :key="'part'+index"
              :class="{ 'is-open' : infoOpen.includes(index) }"
              class="cap-tr"
              @click="setHighlight(index)">

            <!--Arrow Icon: spans multiple rows, rendered by `root` part instance-->
            <td title="Tip: press 'ESC' to close all info views."
                class="cap-td"
                @click="showInfo(index); setHighlight(index);">
              <svg class="cap-icon cap-icon_expand">
                <use xlink:href="#arrow-right"></use>
              </svg>
            </td>
            <!--Part Number-->
            <td class="cap-td">
              <a target="_blank" :href="'https://www.kemet.com/en/us/capacitors/ceramic/product/' + part.kemetPn">
                <div v-if="highlight === index">
                  <strong>
                    {{ part.kemetPn }}
                  </strong>
                </div>
                <div v-else>
                  {{ part.kemetPn }}
                </div>
              </a>
            </td>
            <!--Series-->
            <td class="cap-td">
              <span>{{ part.series }}</span>
            </td>
            <!--etc-->
            <td class="cap-td">
              <span>{{ part.pitch }}</span>
            </td>
            <td class="cap-td">
              <span>{{ part.capValue / 1E6 | showUnit }}</span>
            </td>
            <td class="cap-td">
              <span v-if="isVac">{{ part.vac | singlePrecision }}</span>
              <span v-if="!isVac">{{ part.vdc | singlePrecision }}</span>
            </td>
            <td class="cap-td">
              <span>{{ part.minTemp | singlePrecision }}</span>
            </td>
            <td class="cap-td">
              <span>{{ part.maxTemp | singlePrecision }}</span>
            </td>
            <td class="cap-td">
              <span
                  v-if="(isVac && part.status == 'STATIC') || (!isVac && !part.hasDcModel) || lifetimeHoursTotal[index] === 'NaN'">N/A</span>
              <span v-else>{{ lifetimeHoursTotal[index] / (24 * 356) | precision(1) | commas }}</span>
            </td>
            <!--Remove-->
            <td class="cap-td cap-actions">
              <div class="l-inline:center l-box:squish-1">
                                    <span class="e-button:word cap-remove"
                                          css-icon
                                          @click="(e) => { removeCapacitor(part.kemetPn); e.stopPropagation(); }"
                                          title="Remove Part"
                                          style="--loc-button-radius: 2rem;">
                                        &times;
                                    </span>
              </div>
            </td>
          </tr>

          <!--Expanded Information: create row underneath last `child` instance-->
          <tr class="cap-tr cap-info"
              :key="index">
            <td class="cap-td" colspan="100%">
              <div v-if="parts[highlight].ceProps && parts[highlight].ceProps.length < 1"
                   class="cap-info_block" :class="{ 'is-open' : infoOpen.includes(index) }">
                <strong>Additional information is not available for this part.</strong>
              </div>
              <div v-else="parts[highlight].ceProps && parts[highlight].ceProps.length > 0"
                   class="cap-info_block"
                   :class="{ 'is-open' : infoOpen.includes(index) }">
                <!--Level 1-->
                <div class="l-spaced:4" v-if="!part.displayAllCeProps">
                  <div v-for="(ceParam, index) in part.ceProps"
                       :key="'params'+index+'first'"
                       v-if="(ceParam.value !== '') && (ceParam.parameterName !== 'KSIM Support') && (ceParam.parameterName !== 'Unique PN') && (ceParam.step === '1')">
                    <base-table-ce :title="ceParam.parameterName"
                                   :key="'params'+index"
                                   :value="ceParam.value"/>
                  </div>
                </div>
                <!--Level 2-->
                <div class="l-spaced:4" v-else="displayAllCeProps">
                  <div v-for="(ceParam, index) in part.ceProps"
                       :key="'params'+index+'all'"
                       v-if="(ceParam.value !== '') && (ceParam.parameterName !== 'KSIM Support') && (ceParam.parameterName !== 'Unique PN') && ceParam.step === '1'">
                    <base-table-ce :title="ceParam.parameterName"
                                   :key="'params'+index"
                                   :value="ceParam.value"/>
                  </div>

                  <div v-for="(ceParam, index) in part.ceProps"
                       :key="'params'+index+'all'"
                       v-if="(ceParam.value !== '') && (ceParam.parameterName !== 'KSIM Support') && (ceParam.parameterName !== 'Unique PN') && ceParam.step === '2'">
                    <base-table-ce :title="ceParam.parameterName"
                                   :key="'params'+index"
                                   :value="ceParam.value"/>
                  </div>
                </div>

                <!--Toggle Level-->
                <BaseButton v-if="part.ceProps && part.ceProps.length > 0 && part.ceProps.find(p => p.step == '2')"
                            class="displayCeButton" type=":main"
                            @baseButtonClick="callDisplayAllCeProps(index)">
                  {{ part.displayAllCeProps ? 'Display Less' : 'Display More' }}
                </BaseButton>

                <!--Downloads-->
                <base-table-downloads :specsheetUrl="specUrl + part.kemetPn"
                                      :datasheetUrl="dsUrl + part.kemetPn + '.pdf'"
                                      :stepUrl="stepUrl + part.kemetPn + '.step'"
                                      :RoHSUrl="rohsUrl + part.kemetPn + '.pdf'"/>
              </div>
            </td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>
    <!--If no parts-->
    <div v-else class="l-place:center">
      <strong>
        Add a part to the simulation from the left sidebar.
      </strong>
    </div>
  </div>
</template>

<script lang="ts">
import {filterMixin} from "@/mixins/filterMixin";

import {createNamespacedHelpers} from 'vuex';
import Vue from 'vue';
import {CD_URL_BASE_PATH, DATASHEET, ROHS, SPEC, STEP} from "@/util/Urls";

const {mapState, mapActions} = createNamespacedHelpers('filmLifetimeV2');

export default Vue.extend({
  mixins: [filterMixin],
  data() {
    return {
      specUrl: CD_URL_BASE_PATH +SPEC,
      dsUrl: CD_URL_BASE_PATH + DATASHEET,
      stepUrl: CD_URL_BASE_PATH+STEP,
      rohsUrl: CD_URL_BASE_PATH+ROHS,
      infoOpen: [] as number[],
    }
  },
  computed: {
    ...mapState([
      'highlight',
      'componentKey',
      'lifetimeHoursTotal',
      'parts',
      'profileList',
      'displayAllCeProps',
      'isVac',
      'hasDcModel'
    ]),
  },
  filters: {
    singlePrecision: function (value: string) {
      let v = parseFloat(value).toFixed(1);
      if (v.slice(-1) === "0") { // only show decimal if it's not a zero
        return v.slice(0, -2)
      }
      return v
    },
    precision: function (value: string, digits: number) {
      let n = parseFloat(value).toFixed(digits);
      if (n != n) { // check if NaN
        return '0'
      }
      return n
    },
    commas: function (value: string) {
      let returnString = value;
      let indexOfDecimal = returnString.indexOf(".");
      let startIndex = -1;

      if (indexOfDecimal === -1) // no decimals
        startIndex = returnString.length - 3;
      else
        startIndex = indexOfDecimal - 3;

      for (let i = startIndex; i > 0; i = i - 3) {
        returnString = returnString.slice(0, i) + "," + returnString.slice(i);
      }

      return returnString;
    }
  },
  methods: {
    ...mapActions([
      'removeCapacitor',
      'setHighlight',
      'setDisplayAllCeProps',
      'incrementComponentKey'

    ]),
    showInfo: function (rowIndex: any) {
      //If already in list (being shown), remove from list
      if (this.infoOpen.includes(rowIndex)) {
        const idx = this.infoOpen.findIndex(row => row === rowIndex);
        this.infoOpen.splice(idx, 1);
      }
      // Else add to list
      else {
        this.infoOpen.push(rowIndex);
      }
    },
    callDisplayAllCeProps: function (index) {
      this.setDisplayAllCeProps(index);
    },
  },
  mounted() {
    document.body.addEventListener('keyup', e => {
      if (e.keyCode === 27) { // esc
        this.removeCapacitor();
      }
    });
  },
  watch: {
    parts: {
      handler: function () {
        if (this.parts.length > 0) {
          this.incrementComponentKey();
        }
      },
      deep: false,
      immediate: false
    },
  }
});
</script>

<style>

.cap-parts {
  margin-bottom: 2rem;
}

.cap-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1);
}

.cap-tr {
  box-shadow: inset 1px 0 0 rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s ease-out;

  &.is-open {
    background-color: hsla(206, 60%, 47%, 0.14);
  }

}

.cap-th,
.cap-td {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  text-align: center;
  box-shadow: inset -1px 1px 0 rgba(0, 0, 0, 0.1);
}

.cap-th {
  color: #fff;
  /* background-color: var(--color-main); */
  background-color: #3081c0;
}

.cap-legend {
  width: 1rem;
  height: 1rem;
}

.cap-td .l-field {
  margin-bottom: 0;
}

.cap-info {
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -8px 8px -8px rgba(0, 0, 0, 0.15), inset 0 8px 8px -8px rgba(0, 0, 0, 0.15);
}

.cap-info_block {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  will-change: max-height;

  &.is-open {
    max-height: 1000px;
  }

}

.cap-remove.cap-remove {
  color: var(--color-main);
  font-weight: normal;
  font-size: 2rem;
  cursor: pointer;
}

.cap-icon {
  width: 1.35rem;
  height: 1.35rem;
  color: var(--color-main);
  transition: all 0.2s ease-out;
}

.cap-icon_expand {
  position: relative;
  top: 3px;
}

.is-open .cap-icon_expand {
  transform: rotate(90deg);
}
</style>