<template>
    <div class="slider-container">
        <h4 class="slider-title">{{title}}</h4>
        <div class="slider">
            <button type="button" @click="decreaseOutput" :class="{disabled: minDisabled}" :disabled="minDisabled">-</button>
            <input type="range" name="slider" class="slider" :min="min" :max="setMaxValue" :step="step" v-model.number="output" />
            <button type="button" @click="increaseOutput" :class="{disabled: maxDisabled}" :disabled="maxDisabled">+</button>
        </div>
        <div>
            <input type="number" class="output" v-model.number="output" :step="step" @change="checkBoundaries" />
        </div>
    </div>
</template>

<script lang="ts">
    import vue from 'vue';
    export default vue.extend({
        props: {
            title: {
                type: String,
                required: true
            },
            min: {
                type: Number,
                required: true
            },
            max: {
                type: Number,
                required: true
            },
            step: {
                type: Number,
                required: true
            },
            value: {
                type: Number,
                required: true
            }
        },
        data() {
            return {
                minDisabled: false,
                maxDisabled: false,
                output: this.value,
                setMaxValue: 0,
            }
        },
        created() {
            this.setMaxValue = this.max;
        },
        watch: {
            output: function () {
                this.minDisabled = this.min == this.output;
                this.maxDisabled = this.output == this.setMaxValue;
                this.$emit('change', this.title, this.output);
            },
        },
        methods: {
            checkBoundaries() {
                if (this.output < this.min) {
                    this.output = this.min;
                } else if (this.output > this.setMaxValue) {
                    this.output = this.setMaxValue;
                } else if (String(this.output).length == 0) {
                    this.output = this.min;
                }
            },
            convertOutputToNum(data) {
                return Number(data);
            },
            decreaseOutput() {
                if (this.output > this.min) {
                    let convertedOutput = this.convertOutputToNum(this.output);
                    convertedOutput -= this.step;
                    this.output = +convertedOutput.toFixed(6);
                }
            },
            increaseOutput() {
                if (this.output < this.setMaxValue) {
                    let convertedOutput = this.convertOutputToNum(this.output);
                    convertedOutput += this.step;
                    this.output = +convertedOutput.toFixed(6);
                }
            }
        }
    })
</script>

<style lang="postcss" scoped>
    input[type=range] {
        -webkit-appearance: none;
        margin: 18px 0;
        width: 80px;
    }

    p {
        margin: 0;
    }

    .slider-title {
        font-size: 12px;
    }

    .output {
        font-size: 12px;
        border: none;
        text-align: center;
    }

        .output:focus {
            font-weight: 500;
            outline: none;
        }

    .slider button {
        border-radius: 50%;
        height: 22px;
        width: 22px;
        border: 1px solid var(--color-gray-lighter);
        font-size: 12px;
        background-color: white;
        font-weight: bold;
        margin: 2px;
        box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, .1);
        outline: none;
        color: var(--color-main);
    }

        .slider button:hover {
            background-color: var(--color-main);
            color: white;
        }

    input[type=range]:focus {
        outline: none;
    }

    input[type=range]::-webkit-slider-runnable-track {
        width: 100%;
        height: 5px;
        cursor: pointer;
        background: #9FBBD4;
        border-radius: 5px;
    }

    input[type=range]::-webkit-slider-thumb {
        box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, .2);
        height: 26px;
        width: 26px;
        border-radius: 50%;
        background: var(--color-main);
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -10px;
    }

    .slider-container {
        text-align: center;
        border: 1px solid var(--color-gray-lighter);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-content: center;
        padding: 5px;
        margin-right: 20px;
        margin-top: 10px;
    }

    button.disabled {
        color: var(--color-gray-lighter);
    }

        button.disabled:hover {
            background-color: white;
            color: var(--color-gray-lighter);
        }

    ::-moz-range-track {
        width: 100%;
        height: 5px;
        cursor: pointer;
        background: #9FBBD4;
        border-radius: 5px;
    }

    ::-moz-range-thumb {
        box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, .2);
        height: 26px;
        width: 26px;
        border-radius: 50%;
        background: var(--color-main);
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -10px;
        border: none;
    }

    .slider {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
</style>