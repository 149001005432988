<template>
    <div>
        <div>
            <h1>Login</h1>
            <form @submit.prevent="submit">
                <input placeholder="Login" type="text" v-model="username">
                <input placeholder="Password" type="password" v-model="password">
                <button type="submit">Login</button>
            </form>
        </div>
        <div>
            <p>If you are not re-directed to the admin page, your login was not successful.</p>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue';
    export default Vue.extend({
        data() {
            return {
                username: "",
                password: "",
            }
        },
        methods: {
            submit() {
                this.$auth.login({
                    username: this.username,
                    password: this.password,
                });
            }
        }
    })
</script>

<style lang="postcss">
    .hm-empty {
        width: 100%;
        height: 100%;
    }

    .hm-empty_svg {
        width: 20vw;
        min-width: 200px;
        height: 20vw;
        min-height: 200px;
    }
</style>