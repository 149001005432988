<template>
    <div>
        <div class="slider-row">
            <template v-for="(prop, index) in parts[highlight].seedProps">
                    <template v-if="(prop.simulation === true) && (prop.inputType === 'slider') && (prop.value != null)" >
                        <Component :is="'base-input-'+prop.inputType"
                                   :title="prop.title"
                                   :min="prop.min"
                                   :max="prop.max"
                                   :step="prop.step"
                                   :value="prop.value"
                                   v-model="prop.value"
                                   @change="onChange"/>
                    </template>
            </template>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue from "vue";

    //State
    import { createNamespacedHelpers } from 'vuex';
    const { mapState } = createNamespacedHelpers('seed');
    import BaseInputSlider from "@/components/_base/base-input-slider.vue";

    export default Vue.extend({
        data() {
            return {
            }
        },
        computed: {
            ...mapState([
                'highlight',
                'parts',
            ]),
        },
        methods: {
            onChange(title, output) {
                this.$emit("change", title, output)
            }
        },
    });
</script>

<style>
    .slider-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
</style>