<template>
    <div class="baseInputNumber l-field">
        <label :title="name" class="e-label">
            {{title}}
            <span v-if="isRequired">*</span>
        </label>
        <input class="e-input"
               ref="input"
               :disabled="disabled"
               :style="baseStyle"
               v-model.number="local_value"
               pattern="-?([0-9]*[.,]?[0-9]*?)?"
               :width="width"
               :required="isRequired ? true : false"
               v-on:keydown.up="increaseVal"
               v-on:keydown.down="decreaseVal"
               @change="checkForValue" />
        <span class="e-validation">Out of range</span>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue';

    export default Vue.extend({
        inheritAttrs: false,
        props: {
            isRequired: {
                type: Boolean,
                required: false,
                default: false
            },
            value: {
                type: Number,
                required: true
            },
            max: {
                type: Number,
                required: true,
            },
            min: {
                type: Number,
                required: true,
            },
            step: {
                type: Number,
                required: false,
            },
            title: {
                type: String,
                required: false
            },
            width: {
                type: Number,
                required: false,
                default: 50
            },
            disabled: {
                type: Boolean,
                required: false,
                default: false
            },
            name: {
                type: String,
                required: false
            }
        },
        data() {
            return {
                local_value: this.value,
                baseStyle: { 'width': this.width + 'ch' } // use in CSS
            }
        },
        methods: {
            checkForValue() {
                if (this.local_value === "-") {
                    let value = this.$refs.input;
                    value.setCustomValidity("Error");
                }
            },
            increaseVal(event) {
                event.preventDefault();
                let val = parseFloat((this.local_value += this.step).toFixed(6));
                this.local_value = val;
            },
            decreaseVal() {
                let val = parseFloat((this.local_value -= this.step).toFixed(6));
                this.local_value = val;
            },
            numberValidation() {
                let value = this.$refs.input;
                value.setCustomValidity("");
                if (this.local_value == "-") {
                    if (this.min >= 0) {
                        value.setCustomValidity("Error");
                    }
                } else if (this.min <= this.local_value && this.local_value <= this.max) {
                    this.$emit('change', this.local_value)
                } else {
                    value.setCustomValidity("Error");
                }
            },
        },
        watch: {
            "local_value": {
                handler: function () {
                    this.numberValidation();
                }
            },
        }
    })
</script>

<style scoped>
    .baseInputNumber {
        text-align: center;
    }

    .e-input {
        width: var(--size3);
        appearance: textfield;
        &::-webkit-inner-spin-button

    {
        -webkit-appearance: none;
    }
    }
</style>