<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div class="modal-header">
                        <slot name="header">
                            default header
                        </slot>
                    </div>

                    <div class="modal-body">
                        <slot name="body">
                            default body
                        </slot>
                    </div>

                    <div class="modal-footer">
                        <slot name="footer">

                        </slot>
                        <div class="modal-footer_close">
                            <BaseButton class="modal-default-button" v-on:baseButtonClick="$emit('close')">
                                Close
                            </BaseButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script lang="ts">
    import Vue from 'vue';
    export default Vue.extend({
    });
</script>

<style lang="postcss">
    .modal-mask {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 11;
        display: table;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        transition: opacity 0.3s ease;
    }

    .modal-wrapper {
        z-index: 12;
        display: table-cell;
        vertical-align: middle;
    }

    .modal-container {
        width: 75vw;
        margin: 0px auto;
        padding: 20px 30px;
        font-family: Helvetica, Arial, sans-serif;
        background-color: #fff;
        transition: all 0.2s ease;
        border-radius: 10px;
        box-shadow: 0 20px 80px rgba(0, 0, 0, 0.33);
    }

    .modal-header h2 {
        margin-top: 0;
        color: #3f48cc;
    }

    .modal-body {
        margin: 20px 0;
    }

    .modal-footer_close {
        display: flex;
        justify-content: flex-end;
    }

    /*
     * The following styles are auto-applied to elements with
     * transition="modal" when their visibility is toggled
     * by Vue.js.
     *
     * You can easily play with the modal transition by editing
     * these styles.
     */

    .modal-enter {
        opacity: 0;
    }

    .modal-leave-active {
        opacity: 0;
    }

        .modal-enter .modal-container,
        .modal-leave-active .modal-container {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }
</style>