<template>
</template>

<script lang="ts">
// External
import {parse} from 'flatted';

// Internal
import initialSeedState from '@/store/util/InitialSeedState';
import {instance} from "@/util/Alerts";
import Vue from 'vue';

export default Vue.extend({
  created() {
    // Load share link
    if (this.$route.params.id) {
      instance.post('/api/share/load', {
        id: this.$route.params.id
      })
          .then((response) => {
            // Support backwards compatibility
            let state = parse(response.data.state) as RootState;

            //// Check if Chart.js Icon Recycling is implemented
            let recyclingImplemented = true;
            if (!state.calcZ.hasOwnProperty('partStyleApplied') && !state.calcZ.hasOwnProperty('partStyleAvailable')) {
              recyclingImplemented = false;
              state.calcZ.partStyleApplied = [0];
              state.calcZ.partStyleAvailable = [19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1];
              state.calcL.partStyleApplied = [0];
              state.calcL.partStyleAvailable = [19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1];
            }

            //// CapacitorModel
            ////// Check for old duplicate & clone properties
            if (state.calcZ.parts[0].hasOwnProperty('duplicate') && state.calcZ.parts[0].hasOwnProperty('clone')) {
              for (let part of state.calcZ.parts) {
                // Use new instance property
                // @ts-ignore
                delete part.duplicate, part.clone;
                if (part.basePn === "Combined") {
                  part.instances = 0;
                } else {
                  part.instances = 1;
                  part.param.qty.width = 6; // cosmetic
                }
                // Apply chart styles
                if (!recyclingImplemented) {
                  const styleIndex = state.calcZ.partStyleAvailable.pop() as number;
                  state.calcZ.partStyleApplied.push(styleIndex);
                }
              }
            }
            ////// Check for maxTemp as type string
            if (typeof (state.calcZ.parts[0].maxTemp) === 'string') {
              state.calcZ.parts[0].maxTemp = 155;
            }
            ////// Check for TCC/VCAC
            if (!state.calcZ.parts[0].hasOwnProperty('tcc') && !state.calcZ.parts[0].hasOwnProperty('vcac')) {
              for (let part of state.calcZ.parts) {
                part.tcc = "";
                part.vcac = "";
              }
            }

            //// InductorModel
            ////// Check for old duplicate & clone properties
            if (state.calcL.parts.length > 0) { // IndSim we don't have Combined part, check to see if we have any parts first
              if (state.calcL.parts[0].hasOwnProperty('duplicate') && state.calcL.parts[0].hasOwnProperty('clone')) {
                for (let part of state.calcL.parts) {
                  // @ts-ignore
                  delete part.duplicate, part.clone;
                  part.instances = 1;
                  if (!recyclingImplemented) {
                    const styleIndex = state.calcL.partStyleAvailable.pop() as number;
                    state.calcL.partStyleApplied.push(styleIndex);
                  }
                }
              }
            }

            // Add seed state module if missing
            state.seed = initialSeedState;

            // Restore state
            this.$store.replaceState(state);
            // Restore route
            this.$router.push(response.data.route);
          });
    }
  },
})
</script>

<style lang="postcss">
</style>