import {ActionContext} from "vuex";
import {instance} from "@/util/Alerts";
import {pacSeriesUrl, pacUrl} from "@/util/Urls";

type PowerArrayCalculatorContext = ActionContext<PowerArrayCalculatorState, RootState>;

const state = (): PowerArrayCalculatorState => ({
    minCap: 0,
    maxCap: 0,
    acVoltage: 0,
    acCurrent: 0,
    frequency: 0,
    arraySeries: 10,
    arrayParallel: 10,
    partnumbers: "",
    v1: 0,
    v2: 0,
    series: ["HV C0G", "KC-LINK", "KC-LINK KONNEKT"],
    powerArrayResults: new Array<PacResultModel>(),
    powerArrayRequest: {} as PacRequestModel,
    searchResult: false,
    sortOrder: true,
    capUnit: "nf",
    freqUnit: "khz",
    seriesOptions: [] as BaseDropdownOption[],
    isLoading: false,
    lowestSinglePrice: 0,
    lowestTotalPrice: 0,
    stockFilter: "all",
    caseSize: ["1206", "1210", "1812", "2220", "3640"],
    searchInput: "",
});

const getters = {
    getAcVoltage(state: PowerArrayCalculatorState) {
        return state.acVoltage
    }
};

const mutations = {
    setSearchInput(state: PowerArrayCalculatorState, payload: string) {
        state.searchInput = payload;
    },
    setMinCap(state: PowerArrayCalculatorState, payload: number) {
        state.minCap = payload;
    },
    setMaxCap(state: PowerArrayCalculatorState, payload: number) {
        state.maxCap = payload;
    },
    setAcVoltage(state: PowerArrayCalculatorState, payload: number) {
        state.acVoltage = payload;
    },
    setAcCurrent(state: PowerArrayCalculatorState, payload: number) {
        state.acCurrent = payload;
    },
    setFrequency(state: PowerArrayCalculatorState, payload: number) {
        state.frequency = payload;
    },
    setArraySeries(state: PowerArrayCalculatorState, payload: number) {
        state.arraySeries = payload;
    },
    setArrayParallel(state: PowerArrayCalculatorState, payload: number) {
        state.arrayParallel = payload;
    },
    setPartNumber(state: PowerArrayCalculatorState, payload: string) {
        state.partnumbers = payload;
    },
    setStockFilter(state: PowerArrayCalculatorState, payload: string) {
        state.stockFilter = payload;
    },
    setV1(state: PowerArrayCalculatorState, payload: number) {
        state.v1 = payload;
    },
    setV2(state: PowerArrayCalculatorState, payload: number) {
        state.v2 = payload;
    },
    setIsLoading(state: PowerArrayCalculatorState, payload: boolean) {
        state.isLoading = payload;
    },
    setSeries(state: PowerArrayCalculatorState, payload: string[]) {
        state.series = payload;
    },
    setCaseSize(state: PowerArrayCalculatorState, payload: string[]) {
        state.caseSize = payload;
    },
    setCapUnit(state: PowerArrayCalculatorState, payload: string) {
        state.capUnit = payload;
    },
    setFreqUnit(state: PowerArrayCalculatorState, payload: string) {
        state.freqUnit = payload;
    },
    setSortOrder(state: PowerArrayCalculatorState, payload: boolean) {
        state.sortOrder = payload;
    },
    setPacResult(state: PowerArrayCalculatorState, payload: PacResultModel[]) {
        state.powerArrayResults = payload;
    },

    setPowerArrayCalculatorResults(
        state: PowerArrayCalculatorState,
        payload: PacResultModel
    ) {
        state.powerArrayResults.push(payload)
    },

    clearPowerArrayResults: (state: PowerArrayCalculatorState) => {
        state.powerArrayResults = [];
    },
    setSearchReault(state: PowerArrayCalculatorState, payload: boolean) {
        state.searchResult = payload;
    },
    setSeriesOptions(state: PowerArrayCalculatorState, payload: BaseDropdownOption[]) {
        state.seriesOptions = payload;
    },
    setLowestSinglePrice(state: PowerArrayCalculatorState, payload: number) {
        state.lowestSinglePrice = payload;
    },
    setLowestTotalPrice(state: PowerArrayCalculatorState, payload: number) {
        state.lowestTotalPrice = payload;
    },
};

const actions = {
    setSearchInput(context: PowerArrayCalculatorContext, payload: string) {
        payload = payload.toUpperCase().trim()
        context.commit("setSearchInput", payload);
    },
    setPacResult(context: PowerArrayCalculatorContext, payload: PacResultModel[]) {
        context.commit("setPacResult", payload);
    },
    setPowerArrayTable(context: PowerArrayCalculatorContext, payload: PacResultModel[]) {
        context.commit("setPowerArrayTable", payload);
    },
    setMinCap(context: PowerArrayCalculatorContext, payload: number) {
        context.commit("setMinCap", payload);
    },
    setMaxCap(context: PowerArrayCalculatorContext, payload: number) {
        context.commit("setMaxCap", payload);
    },
    setAcVoltage(context: PowerArrayCalculatorContext, payload: number) {
        context.commit("setAcVoltage", payload);
    },
    setAcCurrent(context: PowerArrayCalculatorContext, payload: number) {
        context.commit("setAcCurrent", payload);
    },
    setFrequency(context: PowerArrayCalculatorContext, payload: number) {
        context.commit("setFrequency", payload);
    },
    setArraySeries(context: PowerArrayCalculatorContext, payload: number) {
        context.commit("setArraySeries", payload);
    },
    setArrayParallel(context: PowerArrayCalculatorContext, payload: number) {
        context.commit("setArrayParallel", payload);
    },
    setPartNumber(context: PowerArrayCalculatorContext, payload: string) {
        context.commit("setPartNumber", payload);
    },
    setSeries(context: PowerArrayCalculatorContext, payload: string[]) {
        context.commit("setSeries", payload);
    },
    setCaseSize(context: PowerArrayCalculatorContext, payload: string[]) {
        context.commit("setCaseSize", payload);
    },
    setV1(context: PowerArrayCalculatorContext, payload: number) {
        context.commit("setV1", payload);
    },
    setV2(context: PowerArrayCalculatorContext, payload: number) {
        context.commit("setV2", payload);
    },
    setIsLoading(context: PowerArrayCalculatorContext, payload: boolean) {
        context.commit("pac/setIsLoading", payload)
    },
    setCapUnit(context: PowerArrayCalculatorContext, payload: number) {
        context.commit("setCapUnit", payload);
    },
    setFreqUnit(context: PowerArrayCalculatorContext, payload: number) {
        context.commit("setFreqUnit", payload);
    },
    setLowestTotalPrice(context: PowerArrayCalculatorContext, payload: number) {
        context.commit("setLowestTotalPrice", payload);
    },
    setLowestSinglePrice(context: PowerArrayCalculatorContext, payload: number) {
        context.commit("setLowestSinglePrice", payload);
    },
    setSortOrder(context: PowerArrayCalculatorContext, payload: boolean) {
        context.commit("setSortOrder", payload);
    },
    setStockFilter(context: PowerArrayCalculatorContext, payload: string) {
        context.commit("setStockFilter", payload)
    },
    setSearchReault(context: PowerArrayCalculatorContext, payload: boolean) {
        context.commit("setSearchReault", payload);
    },
    clearPowerArrayResults(context) {
        context.commit('PowerArrayResults');
    },
    getSeriesOptions(context: PowerArrayCalculatorContext) {
        const opts = [] as BaseDropdownOption[];
        instance.get(pacSeriesUrl).then((response) => {
            opts.push({
                value: "",
                label: "All",
                disabled: false
            } as BaseDropdownOption);
            for (let i = 0; i < response.data.length; i++) {
                opts.push({
                    value: response.data[i],
                    label: response.data[i],
                    disabled: false
                } as BaseDropdownOption);
            }
            context.commit("setSeriesOptions", opts);
        });
    },
    getPowerArrayCalculatorResults(
        context: PowerArrayCalculatorContext,
        payload: PacRequestModel
    ) {
        context.commit("setIsLoading", true)
        instance.post(pacUrl, {
            partnumbers: payload.partnumbers,
            requirements: {
                minCap: payload.minCap,
                maxCap: payload.maxCap,
                Vrms: payload.acVoltage,
                Arms: payload.acCurrent,
                frequency: payload.frequency,
                maxNumberInSeries: payload.arraySeries,
                maxNumberInParallel: payload.arrayParallel,
                size: {
                    CompareWidth: payload.v1,
                    CompareLength: payload.v2,
                },
                series: payload.series,
                CapUnit: payload.capUnit,
                FreqUnit: payload.freqUnit,
            }
        }, {
            responseType: "json"
        })
            .then((response) => {
                context.commit("setSearchReault", true)
                const caseSize = payload.caseSize

                let caseSizeString: string = ""
                if (caseSize.length > 1) {
                    caseSizeString = caseSize.join(",")
                } else {
                    caseSizeString = caseSize[0]
                }
                const pacResults = [] as PacResultModel[]
                let mouserPriceList = [] as object[]
                let digiKeyPriceList = [] as object[]
                let mouserStock = 0 as number
                let digiKeyStock = 0 as number
                let lowestSinglePrice = 0 as number
                let lowestTotalPrice = 0 as number
                context.commit("clearPowerArrayResults");

                for (let key in response.data) {

                    for (let childKey in response.data[key]["ParallelArrayStats"]) {
                        let totalStock = false as boolean


                        for (let priceKey in response.data[key]["PriceInfo"]["SellerInfoList"]) {
                            if (response.data[key]["PriceInfo"]["SellerInfoList"][priceKey].Seller === "Mouser") {
                                mouserPriceList = response.data[key]["PriceInfo"]["SellerInfoList"][priceKey]["PricesList"]
                                mouserStock = response.data[key]["PriceInfo"]["SellerInfoList"][priceKey].StockQuantity
                                if (mouserStock > 0) {
                                    totalStock = true
                                }
                            } else if (response.data[key]["PriceInfo"]["SellerInfoList"][priceKey].Seller === "DigiKey") {
                                digiKeyPriceList = response.data[key]["PriceInfo"]["SellerInfoList"][priceKey]["PricesList"]
                                digiKeyStock = response.data[key]["PriceInfo"]["SellerInfoList"][priceKey].StockQuantity
                                if (digiKeyStock > 0) {
                                    totalStock = true
                                }
                            }
                        }


                        if (lowestTotalPrice === 0 && lowestSinglePrice === 0) {
                            lowestSinglePrice = response.data[key]["PriceInfo"].DistyPrice
                            lowestTotalPrice = response.data[key]["ParallelArrayStats"][childKey].SolnPrice
                        }
                        if (response.data[key]["PriceInfo"].DistyPrice != 0 && response.data[key]["PriceInfo"].DistyPrice < lowestSinglePrice) {
                            lowestSinglePrice = response.data[key]["PriceInfo"].DistyPrice
                        }
                        if (response.data[key]["ParallelArrayStats"][childKey].SolnPrice != 0 && response.data[key]["ParallelArrayStats"][childKey].SolnPrice < lowestTotalPrice) {
                            lowestTotalPrice = response.data[key]["ParallelArrayStats"][childKey].SolnPrice
                        }
                        if (response.data[key].ChipSize!=""&&caseSizeString.includes(response.data[key].ChipSize.toString().split('-')[0])) {
                            pacResults.push({
                                partNumber: response.data[key].Pn,
                                footPrint: response.data[key]["ParallelArrayStats"][childKey].FootPrint,
                                chipSize: response.data[key].ChipSize,
                                singlePrice: response.data[key]["PriceInfo"].DistyPrice,
                                totalPrice: response.data[key]["ParallelArrayStats"][childKey].SolnPrice,
                                seriesParallelCombination: response.data[key]["ParallelArrayStats"][childKey].NumberSeries + "s" +
                                    response.data[key]["ParallelArrayStats"][childKey].NumberParallel + "p",
                                totalMlccs: response.data[key]["ParallelArrayStats"][childKey].NumberSeries * response.data[key]["ParallelArrayStats"][childKey].NumberParallel,
                                arrayCapacitance: response.data[key]["ParallelArrayStats"][childKey].Capacitance,
                                arrayVoltageRating: response.data[key]["ParallelArrayStats"][childKey].ARRAY_VOLTAGE_RATING,
                                arrayVoltage: response.data[key]["ParallelArrayStats"][childKey].ARRAY_VOLTAGE,
                                arrayCurrentRating: response.data[key]["ParallelArrayStats"][childKey].Current,
                                mlccVoltageRating: response.data[key]["ParallelArrayStats"][childKey].MLCC_VOLTAGE_RATING,
                                mlccVoltage: response.data[key]["ParallelArrayStats"][childKey].MLCC_VOLTAGE,
                                mlccCurrentRating: response.data[key]["ParallelArrayStats"][childKey].MLCC_CURRENT_RATING,
                                mlccCurrent: response.data[key]["ParallelArrayStats"][childKey].MLCC_CURRENT,
                                mouserPriceList: mouserPriceList,
                                digiKeyPriceList: digiKeyPriceList,
                                mouserStock: mouserStock,
                                digiKeyStock: digiKeyStock,
                                totalStock: totalStock,
                                series: response.data[key].ApplicationDef,
                            } as PacResultModel);

                        }
                        mouserPriceList = []
                        digiKeyPriceList = []
                    }
                }
                context.commit('setPacResult', pacResults)
                context.commit('setLowestSinglePrice', lowestSinglePrice)
                context.commit('setLowestTotalPrice', lowestTotalPrice)
                context.commit("setIsLoading", false)
            });

    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
