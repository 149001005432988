<template>
     <span>
       <svg class="ind-icon ind-icon_expand" @click="rotation" :class="{ clicked: isClicked }" style="color: white">
         <use xlink:href="#arrow-down"></use>
       </svg>
     </span>
</template>

<script lang="ts">

// State
import {createNamespacedHelpers} from "vuex";
// Components

// Local
import Vue from "vue";

const {mapState, mapGetters, mapActions} = createNamespacedHelpers("pac");


export default Vue.extend({


  components: {}, data() {
    return {
      isClicked: false
    }
  },

  methods: {
    ...mapActions([]),
    rotation() {
      this.isClicked = !this.isClicked
    }
  }

});
</script>

<style>


.clicked {
  transform: rotate(180deg);
}
</style>
