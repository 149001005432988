
import { duplicatePart } from "@/store/util/PartsMutations"
import { logPartUrl } from "@/util/Urls"

import { ActionContext } from "vuex";
import {instance} from "@/util/Alerts";
type CalcLContext = ActionContext<CalcLState, RootState>;

const maxParts = 10;

const state = (): CalcLState => ({
    componentKey: 0,
    highlight: 0,
    parts: new Array<InductorModel>(),
    partStyleApplied: [] as number[],
    partStyleAvailable: [19, 18, 17, 16, 15, 14, 13, 12, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1],
    plotType: {
        title: "Chart Type",
        options: [
            { label: "Inductance", value: "inductance", disabled: false },
            { label: "Power Loss", value: "split_power_loss", disabled: false },
            { label: "Temperature Rise", value: "deltatemp", disabled: false }
        ],
        select: "inductance" /* appended to end of CalcL API URL */
    } as InputDropdown,
    lossCurves: {
        title: "Curves",
        options: [
            { value: "0", label: "DC" } as BaseDropdownOption,
            { value: "1", label: "AC" } as BaseDropdownOption,
            { value: "2", label: "Core" } as BaseDropdownOption,
            { value: "3", label: "Total" } as BaseDropdownOption,
        ] as BaseDropdownOption[],
        select: ["3"],
    } as InputCheckbox,

});

const getters = {
    /* Created to avoid a deep watcher on "parts" */
    partsLength(state: CalcLState) {
        return state.parts.length;
    },
    /* Create getter for a single object because BasePlot takes single object*/
    bodyArray(state: CalcLState) { /* JSON object for CalcL API POST operation*/
        const arr = [] as any[]; // can define type later
        const p = state.parts;
        for (let i = 0; i < state.parts.length; i++) {
            const o = {
                requester_id: "ksim",
                plot_type: state.plotType.select,
                part_number: p[i].pn,
                frequency: p[i].param.frequency.select * 1000, // text box labeled kHz
                max_current: p[i].param.currentOutput.select,
                vin: p[i].param.voltageInput.select,
                vout: p[i].param.voltageOutput.select
            };
            arr.push(o);
        }
        return arr;
    },
    //can just use body ?
    individualParameters(state: CalcLState) {
        // This is only used to trigger a watcher instead of using a deep watcher on the parts array
        let s = "";
        for (let i = 0; i < state.parts.length; i++) {
            s += state.parts[i].param.frequency.select + ",";
            s += state.parts[i].param.voltageOutput.select + ",";
            s += state.parts[i].param.currentOutput.select + ",";
            s += state.parts[i].param.temperatureAmbient.select + ",";
            s += state.parts[i].param.voltageInput.select + ";";
        }
        return s;
    }
};

const mutations = {
    addPart(state: CalcLState, payload: InductorModel) {
        state.parts.push(payload);

        const logBody = { partNumber: payload.pn, method: "fromList-ind", found: true, session: window.localStorage.getItem('uuid') };
        instance.post(logPartUrl, logBody);

        //assign style index
        const styleIndex = state.partStyleAvailable.pop() as number;
        state.partStyleApplied.push(styleIndex);

        if (state.parts.length === 1) state.highlight = 0;
    },
    duplicatePart,
    removePart(state: CalcLState, payload: number) {
        // If highlight last part, decrement highlight to prevent invalid index
        if (state.highlight === state.parts.length - 1) state.highlight--;

        // Handling duplicates
        if (state.parts[payload].instances > 0) { // if root part
            if (state.parts[payload].instances > 1) { // if also more than once instance
                state.parts[payload + 1].instances = state.parts[payload].instances - 1;
            }
        }
        else { // part removed is not root
            let rootPartIndex = -1;
            for (let i = 0; i < state.parts.length; i++) { // Check each part
                if (state.parts[i].pn === state.parts[payload].pn) { // Identify based on part number
                    rootPartIndex = i;
                    break;
                }
            }
            if (rootPartIndex !== -1) {
                state.parts[rootPartIndex].instances--;
            }
        }

        // Recycle style
        state.partStyleAvailable.push(state.partStyleApplied[payload]);
        state.partStyleApplied.splice(payload, 1);

        // Remove part
        state.parts.splice(payload, 1);
        state.componentKey++; //force render hack
    },
    incrementComponentKey(state: CalcLState) {
        state.componentKey++;
    },
    setCeDetails(state: CalcLState, payload: { pn: string, ceProps: ComponentEdgeParameterDto }) {
        for (let i = 0; i < state.parts.length; i++) {
            if (state.parts[i].pn === payload.pn) { // Find the cap 
                state.parts[i].ceProps = payload.ceProps; // and add the details
            }
        }
    },
    setHighlight(state: CalcLState, payload: number) {
        state.highlight = payload;
    },
};

const actions = {
    addPart(context: CalcLContext, payload: InductorModel) {
        if (context.state.parts.length < maxParts) {
            let partExists = false; // Assume part not in list to begin
            if (context.state.parts != null) { // If list not empty, let's search it
                for (let i = 0; i < context.state.parts.length; i++) { // Check each part
                    if (context.state.parts[i].pn === payload.pn) { // Identify based on part number
                        partExists = true; // If match, we won't add part below 
                    }
                }
            }
            if (!partExists) {
                context.commit("addPart", payload);
                context.dispatch("fetchCeProps", (payload));
            }
        } else {
            alert(`You cannot add any more parts. The maximum is ${maxParts}.`);
            context.commit("incrementComponentKey"); // trigger the CapacitorResults to render again so that the box doesn't get checked
        }
    },
    duplicatePart(context: CalcLContext, payload: number) {
        if (context.state.parts.length < maxParts) {
            context.commit("duplicatePart", payload);
        }
        else {
            alert(`You cannot add any more parts. The maximum is ${maxParts}.`);
        }
    },
    fetchCeProps(context: CalcLContext, payload: { pn: string, capType: string }) {
        instance.post("/api/ce/params/", { partNumber: payload.pn, capType: "Inductors" } as ComponentEdgeParameterRequest)
            .then((response) => {
                let pn = payload.pn;
                context.commit("setCeDetails", { pn, ceProps: response.data });
            });
    },
    clearPlotDatasets(context: CalcLContext) {
        context.commit("clearPlotDatasets");
    },
    resetPlotData(context: CalcLContext) {
        context.commit("resetPlotData");
    },
    removePartByPn(context: CalcLContext, payload: string) {
        // Go backwards - we want to delete root part last
        for (let i = context.state.parts.length - 1; i >= 0; i--) {
            if (context.state.parts[i].pn === payload) {
                context.commit("removePart", i);
                break;
            }
        }
    },
    removePartByIndex(context: CalcLContext, index: number) {
        context.commit("removePart", index);
    },
    incrementComponentKey(context: CalcLContext) {
        context.commit("incrementComponentKey");
    },
    setHighlight(context: CalcLContext, payload: number) {
        context.commit("setHighlight", payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}

