<template>
  <div class="e-sidebar_panel">
    <Film/>
  </div>
</template>

<script lang="ts">
import Film from './Film.vue';

import Vue from 'vue';
export default Vue.extend({
  components: {
    Film
  },
})
</script>

<style scoped>
.e-sidebar_type,
.e-label {
  width: calc(100% - 2rem);
  margin-right: 1rem;
  margin-left: 1rem;
}
</style>